import React from 'react';
import '../Pages/Gallery.css';

const Gallery = ({ images }) => {
  return (
    <div className='container-fluid'>
    <div className="gallery-container">
      {images.map((image) => (
        <img
          src={image.src}
          alt={image.alt}
          key={image.id}
          className="gallery-image"
        />
      ))}
    </div>
    </div>
  );
};

export default Gallery;