/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios"
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import {
  Row,
  // , CardSubtitle
} from "reactstrap";
require("dotenv").config();

import Back from "../components/Back"
import Throbber from "../components/Pages/Throbber"
import SingleCard from "./Card";


function ForeignPackage(props) {
  const [propMainPageOptions, propSetmainPageOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  var parLoc = props.match.params.locationname;
  // To Specify all property ids below the main locations
  useEffect(() => {
    async function fetchAllPropertyDataForLocation() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(
          process.env.REACT_APP_DB_URL + "/for/allproperties/getdata/" + parLoc
        );
        // console.log("For all locations", req.data);
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // console.log("Earlier Array", req.data)
        var req_arr = req.data.sort((a, b) => b.raiting - a.rating);

        // console.log("Package Foreign", req_arr);

        // // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // // await sleep(2000);
        // // setmainPageOptions(req.data);
        // // setLoading(true);
        propSetmainPageOptions(req_arr);
        setLoading(true);
      } catch (error) {
        // console.log('Error is')
        // console.error(error);
        setLoading(false);
        window.location = "/";
        alert(
          "Apologies from picTournic. This Property is not available for booking. Redirecting to home page"
        );
      }
    }
    fetchAllPropertyDataForLocation();
  }, []);

  return (
    
    <>
    {loading ?
    <>
    <br></br>
      <div className="container-fluid">
        <center><h1>{parLoc}</h1></center>
        <Row xs="1" sm="2" md="3" lg="4">
          <SingleCard options={propMainPageOptions} />
          {/* <SingleCard /> */}
        </Row>
      </div>
    </>:<Throbber />}
    
    </>
  );
}

export default withRouter(ForeignPackage);
