import React from "react";
import emailjs from "emailjs-com";
import "../components/DetailRequestForm.css";
import "./ContactUs.css";
import ReactGa from "react-ga4";
require('dotenv').config();

export default function ContactUs({ propertyid }) {
  function handleBookingSubmitEvent() {
    // e.preventDefault();

    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    ReactGa.event({
      category: "Booking Request Raised for " + propertyid,
      action: "User Submit Query",
      label: "User is Intrested to Book Property",
      value: propertyid,
    });
  }

  function sendEmail(e) {
    e.preventDefault();

    if (e.target.checkin.value <= e.target.checkout.value) {
      // console.log("This form will be submitted");

      emailjs
        .sendForm(
          process.env.REACT_APP_MAIL_SERVICE,
          process.env.REACT_APP_MAIL_TEMPLATE,
          e.target,
          process.env.REACT_APP_MAIL_USER
        )
        .then(
          (result) => {
            // console.log(result.text);
            if (result.text === "OK") {
              handleBookingSubmitEvent();
              alert(
                "Your mail has been Sent Succesfully. Our Executive will get in touch soon for your Booking updates."
              );
              window.location.href = "/";
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    } else {
      alert(
        "Check-Out Date cannot be earlier than Check-In date. Requesting you to Please update dates. Thanks!"
      );
    }
  }

  return (
    <div className="container" style={{ boxShadow: "none" }}>
      <h3 style={{ textAlign: "center", color:"brown" }}>
        For Availability & Booking <br></br> Please send below Details to us{" "}
        <i className="fas fa-handshake" style={{ color: "brown" }}></i>{" "}
      </h3>

      <div
        className="container"
        style={{
          // backgroundColor: "rgb(212 225 239",
          borderRadius: "10px",
          // boxSizing: "content-box",
          boxShadow: "none",
          textAlign:"left",
          fontSize:"100",
        }}
      >
        <p className="right" style={{ paddingTop: "20px" }}>
          <span className="color">* </span>Feilds are Mandatary!
        </p>
        <form className="contact-form" onSubmit={sendEmail}>
          <div className="row">
            <div className="col-lg-6 font_size">
              <div className="mb-3 font_size">
                <strong>
                  {" "}
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black" }}>
                    <span className="color">* </span>Check-In Date :
                  </label>
                </strong>
                <input
                  type="Date"
                  name="checkin"
                  className="form-control"
                  required
                  min={new Date().toISOString().split("T")[0]}
                  style={{fontSize:"25px"}}
                />
              </div>
              <div className="mb-3">
                <strong>
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black"  }}>Property Id :</label>
                </strong>
                <input
                  type="text"
                  className="form-control"
                  name="destination"
                  value={propertyid || ""}
                  readOnly
                  style={{fontSize:"25px"}}
                />
              </div>

              <div className="mb-3">
                <strong>
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black" }}>
                    <span className="color">* </span>Phone number :
                  </label>
                </strong>
                {/* <input type="number"  name="" className="form-control" placeholder="Enter Contact Detail" required onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() } />	 */}
                <input
                  type="text"
                  className="form-control"
                  name="phonenumber"
                  pattern="[0-9]{10}"
                  title="Please enter exactly 10 digits of your phone number"
                  // onKeyDown={(e) =>
                  //   /[+\-.,]$/.test(e.key) && e.preventDefault()
                  // }
                  required
                  style={{fontSize:"25px"}}
                />
              </div>

              <div className="mb-3">
                <strong>
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black" }}>
                    <span className="color">* </span>Number of adults :
                  </label>
                </strong>
                <input
                  type="number"
                  className=" form-control"
                  name="adults"
                  required
                  min="0"
                  style={{fontSize:"25px"}}
                  onKeyDown={(e) =>
                    /[+\-.,]$/.test(e.key) && e.preventDefault()
                    
                  }
                />
              </div>
              <div className="mb-3">
                <strong>
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black"  }}>Email :</label>
                </strong>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="user@gmail.com"
                  style={{fontSize:"25px"}}
                />
              </div>

              <div className="mb-3" hidden>
                <strong>
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black"  }}>Meal plan :</label>
                </strong>
                <select name="mealplan" className="form-control" style={{fontSize:"25px"}}>
                  <option>Please select...</option>
                  <option>Breakfast Only</option>
                  <option>Breakfast & Lunch Only</option>
                  <option>Breakfast & Dinner Only</option>
                  <option>Brakfast, lunch & Dinner</option>
                  <option>No Meal Needed</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 font_size">
              <div className="mb-3 font_size">
                <strong>
                  {" "}
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black"  }}>
                    <span className="color">* </span>Check-Out Date :
                  </label>
                </strong>
                <input
                  type="Date"
                  name="checkout"
                  className="form-control"
                  required
                  min={new Date().toISOString().split("T")[0]}
                  style={{fontSize:"25px"}}
                />
              </div>

              <div className="mb-3">
                <strong>
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black"  }}>
                    <span className="color">* </span>Name :
                  </label>
                </strong>
                <input
                  type="text"
                  name="username"
                  placeholder="User Name"
                  className="form-control"
                  style={{fontSize:"25px"}}
                  onKeyDown={(e) =>
                    /[{"<>':;}|+\-_.*,()!@#$%^&=0-9]$/.test(e.key) &&
                    e.preventDefault()
                  }
                  required
                />
              </div>
              <div className="mb-3" hidden>
                <strong>
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black"  }}>Alternate number :</label>
                </strong>
                <input
                  type="text"
                  style={{fontSize:"25px"}}
                  className="form-control"
                  name="altphonenumber"
                  title="Please enter exactly 10 digits of your alternate number"
                  placeholder="if any (optional)"
                  pattern="[0-9]{10}"
                  // onKeyDown={(e) =>
                  //   /[+\-.,]$/.test(e.key) && e.preventDefault()
                  // }
                />
              </div>
              <div className="mb-3">
                <strong>
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black"  }}>
                    Number of Children :
                  </label>
                </strong>
                <input
                style={{fontSize:"25px"}}
                  type="number"
                  name="children"
                  className="form-control"
                  min="0"
                  onKeyDown={(e) =>
                    /[+\-.,]$/.test(e.key) && e.preventDefault()
                  }
                />
              </div>
              <div className="mb-3">
                <strong>
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black"  }}>Number of rooms :</label>
                </strong>
                <input
                style={{fontSize:"25px"}}
                  type="number"
                  name="rooms"
                  className="form-control"
                  min="0"
                  onKeyDown={(e) =>
                    /[+\-.,]$/.test(e.key) && e.preventDefault()
                  }
                />
              </div>
              <div className="mb-3">
                <strong>
                  <label style={{ fontSize: "25px",fontWeight:"100", color:"black"  }}>Food Preference :</label>
                </strong>
                <select name="foodprefrence" className="form-control" style={{fontSize:"25px"}}>
                  <option>Please select...</option>
                  <option>Veg</option>
                  <option>Non-Veg</option>
                </select>
              </div>
            </div>
          </div>
          <div className="container" style={{ boxShadow: "none" }}>
            <strong>
              <label style={{ fontSize: "25px",fontWeight:"100", color:"black"  }}>Message (Optional) : </label>
            </strong>
            <textarea
              className="form-control"
              style={{fontSize:"25px"}}
              name="message"
              rows="4"
            ></textarea>
          </div>
          <div className="row">
            <div className="col-lg-5"></div>
            <div className="col-lg-2">
              <center>
                <input type="submit" className="btn btn-secondary" name="" />
              </center>
            </div>
            <div className="col-lg-5"></div>
          </div>
        </form>
      </div>
    </div>
  );
}
