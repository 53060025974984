import {
  Card,
  CardText,
  CardBody,
  CardLink,
  CardTitle,
  // , CardSubtitle
} from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// import "./Card.css";
import { Rating } from "react-simple-star-rating";
import React from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../components/css/Singlecard.css";

const SingleCard = ({ options, title, index, activeCard, setActiveCard }) => {
  // alert(options);
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {options.map((everyoption) => (
        <Card
          className={"singlecard singlecard" + everyoption.propertyid}
          key={everyoption.propertyid}
          style={{ width: "340px", height: "auto" }}
        >
          <CardText className="singlecard-text singlecard-rating">
            <Rating
              initialValue={everyoption.rating}
              transition
              size={30}
              allowHalfIcon
              readonly={true}
              allowHover={false}
            />
          </CardText>

          <CardText className="singlecard-text singlecard-star-rating">
            ({everyoption.rating} Star rating from {everyoption.number_of_users}{" "}
            Reviews)
          </CardText>

          {/* <CardText className="singlecard-text singlecard-mainloc">
            {everyoption.mainloc}
          </CardText> */}

          <img
            className="singlecard-image"
            src={"/Prop" + everyoption.propertyid + ".webp"}
            alt={everyoption.alt_image_name}
          />

          <CardBody>
            <CardLink
              className="singlecard-link"
              href={`/${everyoption.propertyid}?userRating=${everyoption.rating}&locationDetails=${everyoption.location}`}
            >
              EXPLORE #{everyoption.propertyid}
            </CardLink>

            <CardText className="singlecard-text singlecard-location">
              <i className="singlecard-icon fas fa-location-arrow"></i>
              {everyoption.location}
            </CardText>

            <CardTitle className="singlecard-title" tag="h6">
              {everyoption.title}
            </CardTitle>
          </CardBody>
        </Card>
      ))}
    </>
  );
};

export default SingleCard;
