/* eslint-disable */
import * as ReactBootstrap from "react-bootstrap";
import { useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./Navbar.css";
import logo from "../assests/pictournic_logo_file.png";
import Home from "../components/Home";
import ContactUs from "./ContactUs";
import ScrollTop from "./ScrollTop";
import { IoMdLogIn } from "react-icons/io";
import AboutUs from "./AboutUs";
import ReactGa from "react-ga4";
import Typewriter from "typewriter-effect";
import BookPageDetails from "./BookPageDetails";
import NotFoundPage from "./NotFoundPage";
// import { useNavigate } from "react-router-dom";
import SelectPage from "./Pages/SelectPage";
import SelectLocationPage from "./Pages/SelectLocationPage";
import SubLocations from "./Pages/SubLocations";
import PropertiesListing from "./Pages/PropertiesListing";
import Alllocations from "./Alllocations";
import React, { useEffect } from "react";
import PartnerCards from "./PartnerCards";
import ForeignPackage from "./ForeignPackage";
import toast from "react-hot-toast";
// import { useCookies } from "react-cookie";
// import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import SearchFilter from "./Pages/SearchFilter";
import SearchFilterProperties from "./Pages/SearchFilterProperties";
import { Nav, NavDropdown } from "react-bootstrap";
import Bikerides from "./Bikerides";
import SubHeaderNav from "./Pages/SubHeaderNav";
import CollapsibleDiv from "./Pages/CollapsibleDiv";
import ImageSearch from "./Pages/ImageSearch";
import RouteImage from "./Pages/RouteImage";
import PackageFormat from "./Pages/PackageFormat";
import PackageDetail from "./Pages/PackageDetail";
import CategorySelect from "./Pages/CategorySelect";
import UserModal from "./Pages/UserModel";
import { FaWindows } from "react-icons/fa";
import LoginPage from "./Pages/LoginPage";
import RegisterPage from "./Pages/SignUpPage";
import ConfirmationModal from "./Pages/ConfirmationModal";
import NewLaunches from "./Pages/NewLaunchs";
import PackageFormatAsia from "./Pages/PackageFormatAsia";
import PackageFormatDomestic from "./Pages/DomesticPackages";
import InternationalPackage from "./Pages/InternationPackage";
import OneDayTrip from "./Pages/OneDayTrip";
import Seperators from "./Pages/Seperators";

require("dotenv").config();
function Navbar() {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  useEffect(() => {
    // alert(window.location.pathname)
    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    // ReactGa.pageview(window.location.pathname + window.location.search);
    ReactGa.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.searc,
    });
  });
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userConfirmation, setUserConfirmation] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const handleLogoutClick = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_DB_URL + "/logout", {
        method: "POST",
        credentials: "include",
      });

      if (response.ok) {
        // removeCookie("token", { path: "/" });
        localStorage.removeItem("user");
        setIsUser(false);
        setIsUserLoggedIn(false);
        window.location.href = "/";
        toast.success("Logged out successfully");
      } else {
        toast.error("Failed to logout. Please try again.");
      }
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("Failed to logout. Please try again.");
    }
  };

  useEffect(() => {
    setIsUserLoggedIn(localStorage.getItem("user") !== null);
  }, []);

  // const handleUserClick = () => {
  //   const tokenExists = document.cookie
  //     .split(";")
  //     .some((item) => item.trim().startsWith("token="));
  //   if (tokenExists) {
  //     setIsUser(false);
  //     navigate("/login");
  //   } else {
  //     setIsUser(true);
  //   }
  // };

  // const toggleModal = () => {
  //   setShowModal(!showModal);
  //   const modalComponent = document.querySelector(".nav-modal-component");
  //   if (modalComponent) {
  //     modalComponent.classList.toggle("slide-in");
  //   }
  // };

  return (
    <>
      <Router>
        <ReactBootstrap.Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          sticky="top"
        >
          <ReactBootstrap.Nav.Link
            href="/"
            style={{
              color: "White",
              padding: "0px !important",
              fontSize: "35px",
              // fontWeight: "600",
              // backgroundColor: "white",
              borderRadius: "20px",
            }}
          >
            <img className="logo" src={logo} alt="picTOURnic"></img>
          </ReactBootstrap.Nav.Link>
          <div className="desktopview">
            <ReactBootstrap.Nav.Link
              href="/"
              style={{
                fontSize: "32px",
                color: "white",
                fontWeight: "400",
                // paddingLeft: "100px",
              }}
            >
              <div className="typewriter">
                <Typewriter
                  options={{ loop: true, autoStart: true }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("PICNIC + TOUR")

                      // .callFunction(() => {
                      //   console.log('String typed out!');
                      // })
                      .pauseFor(1000)
                      .deleteAll();
                    //
                    typewriter.typeString("picTOURnic").pauseFor(4500).start();
                  }}
                />
              </div>
              {/* picTOURnic */}
            </ReactBootstrap.Nav.Link>
          </div>
          <div className="mobile">
            <ReactBootstrap.Nav.Link
              href="/"
              style={{ fontSize: "16px", color: "white" }}
            >
              <div className="typewriter">
                <Typewriter
                  options={{ loop: true, autoStart: true }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("PICNIC + TOUR")

                      // .callFunction(() => {
                      //   console.log('String typed out!');
                      // })
                      .pauseFor(1000)
                      .deleteAll();
                    //
                    typewriter.typeString("picTOURnic").pauseFor(4500).start();
                  }}
                />
              </div>
              {/* picTOURnic */}
            </ReactBootstrap.Nav.Link>
          </div>
          <ReactBootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <ReactBootstrap.Navbar.Collapse id="responsive-navbar-nav">
            {/* <h3 style={{paddingLeft:"170px", fontWeight:"600", fontSize: "25px", margin: "0 auto", color:"#000480"}}>picTOURnic</h3> */}
            <ReactBootstrap.Nav className="mr-auto"></ReactBootstrap.Nav>
            <Nav.Link>
              <CategorySelect />
            </Nav.Link>
            <ReactBootstrap.Nav>
              <ReactBootstrap.Nav.Link
                href="/search"
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                <i className="fa fa-search"></i> Search
              </ReactBootstrap.Nav.Link>
              {/* <ReactBootstrap.Nav.Link
                href="/roadtrip/rides"
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                ROAD Trips
                <sup style={{ color: "yellow", fontWeight: "700" }}> NEW</sup>
              </ReactBootstrap.Nav.Link> */}

              <ReactBootstrap.Nav.Link
                href="/"
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Home
              </ReactBootstrap.Nav.Link>

              {/* <ReactBootstrap.NavDropdown
                title="Jinni"
                id="basic-nav-dropdown"
                show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
              >
                <ReactBootstrap.NavDropdown.Item href="/Day Picnic">
                  Day Picnic
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Nature Stays">
                  Nature Stays
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Beach Stays">
                  Beach Stays
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Tree Houses">
                  Tree Houses
                </ReactBootstrap.NavDropdown.Item>

                <ReactBootstrap.NavDropdown.Item href="/Tent Stays">
                  Tent Stays
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/River Front or Lake Stays">
                  River Front / Lake Stays
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Hill top">
                  Hill Top
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Forest Stay">
                  Forest Stay
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Luxury">
                  Luxury
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Tea coffee Spice Plantation Stay">
                  Tea Plantation
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Group Stay">
                  Group Stay
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Adventure">
                  Adventure
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Workation">
                  Workation
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Celebrations">
                  Celebrations
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/Houseboat or Cruise">
                  Houseboat/Cruise
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="/packages/package%20tour">
                  Package Tour
                </ReactBootstrap.NavDropdown.Item>
              </ReactBootstrap.NavDropdown> */}

              <ReactBootstrap.Nav.Link
                href="/aboutus"
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                About US
              </ReactBootstrap.Nav.Link>
              <ReactBootstrap.Nav.Link
                href="/contactus"
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Contact US
              </ReactBootstrap.Nav.Link>
              {/* <li className="d-flex align-items-center nav-item"> */}
              {isUserLoggedIn ? (
                <UserModal
                  handleLogoutClick={() => setUserConfirmation(true)}
                  setIsUser={setIsUser}
                />
              ) : (
                // <UserModal /
                <button
                  className="btn btn-outline-primary d-flex align-items-center text-light"
                  onClick={() => (window.location.href = "/login")}
                  style={{
                    border: window.innerWidth <= 600 ? "none" : "",
                    backgroundColor:
                      window.innerWidth <= 600 ? "transparent" : "",
                    color: window.innerWidth <= 600 ? "white" : "",
                    padding: window.innerWidth <= 600 ? "10px 0px" : "",
                  }}
                >
                  <IoMdLogIn size={"26px"} />
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      cursor: "pointer",
                      maxWidth: "200px",
                    }}
                    className="pl-2 fs-5"
                  >
                    Login
                  </span>
                </button>
              )}
              {/* </li> */}
            </ReactBootstrap.Nav>
          </ReactBootstrap.Navbar.Collapse>
        </ReactBootstrap.Navbar>

        {/* <SubHeaderNav /> */}

        <ScrollTop />
        <Switch>
          <Route path="/trending-places" exact>
            <NewLaunches />
          </Route>
          <Route path="/" exact={true}>
            <Home />
          </Route>
          <Route path="/login" exact={true}>
            <LoginPage />
          </Route>
          <Route path="/signup" exact={true}>
            <RegisterPage />
          </Route>

          <Route
            path="/extract/propidinformation/result/partners/:partnerName"
            exact
          >
            <PartnerCards />
          </Route>

          <Route path="/contactus">
            <ContactUs />
          </Route>
          <Route path="/aboutus">
            <AboutUs />
          </Route>
          <Route path="/search">
            <SearchFilter />
          </Route>
          <Route path="/Package Tour" exact>
            {/* <CollapsibleDiv />
             */}
             <Seperators />
          </Route>
          <Route path="/packages/Package Tour">
            <CollapsibleDiv />
          </Route>
          <Route path="/Booking/:propid" exact>
            <BookPageDetails />
          </Route>

          <Route path="/:id" exact>
            <SelectPage />
          </Route>

          <Route path="/locations/all" exact>
            <Alllocations />
          </Route>
          <Route path="/filter/locationdetails/:locationname" exact>
            <SelectLocationPage />{" "}
          </Route>

          <Route path="/locations/filter/locationdetails/:locationname" exact>
            <SelectLocationPage />{" "}
          </Route>
          <Route
            path="/filter/locationdetails/:locationname/:categoryname"
            exact
          >
            <SubLocations />{" "}
          </Route>
          <Route
            path="/locations/filter/locationdetails/:locationname/:categoryname"
            exact
          >
            <SubLocations />{" "}
          </Route>
          <Route
            path="/filter/locationdetails/:locationname/:categoryname/:sublocation/:categoryid"
            exact
          >
            <PropertiesListing />{" "}
          </Route>
          <Route
            path="/locations/filter/locationdetails/:locationname/:categoryname/:sublocation/:categoryid"
            exact
          >
            <PropertiesListing />{" "}
          </Route>

          <Route path="/foregin/Package/:locationname" exact>
            <ForeignPackage />
          </Route>

          <Route path="/filteredlocation/:locationname" exact>
            <SearchFilterProperties />
          </Route>

          <Route path="/roadtrip/rides" exact>
            <Bikerides />
          </Route>
          <Route path="/image/search/:location" exact>
            <ImageSearch />
          </Route>
          <Route path="/gallery/show/:id/:location" exact>
            <RouteImage />
          </Route>
          <Route path="/package/europe" exact>
            <PackageFormat />
          </Route>
          <Route path="/package/asia" exact>
            <PackageFormatAsia />
          </Route>
          <Route path="/package/domestic" exact>
            <PackageFormatDomestic />
          </Route>
          <Route path="/package/International" exact>
            <InternationalPackage />
          </Route>
          <Route path="/package/:location/:details" exact>
            <PackageDetail />
          </Route>
          {/* /package/packageid/" + packageItem.packageId */}
          <Route path="/package/packageid/:packageid" exact>
            <PackageDetail />
          </Route>
          
          <Route path="/OneDaypackage/trip" exact>
            <OneDayTrip />
          </Route>
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
      {userConfirmation && (
        <ConfirmationModal
          heading="Are you sure you want to Logout?"
          handleSuccess={handleLogoutClick}
          setUserConfirmation={setUserConfirmation}
        />
      )}
    </>
  );
}

export default Navbar;
