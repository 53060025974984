/* eslint-disable */
import React from "react";
import Back from "../Back";
import { withRouter } from "react-router-dom";
import axios from "../../axios";
import { useState, useEffect } from "react";
import SingleCard from "../Card";
import {
  Row,
  // , CardSubtitle
} from "reactstrap";
import SearchFilter from "./SearchFilter";
import Throbber from "../Pages/Throbber";
import ReturnHomeButton from "./ReturnHome";

function SearchFilterProperties(props) {
  // console.log(props);
  var location_filter = props.match.params.locationname;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        
        // console.log('Calling DB')
        const req = await axios.get(
          process.env.REACT_APP_DB_URL + "/filter/search/" + location_filter
        );
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // const normarray = ;
        // console.log(req.data);
        setItems(req.data.sort((a, b) => b.rating - a.rating));
        setLoading(true);
        // console.log("The data set here is ", req.data)
      } catch (error) {
        // console.log('Error is')
        console.error(error);
      }
    }
    fetchData();
  }, []);
  // console.log(location_filter)
  return (
    <>
      {loading ? (
        <>
        {/* <SearchFilter /> */}
          <div className="container" style={{ boxShadow: "none" }}>
            <center>
              <h1 style={{ color: "brown" }}>
                {/* <br></br> */}
                pic <span style={{ color: "green" }}>{location_filter}</span>
              </h1>
            </center>
          </div>
          {/* <Back />
           */}
           <ReturnHomeButton />
          <div className="container-fluid">
            <Row xs="1" sm="2" md="4" lg="5">
              <SingleCard options={items} />
            </Row>
          </div>
          
        </>
      ) : (
        <>
          <Throbber />
        </>
      )}
    </>
  );
}

export default withRouter(SearchFilterProperties);
