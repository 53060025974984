/* eslint-disable */
// import { Row, Col, Container } from "reactstrap";
import React, { useEffect, useState } from "react";
// import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
// import Aos from "aos";
import "aos/dist/aos.css";
// import axios from "../axios";
// import Throbber from "./Pages/Throbber";
// import data from '../Data/Farm1.json'
import { withRouter } from "react-router-dom";
import PlayVideo from "../PlayVideo";
import "../DetailPages/Details.css";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import a1 from '../../assests/property/1/1_1.jpg'
// import a2 from '../../assests/property/1/1_2.jpg'
import Back from "../Back";
// import Book from '../Book';
// import Pricedetails from '../Pricedetails.js'
import axios from "../../axios";
import Throbber from "../Pages/Throbber";
import Title from "./Title";
import Book from "../Book";
import Activities from "./Activities";
import Landmark from "./Landmark";
import Facilities from "./Facilities";
import Timing from "./Timing";
import Price from "./Price";
import MealTiming from "./MealTiming";
import Celebrations from "./Celebrations";
import KnowBeforeVisit from "./KnowBeforeVisit";
import Bookingpolicy from "./Bookingpolicy";
import CancilationPolicy from "./CancilationPolicy";
import Description from "./Description";
import MealPlan from "./MealPlan";
import Alllocations from "../Alllocations";
// import MainPageCard from "../MainPageCard";
require("dotenv").config();
function PropertiesDetails(props) {
  const [pagedetail, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [propertyCode, setPropertyCode] = useState([]);
  const [pageImage, setPageImage] = useState([]);
  const [photos, setPhotos] = useState([]);
  var para = props.match.params.id;
  // console.log(props);
  const searchParams = new URLSearchParams(location.search);
  const userRating = searchParams.get("userRating");
  const locationDetails = searchParams.get("locationDetails");

  // Now, you can use 'id' and 'pageName' in your component logic
  // console.log("userRating:", userRating);
  // console.log("locationDetails:", locationDetails);
  //Third Page content details with respect to ID
  useEffect(() => {
    async function fetchPropertiesDetails() {
      try {
        // console.log("Calling DB for Third Page");
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        const req = await axios.get(process.env.REACT_APP_DB_URL + "/" + para);
        // console.log("Third Page Request Starts");
        //   console.log(req.data)
        //   console.log(typeof(req.data))
        //   var abc = []
        //   req.data.map((index,value)=>{
        //     // console.log(index[0])
        //     abc.push(index[0])
        //   })
        // console.log(req.data[0]);
        // console.log("Third Page Request Ends");
        setPageDetails(req.data[0]);
        setPageImage("Prop" + para + ".webp");
        setPhotos(req.data[0]["pictures"]);
        const pics = req.data[0]["pictures"];
        const altpics = [];
        pics.map((item) => {
          altpics.push(item.replace(/ /g, ""));
        });
        // console.log(altpics)
        if (req.data[0] !== undefined) {
          setLoading(true);
        } else {
          setLoading(false);
          // const sleep = (ms) =>
          //   new Promise((resolve) => setTimeout(resolve, ms));
          // await sleep(2000);

          // alert("Redirecting to home Page");
          window.location = "/";
          alert(
            "Apologies from picTournic. This Property is not available for booking. Redirecting to home page"
          );
        }

        //   setproperties(abc)
        // console.log('unpacking data from Server')
        // req.map((item)=>{
        //   console.log(item)
        // })
        // console.log('Unpacked')
        //   setmainPageOptions(req.data);
      } catch (error) {
        // console.log("Error is");
        // console.error(error);
        setLoading(false);
      }
    }
    fetchPropertiesDetails();
    // eslint-disable-next-line
  }, []);

  // Being used for fetching data for the home page .
  //Here the main Categories will be extracted from backend server
  const [mainPageOptions, setmainPageOptions] = useState([]);

  //Only to fetch the Property Code
  useEffect(() => {
    async function fetchPropertycode() {
      try {
        // console.log("Calling DB for Property code");
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        const req = await axios.get(
          process.env.REACT_APP_DB_URL + "/fetching/propertycode/" + para
        );
        // console.log(req.data);
        // console.log("Property Code returns");
        setPropertyCode(req.data);
      } catch (error) {
        // console.log("Error is");
        // console.error(error);
        setLoading(false);
      }
    }
    fetchPropertycode();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>Stay Picnic Tour {para}</title>
      </Helmet>
      <div className="container" style={{ boxShadow: "none" }}>
        {loading ? (
          <>
            {/* MUST ADD THE ABOUT PROPERTY SUPPORT, Add in DB the tag and Schema within mongoose need to be updated to Type String */}

            {/* For  the Title of the Page */}
            {(() => {
              if (pagedetail.title.length > 0) {
                return (
                  <Title
                    options={pagedetail.title}
                    image={pageImage}
                    photos={photos}
                    locationDetails={locationDetails}
                    userRating={userRating}

                  />
                );
              }

              return null;
            })()}

            {/* For the booking ID Page */}
            {(() => {
              if (propertyCode.length > 0) {
                return <Book propertyid={propertyCode} />;
              }

              return null;
            })()}

            {/* For the Description Page */}
            {(() => {
              if (pagedetail.propertyInfo.length > 0) {
                return <Description options={pagedetail.propertyInfo} />;
              }

              return null;
            })()}
            <br></br>
            {/* For the Facilities Page */}
            {(() => {
              if (
                pagedetail.facilities.length > 0 &&
                pagedetail.facilities[0] !== "-"
              ) {
                return <Facilities options={pagedetail.facilities} />;
              }

              return null;
            })()}
            <br></br>

            {/* For the Activities Page */}
            {(() => {
              if (
                pagedetail.activities.length > 0 &&
                pagedetail.activities[0] !== "-"
              ) {
                return <Activities options={pagedetail.activities.sort()} />;
              }

              return null;
            })()}
            <br></br>
            {/* For YouTube URL */}
            {(() => {
              if (
                pagedetail.videourl.length > 0 &&
                pagedetail.videourl !== "-"
              ) {
                return <PlayVideo option={pagedetail.videourl} />;
              }

              return null;
            })()}

            {/* For Near By Locations */}
            {(() => {
              if (
                pagedetail.landmark.length > 0 &&
                pagedetail.landmark[0] !== "-"
              ) {
                return <Landmark options={pagedetail.landmark} />;
              }

              return null;
            })()}
            <br></br>
            {/* Meal Plan */}
            {(() => {
              if (
                pagedetail.mealPlan.length > 0 &&
                pagedetail.mealPlan[0] !== "-"
              ) {
                return <MealPlan options={pagedetail.mealPlan} />;
              }

              return null;
            })()}
            <br></br>
            {/* For Timings */}
            {(() => {
              if (
                pagedetail.timing.length > 0 &&
                pagedetail.timing[0] !== "-"
              ) {
                return <Timing options={pagedetail.timing} />;
              }

              return null;
            })()}
            <br></br>
            {/* For Price */}
            {(() => {
              if (
                pagedetail.startprice.length > 0 &&
                pagedetail.startprice[0] !== "-"
              ) {
                return <Price options={pagedetail.startprice} />;
              }

              return null;
            })()}

            {/* For the booking ID Page */}
            {(() => {
              if (propertyCode.length > 0) {
                return <Book propertyid={propertyCode} />;
              }

              return null;
            })()}

            {/* <Back /> */}

            {/* For Meal Timing*/}
            {(() => {
              if (pagedetail.mealtiming.length > 1) {
                return <MealTiming options={pagedetail.mealtiming.sort()} />;
              }

              return null;
            })()}

            {/* For Celebrations*/}
            {(() => {
              if (pagedetail.celebration.length > 1) {
                return <Celebrations options={pagedetail.celebration} />;
              }

              return null;
            })()}
            <br></br>
            <div
              style={{
                width: "100%",
                // background: "linear-gradient(90deg, #ff6f61 0%, #ff9040 100%)",
                background: "#a8a09c70",
                padding: "20px", // Add padding for better styling
                color: "white", // Text color
                // textAlign: "center", // Center align the content
                borderRadius: "10px", // Add rounded corners
                // border: "5px solid black"
              }}
            >
              {(() => {
                if (pagedetail.knowbeforeyouvisit.length > 0) {
                  return (
                    <KnowBeforeVisit options={pagedetail.knowbeforeyouvisit} />
                  );
                }
                return null;
              })()}
              <br />
              {/* For Booking Policy*/}
              {(() => {
                if (pagedetail.bookingpolicy.length > 0) {
                  return <Bookingpolicy options={pagedetail.bookingpolicy} />;
                }
                return null;
              })()}
              <br />
              {/* For Cancellation Policy*/}
              {(() => {
                if (pagedetail.cancellationpolicy.length > 0) {
                  return (
                    <CancilationPolicy
                      options={pagedetail.cancellationpolicy}
                    />
                  );
                }
                return null;
              })()}
            </div>
          </>
        ) : (
          <Throbber />
        )}
      </div>

      <div
        className="container-fluid"
        style={{
          boxShadow: "none",
          width: "100%",
          // background: "linear-gradient(90deg, rgb(255 97 160) 0%, rgb(255, 144, 64) 100%)",
          // padding:"20px"

          // textAlign: "center", // Center align the content
        }}
      >
        <Alllocations />
      </div>
    </>
  );
}

export default withRouter(PropertiesDetails);
