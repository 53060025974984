import React from "react";
import { withRouter } from "react-router-dom";
// import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import ReactWhatsapp from "react-whatsapp";
import dataSet from "./dataSetImageSearch.json";
// import { Rating } from "react-simple-star-rating";
import {
  Row,
  Col,
  // , CardSubtitle
} from "reactstrap";
import { Rating } from "react-simple-star-rating";
import ReturnHomeButton from "./ReturnHome";
// import Back from "../Back";

function ImageSearch(props) {
  
  const currentLocation = window.location.href;

  if (currentLocation.includes("GOA")) {
    // Redirect to a different URL for Goa
    window.location.href = "/filteredlocation/Goa";
    return null; // Render nothing after redirect
  }

  if (currentLocation.includes("PONDICHERRY")) {
    // Redirect to a different URL for Goa
    window.location.href = "/filteredlocation/Pondicherry";
    return null; // Render nothing after redirect
  }
  if (currentLocation.includes("UDUPI")) {
    // Redirect to a different URL for Goa
    window.location.href = "/filteredlocation/Udupi";
    return null; // Render nothing after redirect
  }
  if (currentLocation.includes("OOTY")) {
    // Redirect to a different URL for Goa
    window.location.href = "/filteredlocation/Ooty";
    return null; // Render nothing after redirect
  }
  if (currentLocation.includes("GOKARNA")) {
    // Redirect to a different URL for Goa
    window.location.href = "/filteredlocation/Gokarna";
    return null; // Render nothing after redirect
  }




  // console.log(currentLocation);
  const fetchLocationInformation = (locationId) => {
    for (const location of dataSet) {
      // Check if the locationId exists as a key in the dataset
      if (location[locationId]) {
        const sortedPlaces = location[locationId].sort((a, b) => {
          // Convert displayOrder values to numbers for proper numerical comparison
          const orderA = parseFloat(a.displayOrder);
          const orderB = parseFloat(b.displayOrder);
          return orderA - orderB;
        });
        return sortedPlaces;
      }
    }
    // If no matching locationId is found
    return null;
  };

  const paramLocationId = props.match.params.location; // Replace with your actual value
  const locationInformation = fetchLocationInformation(paramLocationId);

  return (
    <>
      <br />
      {/* <Back /> */}
      <ReturnHomeButton />
      <div>
        <center>
          <h2 style={{ fontSize: "2em", margin: "0" }}>{paramLocationId}</h2>
        </center>
        <div className="container-pic">
          <Row xs="1" sm="3" md="3" lg="5">
            {locationInformation.map((property) => (
              <Col
                key={property.webnum}
                className="mb-4"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "1px",
                  margin: "3px",  
                }}
              >
                {property.starRaiting && (
                  <>
                    <center>
                      {property.prtype && (
                        <div className="additional-text2">
                          <span>
                            {property.kms} Km from {paramLocationId}
                          </span>
                        </div>
                      )}
                      <Rating
                        initialValue={property.googleReviews}
                        transition
                        size={30}
                        allowHalfIcon
                        readonly={true}
                        allowHover={false}
                      />
                      <div className="additional-text1">
                        {property.googleReviews} Rating ({property.users} Users)
                      </div>
                    </center>
                  </>
                )}
                <a
                  href={`/gallery/show/${property.webnum}/${paramLocationId}`}
                  // onClick={() => handleGalleryClick(property.webnum)}
                >
                  <div className="image-container-pic">
                    <div className="image-overlay-container-pic">
                      <img
                        src={`/ImageSearch/${property.webnum}_1.webp`}
                        alt="Sample"
                        className="image-pic"
                      />
                      <div className="overlay-pic">
                        <p className="overlay-text-picc">
                          {property.sublocation.trim()}
                        </p>
                        <p className="overlay-text-picc">
                          {property.prtype.trim()}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                <div
                  className="text-center"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  <a
                    href={`/gallery/show/${property.webnum}/${paramLocationId}`}
                    style={{
                      color: "black", // Button text color
                    }}
                    // onClick={() => handleGalleryClick(property.webnum)}
                  >
                    Gallery
                  </a>
                </div>
                <div
                  className="text-center"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  <ReactWhatsapp
                    style={{
                      backgroundColor: "#3cb371",
                      color: "white",
                      padding: "5px",
                      borderRadius: "20px",
                      border: "none",
                      fontSize: "15px",
                      marginBottom: "5px",
                    }}
                    number="+919663803335"
                    message={`Hi picTOURnic, I am interested in Image Search for Property ID ${property.webnum}`}
                  >
                    <i
                      className="fab fa-whatsapp"
                      style={{ fontSize: "15px" }}
                    ></i>{" "}
                    Enquiry
                  </ReactWhatsapp>
                  <br />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}

export default withRouter(ImageSearch);
