import React from "react";
import PackageCarousel from "./Package/PackageCarousel";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
function PackageDetail(props) {
  function findPackageById(packageId) {
    // Iterate through the InternationalData array
    for (let i = 0; i < dataPackage.length; i++) {
      // Iterate through the objects in the current array
      for (let j = 0; j < dataPackage[i].length; j++) {
        // Check if the current object has the title "PTN_PkG_Id"
        if (dataPackage[i][j].title === "packageId") {
          // If the content matches the packageId, return the array
          if (dataPackage[i][j].content === packageId) {
            return dataPackage[i];
          }
          // Break the inner loop as we found the "PTN_PkG_Id" object
          break;
        }
      }
    }
    // If no matching package ID is found, return null
    return null;
  }
  const dataPackage = [
    [
      {
        title: "packageId",
        content: "PTN_PkG_0001",
      },
      {
        title: "package_name",
        content: "Green Wayanad - CULTURAL MYSORE",
      },
      // {
      // title: "places",
      // content: ["Karnataka", "Kerala"],
      // // },
      {
        title: "tour_type",
        content: ["Holiday Package", "Family tour", "Honeymoon Package"],
      },
      {
        title: "Highlights",
        content: [
          "Sri Rangapatna - Tippu Sultan's Island Capital, Palace, Fort",
          "Chamundeswari Temple",
          "Maharaja's Palace",
          "Brindavan Gardens",
          "Pookode Lake",
          "Chain Tree",
          "Edakkal Caves",
          "Soochippara Waterfalls",
          "Banasura Sagar Dam",
          "Sultan Battery - Museum",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "3N/4D",
          Cities: ["Bengaluru", "Mysore", "Wayanad", "Bengaluru"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Bengaluru", duration: "" },
              { name: "Mysore", duration: "1N" },
              { name: "Wayanad", duration: "2N" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "Bengaluru – Mysore",
              description:
                "Departure to Mysore (140 Kms/3.5 Hrs) Enroute Visit Sri Rangapatna - Tippu Sultan's Island Capital, Palace, Fort. Afternoon visit Chamundeswari Temple, Maharaja's Palace and light & sound fountains at the Brindavan Gardens. Night Stay in Mysore.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "Mysore – Wayanad",
              description:
                "After an early breakfast depart for Wayanad (290 Kms/ 6 Hrs), one of the most scenic places in Kerala Which can still be called a virgin landscape. Lofty peaks, gurgling waterfalls and luxuriant forests add magic to Your journey up the winding roads to this hill station. Visit Pookode lake for boating and historical Chain Tree. Night stay in Wayanad.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header: "Wayanad Local Visits",
              description:
                "Sight-seeing of Edakkal Caves, Soochippara waterfalls and Banasura Sagar Dam. Night Stay in Wayanad.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "Wayanad – Bengaluru",
              description:
                "Departure to Bengaluru (290 Kms/8 Hrs), enroute visit Sultan Battery - Museum. Evening drop at Airport/Railway Station.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "destinaions",
        content: ["Bengaluru", "Mysore", "Wayanad"],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 15000,
            text: "per person",
          },
          {
            packageDuration: "3N/4D",
          },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0001_Mysore1.webp",
            text: "PTN_PkG_0001_Mysore1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0001_Mysore2.webp",
            text: "PTN_PkG_0001_Mysore2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0001_Mysore3.webp",
            text: "PTN_PkG_0001_Mysore3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0001_Mysore4.webp",
            text: "PTN_PkG_0001_Mysore4.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0001_Wayanad1.webp",
            text: "PTN_PkG_0001_Wayanad1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0001_Wayanad2.webp",
            text: "PTN_PkG_0001_Wayanad2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0001_Wayanad3.webp",
            text: "PTN_PkG_0001_Wayanad3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0001_Wayanad4.webp",
            text: "PTN_PkG_0001_Wayanad4.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0001_Wayanad5.webp",
            text: "PTN_PkG_0001_Wayanad5.webp",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0002",
      },
      {
        title: "package_name",
        content: "QUEEN OOTY - PARADISE KODAI - CULTURAL MYSORE",
      },
      // {
      // title: "places",
      // content: ["Karnataka", "Tamilnadu"],
      // // },
      {
        title: "tour_type",
        content: ["Holiday Package", "Family tour", "Honeymoon Package"],
      },
      {
        title: "Highlights",
        content: [
          "Lal Bagh Gardens",
          "Bull Temple",
          "Vidhana Soudha",
          "Visveswaraya Industrial & Technology Museum",
          "Sri Rangapatna - Tippu Sultan's Island Capital, Palace, Fort",
          "Chamundeswari Temple",
          "Maharaja's Palace",
          "Brindavan Gardens",
          "Botanical Gardens",
          "Ooty Lake",
          "Kodaikanal",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "5N/6D",
          Cities: ["Bengaluru", "Mysore", "Ooty", "Kodaikanal", "Bengaluru"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Bengaluru", duration: "1N" },
              { name: "Mysore", duration: "1N" },
              { name: "Ooty", duration: "1N" },
              { name: "Kodaikanal", duration: "2N" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "Arrive Bengaluru",
              description:
                "Arrive Bengaluru Airport / Railway Station and transfer to hotel. Afternoon visit Lal Bagh Gardens, Bull Temple, Vidhana Soudha, Visveswaraya Industrial & Technology Museum. Night Stay in Bengaluru.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "Bengaluru – Mysore",
              description:
                "Enroute Visit Sri Rangapatna - Tippu Sultan's Island Capital, Palace, Fort. Upon arrival at Mysore check into hotel. Afternoon visit Chamundeswari Temple, Maharaja's Palace and light & sound fountains at the Brindavan Gardens. Night Stay in Mysore.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header: "Mysore – Ooty",
              description:
                "After an early breakfast depart for Ooty (160 Kms/4.5 Hrs), the Queen of Hill Stations. Visit Botanical Gardens and the scenic Ooty Lake where you may enjoy a boat ride. Night Stay in Ooty.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "Ooty – Kodaikanal",
              description:
                "After breakfast, proceed to Kodaikanal (268 Kms /7 Hrs), a charming hill station. Evening at leisure. Night Stay in Kodaikanal.",
              meal: "",
            },
            {
              dayname: "Day 05",
              header: "Kodaikanal",
              description:
                "Visit Shenbaganur Museum, Coakers walk, Telescope House and Kodai Lake. Night Stay in Kodaikanal.",
              meal: "",
            },
            {
              dayname: "Day 06",
              header: "Kodaikanal – Bengaluru",
              description:
                "Departure to Bengaluru and transfer to Railway station /Airport for onward journey.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "destinaions",
        content: ["Bengaluru", "Mysore", "Ooty", "Kodaikanal"],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0002_Kodaikanal1.webp",
            text: "PTN_PkG_0002_Kodaikanal1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_Kodaikanal2.webp",
            text: "PTN_PkG_0002_Kodaikanal2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_Kodaikanal3.webp",
            text: "PTN_PkG_0002_Kodaikanal3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_Kodaikanal4.webp",
            text: "PTN_PkG_0002_Kodaikanal4.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_Kodaikanal5.webp",
            text: "PTN_PkG_0002_Kodaikanal5.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_Mysore1.webp",
            text: "PTN_PkG_0002_Mysore1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_Mysore2.webp",
            text: "PTN_PkG_0002_Mysore2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_Mysore3.webp",
            text: "PTN_PkG_0002_Mysore3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_Mysore4.webp",
            text: "PTN_PkG_0002_Mysore4.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_ooty1.webp",
            text: "PTN_PkG_0002_ooty1.webp",
          },
          // {
          //   carousel_ima: "/PTN_PkG_0002_ooty2.jfif",
          //   text: "PTN_PkG_0002_ooty2.jfif",
          // },
          {
            carousel_ima: "/PTN_PkG_0002_ooty3.webp",
            text: "PTN_PkG_0002_ooty3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_ooty4.webp",
            text: "PTN_PkG_0002_ooty4.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0002_ooty5.webp",
            text: "PTN_PkG_0002_ooty5.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 21350,
            text: "per person",
          },
          {
            packageDuration: "5N/6D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0003",
      },
      {
        title: "package_name",
        content:
          "SILICON VALLEY BENGALURU - CULTURAL MYSORE - GLORIOUS COFFEE CAPITAL COORG TOUR",
      },
      // {
      // title: "places",
      // content: ["Karnataka"],
      // // },
      {
        title: "tour_type",
        content: ["Holiday Package", "Family tour", "Honeymoon Package"],
      },
      {
        title: "Highlights",
        content: [
          "Lalbagh Gardens",
          "Cubbon Park",
          "Bull Temple",
          "Visveswaraya Industrial & Technology Museum",
          "Vidhana Soudha",
          "ISKCON Temple",
          "Sri Rangapatna - Tippu Sultan’s Island capital, Palace, Fort",
          "Chamundeswari Temple",
          "Maharaja’s Palace",
          "Sound & Light fountains at Brindavan Garden",
          "Madikere (Coorg)",
          "Talacauvery",
          "Fort",
          "Abbey Waterfalls",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "4N/5D",
          Cities: ["Bengaluru", "Mysore", "Madikere (Coorg)", "Bengaluru"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Bengaluru", duration: "1N" },
              { name: "Mysore", duration: "1N" },
              { name: "Madikere (Coorg)", duration: "2N" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "Bengaluru",
              description:
                "Pick-up from Bengaluru Airport / Railway station. Transfer to Hotel. Local visits: Lalbagh Gardens, Cubbon Park, Bull Temple, Visveswaraya Industrial & Technology Museum, Vidhana Soudha, ISKCON Temple. Night Stay in Bengaluru.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "Bengaluru – Mysore",
              description:
                "After breakfast - Departure to Mysore (140 Kms /2.5 Hrs.). Transfer to Hotel. Local visits Sri Rangapatna - Tippu Sultan’s Island capital, Palace, Fort, Upon arrival at Mysore check into hotel. Afternoon visit Chamundeswari Temple, Maharaja’s Palace and Sound & Light fountains at Brindavan Garden. Night Stay in Mysore.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header: "Mysore - Madikere (Coorg)",
              description:
                "After breakfast proceed to Madikere (130 Kms/ 3 Hrs). Transfer to Hotel. Tucked in the lap of cloud-wreathed hills, in this small town, having an enchanting dream line creation around it, could be enjoyed by walk also. Leisure in the evening. Night Stay in Madikere.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "Madikere (Coorg)",
              description:
                "After breakfast Local visit Talacauvery. The principal river is the Kaveri (Cauvery), Fort, Abbey Waterfalls. Night Stay in Madikere.",
              meal: "",
            },
            {
              dayname: "Day 05",
              header: "Madikere – Bengaluru",
              description:
                "After breakfast check-out from hotel - Departure to Bengaluru (260 Kms/ 6 hrs) drop at Airport / Railway station.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Bengaluru", "Mysore", "Madikere (Coorg)"],
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0003_Bengaluru1.webp",
            text: "PTN_PkG_0003_Bengaluru1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0003_Bengaluru2.webp",
            text: "PTN_PkG_0003_Bengaluru2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0003_Bengaluru3.webp",
            text: "PTN_PkG_0003_Bengaluru3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0003_Coorg1.webp",
            text: "PTN_PkG_0003_Coorg1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0003_Coorg2.webp",
            text: "PTN_PkG_0003_Coorg2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0003_Coorg3.webp",
            text: "PTN_PkG_0003_Coorg3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0003_Mysore1.webp",
            text: "PTN_PkG_0003_Mysore1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0003_Mysore2.webp",
            text: "PTN_PkG_0003_Mysore2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0003_Mysore3.webp",
            text: "PTN_PkG_0003_Mysore3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0003_Mysore4.webp",
            text: "PTN_PkG_0003_Mysore4.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 15000,
            text: "per person",
          },
          {
            packageDuration: "4N/5D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0004",
      },
      {
        title: "package_name",
        content:
          "MAJESTIC KARNATAKA WITH HERITAGE – DIVINE TEMPLES – BEACH – WATERFALLS 6N/7D PACKAGE",
      },
      // {
      // title: "places",
      // content: ["Karnataka"],
      // // },
      {
        title: "tour_type",
        content: ["Holiday Package", "Family tour", "Honeymoon Package"],
      },
      {
        title: "Highlights",
        content: [
          "Lalbagh",
          "Cubbon Park",
          "Bull Temple",
          "Visveswaraya Industrial & Technology Museum",
          "Vidhana Soudha",
          "ISKCON Temple",
          "Shravanabelagola",
          "Kukke Subramanya",
          "Dharmasthala",
          "Mangalore",
          "Mangala Devi Temple",
          "Gokarananatheshwara Temple",
          "Kadri Park",
          "Tanneerbhavi Beach",
          "Kalasa",
          "Kalaseshwara Temple",
          "Kudremukh National Park",
          "Sringeri",
          "Sharadambha Temple",
          "Kollur Mookambika Temple",
          "Murudeshwar - Lord Shiva Temple",
          "Jog falls",
          "Belur",
          "Halebidu",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "6N/7D",
          Cities: [
            "Bengaluru",
            "Hassan",
            "Mangalore",
            "Udupi",
            "Gokarna",
            "Murudeshwara",
            "Sringeri",
            "Subramanya",
            "Horanadu",
            "Jogg Falls",
            "Beluru",
            "Halebidu",
          ],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Bengaluru", duration: "1N" },
              { name: "Hassan", duration: "2N" },
              { name: "Mangalore", duration: "1N" },
              { name: "Udupi", duration: "1N" },
              { name: "Gokarna", duration: "1N" },
              { name: "Murudeshwara", duration: "" },
              { name: "Sringeri", duration: "" },
              { name: "Subramanya", duration: "" },
              { name: "Horanadu", duration: "" },
              { name: "Jog Falls", duration: "" },
              { name: "Beluru", duration: "" },
              { name: "Halebidu", duration: "" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "SILICON VALLEY BENGALURU",
              description:
                "Pick-up from Bengaluru Airport / Railway station. Transfer to Hotel. Local visits: Lalbagh, Cubbon Park, Bull Temple, Visveswaraya Industrial & Technology Museum, Vidhana Soudha, ISKCON Temple. Night Stay in Bengaluru.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "BENGALURU - SHRAVANABELAGOLA – HASSAN",
              description:
                "After breakfast Departure to Hassan (160 kms. / 3 hrs.). Enroute visit Shravanabelagola (one of the most celebrated Jain Pilgrimage centres). Transfer to Hotel. Night Stay in Hassan.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header: "HASSAN - KUKKE SUBRAMANYA – DHARMASTHALA - MANGALORE",
              description:
                "After breakfast Departure to Mangalore - (180 kms. / 3.5 Hrs.). Enroute visit Kukke Subramanya. Dharamasthala. Transfer to Hotel. Local visits Mangala Devi Temple, Gokarananatheshwara Temple, Kadri Park and Tanneerbhavi Beach. Night Stay in Mangalore.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "MANGALORE – KALASA - SRINGERI – UDUPI",
              description:
                "After Breakfast Departure to Udupi. Enroute visit Horanadu - Annapurneswari Temple, Kalasa - Kalaseshwara Temple, Kudremukh National Park, Sringeri - Sharadambha Temple, Transfer to Hotel. Night Stay in Udupi.",
              meal: "",
            },
            {
              dayname: "Day 05",
              header: "KOLLUR - MURUDESHWAR – GOKARNA",
              description:
                "After breakfast Departure to Gokarna. Enroute visit Kollur Mookambika Temple. Murudeshwar - Lord Shiva Temple. Transfer to Hotel. Local visit. Night Stay in Gokarna.",
              meal: "",
            },
            {
              dayname: "Day 06",
              header: "GOKARNA - JOG FALLS - BELUR - HALEBIDU – HASSAN",
              description:
                "After Breakfast Departure to Hassan (360 Kms/7 Hrs.). Enroute visit Jog falls, Belur, Halebidu transfer to hotel. Night Stay in Hassan.",
              meal: "",
            },
            {
              dayname: "Day 07",
              header: "Hassan – Bengaluru",
              description:
                "After breakfast - Departure to Bengaluru (180 Kms/5 Hrs) Drop at Airport/Railway station.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: [
          "Bengaluru",
          "Hassan",
          "Mangalore",
          "Udupi",
          "Gokarna",
          "Murudeshwara",
          "Sringeri",
          "Subramanya",
          "Horanadu",
          "Jogg Falls",
          "Beluru",
          "Halebidu",
        ],
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0004_Belur.webp",
            text: "PTN_PkG_0004_Belur.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_Darmasthala.webp",
            text: "PTN_PkG_0004_Darmasthala.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_Gokarna.webp",
            text: "PTN_PkG_0004_Gokarna.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_Halebeedu.webp",
            text: "PTN_PkG_0004_Halebeedu.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_Horanadu.webp",
            text: "PTN_PkG_0004_Horanadu.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_JogFalls.webp",
            text: "PTN_PkG_0004_JogFalls.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_KukkeSubrahmanya.webp",
            text: "PTN_PkG_0004_KukkeSubrahmanya.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_Mangalore.webp",
            text: "PTN_PkG_0004_Mangalore.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_Murudeshwara.webp",
            text: "PTN_PkG_0004_Murudeshwara.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_Shravanabelagola.webp",
            text: "PTN_PkG_0004_Shravanabelagola.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_Sringeri.webp",
            text: "PTN_PkG_0004_Sringeri.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0004_Udupi.webp",
            text: "PTN_PkG_0004_Udupi.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 21000,
            text: "per person ",
          },
          {
            packageDuration: "6N/7D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0005",
      },
      {
        title: "package_name",
        content:
          "INCREDIBLE ANCIENT HERITAGE TOUR TO HAMPI-HOSPET-AIHOLE-PATTADAKAL-BADAMI 3N/4D PACKAGE",
      },
      // {
      // title: "places",
      // content: ["Hampi", "Pattadakal", "Aihole", "Badami", "Bellary", "Hospet"],
      // // },
      {
        title: "tour_type",
        content: ["Holiday Package", "Family tour", "Honeymoon Package"],
      },
      {
        title: "Highlights",
        content: [
          "Virupaksha Swamy Temple",
          "Museum",
          "Vijaya Vittala Temple",
          "Mahanavami Dibba",
          "Narasimha Swamy Temple",
          "Hazara Rama Temple",
          "Royal Palace",
          "Raghunath Temple",
          "Aihole",
          "Durga Temple",
          "Lord Shiva Temple",
          "Kontigudi temples",
          "Pattadakal",
          "Jambu Linga",
          "Kada Siddeshvara",
          "Galaganatha",
          "Badami",
          "Cave temples",
          "Badami Fort",
          "Agastya Lake",
          "Tippu's Fort",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "3N/4D",
          Cities: [
            "Hampi",
            "Pattadakal",
            "Aihole",
            "Badami",
            "Bellary",
            "Hospet",
          ],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Hampi", duration: "2N" },
              { name: "Badami", duration: "1N" },
              { name: "Pattadakal", duration: "" },
              { name: "Aihole", duration: "" },
              { name: "Bellary", duration: "" },
              { name: "Hospet", duration: "" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "BANGALORE- HAMPI",
              description:
                "Pick-up from Bengaluru Airport / Railway station (360 Kms/6 Hrs). Transfer to Hampi hotel. Night Stay in Hampi.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "BOULDER-STREWN LANDSCAPE HAMPI",
              description:
                "After breakfast proceed to local sightseeing in and around Hampi covering the places: Virupaksha Swamy Temple, Museum, Vijaya Vittala Temple, Mahanavami Dibba, Narasimha Swamy Temple, Hazara Rama Temple, Royal Palace, Raghunath Temple etc. Night Stay in Hampi.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header:
                "CRADLE OF HINDU TEMPLE ARCHITECTURE AIHOLE – UNESCO WORLD HERITAGE CHISELLED TEMPLES PATTADAKAL – PICTURESQUE  SCULPTURES BADAMI",
              description:
                "After early breakfast, proceed to Aihole, Pattadakal, and Badami. Visit various temples and sites of historical significance. Transfer to a hotel in Badami. Night Stay in Badami.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "BADAMI – BELLARY",
              description:
                "After breakfast, check out the hotel and then depart back to Bellary (200 Kms/4 Hrs) & transfer to Airport / Railway Station for return journey.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Hampi", "Badami"],
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0005_Aihole.webp",
            text: "PTN_PkG_0005_Aihole.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0005_Badami1.webp",
            text: "PTN_PkG_0005_Badami1.webp",
          },
          // {
          //   carousel_ima: "/PTN_PkG_0005_Badami2.webp",
          //   text: "PTN_PkG_0005_Badami2.webp",
          // },
          {
            carousel_ima: "/PTN_PkG_0005_Hampi1.webp",
            text: "PTN_PkG_0005_Hampi1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0005_Hampi2.webp",
            text: "PTN_PkG_0005_Hampi2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0005_Hospet.webp",
            text: "PTN_PkG_0005_Hospet.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0005_Pattadhkallu1.webp",
            text: "PTN_PkG_0005_Pattadhkallu1.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 16000,
            text: "per person",
          },
          {
            packageDuration: "3N/4D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0006",
      },
      {
        title: "package_name",
        content: "SPIRITUAL DIVINE TAMILNADU 5N/6D PACKAGE",
      },
      // {
      // title: "places",
      // content: [
      //   "Kanyakumari",
      //   "Thanjavur",
      //   "Rameswaram",
      //   "Trichy",
      //   "Madurai",
      //   "Mahabalipuram",
      //   "Pondicherry",
      //   "Srirangam",
      // ],
      // },
      {
        title: "tour_type",
        content: ["Holiday Package", "Family tour"],
      },
      {
        title: "Highlights",
        content: [
          "Kapaleshwar Temple",
          "Fort St. George",
          "Government Museum",
          "Marina Beach",
          "Mahabalipuram",
          "Pallava's Panch Rathas",
          "Arjun Shilanyas",
          "Pondicherry",
          "Sri Aurobindo Ashram",
          "Ganesh Mandir",
          "Brihadeshwara Shiva Temple",
          "Lord Vishnu Temple at Srirangam",
          "Ramanath Swamy Temple",
          "Vivekananda Rock",
          "Thanumalayam Temple",
          "Meenakshi Temple",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "5N/6D",
          Cities: [
            "Kanyakumari",
            "Thanjavur",
            "Rameswaram",
            "Trichy",
            "Madurai",
            "Mahabalipuram",
            "Pondicherry",
            "Srirangam",
          ],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Chennai", duration: "1N" },
              { name: "Tanjore", duration: "1N" },
              { name: "Rameswaram", duration: "1N" },
              { name: "Kanyakumari", duration: "1N" },
              { name: "Madurai", duration: "1N" },
              
              { name: "Thanjavur", duration: "" },
              { name: "Trichy", duration: "" },
              { name: "Mahabalipuram", duration: "" },
              { name: "Pondicherry", duration: "" },
              { name: "Srirangam", duration: "" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "SOUTH GATEWAY CHENNAI",
              description:
                "Arrive Chennai and transfer to the Hotel. Afternoon proceed on a sightseeing tour of the city, visiting Kapaleshwar Temple, Fort St. George, Government Museum and Marina Beach. Night Stay in Chennai.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header:
                "SOUTH GATEWAY CHENNAI – UNESCO CULTURAL HERITAGE MAHABALIPURAM – FRENCH HERITAGE SCENIC COASTLINE PONDICHERRY – CULTURAL HERITAGE TANJAVUR",
              description:
                "After breakfast, proceed to Tanjore, visiting Mahabalipuram, Pondicherry enroute. Upon arrival at Tanjore, visit Brihadeshwara Shiva Temple. Night Stay in Tanjore.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header:
                "CULTURAL HERITAGE TANJAVUR – RICH HISTORY & CULTURAL HERITAGE CITY TRICHY – PILIGRIMAGE ISLAND TEMPLE CITY RAMESHWARAM",
              description:
                "Proceed to Trichy to visit Lord Vishnu Temple at Srirangam, then depart to Rameswaram. Upon arrival at Rameswaram, visit Ramanath Swamy Temple. Night Stay in Rameswaram.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header:
                "PILIGRIMAGE ISLAND TEMPLE CITY RAMESHWARAM – SOUTHERN MOST TIP KANYAKUMARI",
              description:
                "Visit Ramanath Swamy Temple and then proceed to Kanyakumari. Visit Vivekananda Rock and enjoy Sunrise. Night Stay in Kanyakumari.",
              meal: "",
            },
            {
              dayname: "Day 05",
              header:
                "SOUTHERN MOST TIP KANYAKUMARI - SUCHINDRAM – CULTURAL CAPITAL TEMPLE CITY MADURAI",
              description:
                "Enjoy Sunrise at Kanyakumari, then proceed to Suchindram and visit Thanumalayam Temple. Later, proceed to Madurai and visit Meenakshi Temple. Night Stay in Madurai.",
              meal: "",
            },
            {
              dayname: "Day 06",
              header: "MADURAI – BENGALURU",
              description:
                "Morning checkout from hotel and proceed to Bengaluru.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: [
          "Chennai",
          "Tanjore",
          "Rameswaram",
          "Kanyakumari",
          "Madurai",
          "Bengaluru",
        ],
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0006_Kanyakumari.webp",
            text: "PTN_PkG_0006_Kanyakumari.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0006_Madurai.webp",
            text: "PTN_PkG_0006_Madurai.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0006_Mahabalipuram.webp",
            text: "PTN_PkG_0006_Mahabalipuram.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0006_Pondicherry.webp",
            text: "PTN_PkG_0006_Pondicherry.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0006_Rameshwaram.webp",
            text: "PTN_PkG_0006_Rameshwaram.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0006_Srirangam.webp",
            text: "PTN_PkG_0006_Srirangam.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0006_Tanjavur.webp",
            text: "PTN_PkG_0006_Tanjavur.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0006_Trichy.webp",
            text: "PTN_PkG_0006_Trichy.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 25950,
            text: "per person",
          },
          {
            packageDuration: "5N/6D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0007",
      },
      {
        title: "package_name",
        content: "KERALA BACKWATERS ALAPPUZHA RESORT AND HOUSEBOAT",
      },
      // {
      // title: "places",
      // content: ["Kerala, Alleppey"],
      // },
      {
        title: "tour_type",
        content: ["Honeymoon package", "Family Package", "Group Package"],
      },
      {
        title: "Highlights",
        content: ["Honeymoon package", "Family Package", "Group Package"],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "3N/4D",
          Cities: ["Alappuzha"],
          Country: ["India"],
          CountryNames: {
            India: [{ name: "Alappuzha", duration: "3N" }],
          },
          Days: [
            {
              dayname: "Day 01:",
              header: "ALAPPUZHA BACKWATERS RESORT",
              description: "Check-in to Resort/Hotel. Night Stay in Alappuzha.",
              meal: "",
            },
            {
              dayname: "Day 02:",
              header: "ALAPPUZHA HOUSEBOAT",
              description:
                "After Breakfast Check in to the Single bedroom luxury houseboat at 12:30 pm followed by Lunch at the Roof. Cruising at the House boat with Evening tea/coffee and snacks. Stay with complementary Candlelight dinner. Night Stay in Alappuzha Houseboat.",
              meal: "Breakfast, Lunch, dinner",
            },
            {
              dayname: "Day 03:",
              header: "ALAPPUZHA HOUSEBOAT",
              description:
                "Start your day with Breakfast at luxury houseboat. Check out from the luxury houseboat at 09:00 AM. Stay at Lake-view villa, Complementary Full body massage for the couple followed by lunch. One hour of evening sunset cruise with evening tea/coffee and snacks. Dinner at lakeside. Night Stay at Resort in Alappuzha.",
              meal: "Breakfast, Lunch, dinner",
            },
            {
              dayname: "Day 04:",
              header: "ALAPPUZHA BACKWATERS RESORT",
              description: "Breakfast by the Pool. Check out at 12.00 PM",
              meal: "Breakfast",
            },
          ],
        },
      },

      {
        title: "Package Inclusion",
        content: [
          {
            header: "Room Type",
            subDetail: [
              "Option 1: Lake View Villa",
              "Option 2: Pool View duplex Villa",
            ],
          },
          {
            header: "Facilities in Resort",
            subDetail: [
              "Swimming Pool (Access based on Government rules)",
              "House Boat",
              "Cruise",
              "Ayurveda and Yoga Service",
            ],
          },
          {
            header: "Room Package Inclusions",
            subDetail: [
              "Welcome Drink",
              "Breakfast, Lunch, and Dinner",
              "Candle Light dinner",
              "Luxury houseboat stay",
              "Cruise",
              "Full Body massage for couple",
            ],
          },
        ],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "destinaions",
        content: ["Alappuzha"],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0007_Alleppy1.webp",
            text: "PTN_PkG_0007_Alleppy1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0007_Alleppy2.webp",
            text: "PTN_PkG_0007_Alleppy2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0007_Alleppy3.webp",
            text: "PTN_PkG_0007_Alleppy3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0007_Alleppy4.webp",
            text: "PTN_PkG_0007_Alleppy4.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 41999,
            text: "Per Couple",
          },
          {
            packageDuration: "3N/4D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0008",
      },
      {
        title: "package_name",
        content: "GREEN QUEEN WAYANAD HONEYMOON",
      },
      // {
      // title: "places",
      // content: ["Waynad"],
      // },
      {
        title: "tour_type",
        content: ["Honeymoon package", "Family Package", "Group Package"],
      },
      {
        title: "Highlights",
        content: [
          "Off-road drive to resort",
          "Wooden Cottage and Suit Room accommodation",
          "Infinity Swimming Pool",
          "Cycling",
          "Campfire",
          "Trekking",
          "Bird Watching",
          "Nature Walk to River Stream",
          "Guided Trekking through plantations",
          "Aromatherapy massage",
          "Pookode lake sightseeing",
          "Chain Tree visit",
          "View Point visit",
          "Campfire with fireflies",
          "Delicious meals including breakfast, lunch, and dinner",
          "Candle Light Dinner",
          "Welcome amenities: Chocolate, Fruit Basket, Red Wine",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "2N/3D",
          Cities: ["Wayanad"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Wayanad", duration: "2N" },
              { name: "Pookode Lake", duration: "" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "HEAVEN OF KERALA WAYANAD",
              description:
                "Pickup from Resort Car parking and beginning of Off-road drive to resort. Arrival and Check-In at resort. Relax in cozy Wooden Cottage or Suit Room. Enjoy traditional lunch, snacks, and campfire. Dine by candle light. Night Stay in Wayanad Resort.",
              meal: "Lunch, dinner",
            },
            {
              dayname: "Day 02",
              header: "POOKODE LAKE HEAVEN OF KERALA WAYANAD",
              description:
                "Guided trekking among the cardamom plantations. Refreshing breakfast. Choose between aromatherapy massage or half day sightseeing to Pookode lake, Chain Tree, and View Point. Enjoy campfire with fireflies and Delicious dinner. Night Stay in Wayanad Resort.",
              meal: "Breakfast, Lunch, dinner",
            },
            {
              dayname: "Day 03",
              header: "WAYANAD",
              description:
                "Scrumptious breakfast. Check out at 10am. Optional: Accommodation shift to tree-house, 90 minutes couples aromatherapy massage, additional lunch package. Romantic walk through the resort and tasty snacks served at Tea time.",
              meal: "Breakfast, Lunch",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Wayanad"],
      },
      {
        title: "Package Inclusion",
        content: [
          {
            header: "Room Type",
            subDetail: ["Wooden Cottage", "Suit Room"],
          },
          {
            header: "Facilities in Resort",
            subDetail: [
              "Infinity Swimming Pool (Access based on Government rules)",
              "Cycling",
              "Campfire",
              "Trekking",
              "Bird Watching",
              "Nature Walk to River Stream",
            ],
          },
          {
            header: "Room Package Inclusions",
            subDetail: [
              "Breakfast, Lunch, and Dinner with campfire",
              "Wooden Cottage stay and Suit room with flower decoration",
              "Candle Light Dinner for 1 night",
              "Chocolate, Fruit, and Wine for 1st night stay",
              "Guided Trekking to plantation and use of all resort facilities",
            ],
          },
        ],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0008_Wayanad1.webp",
            text: "PTN_PkG_0008_Wayanad1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0008_Wayanad2.webp",
            text: "PTN_PkG_0008_Wayanad2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0008_Wayanad3.webp",
            text: "PTN_PkG_0008_Wayanad3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0008_Wayanad4.webp",
            text: "PTN_PkG_0008_Wayanad4.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0008_Wayanad5.webp",
            text: "PTN_PkG_0008_Wayanad5.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 27700,
            text: "Per Couple",
          },
          {
            packageDuration: "2N/3D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0009",
      },
      {
        title: "package_name",
        content: "GREEN QUEEN WAYANAD HONEYMOON",
      },
      // {
      // title: "places",
      // content: ["Waynad"],
      // },
      {
        title: "tour_type",
        content: ["Honeymoon package", "Family Package", "Group Package"],
      },
      {
        title: "Highlights",
        content: [
          "Resort check-in and relaxation",
          "Visit to nearby Waterfall",
          "Banasura Sagar dam visit",
          "Tea Plantation visit",
          "Museum and Tea Factory visit",
          "Pookode lake visit",
          "Kuruva Island visit",
          "Tholpetty Wildlife Sanctuary Visit",
          "Thirunelly Temple visit",
          "Edakkal Caves visit",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "3N/4D",
          Cities: ["Wayanad"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Wayanad", duration: "3N" },
              { name: "Banasura Sagar Dam", duration: "" },
              { name: "Kuruva Island", duration: "" },
              { name: "Edakkal Caves", duration: "" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "HEAVEN OF KERALA WAYANAD",
              description:
                "Arrival and Check-In at resort. Relax and enjoy at Resort followed by lunch. Visit to nearby Waterfall. Night Stay in Wayanad Resort.",
              meal: "Lunch",
            },
            {
              dayname: "Day 02",
              header: "BANASURA SAGAR DAM – TEA PLANTATION VISIT WAYANAD",
              description:
                "Banasura Sagar dam visit, Tea Plantation visit, Museum and Tea Factory visit. Pookode lake visit with various boating options available. Night Stay in Wayanad Resort.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header: "KURUVA ISLAND VISIT – THOLPETTY WILDLIFE SANCTUARY",
              description:
                "Kuruva Island visit, Tholpetty Wildlife Sanctuary Visit, Thirunelly Temple visit. Night Stay in Wayanad Resort.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "EDAKKAL CAVES",
              description: "Check-out from resort/hotel. Edakkal Caves visit.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Wayanad"],
      },
      {
        title: "Package Inclusion",
        content: [
          {
            header: "Room Type",
            subDetail: ["Luxury Room"],
          },
          {
            header: "Facilities in Resort",
            subDetail: [
              "Infinity Swimming Pool (Access based on Government rules)",
              "Cycling",
              "Campfire",
              "Trekking",
              "Bird Watching",
              "Nature Walk to River Stream",
            ],
          },
          {
            header: "Room Package Inclusions",
            subDetail: [
              "Breakfast and Dinner",
              "Luxury room with Candle Light Dinner for 1 night",
              "Bed Flower Decoration",
              "Cake",
              "Walking tour/Nature walk to river Streams",
            ],
          },
        ],
      },

      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0009_Wayanad1.webp",
            text: "PTN_PkG_0009_Wayanad1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0009_Wayanad2.webp",
            text: "PTN_PkG_0009_Wayanad2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0009_Wayanad3.webp",
            text: "PTN_PkG_0009_Wayanad3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0009_Wayanad4.webp",
            text: "PTN_PkG_0009_Wayanad4.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0009_Wayanad5.webp",
            text: "PTN_PkG_0009_Wayanad5.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 36000,
            text: "Per Couple",
          },
          {
            packageDuration: "3N/4D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0010",
      },
      {
        title: "package_name",
        content: "DISCOVER PARADISE ON THE EARTH KASHMIR",
      },
      // {
      // title: "places",
      // content: ["Srinagar", "Gulmarg", "Pahalgam", "Sonmarg"],
      // },
      {
        title: "tour_type",
        content: ["Honeymoon package", "Family Package", "Group Package"],
      },
      {
        title: "Highlights",
        content: [
          "Shikara Ride at Dal Lake",
          "Nishat Garden",
          "Shalimar Garden",
          "Chashme Shahi",
          "Parimahal",
          "Gulmarg Ski Resort",
          "Jai Jai Shiv Shankar Temple",
          "Gondola ride from Gulmarg to Kongdori and Affarwat",
          "Pahalgam (The Valley of Shepherds)",
          "Lidder stream",
          "Pony trails with mountain view",
          "Betaab valley",
          "Aru",
          "Chandanwari",
          "Sonamarg (Meadow of Gold)",
          "Sindh meanders",
          "Thajiwas glacier",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "4N/5D",
          Cities: ["Srinagar", "Gulmarg", "Pahalgam", "Sonmarg"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Srinagar", duration: "3N" },
              { name: "Dal lake", duration: "" },
              { name: "Jai Shiv Shankar Temple", duration: "" },
              { name: "Gondola Ride", duration: "" },
              { name: "Pahalgam", duration: "1N" },
              { name: "Gulmarg", duration: "" },
              { name: "Sonmarg", duration: "" },
              { name: "Aru Valley", duration: "" },
              { name: "Betab Valley", duration: "" },
              { name: "Chandanwari Valley", duration: "" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "LAKES & HOUSEBOAT IN SRINAGAR",
              description:
                "Meet and greet on arrival. Transfer to Srinagar hotel. Enjoy Shikara Ride at Dal Lake and local sightseeing. Night Stay in Srinagar Houseboat.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "SKI RESORT TOWN GULMARG",
              description:
                "Drive to Gulmarg. Visit Gulmarg Ski Resort and Jai Jai Shiv Shankar Temple. Enjoy Gondola ride from Gulmarg to Kongdori and Affarwat. Night Stay in Srinagar.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header: "THE VALLEY OF SHEPHERDS PAHALGHAM",
              description:
                "Drive to Pahalgam. Visit saffron fields of pampore, Awantipura Ruins, and Lidder stream. Enjoy pony trails and visit Betaab valley, Aru, and Chandanwari. Night Stay in Pahalgam.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "BEAUTIFUL HILL STATION SONAMARG",
              description:
                "Full day trip to Sonamarg. Visit Sindh meanders and Thajiwas glacier. Night Stay in Srinagar.",
              meal: "",
            },
            {
              dayname: "Day 05",
              header: "BACK TO SRINAGAR AIRPORT",
              description:
                "Check-out from hotel and transfer to Srinagar airport.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Srinagar", "Pahalgam"],
      },
      {
        title: "Package Inclusion",
        content: [
          { header: "MEET AND GREET ON ARRIVAL" },
          { header: "PICK UP AND DROP BACK SRINAGAR AIRPORT" },
          { header: "01 DOUBLE ROOMS" },
          { header: "MEAL PLAN :- ROOM + DINNER + BREAKFAST" },
          { header: "HOTEL FOR 03 NIGHTS" },
          { header: "DELUXE HOUSEBOAT IN SRINAGAR FOR 01 NIGHT" },
          { header: "TRANSPORTATION BY PVT CAR NON AC" },
          { header: "SHIKARA RIDE COMPLIMENTARY AT DAL LAKE" },
          { header: "NO ADDITIONAL TAXES" },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          { header: "ANY KIND OF AIRFARE" },
          { header: "TIPS, DRINKS, LAUNDRY, TELEPHONE CHARGES" },
          {
            header:
              "UNION CARS, GONDOLA RIDE, HORSE RIDE, CHAIN CARS, NOW JEEPS, ATV CARS, SNOW ACTIVITIES",
          },
          { header: "ANYTHING WHICH IS NOT SPECIFIED IN THE INCLUSIONS" },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0010_Gulmarg1.webp",
            text: "PTN_PkG_0010_Gulmarg1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0010_Gulmarg2.webp",
            text: "PTN_PkG_0010_Gulmarg2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0010_Pahlgham1.webp",
            text: "PTN_PkG_0010_Pahlgham1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0010_Pahlgham2.webp",
            text: "PTN_PkG_0010_Pahlgham2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0010_Sonmarg1.webp",
            text: "PTN_PkG_0010_Sonmarg1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0010_Sonmarg2.webp",
            text: "PTN_PkG_0010_Sonmarg2.webp",
          },
          // {
          //   carousel_ima: "/PTN_PkG_0010_Srinagar1.jpe",
          //   text: "PTN_PkG_0010_Srinagar1.webp",
          // },
          {
            carousel_ima: "/PTN_PkG_0010_Srinagar2.webp",
            text: "PTN_PkG_0010_Srinagar2.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 27999,
            text: "Per Couple",
          },
          {
            packageDuration: "4N/5D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0011",
      },
      {
        title: "package_name",
        content: "DISCOVER HEAVEN ON THE EARTH KASHMIR",
      },
      // {
      // title: "places",
      // content: ["Srinagar", "Gulmarg", "Pahalgam", "Sonmarg"],
      // },
      {
        title: "tour_type",
        content: ["Honeymoon package", "Family Package", "Group Package"],
      },
      {
        title: "Highlights",
        content: [
          "Nishat Garden",
          "Shalimar Garden",
          "Cheshme Shahi",
          "Parimahal",
          "Shanakracharya Temple",
          "Pahalgam (The Valley of Shepherds)",
          "Saffron fields of pampore",
          "Awantipura Ruins",
          "Lidder stream",
          "Gulmarg Ski Resort",
          "Jai Jai Shiv Shankar Temple",
          "Gondola ride from Gulmarg to Kongdori and Affarwat",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "7N/8D",
          Cities: ["Shrinagar", "Pahalgam", "Gulmarg", "Sonmarg"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Srinagar", duration: "3N" },
              { name: "Pahalgam", duration: "2N" },
              { name: "Gulmarg", duration: "2N" },
              // { name: "Dal lake", duration: "" },
              { name: "Jai Shiv Shankar Temple", duration: "" },
              { name: "Gondola Ride", duration: "" },

              { name: "Gulmarg", duration: "" },
              { name: "Sonmarg", duration: "" },
              { name: "Aru Valley", duration: "" },
              { name: "Betab Valley", duration: "" },
              { name: "Chandanwari Valley", duration: "" },
              { name: "Shanakracharya Temple", duration: "" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "LAKES & HOUSEBOAT IN SRINAGAR",
              description:
                "Meet and greet on arrival. Transfer to Srinagar hotel. Local sightseeing and Overnight stay in SRINAGAR hotel.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "LAKES & HOUSEBOAT IN SRINAGAR",
              description:
                "Visit to Cheshme shahi, Parimahal, Shanakracharya Temple. Overnight stay in SRINAGAR Hotel.",
              meal: "",
            },
            {
              dayname: "Day 03 & Day 04",
              header: "THE VALLEY OF SHEPHERDS PAHALGHAM",
              description:
                "Drive to Pahalgam. Visit saffron fields of pampore, Awantipura Ruins, and Lidder stream. Enjoy pony trails and visit mini-Switzerland, green valley, Kashmir valley, Bobby house. Evening return dinner and night Stay at PAHALGHAM Hotel.",
              meal: "",
            },
            {
              dayname: "Day 05 & Day 06",
              header: "SKI RESORT TOWN GULMARG WITH GONDOLA RIDE",
              description:
                "Drive to Gulmarg. Visit Gulmarg Ski Resort and Jai Jai Shiv Shankar Temple. Enjoy Gondola ride from Gulmarg to Kongdori and Affarwat. Travel by locally hired Union car to Aru, Betab Valley, Chandanwari, River lidder. Evening return dinner and night stay at GULMARG Hotel.",
              meal: "",
            },
            {
              dayname: "Day 07",
              header: "LAKES & HOUSEBOAT IN SRINAGAR",
              description:
                "Drive back to Srinagar. Check In to House boat. Enjoy Shikara ride and evening visit to market for shopping. Overnight stay in SRINAGAR Hotel.",
              meal: "",
            },
            {
              dayname: "Day 08",
              header: "DROP BACK",
              description:
                "Check out from House Boat and transfer to Srinagar airport.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Srinagar", "Pahalgam", "Gulmarg"],
      },
      {
        title: "Package Inclusion",
        content: [
          { header: "MEET AND GREET ON ARRIVAL" },
          { header: "PICK UP AND DROP BACK SRINAGAR AIRPORT" },
          { header: "03 DOUBLE ROOMS" },
          { header: "MEAL PLAN :- ROOM + DINNER + BREAKFAST" },
          { header: "HOTEL FOR 06 NIGHTS" },
          { header: "HOUSEBOAT IN SRINAGAR FOR 01 NIGHT" },
          { header: "TRANSPORTATION BY PVT CAR NON AC - 3" },
          { header: "SHIKARA RIDE COMPLIMENTARY AT DAL LAKE" },
          { header: "VEGETERIAN MEAL" },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          { header: "ANY KIND OF AIRFARE" },
          { header: "TIPS, DRINKS, LAUNDRY, TELEPHONE CHARGES" },
          {
            header:
              "UNION CARS, GONDOLA RIDE, HORSE RIDE, CHAIN CARS, NOW JEEPS, ATV CARS, SNOW ACTIVITIES",
          },
          { header: "TRAVEL INSURANCE" },
          { header: "GUIDE CHARGES" },
          { header: "TELEPHONE" },
          { header: "GST 5%" },
          { header: "ANYTHING WHICH IS NOT SPECIFIED IN THE INCLUSIONs" },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0011_Gulmarg1.webp",
            text: "PTN_PkG_0011_Gulmarg1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0011_Gulmarg2.webp",
            text: "PTN_PkG_0011_Gulmarg2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0011_Pahlgham1.webp",
            text: "PTN_PkG_0011_Pahlgham1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0011_Pahlgham2.webp",
            text: "PTN_PkG_0011_Pahlgham2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0011_Sonmarg1.webp",
            text: "PTN_PkG_0011_Sonmarg1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0011_Sonmarg2.webp",
            text: "PTN_PkG_0011_Sonmarg2.webp",
          },
          // {
          //   carousel_ima: "/PTN_PkG_0011_Srinagar1.jpe",
          //   text: "PTN_PkG_0011_Srinagar1.jpe",
          // },
          {
            carousel_ima: "/PTN_PkG_0011_Srinagar2.webp",
            text: "PTN_PkG_0011_Srinagar2.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 74900,
            text: "Per Couple",
          },
          {
            packageDuration: "7N/8D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0012",
      },
      {
        title: "package_name",
        content: "4N/5D HONEYMOON PACKAGE",
      },
      // {
      // title: "places",
      // content: ["Kodaikanal"],
      // },
      {
        title: "tour_type",
        content: ["Honeymoon package", "Family Package", "Group Package"],
      },
      {
        title: "Highlights",
        content: [
          "Kodai city view",
          "Kurinji Andavar temple",
          "Palani view",
          "Chettiar Park",
          "Jain temple",
          "Museum",
          "Silver waterfalls",
          "Kodai Lake",
          "Bear Shola falls",
          "La Saleth church",
          "500 years old tree",
          "Dolphin nose",
          "Mountain beauty",
          "Vattakkanal falls",
          "Fairy falls",
          "Loin cave",
          "Observatory pine forest",
          "Gundar falls",
          "Mahalakshmi temple",
          "Poombarai village view",
          "Kulandai velappar temple",
          "Mannavanur Lake view",
          "Mannavanur Lake",
          "Coakers walk",
          "Green valley view",
          "Golf course",
          "Pillar rocks",
          "Devil kitchen",
          "Pine tree forest",
          "Moier point",
          "Upper Lake view",
          "Bryant park",
          "Kodai Lake cycling",
          "Horse riding",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "4N/5D",
          Cities: ["Kodaikanal"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Kodaikanal", duration: "4N" },
              { name: "Kodai city view", duration: "" },
              { name: "Kurinji Andavar temple", duration: "" },
              { name: "Palani view", duration: "" },

              { name: "Museum", duration: "" },
              { name: "Silver waterfalls", duration: "" },
              { name: "Kodai Lake", duration: "" },
              { name: "Bear Shola falls", duration: "" },
              { name: "La Saleth church", duration: "" },

              { name: "Dolphin nose", duration: "" },

              { name: "Vattakkanal falls", duration: "" },

              { name: "Gundar falls", duration: "" },
              { name: "Mahalakshmi temple", duration: "" },
              { name: "Poombarai village view", duration: "" },
              { name: "Kulandai velappar temple", duration: "" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "NATURE’S PARADISE KODAIKANAL",
              description:
                "Check-in to Kodaikanal Hotel. Visit Kodai city view, Kurinji Andavar temple, Palani view, Chettiar Park, Jain temple, Museum, Silver waterfalls, and Kodai Lake. Overnight stay in Kodaikanal Hotel.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "BEAUTY OF KODAIKANAL AND FALLS VISIT",
              description:
                "Visit Bear Shola falls, La Saleth church, 500 years old tree, Dolphin nose, Mountain beauty, Vattakkanal falls, Fairy falls, and Loin cave. Overnight stay in Kodaikanal Hotel.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header: "VILLAGE, TEMPLE & LAKE OF KODAIKANAL VISIT",
              description:
                "Visit Village Tour, Observatory pine forest, Gundar falls, Mahalakshmi temple, Poombarai village view, Kulandai velappar temple, Mannavanur Lake view, and Mannavanur Lake. Overnight stay in Kodaikanal Hotel.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "VALLEY VIEW OF KODAIKANAL",
              description:
                "Visit Coakers walk, Green valley view, Golf course, Pillar rocks, Devil kitchen, Pine tree forest, Moier point, Upper Lake view, Bryant park, Kodai Lake cycling, and horse riding. Overnight stay in Kodaikanal Hotel.",
              meal: "",
            },
            {
              dayname: "Day 05",
              header: "KODAIKANAL",
              description: "Check out from hotel.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Kodaikanal"],
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0012_Kodaikanal1.webp",
            text: "PTN_PkG_0012_Kodaikanal1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0012_Kodaikanal2.webp",
            text: "PTN_PkG_0012_Kodaikanal2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0012_Kodaikanal3.webp",
            text: "PTN_PkG_0012_Kodaikanal3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0012_Kodaikanal4.webp",
            text: "PTN_PkG_0012_Kodaikanal4.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0012_Kodaikanal5.webp",
            text: "PTN_PkG_0012_Kodaikanal5.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 34900,
            text: "Per Couple",
          },
          {
            packageDuration: "4N/5D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0013",
      },
      {
        title: "package_name",
        content:
          "HILL STATION OVER-LOOKING TEA ESTATE MUNNAR – CARDAMOM HILLS THEKKADY – BACK-WATERS & CRUISE IN ALAPPUZHA",
      },
      // {
      // title: "places",
      // content: ["Munnar", "Thekkady", "Alleppey"],
      // },
      {
        title: "tour_type",
        content: ["Honeymoon package", "Family Package", "Group Package"],
      },
      {
        title: "Highlights",
        content: [
          "Explore Munnar, Thekkady, Alleppey, and Cochin in 5 Days",
          "Experience the beauty of tea estates, waterfalls, and rivers in Munnar",
          "Enjoy activities in the Periyar Tiger Reserve in Thekkady",
          "Experience the backwaters and houseboat cruise in Alleppey",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "4N/5D",
          Cities: ["Munnar", "Thekkady", "Alleppey"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Munnar", duration: "2N" },
              { name: "Thekkady", duration: "1N" },
              { name: "Alappuzha", duration: "1N" },
              
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "LAND OF TEA ESTATE, WATERFALLS & RIVERS IN MUNNAR",
              description:
                "Arrive in Cochin, pickup and drive to Munnar (140 km / 4 Hours). Overnight stay in Munnar Resort/Hotel.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "HILLSTATION OVERLOOKING TEA ESTATES IN MUNNAR",
              description:
                "Munnar one day Excursion with splendid waterfalls, tea estates, and rivers.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header: "LORD OF JUNGLE & CARDAMOM HILLS OF THEKKADY",
              description:
                "Enjoy all activities in the Periyar Tiger reserve. Drive from Munnar to Thekkady (110 km / 3 Hours).",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "BACK-WATERS & HOUSE-BOAT CRUISE IN ALAPPUZHA",
              description:
                "Alappuzha is all about lakes and backwaters. Drive from Thekkady to Alleppey (160 km / 4 Hours).",
              meal: "",
            },
            {
              dayname: "Day 05",
              header: "BACK TO COCHIN",
              description:
                "Check out from hotel. Drive from Alleppey to Cochin (90 km / 2 Hours). End of Tour.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Munnar", "Thekkady", "Alappuzha", "Cochin"],
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0013_Alleppy1.webp",
            text: "PTN_PkG_0013_Alleppy1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0013_Alleppy2.webp",
            text: "PTN_PkG_0013_Alleppy2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0013_Alleppy3.webp",
            text: "PTN_PkG_0013_Alleppy3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0013_Alleppy4.webp",
            text: "PTN_PkG_0013_Alleppy4.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0013_Munnar1.webp",
            text: "PTN_PkG_0013_Munnar1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0013_Munnar2.webp",
            text: "PTN_PkG_0013_Munnar2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0013_Thekkadi1.webp",
            text: "PTN_PkG_0013_Thekkadi1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0013_Thekkadi2.webp",
            text: "PTN_PkG_0013_Thekkadi2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0013_Thekkadi3.webp",
            text: "PTN_PkG_0013_Thekkadi3.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 3000,
            text: "Per Night",
          },
          {
            packageDuration: "4N/5D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0014",
      },
      {
        title: "package_name",
        content: "DIVINE SPIRITUAL TOUR- VARANASI, AYODHYA 7N/8D PACKAGE",
      },
      // {
      // title: "places",
      // content: ["Varanasi", "Prayagraj", "Ayodhya", "Lucknow", "Nimisharanya"],
      // },
      {
        title: "tour_type",
        content: ["Family Tour", "Spiritual Tour", "Yatra"],
      },
      {
        title: "Highlights",
        content: [
          "Visit Kashi Vishwanath Temple, Vishalakshi & Annapurna temple.",
          "Witness the World-Famous Ganga Aarti at Dashashwamedh Ghat.",
          "Visit Sarnath - a very important place of pilgrimage for Buddhists.",
          "Explore Sangam - the sacred spot of the confluence of three holy rivers.",
          "Visit Ayodhya - the birthplace of Lord Rama, Saryu River, Shri Hanuman Garhi Mandir, Dashrath Bhawan, Kanak Bhawan.",
          "Explore Lucknow - Moti Mahal, La Martiniere, Bara Imam Bara & Chhota Imam Bara.",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "7N/8D",
          Cities: [
            "Varanasi",
            "Prayagraj",
            "Ayodhya",
            "Lucknow",
            "Nimisharanya",
          ],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Varanasi", duration: "2N" },
              { name: "Chitrakoot", duration: "1N" },
              { name: "Prayagraj", duration: "1N" },
              { name: "Ayodhya", duration: "1N" },
              { name: "Lucknow", duration: "2N" },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "VARANASI ARRIVAL & GANGA AARTI",
              description:
                "Meet & greet and transfer to the hotel. Witness the World-Famous Ganga Aarti at Dashashwamedh Ghat. Visit Kashi Vishwanath Temple, Vishalakshi & Annapurna temple. Stay overnight at Varanasi.",
              meal: "Dinner",
            },
            {
              dayname: "Day 2",
              header: "VARANASI LOCAL & SARNATH",
              description:
                "Visit Baba Kal Bhairav, Sankat Mochan, Tulsi Manas Temple, Durga Ji and Birla Temple. Drive to Sarnath, visit Dhamek Stupa, Sarnath Archaeological Museum, Tibetan Buddhist Monastery, Ashoka Pillar. Visit Namo ghat statues in a welcome gesture. Stay overnight at Varanasi.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 3",
              header: "VARANASI – VINDHYACHAL - CHITRAKOOT",
              description:
                "Drive to Vindhyachal, visit Goddess Vindhyavasini Temple. Transfer to Chitrakoot, check in to the hotel. Stay overnight at Chitrakoot.",
              meal: "Breakfast, Dinner",
            },
            {
              dayname: "Day 4",
              header: "CHITRAKOOT LOCAL – PRAYAGRAJ",
              description:
                "Visit Chitrakoot- Kamadgiri Temple, Sati Anusuiya Temple, Gupt Godavari, Ramghat, Spathik Shila, Hanuman Dhara. Drive towards Prayagraj, check in to the hotel. Stay overnight at Prayagraj.",
              meal: "Breakfast, Dinner",
            },
            {
              dayname: "Day 5",
              header: "PRAYAGRAJ LOCAL - AYODHYA",
              description:
                "Visit Sangam-the sacred spot of the confluence of three holy rivers. Visit local sightseeing-Hanuman Mandir, Anand Bhawan, Akshay vat. Drive towards Ayodhya, check in to the hotel. Stay overnight at Ayodhya.",
              meal: "Breakfast, Dinner",
            },
            {
              dayname: "Day 6",
              header: "AYODHYA LOCAL - LUCKNOW",
              description:
                "Visit Ayodhya-the birthplace of Lord Rama, Saryu River, Shri Hanuman Garhi Mandir, Dashrath Bhawan, Kanak Bhawan. Transfer to Lucknow, check in to the hotel. Stay overnight at Lucknow.",
              meal: "Breakfast, Dinner",
            },
            {
              dayname: "Day 7",
              header: "LUCKNOW - NAIMISHARANYA - LUCKNOW",
              description:
                "Drive towards Naimisharanya, explore local sightseeing like Chakra Teerth, Dadhichi Kund, Vyas Gaddi, Sooth Gaddi, Swayambhu Manu and Satrupa, Hanuman Gaddi and Pandav Kila. Transfer to Lucknow, stay overnight.",
              meal: "Breakfast, Dinner",
            },
            {
              dayname: "Day 8",
              header: "LUCKNOW LOCAL & DEPARTURE",
              description:
                "Explore Moti Mahal, La Martiniere, Bara Imam Bara & Chhota Imam Bara. Drop-off at Lucknow Airport/Railway station.",
              meal: "Breakfast",
            },
          ],
        },
      },

      {
        title: "destinaions",
        content: [
          "Varanasi",
          "Prayagraj",
          "Ayodhya",
          "Lucknow",
          "Nimisharanya",
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PackageId_0014_Image12.webp",
            text: "PackageId_0014_Image12.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image11.webp",
            text: "PackageId_0014_Image11.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image10.webp",
            text: "PackageId_0014_Image10.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image09.webp",
            text: "PackageId_0014_Image09.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image08.webp",
            text: "PackageId_0014_Image08.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image07.webp",
            text: "PackageId_0014_Image07.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image06.webp",
            text: "PackageId_0014_Image06.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image05.webp",
            text: "PackageId_0014_Image05.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image04.webp",
            text: "PackageId_0014_Image04.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image03.webp",
            text: "PackageId_0014_Image03.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image02.webp",
            text: "PackageId_0014_Image02.webp",
          },
          {
            carousel_ima: "/PackageId_0014_Image01.webp",
            text: "PackageId_0014_Image01.webp",
          },
        ],
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 31999,
            text: "per person",
          },
          {
            packageDuration: "7N/8D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0015",
      },
      {
        title: "package_name",
        content: "EXPERIENCE ESSENCE OF SPIRITUALITY IN BHUTAN 6N/7D PACKAGE",
      },
      // {
      // title: "places",
      // content: ["Bhutan"],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Transfer from Siliguri/Bagdogra to Jaigaon/Phuentsholing at Bhutan border.",
          "Visit Zangto Pelri Lhakhang & rest of the day is leisure. Overnight at Phuentsholing.",
          "Proceed to Bhutan immigration for immigration formalities.",
          "Journey to Thimphu, the Bhutan capital.",
          "Enjoy the beauty of Bhutan Himalaya, waterfall & dram view.",
          "Check into your respective hotel. Evening leisure time at clock tower & market area.",
          "Drive for a full day Thimphu sightseeing.",
          "Visit Buddha point, Institute of Handicraft Emporium & the National Memorial Chorten.",
          "Proceed to Punakha via Dochu la Pass. Overnight at Punakha.",
          "Visit Punakha Dzong & hike to Chimmi Lhakhang.",
          "Proceed to Paro. Evening leisure time at Paro town. Overnight at Paro.",
          "Excursion to Taktsang Monastery & visit Kichu Lhakhang.",
          "Optional full day sightseeing of Paro. Overnight at Paro.",
          "Proceed to Phuentsholing from Paro. Check into your respective hotel. Overnight at Phuentsholing.",
          "Proceed from Phuentsholing to Siliguri (NJP)/ Bagdogra (IXB) for your onward journey.",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "6N/7D",
          Cities: [
            "Siliguri/Bagdogra",
            "Pheuntsholing",
            "Thimphu",
            "Punakha",
            "Paro",
          ],
          Country: ["Bhutan"],
          CountryNames: {
            Bhutan: [
              {
                name: "Phuentsholing",
                duration: "2N",
              },
              {
                name: "Thimphu",
                duration: "1N",
              },
              {
                name: "Punakha",
                duration: "1N",
              },
              {
                name: "Paro",
                duration: "2N",
              },
            ],
            // India: [
            //   {
            //     name: "Siliguri/Bagdogra",
            //     duration: "1N",
            //   },
            // ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Siliguri/Bagdogra to Pheuntsholing",
              description:
                "Transfer from Siliguri/Bagdogra to Jaigaon/Phuentsholing at Bhutan border. Visit Zangto Pelri Lhakhang & rest of the day is leisure. Overnight at Phuentsholing.",
              meal: "",
            },
            {
              dayname: "Day 2",
              header: "Phuentsholing to Thimphu",
              description:
                "Proceed to Bhutan immigration for immigration formalities. Journey to Thimphu, the Bhutan capital. Enjoy the beauty of Bhutan Himalaya, waterfall & dram view. Check into your respective hotel. Evening leisure time at clock tower & market area.",
              meal: "",
            },
            {
              dayname: "Day 3",
              header: "Thimphu – Punakha",
              description:
                "Drive for a full day Thimphu sightseeing. Visit Buddha point, Institute of Handicraft Emporium & the National Memorial Chorten. Proceed to Punakha via Dochu la Pass. Overnight at Punakha.",
              meal: "",
            },
            {
              dayname: "Day 4",
              header: "Punakha, Dochu-La (Pass) – Paro",
              description:
                "Visit Punakha Dzong & hike to Chimmi Lhakhang. Proceed to Paro. Evening leisure time at Paro town. Overnight at Paro.",
              meal: "",
            },
            {
              dayname: "Day 5",
              header: "Paro sightseeing",
              description:
                "Excursion to Taktsang Monastery & visit Kichu Lhakhang. Optional full day sightseeing of Paro. Overnight at Paro.",
              meal: "",
            },
            {
              dayname: "Day 6",
              header: "Paro to Phuentsholing",
              description:
                "Proceed to Phuentsholing from Paro. Check into your respective hotel. Overnight at Phuentsholing.",
              meal: "",
            },
            {
              dayname: "Day 7",
              header: "Phuentsholing to Siliguri/Bagdogra",
              description:
                "Proceed from Phuentsholing to Siliguri (NJP)/ Bagdogra (IXB) for your onward journey.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: [
          "Siliguri/Bagdogra",
          "Pheuntsholing",
          "Thimphu",
          "Punakha",
          "Paro",
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PackageId_0015_Image14.webp",
            text: "PackageId_0015_Image14.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image13.webp",
            text: "PackageId_0015_Image13.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image12.webp",
            text: "PackageId_0015_Image12.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image11.webp",
            text: "PackageId_0015_Image11.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image10.webp",
            text: "PackageId_0015_Image10.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image09.webp",
            text: "PackageId_0015_Image09.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image08.webp",
            text: "PackageId_0015_Image08.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image07.webp",
            text: "PackageId_0015_Image07.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image06.webp",
            text: "PackageId_0015_Image06.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image05.webp",
            text: "PackageId_0015_Image05.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image04.webp",
            text: "PackageId_0015_Image04.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image03.webp",
            text: "PackageId_0015_Image03.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image02.webp",
            text: "PackageId_0015_Image02.webp",
          },
          {
            carousel_ima: "/PackageId_0015_Image01.webp",
            text: "PackageId_0015_Image01.webp",
          },
        ],
      },
      {
        title: "Package Inclusion",
        content: [
          {
            header:
              "Accommodation on twin sharing   hotels (3 Star Accommodation)",
          },
          { header: "English speaking Guide for the sightseeing only" },
          { header: "Airport pick up & drop" },
          {
            header:
              "Transportation and sightseeing as per the itinerary by - Toyota coaster bus and SUV, Hice bus",
          },
          {
            header:
              "Meal plan- daily breakfast, lunch & dinner in the hotel (buffet – set menu) as per the guest requirement",
          },
          { header: "VISA for visiting all restricted areas" },
          { header: "All taxes & services charges" },
          {
            header:
              "Mineral water on long trips both on trek and cultural tours",
          },
          { header: "In big group, one culture show as complementary" },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          { header: "Museum fees and other special entry fees" },
          { header: "Internal flights" },
          {
            header:
              "4 & 5-star accommodations may require an additional premium",
          },
          {
            header:
              "Personal Expenses (Laundry/Drinks / Telephone calls and any personal expenses)",
          },
          { header: "Travel Insurance" },
          {
            header:
              "SDF- sustainable development fee rupee 1200/person / night",
          },
          { header: "Excess baggage charges" },
          { header: "Tips and Gratuity" },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 16500,
            text: "Per Person",
          },
          {
            packageDuration: "6N/7D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0016",
      },
      {
        title: "package_name",
        content: "ADORABLE EUROPE(Milan Departure) - 2024",
      },
      // {
      // title: "places",
      // content: [
      //   "France",
      //   "Belgium",
      //   "The Netherlands",
      //   "Germany",
      //   "Switzerland",
      //   "Liechtenstein",
      //   "Austria",
      //   "Italy",
      //   "Vatican City",
      // ],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 9 countries in 13 Days: France, Belgium, The Netherlands, Germany, Switzerland, Liechtenstein, Austria, Italy, and Vatican City",
          "City tour with a local guide in Paris",
          "Visit to the 3rd level of the Eiffel Tower",
          "Guided tour of Versailles Palace",
          "Full day excitement and thrill at Disneyland® Paris",
          "Enjoy romantic cruise on River Seine",
          "Illumination tour of Paris",
          "Orientation tour of Brussels with a visit to the Mannekin Pis Statue",
          "Entrance to Keukenhof (till 12th May) or Madurodam (from 13th May)",
          "Amsterdam Canal Cruise",
          "Drive to the heart of the Black Forest with a visit to a cuckoo clock demonstration",
          "Entrance to Mercedes Benz Museum in Stuttgart",
          "Visit to the magnificent Rhine Falls at Schaffhausen",
          "Excursion to Jungfraujoch - Top of Europe",
          "Horse-drawn carriage ride at Interlaken",
          "Ride in the world’s first revolving cable car and walk on the Highest suspension bridge in Europe at Mt. Titlis",
          "Orientation tour of Lucerne with Swiss Chocolate Adventure",
          "Guided tour of Vaduz with Mini Train ride",
          "Visit to Swarovski Crystal Museum in Wattens",
          "St. Mark’s square by Private boat in Venice",
          "Gondola ride in Venice",
          "Guided walking city tour of Florence",
          "Visit to Square of Miracles and Photo-stop at the Leaning Tower in Pisa",
          "Guided City tour with a local English speaking guide in Rome",
          "Visit to Trevi Fountain and Vatican Museum & Sistine Chapel",
          "Visit to the Vatican Museum and St. Peter’s Basilica",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "12N/13D",
          Cities: [
            "Paris",
            "Brussels",
            "Amsterdam",
            "Titisee",
            "Stuttgart",
            "Schaffhausen",
            "Interlaken",
            "Engelberg",
            "Lucerne",
            "Vaduz",
            "Innsbruck",
            "Wattens",
            "Venice",
            "Florence",
            "Pisa",
            "Rome",
          ],
          Country: [
            "France",

            "The Netherlands",
            "Germany",
            "Switzerland",

            "Austria",
            "Italy",
          ],
          CountryNames: {
            France: [
              {
                name: "Paris",
                duration: "3N",
              },
            ],
            Netherlands: [
              {
                name: "Netherlands",
                duration: "1N",
              },
            ],
            Germany: [
              {
                name: "Germany",
                duration: "1N",
              },
            ],
            Switzerland: [
              {
                name: "central switzerland",
                duration: "3N",
              },
            ],
            Austria: [
              {
                name: "Innsbruck",
                duration: "1N",
              },
            ],
            Italy: [
              {
                name: "Bologna",
                duration: "1N",
              },
              {
                name: "Tuscany",
                duration: "2N",
              },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Arrive into Paris",
              description:
                "Welcome! Today, we embark on our journey to Paris, a city celebrated for its high fashion, world-famous museums, awe-inspiring landmarks, and captivating cabarets. Upon arrival, you'll be warmly welcomed by our Travel Professional, who will guide you to your hotel and provide assistance with the check-in process. Overnight in Paris. (Dinner)",
              meal: "Dinner",
            },
            {
              dayname: "Day 2",
              header: "visit to the Eiffel Tower",
              description:
                "Today, we embark on a guided city tour of Paris, where you'll have the opportunity to marvel at some of the most exquisite Parisian attractions. Following our city exploration, we'll ascend to the 3rd Level of the iconic Eiffel Tower and visit the Palace of Versailles. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 3",
              header: "Full day at Disneyland®",
              description:
                "Today promises a day filled with excitement at Disneyland® Paris, followed by a romantic cruise along the picturesque Seine River. After the cruise, we proceed for a captivating illumination tour of Paris. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 4",
              header: "Visit to Keukenhof Gardens or Madurodam",
              description:
                "Today, our journey takes us to Brussels, known as the headquarters of the European Union. Upon arriving in Brussels, our first stop is at the Grand Place, followed by a visit to the Keukenhof Gardens or Madurodam. Overnight in Netherlands. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 5",
              header: "Amsterdam Canal Cruise and Drive to Germany",
              description:
                "Check out and proceed to Amsterdam for a canal cruise. Afterward, continue your picturesque journey towards your hotel in the Frankfurt area and complete the check-in process. Overnight in Germany. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 6",
              header: "Visit to Mercedes Benz Museum",
              description:
                "Today’s day will be a charming experience for any car enthusiast as we visit the Mercedes Benz Museum. Next, we proceed to the heart of the Black Forest, where you'll have the chance to witness the grandeur of the Rhine Falls. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 7",
              header: "Excursion to Jungfraujoch",
              description:
                "Today we proceed to Interlaken for a unique experience of a horse-drawn carriage ride. Later, prepare yourself for an unforgettable alpine journey to Jungfraujoch, also known as The Top of Europe Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 8",
              header: "Visit to Mt. Titlis",
              description:
                "Our journey continues to Lucerne, where you'll be treated to breathtaking scenery and an exhilarating trip to the summit of Mt. Titlis. Later, we proceed with an orientation tour of Lucerne and a visit to the Swiss Chocolate Adventure. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 9",
              header: "Swarovski Crystal Museum",
              description:
                "Today after breakfast, relax in your coach as you take a scenic drive to Innsbruck. Enroute arrive in Vaduz for a guided tour with a Mini Train ride. Next, we proceed to Wattens to visit the Swarovski Crystal Museum. Overnight in Innsbruck / Seefeld. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 10",
              header: "VVenice and Gondola ride",
              description:
                "Check out from your hotel and embark on a journey to Venice, Italy, a city adorned with beautiful monuments. Upon arrival, we'll treat you to a gondola ride through Venice's picturesque waterways. Overnight in Padova/Bologna. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 11",
              header: "tour of Florence",
              description:
                "This morning, embark on a journey to Florence, often referred to as the cradle of the Renaissance. Accompanied by a local guide, immerse yourself in the rich culture of this historic city. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 12",
              header: "Rome and Vatican City",
              description:
                "Drive to Rome for a guided city tour, including a visit to Vatican City and St. Peter’s Basilica. Explore various landmarks such as the Colosseum, Trevi Fountain, and Roman Forum. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 13",
              header: "Leaning Tower of Pisa and Departure",
              description:
                "Post Breakfast, Check-out and proceed towards Pisa for a visit to the Square of Miracles and the Leaning Tower of Pisa. Later, proceed to Milan Airport for your return flight. Bid farewell to the wonderful new friends you've made during this tour. (Breakfast, Lunch)",
              meal: "Breakfast, Lunch",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: [
          "Paris",
          "Brussels",
          "Amsterdam",
          "Titisee",
          "Stuttgart",
          "Schaffhausen",
          "Interlaken",
          "Engelberg",
          "Lucerne",
          "Vaduz",
          "Innsbruck",
          "Wattens",
          "Venice",
          "Florence",
          "Pisa",
          "Rome",
        ],
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "The cost of the Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance, etc.",
          },
          {
            header:
              "Any expenses of personal nature such as Porterage, Laundry, and Alcohol, Food or Drink not in the regular menus provided by us, mini bar and telephone calls",
          },
          {
            header:
              "Any extra meals not included in the itinerary. Meals are pre-set and a choice of menu is not available",
          },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees and local guides availed of by the passengers, other than that mentioned in ‘What your tour price includes’",
          },
          {
            header: "GST, TCS and other taxes as applicable",
          },
          {
            header: "Anything which is not specified in the Itinerary",
          },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0017_Vaduz.webp",
            text: "PTN_PkG_0017_Vaduz.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_VATICAN_CITY.webp",
            text: "PTN_PkG_0020_VATICAN_CITY.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Engelberg.webp",
            text: "PTN_PkG_0021_Engelberg.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Innsbruck.webp",
            text: "PTN_PkG_0021_Innsbruck.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Interlaken.webp",
            text: "PTN_PkG_0021_Interlaken.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Lucerne.webp",
            text: "PTN_PkG_0021_Lucerne.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Schaffhausen.webp",
            text: "PTN_PkG_0021_Schaffhausen.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Amsterdam.webp",
            text: "PTN_PkG_0024_Amsterdam.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Brussels.webp",
            text: "PTN_PkG_0024_Brussels.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Paris.webp",
            text: "PTN_PkG_0024_Paris.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Schaffhausen.webp",
            text: "PTN_PkG_0024_Schaffhausen.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Titisee.webp",
            text: "PTN_PkG_0024_Titisee.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 201315,
            text: "Per Person",
          },
          {
            packageDuration: "12N/13D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0017",
      },
      {
        title: "package_name",
        content: "Best of Europe (Rome Departure) - 12 Nights 13 Days",
      },
      // {
      // title: "places",
      // content: [
      //   "France",
      //   "Belgium",
      //   "The Netherlands",
      //   "Germany",
      //   "Switzerland",
      //   "Liechtenstein",
      //   "Austria",
      //   "Italy",
      //   "Vatican City",
      // ],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 9 countries in 13 Days: France, Belgium, The Netherlands, Germany, Switzerland, Liechtenstein, Austria, Italy and Vatican City",
          "All important attractions and sightseeing included",
          "Services of a Professional & Knowledgeable Tour Manager accompanying you throughout the tour",
          "Accommodation in superior 4-star hotels",
          "Daily Continental buffet breakfast",
          "Delicious unlimited variety of Jain, veg and nonveg Indian cuisine along with options for local cuisine meals included as per itinerary",
          "Daily mineral water bottle (500ml)",
          "Driver tips included",
          "Champagne in France",
          "Black Forest Cake",
          "Chocolate Milkshake in Swiss",
          "Souvenir in Holland",
          "Pizza and Pasta in Pisa",
          "Gelato Ice cream in Rome",
          "Masala tea and Dinners in the hotel in select cities",
          "Guided tour in Paris, Florence, Rome & Vatican Museum",
          "All transfers and extensive sightseeing with entrance fees (as specified in the itinerary) will be done in an air-conditioned coach",
        ],
      },

      {
        title: "TourIternary",
        content: {
          Duration: "12N/13D",
          Cities: [
            "Paris",
            "Brussels",
            "Lisse",
            "Amsterdam",
            "Frankfurt",
            "Titisee",
            "Stuttgart",
            "Schaffhausen",
            "Interlaken",
            "Engelberg",
            "Lucerne",
            "Vaduz",
            "Innsbruck",
            "Venice",
            "Florence",
            "Pisa",
            "Rome",
          ],
          Country: [
            "France",
            "Belgium",
            "The Netherlands",
            "Germany",
            "Switzerland",

            "Austria",
            "Italy",
          ],
          CountryNames: {
            France: [
              {
                name: "Paris",
                duration: "3N",
              },
            ],
            Netherlands: [
              {
                name: "Netherlands",
                duration: "1N",
              },
            ],
            Germany: [
              {
                name: "Germany",
                duration: "1N",
              },
            ],
            Switzerland: [
              {
                name: "central switzerland",
                duration: "3N",
              },
            ],
            Austria: [
              {
                name: "Innsbruck",
                duration: "1N",
              },
            ],
            Italy: [
              {
                name: "Bologna",
                duration: "1N",
              },
              {
                name: "Tuscany",
                duration: "1N",
              },
              {
                name: "Rome",
                duration: "1N",
              },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "Arrive into Paris",
              description:
                "Welcome! Today, we embark on our journey to Paris, a city celebrated for its high fashion, world-famous museums, awe-inspiring landmarks, and captivating cabarets. Upon arrival, you'll be warmly welcomed by our Travel Professional, who will guide you to your hotel and provide assistance with the check-in process. Overnight in Paris.",
              meal: "Dinner",
            },
            {
              dayname: "Day 02",
              header: "Experience a guided city tour of Paris.",
              description:
                "Today, we embark on a guided city tour of Paris, where you'll have the opportunity to marvel at some of the most exquisite Parisian attractions. Our journey will take us through iconic locations such as Place Vendôme, place de l’Opéra Garnier, Musée d’Orsay, Place de la Concorde, the renowned Champs Elysées, the majestic Arc de Triomphe, Alexander Bridge, Les Invalides, and many more. Overnight in Paris.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 03",
              header: " Disneyland® Paris.",
              description:
                "Today promises a day filled with excitement at this beloved amusement park—a perfect destination to unwind and relish a variety of thrilling rides and cinematic experiences with your loved ones. Later in the day, we'll treat you to a romantic cruise along the Seine River, renowned as one of the world's most scenic journeys. After the cruise, we proceed for a captivating and enchanting experience to witness the City of Light in all its nighttime splendor. Overnight in Paris.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 04",
              header: " Visit Grand Place and Manneken Pis statue.",
              description:
                "Today, our journey takes us to Brussels, known as the headquarters of the European Union and the capital of Belgium. Following our time in Brussels, we'll relax in our coach as we travel to the charming city of Lisse. Overnight in Netherlands.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 05",
              header:
                "Onto Amsterdam. Explore Amsterdam with Canal Cruise. Drive to Germany",
              description:
                "Check out and proceed to the Dutch capital Amsterdam and embark on a glass-topped boat where you will have the opportunity to explore the rich history of this city. Afterward, continue your picturesque journey towards your hotel in the Frankfurt area and complete the check-in process. Overnight in Germany.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 06",
              header: "Visit Mercedes Benz Museum.",
              description:
                "After checking out, relax in your coach as today’s day will be a charming experience for any car enthusiast. Later relax and take in the picturesque journey en route to Switzerland. Overnight in Central Switzerland.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 07",
              header: "The breath taking Jungfraujoch",
              description:
                "Today we proceed to Interlaken - a picturesque Swiss town nestled between two stunning lakes. Later, prepare yourself for an unforgettable and magical alpine journey to Jungfraujoch, also known as 'The Top of Europe.' Overnight in Central Switzerland.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 08",
              header: "Visit Mt. Titlis",
              description:
                "Our journey continues to Lucerne, where you'll be treated to breathtaking scenery and a once-in-a-lifetime experience. Later in the day, we'll proceed with an orientation tour of Lucerne and visit The Swiss Chocolate Adventure. Overnight in Central Switzerland.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 09",
              header: "Drive to Vaduz,",
              description:
                "Today after breakfast, relax in your coach as you take a scenic drive to Innsbruck. Enroute arrive in Vaduz, the capital of Liechtenstein, a tiny principality nestled between Switzerland and Austria. Overnight in Innsbruck / Seefeld.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 10",
              header: "Welcome to Venice, Italy",
              description:
                "Check out from your hotel and embark on a journey to Venice, Italy, a city adorned with beautiful monuments and famed for its serpentine canals. Overnight in Padova/Bologna.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 11",
              header: "View the Leaning Tower of Pisa",
              description:
                "This morning, after checking out from your hotel, we'll embark on a journey to Florence, often referred to as the cradle of the Renaissance. Overnight in Tuscany region.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 12",
              header: "All roads lead to Rome ",
              description:
                "Today, it's time to check out and make our way to the eternal city of Rome. Prepare for a guided city tour of Rome, led by an expert English-speaking local guide. Overnight in Rome.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 13",
              header: "Fly back home",
              description:
                "As your memorable holiday comes to a close, today marks the day to return home. Bid farewell to the wonderful new friends you've made during this tour. It's time to check out and make your way to the airport. (Breakfast)",
              meal: "Breakfast",
            },
          ],
        },
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0017_Vaduz.webp",
            text: "PTN_PkG_0017_Vaduz.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_VATICAN_CITY.webp",
            text: "PTN_PkG_0020_VATICAN_CITY.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Engelberg.webp",
            text: "PTN_PkG_0021_Engelberg.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Innsbruck.webp",
            text: "PTN_PkG_0021_Innsbruck.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Interlaken.webp",
            text: "PTN_PkG_0021_Interlaken.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Lucerne.webp",
            text: "PTN_PkG_0021_Lucerne.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Schaffhausen.webp",
            text: "PTN_PkG_0021_Schaffhausen.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Amsterdam.webp",
            text: "PTN_PkG_0024_Amsterdam.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Brussels.webp",
            text: "PTN_PkG_0024_Brussels.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Paris.webp",
            text: "PTN_PkG_0024_Paris.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Schaffhausen.webp",
            text: "PTN_PkG_0024_Schaffhausen.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Titisee.webp",
            text: "PTN_PkG_0024_Titisee.webp",
          },
        ],
      },
      {
        title: "destinaions",
        content: [
          "Paris",
          "Paris",
          "Paris",
          "Brussels",
          "Amsterdam",
          "Black Forest",
          "Interlaken",
          "Mt. Titlis",
          "Vaduz",
          "Venice",
          "Florence",
          "Rome",
        ],
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "The cost of the Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance, etc.",
          },
          {
            header:
              "Any expenses of personal nature such as Porterage, Laundry, and Alcohol, Food or Drink not in the regular menus provided by us, mini bar and telephone calls",
          },
          {
            header:
              "Any extra meals not included in the itinerary. Meals are pre - set and a choice of menu is not available",
          },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees and local guides availed of by the passengers, other than that mentioned in ‘What your tour price includes’",
          },
          { header: "GST, TCS and other taxes as applicable" },
          { header: "Anything which is not specified in the Itinerary" },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 201315,
            text: "Per Person",
          },
          {
            packageDuration: "12N/13D",
          },
        ],
      },
    ],
    //   package no 3------------------------------------------------------------------------------------------------------------------------------
    [
      {
        title: "packageId",
        content: "PTN_PkG_0018",
      },
      {
        title: "package_name",
        content: "European Highlights - 9 Nights 10 Days",
      },
      // {
      // title: "places",
      // content: ["France", "Switzerland", "Austria", "Italy", "Vatican City"],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 5 countries in 10 Days: France, Switzerland, Austria, Italy and Vatican City",
          "Eiffel Tower 2nd level visit",
          "Entrance to Fragonard Perfumery",
          "Romantic cruise on River Seine",
          "Excursion to Mt. Titlis with cable car ride",
          "Walk on the Titlis Cliff Walk, Europe's highest suspension bridge",
          "Orientation tour of Lucerne",
          "Visit the magnificent Rhine Falls at Schaffhausen",
          "Golden Roof visit in Innsbruck",
          "Visit the Swarovski Crystal Museum",
          "St. Mark’s Island visit in Venice",
          "Leaning Tower of Pisa photo-stop",
          "Panoramic view of Florence from Piazzale Michelangelo",
          "Orientation tour of Rome",
          "Visit to St. Peter’s Basilica, Vatican City only",
          "Trevi Fountain visit",
          "Guided tour in Paris",
          "Services of a Professional & Knowledgeable Tour Manager",
          "Accommodation in 3 & 4-star hotels",
          "Daily Continental buffet breakfast",
          "Jain, veg and nonveg Indian cuisine as per itinerary",
          "Daily mineral water bottle (500ml)",
          "Driver tips included",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "9N/10D",
          Cities: [
            "Paris",
            "Central Switzerland",
            "Innsbruck / Seefeld",
            "Padova",
            "Tuscany region",
          ],
          Country: ["France", "Switzerland", "Austria", "Italy"],
          CountryNames: {
            FRANCE: [{ name: "Paris", duration: "2N" }],
            SWITZERLAND: [{ name: "Central Switzerland", duration: "3N" }],
            AUSTRIA: [{ name: "Innsbruck/Seefeld", duration: "1N" }],
            ITALY: [
              { name: "Padova/Bologna", duration: "1N" },
              { name: "Tuscany region", duration: "2N" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "Arrive into Paris",
              description:
                "Hello! Today, we begin our adventure to Paris, a renowned city known for its exceptional fashion, renowned museums, breathtaking landmarks, and captivating cabaret scenes. Upon reaching your destination, our Tour Manager will extend a warm welcome, assist you with hotel check-in, and be available to support you throughout your stay. Overnight in Paris. (Dinner)",
              meal: "Dinner",
            },
            {
              dayname: "Day 02",
              header: "Guided city tour of Paris. ",
              description:
                "Today, Visit to Eiffel tower 2nd Level. Enjoy a romantic cruise on River Seine. Visit Fragonard French perfumery, we commence our guided exploration of Paris, offering you the chance to admire some of its most renowned landmarks. Our schedule includes stops at iconic sites such as Place Vendôme, place de l’Opéra Garnier, Musée d’Orsay, place de la Concorde, Champs Elysées, Arc de Triomphe, Alexander Bridge, Les Invalides, and many other notable locations. Next on our agenda is a visit to the second level of the iconic Eiffel Tower, followed by a romantic cruise along the Seine River and a visit to the Fragonard Perfumery. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 03",
              header: "Drive to scenic Switzerland",
              description:
                "After checking out from your hotel, we will begin our journey towards Switzerland, a picturesque Central European nation characterized by its mountainous terrain, pristine lakes, charming villages, and the towering peaks of the Alps. As we embark on this scenic drive to Switzerland, you'll be treated to breathtaking landscapes. Upon reaching Switzerland, we will proceed to your hotel, where you can check in and unwind in the cozy comforts of your accommodation. Overnight at the hotel in Central Switzerland. (Breakfast, Packed Lunch, Dinner)",
              meal: "Breakfast, Packed Lunch, Dinner",
            },
            {
              dayname: "Day 04",
              header: "Day at leisure in Switzerland",
              description:
                "Today, seize the chance to explore the breathtaking beauty of Switzerland at your leisure. Overnight at the hotel in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 05",
              header: "Visit Mt. Titlis",
              description:
                "Enjoy Cable car rides including the world’s first rotating cable car, the Rotair – to the top of Mt. Titlis at 3,020 metres. Orientation tour of Lucerne. Free time for shopping, Today holds the promise of an extraordinary adventure as you immerse yourself in the breathtaking landscapes of Switzerland. Your journey will take you to the summit of Mt. Titlis, a once-in-a-lifetime experience that involves ascending to an impressive altitude of 3,020 meters through a series of cable cars. Later, explore Lucerne and enjoy free time for shopping. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 06",
              header: " Waterfall in Switzerland",
              description:
                "Today, it's time to check out from your hotel as we embark on a journey to Austria. Along the way, we'll make a stop at the Rhine Falls, visit the Swarovski Crystal Museum, and enjoy an orientation tour of Innsbruck. Overnight in Innsbruck/Seefeld. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 07",
              header: "Welcome to Venice",
              description:
                "After checking out of your hotel, our journey leads us to Venice, Italy, a city celebrated for its magnificent monuments, meandering canals, and its enduring ability to inspire poets and romantics throughout history. Overnight in Padova/Bologna. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 08",
              header: "Photo stop at Piazzale Michelangelo",
              description:
                "This morning, as you complete the check-out process at your hotel, we will commence our journey to Florence's most renowned viewing point – Piazzale Michelangelo. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 09",
              header: "Visit the Vatican City",
              description:
                "Today, we drive to Rome for a day trip. Visit the Vatican City and St. Peter’s Basilica, then see the Colosseum and Trevi Fountain in Rome. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 10",
              header: "A visit tower of Pisa. Fly back home",
              description:
                "Post Breakfast, visit the Leaning Tower of Pisa, then proceed to Milan Airport for your return flight. Bid farewell to the wonderful new friends you've made during this tour. Safe travels! (Breakfast, Lunch)",
              meal: "Breakfast, Lunch",
            },
          ],
        },
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0020_Florence.webp",
            text: "PTN_PkG_0020_Florence.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_Pisa.webp",
            text: "PTN_PkG_0020_Pisa.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_VATICAN_CITY.webp",
            text: "PTN_PkG_0020_VATICAN_CITY.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_Venice.webp",
            text: "PTN_PkG_0020_Venice.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Engelberg.webp",
            text: "PTN_PkG_0021_Engelberg.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Innsbruck.webp",
            text: "PTN_PkG_0021_Innsbruck.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Interlaken.webp",
            text: "PTN_PkG_0021_Interlaken.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Lucerne.webp",
            text: "PTN_PkG_0021_Lucerne.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Paris.webp",
            text: "PTN_PkG_0021_Paris.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Schaffhausen.webp",
            text: "PTN_PkG_0021_Schaffhausen.webp",
          },
        ],
      },
      {
        title: "destinaions",
        content: ["Paris", "Switzerland", "Austria", "Italy"],
      },

      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "The cost of the Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance, etc.",
          },
          {
            header:
              "Any expenses of personal nature such as Porterage, Laundry, and Alcohol, Food or Drink not in the regular menus provided by us, mini bar and telephone calls",
          },
          {
            header:
              "Any extra meals not included in the itinerary. Meals are pre - set and a choice of menu is not available",
          },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees and local guides availed of by the passengers, other than that mentioned in ‘What your tour price includes’",
          },
          { header: "GST, TCS and other taxes as applicable" },
          { header: "Anything which is not specified in the Itinerary" },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 129822,
            text: "",
          },
          {
            packageDuration: "9N/10",
          },
        ],
      },
    ],
    //   package no 4------------------------------------------------------------------------------------------------------------------------------

    [
      {
        title: "packageId",
        content: "PTN_PkG_0019",
      },
      {
        title: "package_name",
        content: "European Highlights -Milan Departure 9 Nights 10 Days",
      },
      // {
      // title: "places",
      // content: ["France", "Switzerland", "Austria", "Italy", "Vatican City"],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 5 countries in 10 Days: France, Switzerland, Austria, Italy and Vatican City",
          "Eiffel Tower 2nd level visit",
          "Entrance to Fragonard Perfumery",
          "Romantic cruise on River Seine",
          "Excursion to Mt. Titlis with cable car ride",
          "Walk on the Titlis Cliff Walk, Europe's highest suspension bridge",
          "Orientation tour of Lucerne",
          "Visit the magnificent Rhine Falls at Schaffhausen",
          "Golden Roof visit in Innsbruck",
          "Visit the Swarovski Crystal Museum",
          "St. Mark’s Island visit in Venice",
          "Leaning Tower of Pisa photo-stop",
          "Panoramic view of Florence from Piazzale Michelangelo",
          "Orientation tour of Rome",
          "Visit to St. Peter’s Basilica, Vatican City only",
          "Trevi Fountain visit",
          "Guided tour in Paris",
          "Services of a Professional & Knowledgeable Tour Manager",
          "Accommodation in 3 & 4-star hotels",
          "Daily Continental buffet breakfast",
          "Jain, veg and nonveg Indian cuisine as per itinerary",
          "Daily mineral water bottle (500ml)",
          "Driver tips included",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "9N/10D",
          Cities: [
            "Paris",
            "Central Switzerland",
            "Innsbruck / Seefeld",
            "Padova",
            "Tuscany region",
          ],
          Country: ["France", "Switzerland", "Austria", "Italy"],
          CountryNames: {
            FRANCE: [{ name: "Paris", duration: "2N" }],
            SWITZERLAND: [{ name: "Central Switzerland", duration: "3N" }],
            AUSTRIA: [{ name: "Innsbruck/Seefeld", duration: "1N" }],
            ITALY: [
              { name: "Padova/Bologna", duration: "1N" },
              { name: "Tuscany region", duration: "2N" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "Arrive into Paris",
              description:
                "Hello! Today, we begin our adventure to Paris, a renowned city known for its exceptional fashion, renowned museums, breathtaking landmarks, and captivating cabaret scenes. Upon reaching your destination, our Tour Manager will extend a warm welcome, assist you with hotel check-in, and be available to support you throughout your stay. Overnight in Paris. (Dinner)",
              meal: "Dinner",
            },
            {
              dayname: "Day 02",
              header: "Guided city tour of Paris",
              description:
                "Today, we commence our guided exploration of Paris, offering you the chance to admire some of its most renowned landmarks. Our schedule includes stops at iconic sites such as Place Vendôme, place de l’Opéra Garnier, Musée d’Orsay, place de la Concorde, Champs Elysées, Arc de Triomphe, Alexander Bridge, Les Invalides, and many other notable locations. Next on our agenda is a visit to the second level of the iconic Eiffel Tower, followed by a romantic cruise along the Seine River and a visit to the Fragonard Perfumery. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 03",
              header: "Drive to scenic Switzerland",
              description:
                "After checking out from your hotel, we will begin our journey towards Switzerland, a picturesque Central European nation characterized by its mountainous terrain, pristine lakes, charming villages, and the towering peaks of the Alps. As we embark on this scenic drive to Switzerland, you'll be treated to breathtaking landscapes. Upon reaching Switzerland, we will proceed to your hotel, where you can check in and unwind in the cozy comforts of your accommodation. Overnight at the hotel in Central Switzerland. (Breakfast, Packed Lunch, Dinner)",
              meal: "Breakfast, Packed Lunch, Dinner",
            },
            {
              dayname: "Day 04",
              header: "Day at leisure in Switzerland",
              description:
                "Today, seize the chance to explore the breathtaking beauty of Switzerland at your leisure. Overnight at the hotel in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Packed Lunch, Dinner",
            },
            {
              dayname: "Day 05",
              header: "Visit Mt. Titlis-",
              description:
                "Today holds the promise of an extraordinary adventure as you immerse yourself in the breathtaking landscapes of Switzerland. Your journey will take you to the summit of Mt. Titlis, a once-in-a-lifetime experience that involves ascending to an impressive altitude of 3,020 meters through a series of cable cars. Later, explore Lucerne and enjoy free time for shopping. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 06",
              header: "See the largest Waterfall in Switzerland",
              description:
                "Today, it's time to check out from your hotel as we embark on a journey to Austria. Along the way, we'll make a stop at the Rhine Falls, visit the Swarovski Crystal Museum, and enjoy an orientation tour of Innsbruck. Overnight in Innsbruck/Seefeld. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 07",
              header: "Welcome to Venice ",
              description:
                "After checking out of your hotel, our journey leads us to Venice, Italy, a city celebrated for its magnificent monuments, meandering canals, and its enduring ability to inspire poets and romantics throughout history. Overnight in Padova/Bologna. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 08",
              header: "Photo stop at Piazzale Michelangelo",
              description:
                "This morning, as you complete the check-out process at your hotel, we will commence our journey to Florence's most renowned viewing point – Piazzale Michelangelo. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 09",
              header: "=Visit the Vatican City =",
              description:
                "Today, we drive to Rome for a day trip. Visit the Vatican City and St. Peter’s Basilica, then see the Colosseum and Trevi Fountain in Rome. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 10",
              header: "A visit Leaning Tower of Pisa. Fly back home",
              description:
                "Post Breakfast, visit the Leaning Tower of Pisa, then proceed to Milan Airport for your return flight. Bid farewell to the wonderful new friends you've made during this tour. Safe travels! (Breakfast, Lunch)",
              meal: "Breakfast, Lunch",
            },
          ],
        },
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0020_Florence.webp",
            text: "PTN_PkG_0020_Florence.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_Pisa.webp",
            text: "PTN_PkG_0020_Pisa.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_VATICAN_CITY.webp",
            text: "PTN_PkG_0020_VATICAN_CITY.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_Venice.webp",
            text: "PTN_PkG_0020_Venice.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Engelberg.webp",
            text: "PTN_PkG_0021_Engelberg.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Innsbruck.webp",
            text: "PTN_PkG_0021_Innsbruck.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Interlaken.webp",
            text: "PTN_PkG_0021_Interlaken.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Lucerne.webp",
            text: "PTN_PkG_0021_Lucerne.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Paris.webp",
            text: "PTN_PkG_0021_Paris.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Schaffhausen.webp",
            text: "PTN_PkG_0021_Schaffhausen.webp",
          },
        ],
      },

      {
        title: "destinaions",
        content: ["Paris", "Switzerland", "Austria", "Italy"],
      },

      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "The cost of the Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance, etc.",
          },
          {
            header:
              "Any expenses of personal nature such as Porterage, Laundry, and Alcohol, Food or Drink not in the regular menus provided by us, mini bar and telephone calls",
          },
          {
            header:
              "Any extra meals not included in the itinerary. Meals are pre - set and a choice of menu is not available",
          },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees and local guides availed of by the passengers, other than that mentioned in ‘What your tour price includes’",
          },
          { header: "GST, TCS and other taxes as applicable" },
          { header: "Anything which is not specified in the Itinerary" },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 129706,
            text: "Per Person",
          },
          {
            packageDuration: "9N/10D",
          },
        ],
      },
    ],

    [
      {
        title: "packageId",
        content: "PTN_PkG_0020",
      },
      {
        title: "package_name",
        content: "Exclusive Europe - 10 Nights / 11 Days",
      },
      // {
      // title: "places",
      // content: ["France", "Switzerland", "Austria", "Italy", "Vatican City"],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 4 countries in 11 Days: France, Switzerland, Austria, Italy & Vatican City",
          "City tour with a local guide in Paris",
          "Visit to the 3rd level of the Eiffel Tower",
          "Full day excitement and thrill at Disneyland® Paris",
          "Romantic cruise on River Seine",
          "Guided tour of Versailles",
          "Excursion to Jungfraujoch - Top of Europe",
          "Visit scenic Interlaken",
          "Witness breath-taking mountain peaks and glaciers at Mt. Titlis",
          "Ride in the world’s first revolving cable car",
          "Walk on the Highest suspension bridge in Europe - Titlis Cliff Walk",
          "Orientation tour of Lucerne",
          "Lake Lucerne Cruise",
          "View the magnificent Rhine Falls",
          "See the Maria Theresien Strasse and the Golden Roof in Innsbruck",
          "Visit the Swarovski Crystal Museum and shop in Wattens",
          "St. Mark’s square by Private boat in Venice",
          "Enjoy a classic Venetian Gondola ride",
          "Guided walking city tour with a local English-speaking guide in Florence",
          "Visit Square of Miracles in Pisa",
          "Photo-stop at the Leaning Tower",
          "Guided City tour with a local English speaking guide in Rome",
          "Visit to Trevi Fountain",
          "Visit the Vatican Museum – see sculptures and masterpieces of Renaissance art",
          "Visit to the awe-inspiring Sistine Chapel",
          "Visit the magnificent St. Peter’s Basilica",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "10N/11D",
          Cities: [
            "Paris",
            "Central Switzerland",
            "Innsbruck / Seefeld",
            "Padova / Rovigo",
            "Tuscany region",
            "Rome",
          ],
          Country: [
            "France",
            "Switzerland",
            "Austria",
            "Italy",
            "Vatican City",
          ],
          CountryNames: {
            FRANCE: [{ name: "Paris", duration: "3N" }],
            SWITZERLAND: [{ name: "Central Switzerland", duration: "3N" }],
            AUSTRIA: [{ name: "Innsbruck / Seefeld", duration: "1N" }],
            ITALY: [
              { name: "Padova/Bologna", duration: "1N" },
              { name: "Tuscany region", duration: "1N" },
              { name: "Rome", duration: "1N" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "Arrive into Paris",
              description:
                "Welcome! Today board our flight to one of the most beautiful and elegant cities of Paris- known for its haute couture, renowned museums, breath-taking beautiful monuments and sensational cabarets. Upon arrival, collect your baggage and proceed outside where you will be met by your professional tour manager, who will later escort you to your hotel. Check in and relax in the comforts of your hotel. Overnight at the hotel in Paris. (Dinner)",
              meal: "Dinner",
            },
            {
              dayname: "Day 02",
              header: "Visit to Eiffel tower",
              description:
                "Today proceed for a guided city tour of Paris. Marvel at the finest Parisian tourist attractions, Place Vendôme, place de l’Opéra Garnier, Musée d’Orsay, place de la Concorde, Champs Elysées, one of the most recognised fashionable avenues in the world, Arc de Triomphe, Alexander Bridge, Les Invalides and several others. Next visit the iconic Eiffel Tower – 3rd Level, and get a stunning view of the city from the top. Our adventure continues with a visit to the Palace of Versailles, an internationally acclaimed masterpiece located in Versailles, approximately 12 miles (19 km) west of Paris, France. Later take a romantic cruise down the river Seine, one of the world’s most scenic cruise, flanked by exquisite monuments like Notre Dame, Eiffel Tower, Louvre, Orsay Museum and many more. Overnight at the hotel in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 03",
              header: "Enjoy at Disneyland® ",
              description:
                "Get ready for a day full of excitement at Disneyland Paris- a popular amusement park. Enjoy different kind of thrill rides and studio movies with your loved ones. You may choose between Disney Park, where fairy tales take flight across five incredible lands filled with classic attractions, shows and street parades with Disney Characters OR you may visit the Walt Disney Studios Park Studios and be astounded by the outstanding stunt shows, watch a film being made and see real-life sets used for creating blockbuster movies. Overnight at the hotel in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 04",
              header: "Drive to the scenic Switzerland",
              description:
                "After checking out from your hotel, we will begin our journey towards Switzerland, a picturesque Central European nation characterized by its mountainous terrain, pristine lakes, charming villages, and the towering peaks of the Alps. As we embark on this scenic drive to Switzerland, you'll be treated to breath taking landscapes. Switzerland's mountains and valleys are renowned as some of the most awe-inspiring in the world, promising stunning vistas at every turn. During your drive through these mountain passes, you'll encounter remarkable landmarks and sites of historical significance. Additionally, the majestic mountain panoramas that unfold before you are sure to leave you in awe. Upon reaching Switzerland, we will proceed to your hotel, where you can check in and unwind in the cozy comforts of your accommodation. Overnight at the hotel in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 05",
              header: "Visit Mt. Titlis",
              description:
                "Today enjoy amazing scenery, a once in a lifetime experience with an exhilarating tip to the top of Mt Titlis at 3020 metres on various cable cars including Rotair, the world’s first revolving cable car. Get a breath taking unrestricted 360 degrees stunning view of the dazzling snow caped peak, deep crevasses and pristine white snow fields, dotted with massive ice boulders from every angle. Do not forget to visit the “Cliff Walk” the highest suspension bridge in Europe along the cliff of Mt.Titlis. Later proceed on an orientation tour of Lucerne one of most beautiful cities in Switzerland. Visit the Lion Monument, Kapell Brucke with some free time to shop for famous Swiss watches, knives and chocolates. Later enjoy a scenic and relaxing cruise on Lake Lucerne. Overnight at the hotel in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 06",
              header: "excursion to the top of Europe",
              description:
                "Enjoy a magical alpine excursion to the top of Europe - the amazing Jungfraujoch which is included in your tour and scenic Interlaken. Get set for a memorable magical alpine excursion to Jungfraujoch – The Top of Europe, a high–point of your tour. First proceed to Grindelwald Terminal. The new 3S-Bahn Eiger Express takes you from the Grindelwald Terminal to the Eigergletscher station in just 15 minutes. There you board a cogwheel train to reach the highest railway station in Europe at 11,333 feet – a world of eternal ice and snow. Visit the Ice Palace where artistes create their work of art in ice. Visit the Sphinx observatory deck to experience a breath-taking panoramic view of the longest glacier in the Alps - Aletsch Glacier. Overnight at the hotel in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 07",
              header: "Visit the magnificent Rhine Falls",
              description:
                "Today after breakfast, sit back and enjoy the scenic drive towards Schaffhausen, where you see the magnificent Rhine Falls – Europe’s biggest and most spectacular falls. Next, we proceed to Wattens, where you'll have the opportunity to explore the Swarovski World of Crystals and be enchanted by the exquisite crystalline artworks on display. Following this captivating visit, we'll continue on to Innsbruck, a city of extraordinary beauty. During our orientation tour, you'll have the chance to admire the iconic Golden Roof, known for its fire-gilded copper tiles, which serves as a defining landmark of Innsbruck. Stroll along the charming cobblestone paths of Maria Theresien Strasse, where you can choose to relax at one of the many outdoor cafes. As you do so, you'll be treated to magnificent baroque architecture and the chance to immerse yourself in the rich culture of medieval Europe. With the splendid Alps as a backdrop, the setting is nothing short of a picture-postcard perfect. Overnight in Innsbruck / Seefeld. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 08",
              header: "Welcome to Venice",
              description:
                "Check out of your hotel and proceed towards Venice – Italy. City of Beautiful Monuments, Serpentine Canals, city that inspired poetry and passion through the ages. Arrive at the pier in Venice, where we board a private boat to arrive at St. Mark’s Square – the main square of Venice. View St Mark’s Basilica - one of the best examples of Byzantine architecture, Witness the famed Bell Tower and Clock Tower with the dominant sign of the Zodiac and current phase of the moon. See the Bridge of Sighs, made of white limestone, that passes over the Rio di Palazzo, connecting the old prisons with interrogations rooms at the Doge’s Palace. Treasure a romantic Gondola ride in a flat-bottomed Venetian rowing boat, amidst the stunning backdrop of baroque buildings. This truly is the best way to enjoy this enchanting city. We return to the pier and later check in to your hotel. Overnight in Padova/Bologna. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 09",
              header: "Guided walking city tour of Florence.",
              description:
                "This morning check-out and drive to Florence – the cradle of the Renaissance. Along with your English-speaking local guide, walk and absorb into the culture of the historic city. View the Duomo – the city’s most iconic landmark, the Campanile and the Baptistery with its Gates to Paradise. Also see the Piazza Della Signoria – an open-air museum crammed with Renaissance sculptures, edged by historic cafes and presided over by the magnificent Palazzo Vecchio and the famed Ponte Vecchio (bridge) across the River Arno. Next, drive to Piazzale Michelangelo to get a spectacular panoramic view of the city. Later proceed towards Pisa. On arrival see the Square of Miracles with the stupendous Leaning Tower of Pisa. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 10",
              header: "All roads lead to Rome.",
              description:
                "Today check-out and proceed towards the eternal city of Rome. Enjoy a guided city tour of Rome with an expert English-speaking local guide. Next visit the Vatican City – the smallest country in the world (Please note shorts or sleeveless vests are not allowed). Enter the Vatican Museum, where you see the renowned classical sculptures and masterpieces of the Renaissance Art. Visit Sistine Chapel – a source of inspiration for art lovers and tourists alike and known for its masterpiece – the Last Judgement. This is also where the Pope gets elected. Visit the divinely beautiful basilica of St. Peter’s. Get mesmerized by the works of Michelangelo and Bernini. We continue and then drive to view the magnificent Colosseum, a vast amphitheatre that is still well preserved. Next, Visit the beautiful Trevi Fountains. The Legend says that tossing a coin over your shoulder gets you back to Rome. Overnight in Rome. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 11",
              header: "Fly back home",
              description:
                "Your memorable holiday ends, today it’s time to fly back home. Say goodbye to the wonderful new friends you have made on the tour. Check out and depart for the Airport with pleasant memories of your European trip which you will cherish for a lifetime. (Breakfast)",
              meal: "Breakfast",
            },
          ],
        },
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0020_Florence.webp",
            text: "PTN_PkG_0020_Florence.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_Pisa.webp",
            text: "PTN_PkG_0020_Pisa.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_VATICAN_CITY.webp",
            text: "PTN_PkG_0020_VATICAN_CITY.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0020_Venice.webp",
            text: "PTN_PkG_0020_Venice.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Engelberg.webp",
            text: "PTN_PkG_0021_Engelberg.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Innsbruck.webp",
            text: "PTN_PkG_0021_Innsbruck.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Interlaken.webp",
            text: "PTN_PkG_0021_Interlaken.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Lucerne.webp",
            text: "PTN_PkG_0021_Lucerne.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Paris.webp",
            text: "PTN_PkG_0021_Paris.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Schaffhausen.webp",
            text: "PTN_PkG_0021_Schaffhausen.webp",
          },
        ],
      },
      {
        title: "destinaions",
        content: ["Paris", "Switzerland", "Austria", "Italy"],
      },

      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "The cost of the Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance, etc.",
          },
          {
            header:
              "Any expenses of personal nature such as Porterage, Laundry, and Alcohol, Food or Drink not in the regular menus provided by us, mini bar and telephone calls",
          },
          {
            header:
              "Any extra meals not included in the itinerary. Meals are pre-set and a choice of menu is not available",
          },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees and local guides availed of by the passengers, other than that mentioned in ‘What your tour price includes’",
          },
          { header: "GST, TCS and other taxes as applicable" },
          { header: "Anything which is not specified in the Itinerary" },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 171591,
            text: "Per Person",
          },
          {
            packageDuration: "10N/11D",
          },
        ],
      },
    ],
    //   package no 5------------------------------------------------------------------------------------------------------------------------------

    [
      {
        title: "packageId",
        content: "PTN_PkG_0021",
      },
      {
        title: "package_name",
        content: "Fascinating Europe - 8 Nights 9 Days",
      },
      // {
      // title: "places",
      // content: ["France", "Switzerland", "Austria", "Germany"],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 4 countries in 9 Days: France, Switzerland, Austria, and Germany.",
          "City tour with a local guide in Paris.",
          "Visit to the 3rd level of the Eiffel Tower.",
          "Guided tour of Versailles Palace.",
          "Full-day excitement and thrill at Disneyland® Paris.",
          "Romantic cruise on the River Seine.",
          "Excursion to Jungfraujoch - Top of Europe.",
          "Visit scenic Interlaken.",
          "Witness breathtaking mountain peaks and glaciers at Mt. Titlis.",
          "Ride in the world’s first revolving cable car.",
          "Walk on the Highest suspension bridge in Europe - Titlis Cliff Walk.",
          "Orientation tour of Lucerne.",
          "Lake Lucerne Cruise.",
          "View the magnificent Rhine Falls.",
          "See the Maria Theresien Strasse and the Golden Roof in Innsbruck.",
          "Visit the Swarovski Crystal Museum and shop in Wattens.",
          "Guided tour of Salzburg.",
          "Visit BMW Welt in Munich.",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "8N/9D",
          Cities: [
            "Paris",
            "Central Switzerland",
            "Innsbruck / Seefeld",
            "Munich",
          ],
          Country: ["France", "Switzerland", "Austria", "Germany"],
          CountryNames: {
            FRANCE: [{ name: "Paris", duration: "3N" }],
            SWITZERLAND: [{ name: "Central Switzerland", duration: "3N" }],
            AUSTRIA: [{ name: "Innsbruck / Seefeld", duration: "1N" }],
            GERMANY: [{ name: "Munich", duration: "1N" }],
          },
          Days: [
            {
              dayname: "Day_01",
              header: "Arrive into Paris ",
              description:
                "Board our flight to Paris. Meet your tour manager, transfer to hotel, and relax. Overnight in Paris. (Dinner)",
              meal: "Dinner",
            },
            {
              dayname: "Day_02",
              header: "Guided city tour of Paris",
              description:
                "Guided city tour including Place Vendôme, Champs Elysées, Arc de Triomphe, etc. Visit Eiffel Tower 3rd Level. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day_03",
              header: "Enjoy Disneyland® Paris",
              description:
                "Full-day enjoyment at Disneyland Paris. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day_04",
              header: "Drive to Scenic Switzerland",
              description:
                "Scenic drive to Switzerland. Check-in at the hotel. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day_05",
              header: "Visit Mt. Titlis ",
              description:
                "Visit Mt. Titlis, enjoy cable car rides. Proceed for an orientation tour of Lucerne. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day_06",
              header: "Enjoy excursion to Jungfraujoch",
              description:
                "Excursion to Jungfraujoch. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day_07",
              header: "Visit the magnificent Rhine Falls",
              description:
                "Visit Rhine Falls and Swarovski Crystal Museum. Overnight in Innsbruck / Seefeld. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day_08",
              header: "Visit Salzburg. Drive to Munich.",
              description:
                "Guided tour of Salzburg. Visit BMW Welt in Munich. Overnight in Munich. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day_09",
              header: "Fly back home",
              description:
                "Check out and transfer to Munich airport for your return flight. (Breakfast)",
              meal: "Breakfast",
            },
          ],
        },
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0021_Engelberg.webp",
            text: "PTN_PkG_0021_Engelberg.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Innsbruck.webp",
            text: "PTN_PkG_0021_Innsbruck.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Interlaken.webp",
            text: "PTN_PkG_0021_Interlaken.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Lucerne.webp",
            text: "PTN_PkG_0021_Lucerne.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Munich.webp",
            text: "PTN_PkG_0021_Munich.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Paris.webp",
            text: "PTN_PkG_0021_Paris.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0021_Schaffhausen.webp",
            text: "PTN_PkG_0021_Schaffhausen.webp",
          },
        ],
      },
      {
        title: "destinaions",
        content: ["Paris", "Switzerland", "Austria", "Germany"],
      },

      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",

        content: [
          {
            header:
              "The cost of the Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance, etc.",
          },
          {
            header:
              "Any expenses of personal nature such as Porterage, Laundry, and Alcohol.",
          },
          { header: "Any extra meals not included in the itinerary." },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees, and local guides.",
          },
          { header: "GST, TCS, and other taxes as applicable." },
          { header: "Anything not specified in the Itinerary." },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 143217,
            text: "Per Person",
          },
          {
            packageDuration: "8N/9D",
          },
        ],
      },
    ],
    //   package no 6------------------------------------------------------------------------------------------------------------------------------
    [
      {
        title: "packageId",
        content: "PTN_PkG_0022",
      },
      {
        title: "package_name",
        content: "Glimpses of United Kingdom - 05 Nights 06 Days",
      },
      // {
      // title: "places",
      // content: ["Europe", "United Kingdom"],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 02 Countries in 6 Days: Scotland & England.",
          "Guided city tours in Glasgow, Edinburgh, Manchester, and London",
          "Entrance to Edinburgh Castle, Madame Tussauds Wax Museum, and Windsor Castle",
          "Cruise on Lake Loch Lomond and Lake Windermere",
          "Enjoy scenic views from the London Eye",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "5N/6D",
          Cities: ["Glasgow", "Edinburgh", "Manchester", "London"],
          Country: ["Scotland", "England"],
          CountryNames: {
            Scotland: [
              {
                name: "Glasgow",
                duration: "2N",
              },
            ],
            England: [
              {
                name: "Manchester",
                duration: "1N",
              },
              {
                name: "London",
                duration: "2N",
              },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "Arrive into Glasgow the gateway to Scotland",
              description:
                "Arrival in Glasgow, check-in to the hotel, and overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "Visit the Edinburgh Castle. Cruise on Lake Loch Lomond",
              description:
                "Guided city tour of Edinburgh including attractions like Edinburgh Castle. Enjoy a cruise on Lake Loch Lomond.",
              meal: "",
            },
            {
              dayname: "Day 3",
              header:
                "Enjoy Scottish Distillery tour. Lake Windermere. Drive to Manchester",
              description:
                "Visit a Scottish Distillery, explore Lake Windermere, and travel to Manchester.",
              meal: "",
            },
            {
              dayname: "Day 4",
              header:
                "Stratford-upon-Avon. Entrance to Windsor castle. Onto London",
              description:
                "Visit Stratford-upon-Avon, Windsor Castle, and travel to London.",
              meal: "",
            },
            {
              dayname: "Day 5",
              header:
                "Guided city tour of London with Changing of Guards. Visit to the famous Madame Tussauds Wax Museum. Ride the London Eye",
              description:
                "Explore London's landmarks, visit Madame Tussauds, and ride the London Eye.",
              meal: "",
            },
            {
              dayname: "Day 6",
              header: "Fly back home",
              description:
                "Check-out and proceed to the airport for departure.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0022_Edinburgh.webp",
            text: "PTN_PkG_0022_Edinburgh.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0022_Glasgow.webp",
            text: "PTN_PkG_0022_Glasgow.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0022_London.webp",
            text: "PTN_PkG_0022_London.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0022_Manchester.webp",
            text: "PTN_PkG_0022_Manchester.webp",
          },
        ],
      },
      {
        title: "destinaions",
        content: [
          "Glasgow",
          "Edinburgh",
          "Manchester",
          "London",
          "London",
          "Departure",
        ],
      },

      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance",
          },
          {
            header:
              "Personal expenses like Porterage, Laundry, Alcohol, and Mini Bar",
          },
          {
            header: "Extra meals not included in the itinerary",
          },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees, and local guides not mentioned in inclusions",
          },
          {
            header: "GST, TCS, and other applicable taxes",
          },
          {
            header: "Anything not specified in the itinerary",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 102684,
            text: "Per Person",
          },
          {
            packageDuration: "5N/6D",
          },
        ],
      },
    ],
    //   package no 7-----------------------------------------------------------------------------------------------------------------------------
    [
      {
        title: "packageId",
        content: "PTN_PkG_0023",
      },
      {
        title: "package_name",
        content: "GRAND WONDERS OF EUROPE - 2024",
      },
      // {
      // title: "places",
      // content: [
      //   "United Kingdom",
      //   "France",
      //   "Belgium",
      //   "The Netherlands",
      //   "Germany",
      //   "Switzerland",
      //   "Liechtenstein",
      //   "Austria",
      //   "Italy",
      //   "Vatican City",
      // ],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 10 countries in 15 Days: United Kingdom, France, Belgium, The Netherlands, Germany, Switzerland, Liechtenstein, Austria, Italy, and Vatican City",
          "Guided city tours in major cities like London, Paris, Florence, and Rome",
          "Visits to iconic landmarks such as the Eiffel Tower, Buckingham Palace, the Vatican Museum, and the Colosseum",
          "Exciting activities like a gondola ride in Venice, Disneyland Paris, and a horse-drawn carriage ride in Interlaken",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "14N/15D",
          Cities: [
            "United Kingdom",
            "France",
            "Belgium",
            "The Netherlands",
            "Germany",
            "Switzerland",
            "Liechtenstein",
            "Austria",
            "Italy",
            "Vatican City",
          ],
          Country: [
            "ENGLAND",
            "FRANCE",
            "BELGIUM",
            "NETHERLANDS",
            "GERMANY",
            "SWITZERLAND",
            "LIECHTENSTEIN",
            "AUSTRIA",
            "ITALY",
          ],
          CountryNames: {
            ENGLAND: [{ name: "London", duration: "2N" }],
            FRANCE: [{ name: "Paris", duration: "3N" }],
            NETHERLANDS: [
              { name: "Netherlands", duration: "1N" },
            ],
            GERMANY: [{ name: "Germany", duration: "1N" }],
            SWITZERLAND: [{ name: "Central Switzerland", duration: "3N" }],
            AUSTRIA: [{ name: "Innsbruck / Seefeld", duration: "1N" }],
            ITALY: [
              { name: "Padova", duration: "1N" },
              { name: "Tuscany", duration: "2N" },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Arrive into London.",
              description:
                "The capital of England and the United Kingdom Commence the journey with a flight to London. Upon arrival, meet the Tour Manager and check-in at the hotel. Overnight in London. (Dinner)",
              meal: "Dinner",
            },
            {
              dayname: "Day 2",
              header: "Guided city tour of London with Changing of Guards.",
              description:
                "Explore London's landmarks with a local guide.  Visit to the famous Madame Tussauds Wax Museum. Ride the London Eye, Visit attractions like the Big Ben, Buckingham Palace, and more. Overnight in London. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 3",
              header: "Arrive into Paris",
              description:
                "Travel by high-speed train from London to Paris. Explore Paris on arrival, known for its art, fashion, and culture. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 4",
              header: "Experience a guided city tour of Paris",
              description:
                "Guided tour of Paris landmarks and a visit to the iconic Eiffel Tower. Explore the Palace of Versailles. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 5",
              header: "Indulge in a day of excitement at Disneyland® Paris.",
              description:
                "Visit Disneyland Paris for a day of fun. Enjoy a romantic cruise on the Seine River. Experience Paris by Night. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 6",
              header: "Onto Brussels.",
              description:
                "Travel to Brussels and explore Grand Place and Manneken Pis statue. Visit Keukenhof Gardens and Madurodam miniature park. Overnight in Netherlands. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 7",
              header: "Explore Amsterdam with Canal Cruise. Drive to Germany",
              description:
                "Explore Amsterdam with a canal cruise. Drive to Germany and check-in at the hotel. Overnight in Germany. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 8",
              header: "Visit Mercedes Benz Museum.",
              description:
                "Visit the Mercedes Benz Museum and travel to the Black Forest. Witness the grandeur of the largest waterfall in Switzerland. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 9",
              header: "Visit Jungfraujoch.",
              description:
                "Enjoy a unique experience of Horse-drawn carriage ride in Interlaken paired with the picturesque beauty of Interlaken. The tour's crowning jewel: An enchanting alpine adventure to the summit of Europe, the breath-taking Jungfraujoch. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 10",
              header: "Visit Mt. Titlis",
              description:
                "Visit Mt. Titlis and enjoy cable car rides.- enjoy Cable car rides including the world’s first rotating cable car, the Rotair – to the top of Mt. Titlis at 3,020 metres. Orientation tour of Lucerne. Experience a Swiss Chocolate Adventure . Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 11",
              header: "Drive to Vaduz",
              description:
                "Drive to Vaduz and take a guided tour. the capital at the Principality of Liechtenstein with a guided mini train ride. Visit Swarovski Crystal Museum – the dazzling world of crystals. Visit Innsbruck – The Tyrolean capital of picturesque Austria (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 12",
              header: "Arival Venice, Italy,",
              description:
                "Welcome to Venice, Italy, the captivating floating city. Immerse yourself in the enchanting experience of a gondola ride through its picturesque waterways Arrive in Venice and take a gondola ride. Explore St. Mark's Square and enjoy the beauty of Venice. Overnight in Padova / Bologna. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 13",
              header: "Guided city tour of Florence.",
              description:
                "Take a guided tour of Florence and see its iconic landmarks. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 14",
              header: "Day trip to the eternal city of Rome.",
              description:
                "Take a day trip to Rome and visit Vatican City and St. Peter’s Basilica. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 15",
              header: "A visit Leaning Tower of Pisa",
              description:
                "Visit the Leaning Tower of Pisa and then proceed to Milan Airport for your return flight. (Breakfast, Lunch)",
              meal: "Breakfast, Lunch",
            },
          ],
        },
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "destinaions",
        content: [
          "England",
          "France",
          "Belgium",
          "Netherlands",
          "Germany",
          "Switzerland",
          "Liechtenstein",
          "Austria",
          "Italy",
        ],
      },

      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0024_Amsterdam.webp",
            text: "PTN_PkG_0024_Amsterdam.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Brussels.webp",
            text: "PTN_PkG_0024_Brussels.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_London.webp",
            text: "PTN_PkG_0024_London.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Paris.webp",
            text: "PTN_PkG_0024_Paris.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Schaffhausen.webp",
            text: "PTN_PkG_0024_Schaffhausen.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Titisee.webp",
            text: "PTN_PkG_0024_Titisee.webp",
          },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance",
          },
          {
            header:
              "Personal expenses like Porterage, Laundry, Alcohol, and Mini Bar",
          },
          { header: "Extra meals not included in the itinerary" },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees, and local guides not mentioned in inclusions",
          },
          { header: "GST, TCS, and other applicable taxes" },
          { header: "Anything not specified in the itinerary" },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 239146,
            text: "Per Person",
          },
          {
            packageDuration: "14N/15D",
          },
        ],
      },
    ],
    //   package no 8------------------------------------------------------------------------------------------------------------------------------
    [
      {
        title: "packageId",
        content: "PTN_PkG_0024",
      },
      {
        title: "package_name",
        content: "GRAND WONDERS OF EUROPE(ROME DEPARTURE) - 2024",
      },
      // {
      // title: "places",
      // content: [
      //   "United Kingdom",
      //   "France",
      //   "Belgium",
      //   "The Netherlands",
      //   "Germany",
      //   "Switzerland",
      //   "Liechtenstein",
      //   "Austria",
      //   "Italy",
      //   "Vatican City",
      // ],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 10 countries in 15 Days: United Kingdom, France, Belgium, The Netherlands, Germany, Switzerland, Liechtenstein, Austria, Italy, and Vatican City",
          "Guided city tours in major cities like London, Paris, Florence, and Rome",
          "Visits to iconic landmarks such as the Eiffel Tower, Buckingham Palace, the Vatican Museum, and the Colosseum",
          "Exciting activities like a gondola ride in Venice, Disneyland Paris, and a horse-drawn carriage ride in Interlaken",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "14N/15D",
          Cities: [
            "United Kingdom",
            "France",
            "Belgium",
            "The Netherlands",
            "Germany",
            "Switzerland",
            "Liechtenstein",
            "Austria",
            "Italy",
            "Vatican City",
          ],
          Country: [
            "ENGLAND",
            "FRANCE",
            "BELGIUM",
            "NETHERLANDS",
            "GERMANY",
            "SWITZERLAND",
            "LIECHTENSTEIN",
            "AUSTRIA",
            "ITALY",
          ],
          CountryNames: {
            ENGLAND: [{ name: "London", duration: "2N" }],
            FRANCE: [{ name: "Paris", duration: "3N" }],
            NETHERLANDS: [
              { name: "Netherlands", duration: "1N" },
            ],
            GERMANY: [{ name: "Germany", duration: "1N" }],
            SWITZERLAND: [{ name: "Central Switzerland", duration: "3N" }],
            AUSTRIA: [{ name: "Innsbruck / Seefeld", duration: "1N" }],
            ITALY: [
              { name: "Padova", duration: "1N" },
              { name: "Tuscany", duration: "2N" },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Arrive into London.",
              description:
                "The capital of England and the United Kingdom Commence the journey with a flight to London. Upon arrival, meet the Tour Manager and check-in at the hotel. Overnight in London. (Dinner)",
              meal: "Dinner",
            },
            {
              dayname: "Day 2",
              header: "Guided city tour of London with Changing of Guards.",
              description:
                "Explore London's landmarks with a local guide.  Visit to the famous Madame Tussauds Wax Museum. Ride the London Eye, Visit attractions like the Big Ben, Buckingham Palace, and more. Overnight in London. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 3",
              header: "Arrive into Paris",
              description:
                "Travel by high-speed train from London to Paris. Explore Paris on arrival, known for its art, fashion, and culture. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 4",
              header: "Experience a guided city tour of Paris",
              description:
                "Guided tour of Paris landmarks and a visit to the iconic Eiffel Tower. Explore the Palace of Versailles. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 5",
              header: "Indulge in a day of excitement at Disneyland® Paris.",
              description:
                "Visit Disneyland Paris for a day of fun. Enjoy a romantic cruise on the Seine River. Experience Paris by Night. Overnight in Paris. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 6",
              header: "Onto Brussels.",
              description:
                "Travel to Brussels and explore Grand Place and Manneken Pis statue. Visit Keukenhof Gardens and Madurodam miniature park. Overnight in Netherlands. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 7",
              header: "Explore Amsterdam with Canal Cruise. Drive to Germany",
              description:
                "Explore Amsterdam with a canal cruise. Drive to Germany and check-in at the hotel. Overnight in Germany. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 8",
              header: "Visit Mercedes Benz Museum.",
              description:
                "Visit the Mercedes Benz Museum and travel to the Black Forest. Witness the grandeur of the largest waterfall in Switzerland. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 9",
              header: "Visit Jungfraujoch.",
              description:
                "Enjoy a unique experience of Horse-drawn carriage ride in Interlaken paired with the picturesque beauty of Interlaken. The tour's crowning jewel: An enchanting alpine adventure to the summit of Europe, the breath-taking Jungfraujoch. Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 10",
              header: "Visit Mt. Titlis",
              description:
                "Visit Mt. Titlis and enjoy cable car rides.- enjoy Cable car rides including the world’s first rotating cable car, the Rotair – to the top of Mt. Titlis at 3,020 metres. Orientation tour of Lucerne. Experience a Swiss Chocolate Adventure . Overnight in Central Switzerland. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 11",
              header: "Drive to Vaduz",
              description:
                "Drive to Vaduz and take a guided tour. the capital at the Principality of Liechtenstein with a guided mini train ride. Visit Swarovski Crystal Museum – the dazzling world of crystals. Visit Innsbruck – The Tyrolean capital of picturesque Austria (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 12",
              header: "Arival Venice, Italy,",
              description:
                "Welcome to Venice, Italy, the captivating floating city. Immerse yourself in the enchanting experience of a gondola ride through its picturesque waterways Arrive in Venice and take a gondola ride. Explore St. Mark's Square and enjoy the beauty of Venice. Overnight in Padova / Bologna. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 13",
              header: "Guided city tour of Florence.",
              description:
                "Take a guided tour of Florence and see its iconic landmarks. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 14",
              header: "Day trip to the eternal city of Rome.",
              description:
                "Take a day trip to Rome and visit Vatican City and St. Peter’s Basilica. Overnight in Tuscany region. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 15",
              header: "A visit Leaning Tower of Pisa",
              description:
                "Visit the Leaning Tower of Pisa and then proceed to Milan Airport for your return flight. (Breakfast, Lunch)",
              meal: "Breakfast, Lunch",
            },
          ],
        },
      },
      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "destinaions",
        content: [
          "England",
          "France",
          "Belgium",
          "Netherlands",
          "Germany",
          "Switzerland",
          "Liechtenstein",
          "Austria",
          "Italy",
        ],
      },

      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0024_Amsterdam.webp",
            text: "PTN_PkG_0024_Amsterdam.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Brussels.webp",
            text: "PTN_PkG_0024_Brussels.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_London.webp",
            text: "PTN_PkG_0024_London.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Paris.webp",
            text: "PTN_PkG_0024_Paris.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Schaffhausen.webp",
            text: "PTN_PkG_0024_Schaffhausen.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0024_Titisee.webp",
            text: "PTN_PkG_0024_Titisee.webp",
          },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance",
          },
          {
            header:
              "Personal expenses like Porterage, Laundry, Alcohol, and Mini Bar",
          },
          { header: "Extra meals not included in the itinerary" },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees, and local guides not mentioned in inclusions",
          },
          { header: "GST, TCS, and other applicable taxes" },
          { header: "Anything not specified in the itinerary" },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 239146,
            text: "Per Person",
          },
          {
            packageDuration: "14N/15D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0025",
      },
      {
        title: "package_name",
        content: "Jewels of East Europe 6 Nights 7 Days - 2024",
      },
      // {
      // title: "places",
      // content: ["Austria", "Hungary", "Slovakia", "Czech Republic", "Germany"],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 5 countries in 7 Days: Austria, Hungary, Slovakia, Czech Republic, and Germany",
          "Guided city tours in Vienna, Budapest, and Prague",
          "Visit iconic landmarks like Schonbrunn Palace, Charles Bridge, and Prague Castle",
          "Enjoy a Danube River Cruise in Budapest",
          "Orientation tours of Bratislava and Munich",
          "Visit the BMW Welt in Munich",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "6N/7D",
          Cities: ["Vienna", "Budapest", "Bratislava", "Prague", "Munich"],
          Country: [
            "Austria",
            "Hungary",
            "Slovakia",
            "CzechRepublic",
            "Germany",
          ],
          CountryNames: {
            Austria: [
              {
                name: "Vienna",
                duration: "1N",
              },
            ],
            Hungary: [
              {
                name: "Budapest",
                duration: "3N",
              },
            ],
            Slovakia: [
              {
                name: "Bratislava",
                duration: "1N",
              },
            ],
            CzechRepublic: [
              {
                name: "Prague",
                duration: "2N",
              },
            ],
            Germany: [
              {
                name: "Munich",
                duration: "1N",
              },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Arrive in Vienna",
              description:
                "Arrive in Vienna, the capital of Austria, and known as the 'City of Music'. Meet your tour manager at Vienna Airport and transfer to the hotel. Overnight stay in Vienna. (Dinner)",
              meal: "Dinner",
            },
            {
              dayname: "Day 2",
              header: "Guided tour of Vienna.",
              description:
                "Explore Vienna's Innere Stadt on a guided sightseeing tour, including Stephansplatz, Karntner strasse, and Vienna State Opera House. Visit Schonbrunn Palace. Drive to Budapest and check in to your hotel. Overnight stay in Budapest. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 3",
              header: "Guided city tour of Budapest.",
              description:
                "Take a guided city tour of Budapest, visit Heroes' Square, Fishermen’s Bastion, and more. Enjoy a Danube River Cruise. Overnight stay in Budapest. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 4",
              header: "Onto Prague. Orientation tour of Bratislava Castle",
              description:
                "Travel to Prague, with a detour to Bratislava for an orientation tour. Explore key attractions including Bratislava Castle. Overnight in Prague. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 5",
              header: "City tour of Prague with Prague Castle.",
              description:
                "Take a walking tour of Prague, visit Prague Castle and Charles Bridge. Witness the Astronomical Clock and explore Old Town Square. Overnight in Prague. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 6",
              header: "OntOrientation tour of Munich.",
              description:
                "Travel to Munich and embark on an orientation tour, visiting Marienplatz and Munich Residence. Visit BMW Welt. Overnight stay in Munich. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 7",
              header: "Fly back home",
              description:
                "Check out and transfer to the airport for your return flight. End of tour. (Breakfast)",
              meal: "Breakfast",
            },
          ],
        },
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0025_Bratislava.webp",
            text: "PTN_PkG_0025_Bratislava.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0025_Budapest.webp",
            text: "PTN_PkG_0025_Budapest.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0025_Munich.webp",
            text: "PTN_PkG_0025_Munich.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0025_Prague.webp",
            text: "PTN_PkG_0025_Prague.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0025_Vienna.webp",
            text: "PTN_PkG_0025_Vienna.webp",
          },
        ],
      },
      {
        title: "destinaions",
        content: ["Austria", "Hungary", "Slovakia", "CzechRepublic", "Germany"],
      },

      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance",
          },
          {
            header:
              "Personal expenses like Porterage, Laundry, Alcohol, and Mini Bar",
          },
          { header: "Extra meals not included in the itinerary" },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees, and local guides not mentioned in inclusions",
          },
          { header: "GST, TCS, and other applicable taxes" },
          { header: "Anything not specified in the itinerary" },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 90524,
            text: "Per Person",
          },
          {
            packageDuration: "6N/7D",
          },
        ],
      },
    ],
    //   package no 9------------------------------------------------------------------------------------------------------------------------------

    [
      {
        title: "packageId",
        content: "PTN_PkG_0026",
      },
      {
        title: "package_name",
        content: "Scandinavian Delights Group Tour 08 Nights / 09 Days",
      },
      // {
      // title: "places",
      // content: ["Denmark", "Norway", "Sweden", "Finland"],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 4 countries in 9 Days: Denmark, Norway, Sweden, and Finland",
          "Guided city tours in Copenhagen, Oslo, Stockholm, and Helsinki",
          "Experience attractions like Tivoli Gardens, Nyhavn Canal Cruise, Vasa Museum, and Rock Church",
          "Enjoy Norway in a Nutshell tour including Fjord cruise, scenic train journey, and Flam Railway ride",
          "Guided tour of Drottningholm Palace",
          "Overnight cruises from Copenhagen to Oslo and from Stockholm to Helsinki",
          "Services of a Professional & Knowledgeable Tour Manager",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "8N/9D",
          Cities: ["Copenhagen", "Oslo", "Geilo", "Stockholm", "Helsinki"],
          Country: ["Denmark", "Norway", "Sweden", "Finland"],
          CountryNames: {
            Denmark: [
              {
                name: "Copenhagen",
                duration: "2N",
              },
            ],
            Norway: [
              {
                name: "Oslo",
                duration: "2N",
              },
              {
                name: "Geilo",
                duration:"1N",
              },
            ],
            Sweden: [
              {
                name: "Stockholm",
                duration: "3N",
              },
            ],
            Finland: [
              {
                name: "Helsinki",
                duration: "1N",
              },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Fly to Copenhagen - Denmark’s capital.",
              description:
                "Arrive in Copenhagen, Denmark. Check in to the hotel and later visit Tivoli Gardens. Overnight in Copenhagen. (Dinner)",
              meal: "Dinner",
            },
            {
              dayname: "Day 2",
              header:
                "Guided city tour of Copenhagen. Nyhavn Canal Cruise. Overnight cruise on DFDS",
              description:
                "Take a guided city tour of Copenhagen, visit landmarks like the Little Mermaid and Amalienborg Palace. Enjoy a Nyhavn Canal Cruise. Overnight on board the DFDS cruise to Oslo. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 3",
              header: "Guided city tour of Oslo.",
              description:
                "Arrive in Oslo and take a guided city tour. Visit Vigeland Park, Holmenkollen Ski Jump, and Museum of Cultural History. Overnight in Geilo. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 4",
              header: "Norway in Nutshell tour.",
              description:
                "Embark on the Norway in Nutshell tour, including scenic train rides and a boat trip through fjords. Return to Oslo. Overnight in Oslo. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 5",
              header: "Drive to Stockholm via Karlstad.",
              description:
                "Drive to Stockholm with a stop in Karlstad. Enjoy the scenic beauty of Lake Vanern. Overnight in Stockholm. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 6",
              header: "Guided city tour of Stockholm.",
              description:
                "Take a guided city tour of Stockholm, visit the Vasa Museum and enjoy a ride on Sky View. Overnight in Stockholm. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 7",
              header: "Guided tour of Drottningholm Palace.",
              description:
                "Visit Drottningholm Palace. Later, board an overnight cruise to Helsinki. Overnight on board the Silja Line cruise. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 8",
              header: "Guided city tour of Helsinki",
              description:
                "Arrive in Helsinki and take a guided city tour, visiting attractions like Senate Square, Sibelius Monument, and Rock Church. Overnight in Helsinki. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 9",
              header: "Fly back home",
              description:
                "Check out and transfer to the airport for your return flight. End of tour. (Breakfast)",
              meal: "Breakfast",
            },
          ],
        },
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0026_Copenhagen.webp",
            text: "PTN_PkG_0026_Copenhagen.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0026_Helsinki.webp",
            text: "PTN_PkG_0026_Helsinki.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0026_Karlstad.webp",
            text: "PTN_PkG_0026_Karlstad.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0026_Oslo.webp",
            text: "PTN_PkG_0026_Oslo.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0026_Stockholm.webp",
            text: "PTN_PkG_0026_Stockholm.webp",
          },
        ],
      },
      {
        title: "destinaions",
        content: ["Denmark", "Norway", "Sweden", "Finland"],
      },

      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance",
          },
          {
            header:
              "Personal expenses like Porterage, Laundry, Alcohol, and Mini Bar",
          },
          { header: "Extra meals not included in the itinerary" },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees, and local guides not mentioned in inclusions",
          },
          { header: "GST, TCS, and other applicable taxes" },
          { header: "Anything not specified in the itinerary" },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 194109,
            text: "Per Person",
          },
          {
            packageDuration: "9N/8D",
          },
        ],
      },
    ],
    //   package no 10-----------------------------------------------------------------------------------------------------------------------------
    [
      {
        title: "packageId",
        content: "PTN_PkG_0027",
      },
      {
        title: "package_name",
        content: "Spanish Fiesta with Portugal 10 Nights 11 Days",
      },
      // {
      // title: "places",
      // content: [
      //   "Lisbon",
      //   "Sintra",
      //   "Seville",
      //   "Cordoba",
      //   "Granada",
      //   "Malaga",
      //   "Toledo",
      //   "Madrid",
      //   "Barcelona",
      // ],
      // },
      {
        title: "tour_type",
        content: ["International", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 10 cities in 11 Days: Lisbon, Sintra, Seville, Cordoba, Granada, Malaga, Toledo, Madrid, and Barcelona",
          "Guided tours in Lisbon, Seville, Cordoba, Granada, Toledo, Madrid, Valencia, Barcelona",
          "Experience Flamenco Show in Seville",
          "Entrance to Alhambra Palace and Generalife Gardens in Granada",
          "Orientation tour of Malaga",
          "Visit the Bullfighting Ring in Madrid",
          "Excursion to Montserrat",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "10N/11D",
          Cities: [
            "Lisbon",
            "Sintra",
            "Seville",
            "Cordoba",
            "Granada",
            "Malaga",
            "Toledo",
            "Madrid",
            "Barcelona",
          ],
          Country: ["Portugal", "Spain"],
          CountryNames: {
            Portugal: [
              {
                name: "Lisbon",
                duration: "2N",
              },
            ],
            Spain: [
              {
                name: "Seville",
                duration: "1N",
              },
              {
                name: "Granada",
                duration: "2N",
              },
              {
                name: "Madrid",
                duration: "2N",
              },
              {
                name: "Valencia",
                duration: "1N",
              },
              {
                name: "Barcelona",
                duration: "2N",
              },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Arrive in Lisbon",
              description: "Welcome to Lisbon, the capital city of Portugal.",
              meal: "Dinner",
            },
            {
              dayname: "Day 2",
              header: "Guided city tour of Lisbon.",
              description:
                "Today after breakfast, meet your professional English-speaking guide and proceed for your guided city tour  Photo-stop at Belem Tower. Orientation tour of Sintra .",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 3",
              header: "Drive to Seville. ",
              description:
                "Check out from your hotel and relax in your coach as we drive to Seville. Enjoy the Flamenco Show",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 4",
              header: "Guided city tour of Seville.",
              description:
                "Today we check out from the hotel and explore the shiny and sunbathed city is the home of Flamenco and Andalusian culture in general with a professional English-speaking guide. Its rich heritage is mirrored on its streets and monuments, especially in the old town where scenes of romance are on every corner on this guided tour. See the most famous landmark of Seville, the Catedral de Santa María de la Sede, the largest gothic church in Spain, a UNESCO World Heritage Site and the final resting place of Christopher Columbus. La Giralda, Real Alcázar, Plaza Nueva, Aire de Sevilla, Catedral de Sevilla and Giralda etc. Come through the old city of Seville, to the cathedral, the Alcázar and the main highlights of the capital of Andalucia. Later we drive to Cordoba. Enroute, have a brief halt at Home to the largest old town in Spain and the only city in the world to have four Unesco-protected sites. On arrival, start with your orientation city tour. Walk through the streets of the city at your own pace, with breaks to take photos, eat or buy a souvenir. You will see the monuments and attractions of Córdoba: Mosque-Cathedral of Córdoba, Alcázar de los Reyes Cristianos, Roman Bridge, Puerta del Puente, Patio de los Naranjos, Jewish Quarter of Córdoba, Plaza of the Corredera etc. Next, we visit the Monastery of San Jernimo. This building has been considered an architectural jewel among the religious buildings of Córdoba since the beginning of the 15th century. It was the first monastery of the sisters of St Jerome in Spain. Continue your drive to Granada and check in to your hotel.( Drive to Granada. Enroute, guided city tour of Cordoba. Visit Monastery of San Jeronimo) Overnight in Granada (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 5",
              header: "Guided city tour of Granada.",
              description:
                "Today after breakfast, meet your professional English speaking guide and follow your guide into the narrow streets covered in white houses, gardened patios and many springs that mix in harmony arabic and christian architecture. Marvel at the famous Puerta Elvira (Gate of Elvira),Puerta Monaita (Monaita Gate),Palacio de Dar-al-Horra, Convent of Santa Isabel la Real etc. Visit the Alhambra Palace, situated atop a hill overlooking Granada, Spain, is a breathtaking testament to Moorish art and architecture. Learn about history, the enclave, the spaces that make up the monumental complex and museums, to discover everything that the monumental complex of the Alhambra and Generalife have to offer. Each step through the Alhambra's halls reveals a tapestry of history, from the Generalife Gardens' tranquillity to the Nasrid Palaces' opulent chambers. The Alhambra's grandeur extends beyond its physical beauty; it whispers tales of Moorish culture, Islamic influence, and the enduring allure of a palace that has stood the test of time, leaving an indelible mark on the landscape and the hearts of those fortunate enough to wander its halls. Following that, sit back in your coach as we drive to Malaga for an orientation tour. Discover Málaga and the charm of its streets, squares, and hidden corners. The Cathedral, Calle Larios, the Atarazanas Market, the Roman Theatre and the Alcazaba are just some of the emblematic monuments that you will see. Additionally, you will discover the peculiar history of Malaga with some secret legends and facts that will let you fall in love with this city.  Visit Alhambra Palace and Generalife Gardens.(Orientation tour of Malaga) Overnight in Granada (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 6",
              header: "Onto Madrid. Enroute visit Toledo",
              description:
                "Check out from your hotel and proceed to Madrid, one of Europe's most dynamic capital and greenest cities. It is known for its historical buildings, food markets, and the royals. Within its boundaries you'll find a range of cultural and historical treasures such as the Royal Palace, Royal Theatre along with numerous museums and art galleries that are unmatched elsewhere in Europe. Enroute, we visit the Imperial former capital city of Toledo, where you will be escorted through its narrow cobblestone streets, soaking up the medieval atmosphere. The visit will include the interior of the magnificent Gothic cathedral and a visit to St. Tome Church, where El Greco's masterpiece painting is displayed. Next we visit the Toledo Sword Factory. Toledo steel, historically known for being unusually hard, is from Toledo, Spain, which has been a traditional sword-making and a metal-working center. The swords forged in Toledo, Spain, were once considered the finest in the world. Continue your drive to Madrid. On arrival, check in and relax in the comforts of your hotel. Overnight in Madrid (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 7",
              header: "Guided city tour of Madrid.",
              description:
                "This morning, meet your guide will take you to visit the city of Madrid which will allow you to see Madrid's most outstanding attractions, like the Puerta del Sol, the magnificent fountains of Neptune and Cibeles, the stately Alcala Gate, the busy Gran Via, the Plaza de España and the Plaza de Oriente. Next, proceed to the Royal Palace of Madrid. It is the official residence of the Spanish royal family at the city of Madrid, although now used only for state ceremonies. It is the largest functioning royal palace and the largest by floor area in Europe. Next, we proceed to visit a famous Bullfighting Ring. To many, the image of Spain is closely associated with bullfights – an art that became popular in the early twelfth century and is still very much alive.  Photo-stop at Royal Palace. Visit Bullfighting Ring. Overnight in Madrid (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 8",
              header: "Onto Valencia. Guided tour of Valencia",
              description:
                "Post breakfast, check out from the hotel as we drive to Valencia which is supposedly known for its vibrant culture, historical significance, and beautiful landscapes. Meet your professional English-speaking guide and proceed for guided tour through the historic center and visit the most emblematic monuments, with a focus on the city's three World Heritage properties. The city of Valencia boasts more than 21 centuries of history, dozens of monuments from every era, and three World Heritage properties. Visit Valencia's most emblematic monuments. Visit the Lonja de la Seda. Discover the Silk Exchange (Lonja de la Seda), the Water Tribunal (Tribunal de las Aguas), and the Fallas (an annual festival featuring ephemeral art, fire, and fireworks). Few destinations in the world can boast three different cultural attractions on the prestigious UNESCO list. Later visit the famous vineyards of Valencia and Uncork the secrets of Valencian wine. Discover the ancient art of winemaking in Spain as you journey through the historic Utiel-Requena region. Later drive to your hotel and check in. Overnight in Valencia (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 9",
              header: "Drive to Barcelona",
              description:
                "Today we check out from the hotel and proceed to the much-awaited city of Barcelona. The vibrant capital of Catalonia in northeastern Spain, is a city that seamlessly blends rich history, modern design, and a captivating cultural scene. Its iconic architecture, notably the mesmerizing works of Antoni Gaudí such as the Sagrada Família and Park Güell, defines the city's skyline. Upon arrival, proceed for a Guided tour of Barcelona. Enjoy a guided walking tour of Barcelona's Gothic Quarter, with a professional English-speaking guide to accompany you. Wander past important historic monuments such as the Saló del Tinell, the Capella de Santa Àgata and the Palau del Lloctinent. Visit the Olympic Village, Columbus monument and Montjuïc Mountain where you will be able to see the Olympic Stadium, see Plaza de Cataluna, lively Ramblas the most famous street of Barcelona. Next, we visit Sagrada Familia. The Sagrada Família is a large unfinished Roman Catholic minor basilica and is a one-of-a-kind temple, for its origins, foundation and purpose. Five generations now have watched the Temple progress in Barcelona. Today, more than 135 years after the laying of the cornerstone, construction continues on the Basilica. Overnight in Barcelona (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 10",
              header: "Excursion to Montserrat",
              description:
                "Today post breakfast, we will be proceeding to one of the most popular day trip from Barcelona – Montserrat. It a visit to a unique mountain range offering views over Catalonia. Venture into the Catalan countryside to visit Montserrat on this full-day tour from Barcelona. See the Basilica and Monastery on Montserrat, a beautiful, multi-peak mountain with dramatic rock formations, with free time to continue exploring the museum, farmer's market, or views from the ridge of Cavall Bernat. Later return to the city and have some free time at Lam Ramblas. As a cultural and social hub, Las Ramblas captures the essence of Barcelona's dynamic spirit, making it a must-visit destination for those seeking the city's unique charm. Overnight in Barcelona (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 11",
              header: "Fly back home",
              description:
                "Your memorable holiday ends, today it’s time to fly back home. Say goodbye to the wonderful new friends you have made on the tour. Check out and depart for the Airport with pleasant memories of your European trip which you will cherish for a lifetime. (Breakfast)",
              meal: "Breakfast",
            },
          ],
        },
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0027_Barcelona.webp",
            text: "PTN_PkG_0027_Barcelona.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0027_Cordoba.webp",
            text: "PTN_PkG_0027_Cordoba.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0027_Granada.webp",
            text: "PTN_PkG_0027_Granada.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0027_Lisbon.webp",
            text: "PTN_PkG_0027_Lisbon.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0027_Madrid.webp",
            text: "PTN_PkG_0027_Madrid.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0027_Malaga.webp",
            text: "PTN_PkG_0027_Malaga.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0027_Seville.webp",
            text: "PTN_PkG_0027_Seville.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0027_Sintra.webp",
            text: "PTN_PkG_0027_Sintra.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0027_Tolado.webp",
            text: "PTN_PkG_0027_Tolado.webp",
          },
        ],
      },
      {
        title: "destinaions",
        content: [
          "Lisbon",
          "Sintra",
          "Seville",
          "Cordoba",
          "Granada",
          "Malaga",
          "Toledo",
          "Madrid",
          "Barcelona",
        ],
      },

      {
        title: "Package Inclusion",
        content: [],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "Cost of Airfare, Passport, POE charges, Visa charges, Overseas Travel Insurance, etc.",
          },
          { header: "Expenses of personal nature" },
          { header: "Extra meals not included in the itinerary" },
          {
            header:
              "Cost of excursions, city sightseeing, entrance fees, and local guides",
          },
          { header: "GST, TCS, and other taxes" },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 175644,
            text: "Per Person",
          },
          {
            packageDuration: "10N/11D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0028",
      },
      {
        title: "package_name",
        content: "Meghalaya Tour Plan 6 Days / 5 Nights",
      },
      // {
      // title: "places",
      // content: ["MEGHALAYA"],
      // },
      {
        title: "tour_type",
        content: ["Family Tour", "Honeymoon Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 6 cities in 6 Days: Shillong, Cherrapunjee, Nongriat, Mawlynnong, Amlarem, Guwahati",
          "Explore famous attractions like Kamakhya Temple, Umiam Lake, Mattilang Park, Mawkdok Dympep Valley, Wahkaba falls, Weisawdong Falls, Deinthlen Falls, Arwah Caves, Double Decker Bridge, Rainbow Falls, Umngot River, Wahkhen, Mawlynnong Village, Krangshuri Falls, PhePhe Falls, and Sualudong falls",
          "Enjoy an orientation tour of Malaga",
          "All transfer and sightseeing included",
          "Accommodations provided in budget stays",
          "Meals included: Breakfast only",
          "Car rentals and driver's expenses covered",
          "Taxes included in the package",
          "Package excludes personal expenses, alcohol & beverages, entry tickets to tourist spots/museums, telephone & laundry services, expenses not mentioned, natural calamity expenses, tips, parking fees, and fees for adventure activities",

        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "5N/6D",
          Cities: [
            "Shillong",
            "Cherrapunjee",
            "Nongriat",
            "Mawlynnong",
            "Amlarem",
            "Guwahati",
          ],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Shillong", duration: "1N" },
              { name: "Cherrapunjee", duration: "2N" },
              { name: "Mawlynnong", duration: "1N" },
              { name: "Shillong", duration: "1N" },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Guwahati to Shillong",
              description:
                "Drive from Guwahati to Shillong City. Enroute visit Kamakhya Temple and Umiam Lake. Evening free at Leisure. Overnight stay in Shillong.",
              meal: "",
            },
            {
              dayname: "Day 2",
              header: "Shillong to Cherrapunjee",
              description:
                "Drive to Cherrapunjee. Visit Mattilang Park, Mawkdok Dympep Valley, Wahkaba falls, Weisawdong Falls, Deinthlen Falls, Arwah Caves. Overnight stay in Cherrapunjee.",
              meal: "",
            },
            {
              dayname: "Day 3",
              header: "Cherrapunjee to Nongriat",
              description:
                "Drive to Tyrna village. Trek to the Double Decker Bridge and Rainbow Falls. Evening free at Leisure. Overnight stay in Cherrapunjee.",
              meal: "",
            },
            {
              dayname: "Day 4",
              header: "Cherrapunjee to Mawlynnong",
              description:
                "Drive to Shnongpdeng. Visit Umngot River, Wahkhen, Mawlynnong Village. Overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 5",
              header: "Dawki to Amlarem to Shillong",
              description:
                "Visit Krangshuri Falls, PhePhe Falls & Sualudong falls. Return to Shillong for overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 6",
              header: "Shillong to Guwahati",
              description: "Drive to Guwahati for departure.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: [
          "Shillong",
          "Cherrapunjee",
          "Nongriat",
          "Mawlynnong",
          "Amlarem",
          "Guwahati",
        ],
      },
      {
        title: "Package Inclusion",
        content: [
          { header: "All Transfer and Sightseeing" },
          { header: "Accommodations – Budget stay" },
          { header: "Meals – Breakfast Only" },
          { header: "Car rentals" },
          { header: "Taxes" },
          { header: "Driver’s expenses" },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          { header: "Any expense of personal nature" },
          { header: "Alcohol & beverage" },
          { header: "Ticket for entry to tourist spot/ Museum" },
          { header: "Telephone & laundry" },
          { header: "All other expenses not mentioned in the package" },
          { header: "Expenses due to natural calamities" },
          { header: "Tips" },
          { header: "Parking Fee" },
          { header: "Fees for adventure activities" },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0028_shnongpdeng.webp",
            text: "PTN_PkG_0028_shnongpdeng.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0030_Chrapunji.webp",
            text: "PTN_PkG_0030_Chrapunji.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0030_Shillong.webp",
            text: "PTN_PkG_0030_Shillong.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0030_Shillong1.webp",
            text: "PTN_PkG_0030_Shillong1.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 22000,
            text: "Per Person",
          },
          {
            packageDuration: "5N/6D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0029",
      },
      {
        title: "package_name",
        content: "Meghalaya Tour Plan 5 Days / 4 Nights",
      },
      // {
      // title: "places",
      // content: ["Meghalaya"],
      // },
      {
        title: "tour_type",
        content: ["Family Tour", "Honeymoon Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 6 cities in 5 Days: Shillong, Cherrapunjee, Nongriat, Mawryngkhang, Mawlynnong, Guwahati",
          "Explore famous attractions like Kamakhya Temple, Umiam Lake, Mattilang Park, Elephant Falls, Mawkdok Dympep Valley, Wahkaba Falls, Weisawdong Falls, Deinthlen Falls, Arwah Caves, Double Decker Bridge, Rainbow Falls, natural pool, Umngot River, Wahkhen, and Mawryngkhang trekking",
          "All transfer and sightseeing included",
          "Accommodations provided in budget stays",
          "Meals included: Breakfast only",
          "Car rentals and driver's expenses covered",
          "Taxes included in the package",
          "Package excludes personal expenses, alcohol & beverages, entry tickets to tourist spots/museums, telephone & laundry services, expenses not mentioned, natural calamity expenses, tips, parking fees, and fees for adventure activities",

        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "4N/5D",
          Cities: [
            "Shillong",
            "Cherrapunjee",
            "Nongriat",
            "Mawryngkhang",
            "Mawlynnong",
            "Guwahati",
          ],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Shillong", duration: "1N" },
              { name: "Cherrapunjee", duration: "1N" },
              { name: "Tyrna", duration: "1N" },
              { name: "Shillong", duration: "1N" },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Guwahati to Shillong (112kms)",
              description:
                "Transfer from Guwahati to Shillong City. Enroute visit Kamakhya Temple and Umiam Lake. Evening free at Leisure. Overnight stay in Shillong.",
              meal: "",
            },
            {
              dayname: "Day 2",
              header: "Shillong to Cherrapunjee (55kms)",
              description:
                "Drive to Cherrapunjee visit Mattilang Park (View of Elephant Falls), Mawkdok Dympep Valley. Visit Wahkaba falls, Weisawdong Falls, Deinthlen Falls, and Arwah Caves. Overnight stay in Cherrapunjee.",
              meal: "",
            },
            {
              dayname: "Day 3",
              header: "Cherrapunjee to Nongriat (12kms)",
              description:
                "Drive to Tyrna village. Trek to Meghalaya’s 'Double Decker Bridge', Rainbow Falls, and natural Pool. Overnight stay in Tyrna Village.",
              meal: "",
            },
            {
              dayname: "Day 4",
              header:
                "Cherrapunjee to Mawryngkhang (51kms) to Mawlynnong (50kms)",
              description:
                "Drive to Shnongpdeng (Umngot River). Enroute Visit Wahkhen and Mawryngkhang trekking. Visit Mawlynnong Village. Travel back to Shillong for an Overnight Stay.",
              meal: "",
            },
            {
              dayname: "Day 5",
              header: "Shillong to Guwahati (112kms)",
              description: "Head to Guwahati for departure.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: [
          "Shillong",
          "Cherrapunjee",
          "Nongriat",
          "Mawryngkhang",
          "Mawlynnong",
          "Guwahati",
        ],
      },
      {
        title: "Package Inclusion",
        content: [
          { header: "All Transfer and Sightseeing" },
          { header: "Accommodations – Budget stay" },
          { header: "Meals – Breakfast Only" },
          { header: "Car rentals" },
          { header: "Taxes" },
          { header: "Driver’s expenses" },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          { header: "Any expense of personal nature" },
          { header: "Alcohol & beverage" },
          { header: "Ticket for entry to tourist spot/ Museum" },
          { header: "Telephone & laundry" },
          { header: "All other expenses not mentioned in the package" },
          { header: "Expenses due to natural calamities" },
          { header: "Tips" },
          { header: "Parking Fee" },
          { header: "Fees for adventure activities Itinerary" },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0029_Tyrna.webp",
            text: "PTN_PkG_0029_Tyrna.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0030_Chrapunji.webp",
            text: "PTN_PkG_0030_Chrapunji.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0030_Shillong.webp",
            text: "PTN_PkG_0030_Shillong.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0030_Shillong1.webp",
            text: "PTN_PkG_0030_Shillong1.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 17800,
            text: "Per Person",
          },
          {
            packageDuration: "4N/5D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0030",
      },
      {
        title: "package_name",
        content: "Meghalaya Tour Plan 7 Days / 6 Nights",
      },
      // {
      // title: "places",
      // content: ["Meghalaya"],
      // },
      {
        title: "tour_type",
        content: ["Family Tour", "Honeymoon Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 9 cities in 7 Days: Shillong, Cherrapunjee, Nongriat, Mawryngkhang, Mawlynnong, Amlarem, Mawphlang, WahRilang, Guwahati",
          "Explore famous attractions like Kamakhya Temple, Umiam Lake, Mattilang Park, Elephant Falls, MawkdokDympep Valley, Wahkaba Falls, Weisawdong Falls, Deinthlen Falls, Arwah Caves, Double Decker Bridge, Rainbow Falls, natural pool, Umngot River, Wahkhen, Mawryngkhang trekking, Krangsuri Falls, Phe Phe Falls, SuaLudong Falls, Mawphlang Scared Groves, WahRilang, and Jakrem hot spring",
          "All transfer and sightseeing included",
          "Accommodations provided in budget stays",
          "Meals included: Breakfast only",
          "Car rentals and driver's expenses covered",
          "Taxes included in the package",
          "Package excludes personal expenses, alcohol & beverages, entry tickets to tourist spots/museums, telephone & laundry services, expenses not mentioned, natural calamity expenses, tips, parking fees, and fees for adventure activities",

        ],
      },

      {
        title: "TourIternary",
        content: {
          Duration: "6N/7D",
          Cities: [
            "Shillong",
            "Cherrapunjee",
            "Nongriat",
            "Mawryngkhang",
            "Mawlynnong",
            "Amlarem",
            "Mawphlang",
            "WahRilang",
            "Guwahati",
          ],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Shillong", duration: "1N" },
              { name: "Cherrapunjee", duration: "2N" },
              { name: "Pongtung", duration: "1N" },
              { name: "Shillong", duration: "2N" },
              { name: "Nongriat", duration: "" },
              { name: "Mawlynnong", duration: "" },
              { name: "WahRilang", duration: "" },       
              
              
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Guwahati to Shillong  ",
              description:
                "Transfer from Guwahati to Shillong City. Enroute visit Kamakhya Temple and Umiam Lake. Evening free at Leisure. Overnight stay in Shillong.",
              meal: "",
            },
            {
              dayname: "Day 2",
              header: "Shillong to Cherrapunjee  ",
              description:
                "Drive to Cherrapunjee visit Mattilang Park (View of Elephant Falls), MawkdokDympep Valley. Visit Wahkaba falls, Weisawdong Falls, Deinthlen Falls, and Arwah Caves. Overnight stay in Cherrapunjee.",
              meal: "",
            },
            {
              dayname: "Day 3",
              header: "Cherrapunjee to Nongriat  ",
              description:
                "Drive to Tyrna village. Trek to Meghalaya’s 'Double Decker Bridge', Rainbow Falls, and natural Pool. Evening free at Leisure. Overnight stay in Cherrapunjee.",
              meal: "",
            },
            {
              dayname: "Day 4",
              header: "Cherrapunjee to Mawryngkhang to Mawlynnong  ",
              description:
                "Proceed to Shnongpdeng. Enroute Visit Wahkhen and Mawryngkhang trekking. Visit Mawlynnong Village. Overnight stay at Pongtung.",
              meal: "",
            },
            {
              dayname: "Day 5",
              header: "Pongtung to Amlarem  to Shillong  ",
              description:
                "Drive to Amlarem. Visit Krangsuri Falls, Phe Phe Falls & SuaLudong Falls. Return to Shillong for overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 6",
              header: "Shillong to Mawphlang to WahRilang  ",
              description:
                "Visit Mawphlang Scared Groves and WahRilang. Stop at Jakrem hot spring. Return to Shillong for overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 7",
              header: "Shillong to Guwahati  ",
              description: "Head to Guwahati for departure.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: [
          "Shillong",
          "Cherrapunjee",
          "Nongriat",
          "Mawryngkhang",
          "Mawlynnong",
          "Amlarem",
          "Mawphlang",
          "WahRilang",
          "Guwahati",
        ],
      },
      {
        title: "Package Inclusion",
        content: [
          { header: "All Transfer and Sightseeing" },
          { header: "Accommodations – Budget stay" },
          { header: "Meals – Breakfast Only" },
          { header: "Car rentals" },
          { header: "Taxes" },
          { header: "Driver’s expenses" },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          { header: "Any expense of personal nature" },
          { header: "Alcohol & beverage" },
          { header: "Ticket for entry to tourist spot/ Museum" },
          { header: "Telephone & laundry" },
          { header: "All other expenses not mentioned in the package" },
          { header: "Expenses due to natural calamities" },
          { header: "Tips" },
          { header: "Parking Fee" },
          { header: "Fees for adventure activities Itinerary" },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0030_Chrapunji.webp",
            text: "PTN_PkG_0030_Chrapunji.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0030_kaziranga.webp",
            text: "PTN_PkG_0030_kaziranga.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0030_kaziranga1.webp",
            text: "PTN_PkG_0030_kaziranga1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0030_Shillong.webp",
            text: "PTN_PkG_0030_Shillong.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0030_Shillong1.webp",
            text: "PTN_PkG_0030_Shillong1.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 25750,
            text: "Per Person",
          },
          {
            packageDuration: "6N/7D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0031",
      },
      {
        title: "package_name",
        content: "VIETNAM PACKAGES",
      },
      // {
      // title: "places",
      // content: ["VIETNAM"],
      // },
      {
        title: "tour_type",
        content: ["Family Tour", "Honeymoon Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Visit 3 cities in 5 days: Hanoi, Ninh Binh, Halong Bay",
          "Explore famous attractions like Bai Dinh Pagoda complex, Trang An, Mua cave, Sung Sot Cave, and Halong Bay",
          "All accommodation provided on a double/twin sharing basis",
          "Meal plan includes breakfast, lunch, and dinner as per the itinerary",
          "Airport transfers and sightseeing tours included",
          "Entrance fees to sightseeing locations covered",
          "SIC transfers between Hanoi and Halong Bay provided",
          "Package excludes flights, visa fees, additional charges for early check-in and late check-out, personal expenses, travel insurance, tipping, optional tours or activities, and surcharges for peak season",

        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "4N/5D",
          Cities: ["Hanoi", "Ninh Binh", "Halong Bay"],
          Country: ["Vietnam"],
          CountryNames: {
            Vietnam: [
              {
                name: "Hanoi",
                duration: "2N",
              },
              {
                name: "Ninh Binh",
                duration: "1N",
              },
              {
                name: "Ha Long Bay",
                duration: "1N",
              }
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Hanoi airport transfer (No Guide)",
              description:
                "Xin Chau! (Hello!) Arrive at Hanoi (The capital of Vietnam) (Named as Nội Bài International Airport). After completing the immigration formalities, you will be greeted by our friendly driver and transferred to your hotel for check-in (Standard Check-in: 2 PM). Hanoi is known for its most famous market (Hang Da market), which is popular amongst the tourists. Spend the day at leisure, exploring the shops in the market. Take a stroll in the famous night market (Open on weekends). Do not forget to walk around the Ta Hien corner (Known as the first street in Hanoi), which attracts all the foreign tourists. Overnight stay in Hanoi.",
              meal: "Breakfast",
            },
            {
              dayname: "Day 2",
              header: "Ninh Binh (A Day excursion) (With Guide)",
              description:
                "After breakfast, our guide and driver will meet you in the hotel lobby and escort you for an exciting day at Ninh Binh. Ninh Binh province is at around 100 km from Hanoi. You will get an opportunity to see the verdant landscape all along the route. Arrive at the Bai Dinh Pagoda complex. Get on the Electric vehicles and visit the massive architecture site - 500 stone statues of Arhats; Bell tower with 27 tons and 36 tons bronze bell; Golden statue of Goddess of Mercy. Walk up summit mountain where 100 tonnes in weight –10 meter in heights bronze statue of Buddha Sakyamuni is located… Amazing views, sacred atmospheres and intriguing Buddhism story presented by our insider guide will not fail to enchant you. Embark on 2 hours Sampan boat in Trang An – A spectacular labyrinth of mirrored waters studded with towering, jungle-clad limestone outcrops. Gently rowed down the exquisite river through numerous caves: Hang Toi (320 meter long), Sang cave, (90 meter long) and Hang Nau Ruou (250 meter long). All passages bring new surprise of mystery hidden inside isolated valleys of tranquillity, that likely transformed you into another world and time. Arrive at Mua cave, start climbing almost 500 stone steps to reach at the top of Mua cave. Feel the fresh air and admire a panoramic view of beautiful Tam Coc area. Start your journey back to Hanoi. Overnight stay in Hanoi.",
              meal: "Breakfast",
            },
            {
              dayname: "Day 3",
              header: "Hanoi to Ha Long Bay (SIC)",
              description:
                "8.15 am: Our bus and guide pick you up from hotel / stay and depart for Ha Long. Stop over for a short rest on the way.\n12.30 pm: Arrive Tuan Chau Harbour and undergo a safety briefing. Check in the cabin.\n13.30 pm: Enjoy lunch with sampling regional fresh seafood while cruising on exquisite water and passing across various small islands\n\n15.00 pm-16.30 pm: Enjoy kayaking through the magnificent landscape with Limestone Mountains. Enjoy swimming or just relaxing on the sun-deck for fantastic views at the bay. Join our Cooking Class in the restaurant.\n19:30 pm: Under the magnificent night sky, enjoy set-menu dinner with a variety of Vietnamese and Asian foods. Laze yourself with our happy-hour drinks at the bar. Overnight on the Cruise.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 4",
              header: "Ha Long Bay to Hanoi (SIC)",
              description:
                "6:15 am: Morning exercises (Tai Chi) on the Sun-deck\n07:15 am: Breakfast with Coffee & amp; Tea\n8:00 am: Visit the Surprising Cave (Hang Sung Sot) discovered by the French in 1901. Opened for tourists since 1936, now it is a must-to-see and a symbol for geology value of Halong.\n9:30 am: Check out time\n11:15 am: Enjoying a traditional Vietnamese Brunch while cruising back to the pier\n12:00 pm: Drive back to Hanoi\n16:30 pm: Arrive in Hanoi, Overnight stay in Hanoi.",
              meal: "Breakfast, Brunch",
            },
            {
              dayname: "Day 5",
              header: "Hanoi Airport Transfer (No Guide)",
              description:
                "After breakfast, proceed to Hanoi airport to board the flight to your onward destination. Tour concludes with some wonderful memories.",
              meal: "Breakfast",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Hanoi", "Ninh Binh", "Halong Bay"],
      },
      {
        title: "Package Inclusion",
        content: [
          {
            header:
              "Accommodation on Double / Twin sharing basis as per the mentioned category of hotels",
          },
          {
            header:
              "Meal Plan as per the details mentioned in the itinerary (B: Breakfast, L: Lunch, D: Dinner)",
          },
          { header: "Airport Transfers as per the itinerary" },
          {
            header:
              "Sightseeing tours by Private AC vehicle as per the itinerary",
          },
          {
            header:
              "Entrance fees as per the sightseeing locations mentioned in the itinerary",
          },
          {
            header:
              "SIC transfers (Hanoi – Halong Bay – Hanoi) as per the itinerary",
          },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          { header: "All domestic and international flights" },
          {
            header:
              "Stamping Fee of USD 25 per person for Vietnam visa (If opted for Visa on arrival)",
          },
          {
            header:
              "Vietnam Visa approval letter from USD. 15 per person onwards (If opted for Visa on arrival)",
          },
          {
            header:
              "Additional charges for Early Check-in and late check-out and Single Supplement",
          },
          {
            header:
              "Personal expenses such as other meals, drinks, telephone, laundry etc",
          },
          { header: "Travel Insurance" },
          {
            header:
              "Compulsory Tipping for guide and driver and for cruise’s crew (USD. 3 per person per day)",
          },
          {
            header:
              "Any optional additional tours or activities during free time",
          },
          { header: "Surcharge for peak season on Vietnamese holidays" },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0033_HalongBay.webp",
            text: "PTN_PkG_0033_HalongBay.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_HalongBay1.webp",
            text: "PTN_PkG_0033_HalongBay1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_HalongBay2.webp",
            text: "PTN_PkG_0033_HalongBay2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_NinhBinh.webp",
            text: "PTN_PkG_0033_NinhBinh.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_NinhBinh1.webp",
            text: "PTN_PkG_0033_NinhBinh1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_NinhBinh2.webp",
            text: "PTN_PkG_0033_NinhBinh2.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 32988,
            text: "Per Person",
          },
          {
            packageDuration: "4N/5D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0032",
      },
      {
        title: "package_name",
        content: "VIETNAM PACKAGES",
      },
      // {
      // title: "places",
      // content: ["VIETNAM"],
      // },
      {
        title: "tour_type",
        content: ["Family Tour", "Honeymoon Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Explore 5 cities in 7 Days: Hanoi, Ninh Binh, Halong Bay, Hoi An, Bana Hills",
          "Visit famous attractions like Bai Dinh Pagoda complex, Trang An, Mua cave, Sung Sot Cave, Hoi An, and Bana Hills",
          "Accommodation provided on a double/twin sharing basis",
          "Meal plan includes breakfast, lunch, and dinner as per the itinerary",
          "Airport transfers and sightseeing tours included",
          "Entrance fees to sightseeing locations covered",
          "SIC transfers between Hanoi and Halong Bay provided",
          "Package excludes flights, visa fees, additional charges for early check-in and late check-out, personal expenses, travel insurance, tipping, optional tours or activities, and surcharges for peak season",

        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "6N/7D",
          Cities: ["Hanoi", "Ninh Binh", "Halong Bay", "Hoi An", "Bana Hills"],
          Country: ["Vietnam"],
          CountryNames: {
            Vietnam: [
              {
                name: "Hanoi",
                duration: "1N",
              },
              {
                name: "Ninh Binh",
                duration: "1N",
              },
              {
                name: "Ha Long Bay",
                duration: "1N",
              },
              {
                name: "Hoi An",
                duration: "2N",
              },
              {
                name: "Bana Hills",
                duration: "1N",
              },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "Hanoi airport transfer ",
              description:
                "Xin Chau! (Hello!) Arrive at Hanoi. Overnight stay in Hanoi.",
              meal: "Breakfast",
            },
            {
              dayname: "Day 02",
              header: "Ninh Binh (A Day excursion)",
              description:
                "After breakfast, our guide and driver will meet you (A Day excursion). Overnight stay in Hanoi. (Breakfast)",
              meal: "Breakfast",
            },
            {
              dayname: "Day 3",
              header: "Hanoi to Ha Long Bay (SIC)",
              description:
                "8.15 am: Our bus and guide pick you up from hotel / stay Hanoi to Ha Long Bay (SIC). Overnight on the Cruise. (Breakfast, Lunch, Dinner)",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 4",
              header: "Ha Long Bay to Hanoi (SIC)",
              description:
                "6:15 am: Morning exercises (Tai Chi) on the Sun-deck. Overnight stay in Hanoi. (Breakfast, Brunch)",
              meal: "Breakfast, Brunch",
            },
            {
              dayname: "Day 5",
              header: "Danang (Day visit to Hoi An)",
              description:
                "After breakfast, you will be greeted by our guide and escorted (Day visit to Hoi An. Overnight stay in Danang. (Breakfast)",
              meal: "Breakfast",
            },
            {
              dayname: "Day 6",
              header: "Danang (Bana Hills day excursion)",
              description:
                "After breakfast, you will be greeted by our guide and escorted (Bana Hills day excursion). Overnight stay in Da Nang. (Breakfast) ",
              meal: "Breakfast",
            },
            {
              dayname: "Day 7",
              header: "Danang Airport Transfer",
              description:
                "After breakfast, proceed to Danang airport to board the flight to your onward destination (No Guide). Tour concludes with some wonderful memories (Breakfast)",
              meal: "Breakfast",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Hanoi", "Ninh Binh", "Halong Bay", "Hoi An", "Bana Hills"],
      },
      {
        title: "Package Inclusion",
        content: [
          {
            header:
              "Accommodation on Double / Twin sharing basis as per the mentioned category of hotels",
          },
          {
            header:
              "Meal Plan as per the details mentioned in the itinerary (B: Breakfast, L: Lunch, D: Dinner)",
          },
          {
            header: "Airport Transfers as per the itinerary",
          },
          {
            header:
              "Sightseeing tours by Private AC vehicle as per the itinerary",
          },
          {
            header:
              "Entrance fees as per the sightseeing locations mentioned in the itinerary",
          },
          {
            header:
              "SIC transfers (Hanoi – Halong Bay – Hanoi) as per the itinerary",
          },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header: "All domestic and international flights",
          },
          {
            header:
              "Stamping Fee of USD 25 per person for Vietnam visa (If opted for Visa on arrival)",
          },
          {
            header:
              "Vietnam Visa approval letter from USD. 15 per person onwards (If opted for Visa on arrival)",
          },
          {
            header:
              "Additional charges for Early Check-in and late check-out and Single Supplement",
          },
          {
            header:
              "Personal expenses such as other meals, drinks, telephone, laundry etc",
          },
          {
            header: "Travel Insurance",
          },
          {
            header:
              "Compulsory Tipping for guide and driver and for cruise’s crew (USD. 3 per person per day)",
          },
          {
            header:
              "Any optional additional tours or activities during free time",
          },
          {
            header: "Surcharge for peak season on Vietnamese holiDays",
          },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0033_banahills.webp",
            text: "PTN_PkG_0033_banahills.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_HalongBay.webp",
            text: "PTN_PkG_0033_HalongBay.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_HalongBay1.webp",
            text: "PTN_PkG_0033_HalongBay1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_HalongBay2.webp",
            text: "PTN_PkG_0033_HalongBay2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_NinhBinh.webp",
            text: "PTN_PkG_0033_NinhBinh.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_NinhBinh1.webp",
            text: "PTN_PkG_0033_NinhBinh1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_NinhBinh2.webp",
            text: "PTN_PkG_0033_NinhBinh2.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 47603,
            text: "Per Person",
          },
          {
            packageDuration: "6N/7D",
          },
        ],
      },
    ],

    [
      {
        title: "packageId",
        content: "PTN_PkG_0033",
      },
      {
        title: "package_name",
        content: "VIETNAM PACKAGES",
      },
      // {
      // title: "places",
      // content: ["VIETNAM"],
      // },
      {
        title: "tour_type",
        content: ["Family Tour", "Honeymoon Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Arrival at Hanoi and hotel transfer",
          "Visit Bai Dinh Pagoda and Trang An",
          "Cruise on Ha Long Bay with kayaking",
          "Explore caves and Tai Chi on cruise",
          "Discover Hoi An's ancient town and culture",
          "Experience Bana Hills cable car and views",
          "Explore Cu Chi Tunnels and rural scenery",
          "Scenic journey to Mekong Delta and boat ride",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "9N/10D",
          Cities: [
            "Ninh Binh",
            "Halong Bay",
            "Hoi An",
            "Bana Hills",
            "Saigon",
            "Cu Chi Tunnels",
            "Mekong Delta",
          ],
          Country: ["Vietnam"],
          CountryNames: {
            Vietnam: [
              {
                name: "Hanoi",
                duration: "2N",
              },
              {
                name: "Ha Long Bay",
                duration: "1N",
              },
              {
                name: "Danang",
                duration: "2N",
              },
              {
                name: "Da Nang",
                duration: "1N",
              },
              {
                name: "Ho Chi Minh",
                duration: "3N",
              },
            ],

          },
          Days: [
            {
              dayname: "Day 1",
              header: "Hanoi airport transfer (No Guide)",
              description:
                "Arrive at Hanoi (The capital of Vietnam) (Named as Nội Bài International Airport). After completing the immigration formalities, you will be greeted by our friendly driver and transferred to your hotel for check-in (Standard Check-in: 2 PM). Hanoi is known for its most famous market (Hang Da market), which is popular amongst the tourists. Spend the day at leisure, exploring the shops in the market. Take a stroll in the famous night market (Open on weekends). Do not forget to walk around the Ta Hien corner (Known as the first street in Hanoi), which attracts all the foreign tourists. Overnight stay in Hanoi.",
              meal: "",
            },
            {
              dayname: "Day 2",
              header: "Ninh Binh (A Day excursion) (With Guide)",
              description:
                "After breakfast, our guide and driver will meet you in the hotel lobby and escort you for an exciting day at Ninh Binh. Ninh Binh province is at around 100 km from Hanoi. You will get an opportunity to see the verdant landscape all along the route. Arrive at the Bai Dinh Pagoda complex. Get on the Electric vehicles and visit the massive architecture site - 500 stone statues of Arhats; Bell tower with 27 tons and 36 tons bronze bell; Golden statue of Goddess of Mercy. Walk up summit mountain where 100 tonnes in weight – 10 meter in heights bronze statue of Buddha Sakyamuni is located… Amazing views, sacred atmospheres and intriguing Buddhism story presented by our insider guide will not fail to enchant you. Embark on 2 hours Sampan boat in Trang An – A spectacular labyrinth of mirrored waters studded with towering, jungle-clad limestone outcrops. Gently rowed down the exquisite river through numerous caves: Hang Toi (320 meter long), Sang cave, (90 meter long) and Hang Nau Ruou (250 meter long). All passages bring new surprise of mystery hidden inside isolated valleys of tranquillity, that likely transformed you into another world and time. Arrive at Mua cave, start climbing almost 500 stone steps to reach at the top of Mua cave. Feel the fresh air and admire a panoramic view of beautiful Tam Coc area. Start your journey back to Hanoi. Overnight stay in Hanoi.",
              meal: "Breakfast",
            },
            {
              dayname: "Day 3",
              header: "Hanoi to Ha Long Bay (SIC)",
              description:
                "8.15 am: Our bus and guide pick you up from hotel / stay and depart for Ha Long. Stop over for a short rest on the way. 12.30 pm: Arrive Tuan Chau Harbour and undergo a safety briefing. Check in the cabin. 13.30 pm: Enjoy lunch with sampling regional fresh seafood while cruising on exquisite water and passing across various small islands. 15.00-16.30: Enjoy kayaking through the magnificent landscape with Limestone Mountains. Enjoy swimming or just relaxing on the sun-deck for fantastic views at the bay. Join our Cooking Class in the restaurant. 19:30: Under the magnificent night sky, enjoy set-menu dinner with a variety of Vietnamese and Asian foods. Laze yourself with our happy-hour drinks at the bar. Overnight on the Cruise.",
              meal: "Breakfast, Lunch, Dinner",
            },
            {
              dayname: "Day 4",
              header:
                "Ha Long Bay to Hanoi + Hanoi airport transfer + Danang hotel drop (SIC)",
              description:
                "6:15 am: Morning exercises (Tai Chi) on the Sun-deck. 07:15 am: Breakfast with Coffee/Tea. 8:00 am: Visit the Surprising Cave (Hang Sung Sot) discovered by the French in 1901. Opened for tourists. Since 1936, now it is a must-to-see and a symbol for geology value of Halong. 9:30 am: Check out time. 11:15: Enjoying a traditional Vietnamese Brunch while cruising back to the pier. 12:00: Drive back to Hanoi. 16:30: Arrive in Hanoi. Proceed to Hanoi airport to board the flight to Danang. Arrive at the Danang international airport (on your own). After completing the immigration formalities, you will be greeted by our friendly driver and transferred to your hotel for check-in (Standard Check-in: 2 PM). Overnight stay in Danang.",
              meal: "Breakfast, Brunch",
            },
            {
              dayname: "Day 5",
              header: "Danang (Day visit to Hoi An) (With Guide)",
              description:
                "After breakfast, you will be greeted by our guide and escorted to the vehicle for travel to Hoi An. Hoi An is a well-preserved ancient town, cut through with canals. Visit the silk museum, Japanese Covered Bridge (300 years old footbridge made of wood), Chinese Assembly Halls, Tan Ky Ancient House and the vibrant market. The town offers some of the most crowded attractions in Vietnam with its old streets lined with old houses and halls, pagodas, temples, ancient wells and tombs. Walk through the narrow shopping lanes of the town and enjoy shopping of lamps and other artifacts. You can also visit some of the famous tailors to get some local style clothes stitched and delivered to your hotel in Danang. Spend some time on the Thu Bon River and try out some local dishes to activate your taste buds. Visit the Coconut village and enjoy the basket boat ride. Start your journey back to Danang. Overnight stay in Danang.",
              meal: "Breakfast",
            },
            {
              dayname: "Day 6",
              header: "Danang (Bana Hills day excursion) (with Guide)",
              description:
                "After breakfast, you will be greeted by our guide and escorted to Bana Hills. Arrive at the cable car station at the base of the hill. Sitting in the cabin, in midair, looking upon the endless tree lone of the forest. Experiencing the pristine ecological system of the Ba Na Mountain will leave you a sense of unforeseeable discovery. Visit the Le Jardin, old wineries - remains of French Villas and Linh Ung pagoda with 27m high Buddha statue. Leave Le Jardin for Gare Debay, the second cable car station, to go to the top of Ba Na. Visit and play game at the Fantasy Park- Ba Na Hills Fantasy Park- the third largest indoor amusement park in the world (only some free games depending on Ba Na policy). Conquer the Peak of Chua Mountain at the height of 1,487m. Enjoy the panorama view of the Da Nang from the top of Ba Na Hills. And then visit Golden Bridge (known as the hand bridge) and the Maize Garden. Return to cable car station and transfer to Da Nang. Overnight stay in Da Nang.",
              meal: "Breakfast",
            },
            {
              dayname: "Day 7",
              header:
                "Danang Airport Transfer + Saigon hotel drop + half day local sightseeing (With Guide for SS)",
              description:
                "After breakfast, proceed to Danang airport to board the flight to Saigon. Arrive at the Tan Sot Nhat international airport (on your own). You will be greeted by our friendly driver and transferred to your hotel for check-in (Standard Check-in: 2 PM). District 1 in Ho Chi Minh city (Also known as Saigon) is the most famous place to stay and shop. Ben Thanh market in District 1 is city's biggest, most authentic market. In the afternoon, our guide with meet you and take you around for a local sightseeing tour. Visit the Reunification Palace (known as Independence Palace), Go back in time to visualize the fall of Saigon and the fleeing of the American troops from this palace. Visit the Notre Dame Cathedral, Central Post Office and War Remnants Museum. Overnight stay in Ho Chi Minh City.",
              meal: "Breakfast",
            },
            {
              dayname: "Day 8",
              header: "Cu Chi Tunnels visit (With Guide)",
              description:
                "After breakfast, our guide will meet you in the hotel lobby and accompany you to Cu Chi. Cu Chi is now considered a heroic district for its role in the anti-American war in Vietnam. It is legendary for original tunnels system of over 220km and is a popular spot for both Vietnamese and foreign visitors. The peacefully rural rice paddy scenery contains ducks and water buffalos swimming in the rivers alongside the road. Visitors will find it hard to imagine the destructions, damage and defoliation all over the area, caused by bombing and mines when Cu Chi was” A Free Target Zone “. The remnants visitors will see show Cu Chi still has her evidence to prove the fierce battleground that took place there and visitors will see the remnants. Try your hands on a real AK-47 rifle (charges to be borne by the guest). Return in your hotel in Ho Chi Minh City in the afternoon.",
              meal: "Breakfast",
            },
            {
              dayname: "Day 9",
              header: "Mekong Delta Day visit (With Guide)",
              description:
                "After breakfast, our guide will take you to Mekong Delta. Enjoy the scenic journey along the National Highway bordered by green rice fields. On arrival at My Tho, take a leisurely boat ride along the river, view the stilt houses, fruit plantations and fishing villages along the river bank. Proceed to Tortoise islet, Orchard Garden. Then a boat ride to An Khanh – a less touristy attraction in Ben Tre, enjoy cruising on a hand-rowed sampan under the shadow of the water coconut trees along natural canals, then enjoy seasonal fruit & honey tea to the sound of “Southern Vietnamese folk music”, performed by locals. Taste the delicious coconut candy & observe the pastoral life as you roam through the fruit plantation and village. Return to the pick-up point and get transferred to Ho Chi Minh. Overnight stay in Ho Chi Minh City.",
              meal: "Breakfast",
            },
            {
              dayname: "Day 10",
              header: "Ho Chi Minh Airport Transfer (No Guide)",
              description:
                "After breakfast, proceed to Ho Chi Minh airport to board the flight to your onward destination. Tour concludes with some wonderful memories.",
              meal: "Breakfast",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: [
          "Ninh Binh",
          "Halong Bay",
          "Hoi An",
          "Bana Hills",
          "Saigon",
          "Cu Chi Tunnels",
          "Mekong Delta",
        ],
      },
      {
        title: "Package Inclusion",
        content: [
          {
            header:
              "Accommodation on Double / Twin sharing basis as per the mentioned category of hotels",
          },
          {
            header:
              "Meal Plan as per the details mentioned in the itinerary (B: Breakfast, L: Lunch, D: Dinner)",
          },
          {
            header: "Airport Transfers as per the itinerary",
          },
          {
            header:
              "Sightseeing tours by Private AC vehicle as per the itinerary",
          },
          {
            header:
              "Entrance fees as per the sightseeing locations mentioned in the itinerary",
          },
          {
            header:
              "SIC transfers (Hanoi – Halong Bay – Hanoi) as per the itinerary",
          },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header: "All domestic and international flights",
          },
          {
            header:
              "Stamping Fee of USD 25 per person for Vietnam visa (If opted for Visa on arrival)",
          },
          {
            header:
              "Vietnam Visa approval letter from USD. 15 per person onwards (If opted for Visa on arrival)",
          },
          {
            header:
              "Additional charges for Early Check-in and late check-out and Single Supplement",
          },
          {
            header:
              "Personal expenses such as other meals, drinks, telephone, laundry etc",
          },
          {
            header: "Travel Insurance",
          },
          {
            header:
              "Compulsory Tipping for guide and driver and for cruise’s crew (USD. 3 per person per day)",
          },
          {
            header:
              "Any optional additional tours or activities during free time",
          },
          {
            header: "Surcharge for peak season on Vietnamese holidays",
          },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0033_banahills.webp",
            text: "PTN_PkG_0033_banahills.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_HalongBay.webp",
            text: "PTN_PkG_0033_HalongBay.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_HalongBay1.webp",
            text: "PTN_PkG_0033_HalongBay1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_HalongBay2.webp",
            text: "PTN_PkG_0033_HalongBay2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_NinhBinh.webp",
            text: "PTN_PkG_0033_NinhBinh.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_NinhBinh1.webp",
            text: "PTN_PkG_0033_NinhBinh1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0033_NinhBinh2.webp",
            text: "PTN_PkG_0033_NinhBinh2.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 66394,
            text: "Per Person",
          },
          {
            packageDuration: "9N/10D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0034",
      },
      {
        title: "package_name",
        content: "Jyotirlingas Tour of Madhya Pradesh",
      },
      // {
      // title: "places",
      // content: ["Indore", "Ujjain", "Maheshwar"],
      // },
      {
        title: "tour_type",
        content: ["Family Tour", "Honeymoon Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Harsiddhi Temple",
          "Bada Ganesh Temple",
          "Kaal Bhairav Temple",
          "Ram Ghat",
          "Mangalnath Temple",
          "Sandeepani Ashram",
          "Omkareshwar Temple",
          "Mamleshwar Mahadev Temple",
          "Maheshwar Ahilya Fort",
          "Ahilyamata’s Cenotaph",
          "Baneshwar Mahadev Temple",
          "Rajarajeshwari Temple",
          "Ek Mukhi Datta Temple",
          "Chappan Gali",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "3N/4D",
          Cities: ["Indore", "Ujjain", "Maheshwar"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Ujjain", duration: "1N" },
              { name: "Maheshwar", duration: "1N" },
              { name: "Indore", duration: "1N" },
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "ARRIVAL INDORE - UJJAIN",
              description:
                "Morning arrival at Indore. Proceed to Ujjain. Check in the hotel. (Early check in subject to room availability). Later visit Temples like Harsiddhi, Bada Ganesh, Kaal Bhairav, Ram ghat, Mangalnath, and Sandeepani ashram. Overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "UJJAIN - OMKARESHWAR - MAHESHWAR",
              description:
                "Morning breakfast, proceed for Omkareshwar, Visit Omkareshwar Temple (one of the twelve Jyotirlingas temples). Visit Mamleshwar Mahadev Temple. Later transfer to Maheshwar. Check in the hotel. Overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header: "MAHESHWAR - INDORE",
              description:
                "After Morning breakfast, check out from the hotel & visit Maheshwar Ahilya fort, Ahilyamata’s cenotaph, Baneshwar Mahadev Temple, Rajarajeshwari Temple, Ek Mukhi Datta Temple. Later transferred to Indore. Check-in at the hotel. Evening visit Chappan gali (food Hub Street). Return back to the hotel. Overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "INDORE DEPARTURE",
              description:
                "After Morning breakfast, check-out hotel & visit local sightseeing Khajrana Ganesh Mandir & Lalbagh. Later drop to Indore railway station / Airport. End of the service!!!",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Ujjain", "Omkareshwar", "Maheshwar", "Indore"],
      },
      {
        title: "Package Inclusion",
        content: [
          {
            header:
              "Accommodation on twin sharing basis as per above given hotel or similar standard",
          },
          { header: "Meals plan- CP plan" },
          { header: "All transfers & sightseeing as per the itinerary only" },
          { header: "GST 5% included" },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          { header: "Anything which is not mentioned in inclusions" },
          { header: "Any guide services during the tour" },
          { header: "Any entrance fee to monuments" },
          { header: "Any tips, laundry, personal nature expenses" },
          {
            header:
              "Any flight/ train ticket. (Need to book by clients themselves)",
          },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0034_Indore.webp",
            text: "PTN_PkG_0034_Indore.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0034_Indore1.webp",
            text: "PTN_PkG_0034_Indore1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0034_Maheshwar.webp",
            text: "PTN_PkG_0034_Maheshwar.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0034_Maheshwar1.webp",
            text: "PTN_PkG_0034_Maheshwar1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0034_Ujjain.webp",
            text: "PTN_PkG_0034_Ujjain.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0034_Ujjain1.webp",
            text: "PTN_PkG_0034_Ujjain1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0034_Ujjain3.webp",
            text: "PTN_PkG_0034_Ujjain3.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 9700,
            text: "Per Person",
          },
          {
            packageDuration: "3N/4D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0035",
      },
      {
        title: "package_name",
        content: "EXOTIC PACHMARHI TOUR",
      },
      // {
      // title: "places",
      // content: ["Pipariya", "Pachmarhi", "Pipariya"],
      // },
      {
        title: "tour_type",
        content: ["Family Tour", "Honeymoon Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Jatashankar",
          "Handikho",
          "Priyadarshini",
          "Gupt Mahadev",
          "Bada Mahadev",
          "Green valley",
          "Rajendragiri",
          "Lake boating",
          "Bison lodge",
          "Pandav Gufa",
          "Bee-fall",
          "Parasailing",
          "Reechgarh",
          "Eco point",
          "Bhavyadrashya",
          "Dhoopgarh sunset Point",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "3N/4D",
          Cities: ["Pipariya", "Pachmarhi", "Pipariya"],
          Country: ["India"],
          CountryNames: {
            India: [
              { name: "Pipariya", duration: "3N" },

    { name: "Reechgarh", duration: "" },
    { name: "Eco point", duration: "" },
    { name: "Bhavyadrashya", duration: "" },
    { name: "Dhoopgarh sunset Point", duration: "" }
            ],
          },
          Days: [
            {
              dayname: "Day 01",
              header: "PIPARIA- PACHMARHI",
              description:
                "Arrival at Pipariya railway station and transfer to hotel in Pachmarhi. Check-in at Hotel. Overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 02",
              header: "SIGHTSEEING OF PACHMARHI 1",
              description:
                "Sightseeing of Pachmarhi visiting non-Forest entry points by CAR (non-AC): Jatashankar, Handikho, Priyadarshini, Gupt Mahadev, Bada Mahadev, Green valley, Rajendragiri, Lake boating. Overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 03",
              header: "SIGHTSEEING OF PACHMARHI 2",
              description:
                "Pachmarhi sightseeing (Forest Entry points): Bison lodge, Pandav Gufa, Bee-fall, Parasailing, Reechgarh, Eco point, Bhavyadrashya, Dhoopgarh sunset Point. Overnight stay.",
              meal: "",
            },
            {
              dayname: "Day 04",
              header: "PACHMARHI – PIPRAIYA DEPARTURE",
              description: "Transfer to Pipariya railway station.",
              meal: "",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: ["Pipariya", "Pachmarhi", "Pipariya"],
      },
      {
        title: "Package Inclusion",
        content: [
          {
            header:
              "Accommodation on twin sharing basis as per above given hotel or similar standard",
          },
          { header: "Meals plan- CP plan" },
          { header: "All transfers & sightseeing as per the itinerary only" },
          { header: "GST 5% included" },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          { header: "Anything which is not mentioned in inclusions" },
          { header: "Any guide services during the tour" },
          { header: "Any entrance fee to monuments" },
          { header: "Any tips, laundry, personal nature expenses" },
          {
            header:
              "Any flight/ train ticket. (Need to book by clients themselves)",
          },
        ],
      },

      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PTN_PkG_0035_Pachmarhi_1.webp",
            text: "PTN_PkG_0035_Pachmarhi_1.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0035_Pachmarhi_2.webp",
            text: "PTN_PkG_0035_Pachmarhi_2.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0035_Pachmarhi_3.webp",
            text: "PTN_PkG_0035_Pachmarhi_3.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0035_Pachmarhi_4.webp",
            text: "PTN_PkG_0035_Pachmarhi_4.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0035_Pachmarhi_5.webp",
            text: "PTN_PkG_0035_Pachmarhi_5.webp",
          },
          {
            carousel_ima: "/PTN_PkG_0035_Pachmarhi_6.webp",
            text: "PTN_PkG_0035_Pachmarhi_6.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 8799,
            text: "Per Person",
          },
          {
            packageDuration: "3N/4D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0036",
      },
      {
        title: "package_name",
        content: "Golden Triangle Tour - 6 Days 5 Nights - 2024",
      },
      // {
      // title: "places",
      // content: ["India"],
      // },
      {
        title: "tour_type",
        content: ["Domestic", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Explore the Golden Triangle of India: Delhi, Agra, and Jaipur",
          "Visit iconic landmarks such as India Gate, Taj Mahal, and Amber Palace",
          "Experience the rich cultural heritage and historical significance of each city",
          "Enjoy family-friendly activities and sightseeing tours",
          "Stay in comfortable accommodations throughout the tour",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "5N/6D",
          Cities: ["Delhi", "Agra", "Mathura", "Fatehpur Sikri", "Jaipur"],
          Country: ["India"],
          CountryNames: {
            India: [
              {
                name: "Delhi",
                duration: "2N",
              },

              {
                name: "Agra",
                duration: "1N",
              },

              {
                name: "Mathura",
                duration: "",
              },

              {
                name: "Fatehpur Sikiri",
                duration: "",
              },
              {
                name: "Jaipur",
                duration: "2N",
              },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Arrive in Delhi",
              description:
                "Arrive at Delhi Airport. Meet and greet at the airport and transfer to the hotel. Check-in at the hotel, freshen up, and have lunch. In the afternoon, embark on a half-day tour of Delhi, visiting India Gate, Rashtrapati Bhavan, National War Memorial, Raj Ghat, Lodhi Garden, Humayun's Tomb, and Birla Mandir. Return to the hotel for overnight stay. (Lunch, Dinner)",
            },
            {
              dayname: "Day 2",
              header: "Full Day Delhi Tour",
              description:
                "Start the day with a visit to Qutub Minar, Lotus Temple, Jantar Mantar, and Akshardham Temple. Explore Red Fort and enjoy shopping in Old Delhi. Return to the hotel in the evening. (Breakfast, Lunch, Dinner)",
            },
            {
              dayname: "Day 3",
              header: "Delhi to Agra via Mathura",
              description:
                "Check out from the hotel after breakfast and head to Mathura. Visit Sri Krishna Janmabhoomi and Vrindavan. Enjoy lunch en route. Continue the journey to Agra and check in at the hotel. Later, visit the Taj Mahal. Overnight stay in Agra. (Breakfast, Lunch, Dinner)",
            },
            {
              dayname: "Day 4",
              header: "Agra to Fatehpur Sikri to Jaipur",
              description:
                "Check out from the hotel and proceed to Fatehpur Sikri. Explore Panch Mahal, Jodha Bai Palace, and Buland Darwaza. Continue the journey to Jaipur. Check in at the hotel upon arrival. In the evening, visit Hawa Mahal and Jal Mahal. Overnight stay in Jaipur. (Breakfast, Lunch, Dinner)",
            },
            {
              dayname: "Day 5",
              header: "Jaipur Sightseeing",
              description:
                "Visit Amber Palace, Nahargarh Fort, Jaigarh Fort, and The City Palace. Explore Jantar Mantar before returning to the hotel. (Breakfast, Lunch, Dinner)",
            },
            {
              dayname: "Day 6",
              header: "Jaipur Local Sightseeing and Departure",
              description:
                "Check out from the hotel after breakfast. Visit Birla Mandir, Rambagh Palace, Albert Hall Museum, and Sisodia Rani Ka Bagh. Enjoy lunch before being dropped off at Jaipur International Airport for departure. (Breakfast, Lunch)",
            },
          ],
        },
      },

      {
        title: "destinaions",
        content: ["Delhi", "Mathura", "Agra", "Fatehpur Sikri", "Jaipur"],
      },
      {
        title: "Package Inclusion",
        content: [
          { header: "Stay at specified hotels in Delhi, Agra, and Jaipur" },
          { header: "Breakfast on all Days" },
          {
            header:
              "Pickup from Delhi Airport and drop to Jaipur Airport in Innova",
          },
          {
            header:
              "Sightseeing and visit to different cities as per the itinerary in AC Innova",
          },
          { header: "Parking, toll, fuel cost, and Driver Charges" },
          { header: "Taxes" },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header:
              "Airfare/train fare, insurance, and any other travel expenses",
          },
          { header: "Lunch and dinner" },
          {
            header: "Tickets for parks, monuments, museums, rides, and safari",
          },
          {
            header:
              "Personal expenses such as laundry, tips, drinks, and other costs not mentioned",
          },
          {
            header:
              "Cost of additional sightseeing or vehicle usage for personal purposes",
          },
          { header: "GST, TCS, and other applicable taxes" },
          { header: "Anything not specified in the itinerary" },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PackageId_0036_Image14.webp",
            text: "PackageId_0036_Image14.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image13.webp",
            text: "PackageId_0036_Image13.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image12.webp",
            text: "PackageId_0036_Image12.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image11.webp",
            text: "PackageId_0036_Image11.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image10.webp",
            text: "PackageId_0036_Image10.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image09.webp",
            text: "PackageId_0036_Image09.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image08.webp",
            text: "PackageId_0036_Image08.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image07.webp",
            text: "PackageId_0036_Image07.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image06.webp",
            text: "PackageId_0036_Image06.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image05.webp",
            text: "PackageId_0036_Image05.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image04.webp",
            text: "PackageId_0036_Image04.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image03.webp",
            text: "PackageId_0036_Image03.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image02.webp",
            text: "PackageId_0036_Image02.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image01.webp",
            text: "PackageId_0036_Image01.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 46600,
            text: "Per Couple",
          },
          {
            packageDuration: "5N/6D",
          },
        ],
      },
    ],
    [
      {
        title: "packageId",
        content: "PTN_PkG_0037",
      },
      {
        title: "package_name",
        content: "Golden Triangle Tour - India, December 6 Nights 7 Days",
      },
      // {
      // title: "places",
      // content: ["Delhi", "Agra", "Fatehpur Sikri", "Ranthambore", "Jaipur"],
      // },
      {
        title: "tour_type",
        content: ["Domestic", "Family Tour"],
      },
      {
        title: "Highlights",
        content: [
          "Explore the iconic Golden Triangle of India: Delhi, Agra, and Jaipur",
          "Visit historical landmarks like India Gate, Taj Mahal, and Amber Palace",
          "Experience wildlife at Ranthambore National Park",
          "Enjoy a tiger safari in Ranthambore",
          "Guided tours of key attractions in each city",
        ],
      },
      {
        title: "TourIternary",
        content: {
          Duration: "6N/7D",
          Cities: ["Delhi", "Agra", "Fatehpur Sikri", "Ranthambore", "Jaipur"],
          Country: ["India"],
          CountryNames: {
            India: [
              {
                name: "Delhi",
                duration: "2N",
              },

              {
                name: "Agra",
                duration: "1N",
              },

              {
                name: "Mathura",
                duration: "",
              },

              {
                name: "Fatehpur Sikiri",
                duration: "",
              },
              {
                name: "Ranthambhor",
                duration: "1N",
              },
              {
                name: "Jaipur",
                duration: "2N",
              },
            ],
          },
          Days: [
            {
              dayname: "Day 1",
              header: "Arrival in Delhi, Half Day City Tour",
              description:
                "Arrive at Delhi Airport, transfer to Hotel Southern Inn. After check-in and lunch, embark on a half-day city tour visiting India Gate, Rashtrapati Bhavan, and other landmarks. Return to the hotel by evening.",
            },
            {
              dayname: "Day 2",
              header: "Mathura, Vrindavan, Taj Mahal Visit",
              description:
                "Check out from Hotel Southern Inn after breakfast. Visit Mathura, Vrindavan, and Taj Mahal. Check-in at The Taj Vilas Hotel Agra in the evening.",
            },
            {
              dayname: "Day 3",
              header: "Fatehpur Sikri, Ranthambore",
              description:
                "Check out from The Taj Vilas Hotel Agra. Visit Fatehpur Sikri. Head towards Ranthambore. Check-in at Ranthambore Tiger Resort in the evening.",
            },
            {
              dayname: "Day 4",
              header: "Ranthambore Tiger Safari, Jaipur",
              description:
                "Enjoy a tiger safari in the morning. Check out from Ranthambore Tiger Resort and proceed to Jaipur. Check-in at Hotel Vesta Maurya. Visit Hawa Mahal and in the evening.",
            },
            {
              dayname: "Day 5",
              header: "Amber Palace, Return to Delhi",
              description:
                "Check out from Hotel Vesta Maurya. Visit Amber Palace. Return to Delhi and check in at Hotel Southern Inn.",
            },
            {
              dayname: "Day 6",
              header: "Delhi Sightseeing",
              description:
                "Explore Delhi's attractions including Qutub Minar, Lotus Temple, and Red Fort. Return to the hotel in the evening.",
            },
            {
              dayname: "Day 7",
              header: "Departure",
              description:
                "Check out from the hotel and proceed to the airport for departure.",
            },
          ],
        },
      },
      {
        title: "destinaions",
        content: [
          "Delhi",
          "Mathura",
          "Vrindavan",
          "Taj Mahal Visit",
          "Fatehpur Sikri",
          "Ranthambore",
          "Ranthambore Tiger Safari",
          "Jaipur",
          "Amber Palace",
        ],
      },

      {
        title: "Package Inclusion",
        content: [
          { header: "Stay at specified hotels with breakfast" },
          { header: "Lunch and Dinner at Ranthambore Tent Stay" },
          { header: "Pick up and Drop to Delhi Airport" },
          { header: "Sightseeing in AC Sedan Car (Swift Dzire/Etios)" },
          { header: "Parking, Toll, Fuel Cost, and Driver Charges" },
          { header: "Taxes" },
        ],
      },
      {
        title: "Package Exclusion",
        content: [
          {
            header: "Airfare/Train Fare, Insurance, and other travel expenses",
          },
          { header: "Lunch and Dinner (except at Ranthambore)" },
          {
            header:
              "Entrance fees to parks, monuments, museums, rides, and safari tickets",
          },
          { header: "Personal expenses like laundry, tips, drinks, etc." },
          {
            header:
              "Cost of additional sightseeing or vehicle use for personal purposes",
          },
        ],
      },
      {
        title: "imagesMainTopcarousel",
        content: [
          {
            carousel_ima: "/PackageId_0036_Image14.webp",
            text: "PackageId_0036_Image14.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image13.webp",
            text: "PackageId_0036_Image13.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image12.webp",
            text: "PackageId_0036_Image12.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image11.webp",
            text: "PackageId_0036_Image11.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image10.webp",
            text: "PackageId_0036_Image10.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image09.webp",
            text: "PackageId_0036_Image09.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image08.webp",
            text: "PackageId_0036_Image08.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image07.webp",
            text: "PackageId_0036_Image07.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image06.webp",
            text: "PackageId_0036_Image06.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image05.webp",
            text: "PackageId_0036_Image05.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image04.webp",
            text: "PackageId_0036_Image04.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image03.webp",
            text: "PackageId_0036_Image03.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image02.webp",
            text: "PackageId_0036_Image02.webp",
          },
          {
            carousel_ima: "/PackageId_0036_Image01.webp",
            text: "PackageId_0036_Image01.webp",
          },
        ],
      },
      {
        title: "briefDescription",
        content: [
          {
            Reviews: "",
          },
          {
            packagePrice: 18700,
            text: "Per Person",
          },
          {
            packageDuration: "6N/7D",
          },
        ],
      },
    ],
  ];

  // const sections = [
  //   {
  //     title: "Highlights",
  //     content: [
  //       "Travel by Eurostar from London to Paris",
  //       "Be at Top of Eiffel tower",
  //       "Swarovski Crystal Museum",
  //       "London Eye - the world's largest cantilevered observation wheel",
  //       "Leaning tower of Pisa",
  //       "Tower of London - Famous for Kohinoor Diamond",
  //       "Siena - Gem of Tuscany",
  //       "Vatican City - the smallest country in the world",
  //     ],
  //   },
  //   {
  //     title: "TourIternary",
  //     content: {
  //       Duration: "12 Days 11 Nights",
  //       Cities: ["City1", "City2", "City3"],
  //       Country: ["CountryName1", "CountryName 2"],
  //       CountryNames: {
  //         England: [{ name: "London", duration: "2N" }],
  //         France: [{ name: "Paris", duration: "1N" }],
  //       },
  //       Days: [
  //         {
  //           Dayname: "Day1",
  //           header: "London",
  //           description:
  //             "Arrive in London – City of Queen’s land, world class museums, Buckingham palace, Big Ben, Tower bridge and much much more. On arrival visit the Tower of London, where we will see the 'Kohinoor Diamond'. You can also photograph yourself with the 'Yeomen Warders', the ceremonial guardians of the Tower of London. Next visit the famous Madame Tussaud's wax museum. This museum houses a unique collection of world's largest wax works of famous personalities, including India's top leaders and film stars.",
  //           meal: "Dinner",
  //         },
  //         {
  //           Dayname: "Day2",
  //           header: "Germany",
  //           description:
  //             "Arrive in London – City of Queen’s land, world class museums, Buckingham palace, Big Ben, Tower bridge and much much more. On arrival visit the Tower of London, where we will see the 'Kohinoor Diamond'. You can also photograph yourself with the 'Yeomen Warders', the ceremonial guardians of the Tower of London. Next visit the famous Madame Tussaud's wax museum. This museum houses a unique collection of world's largest wax works of famous personalities, including India's top leaders and film stars.",
  //           meal: "Dinner",
  //         },
  //         {
  //           Dayname: "Day3",
  //           header: "Amsterdam",
  //           description:
  //             "Arrive in London – City of Queen’s land, world class museums, Buckingham palace, Big Ben, Tower bridge and much much more. On arrival visit the Tower of London, where we will see the 'Kohinoor Diamond'. You can also photograph yourself with the 'Yeomen Warders', the ceremonial guardians of the Tower of London. Next visit the famous Madame Tussaud's wax museum. This museum houses a unique collection of world's largest wax works of famous personalities, including India's top leaders and film stars.",
  //           meal: "Dinner",
  //         },
  //         {
  //           Dayname: "Day4",
  //           header: "Paris",
  //           description:
  //             "Arrive in London – City of Queen’s land, world class museums, Buckingham palace, Big Ben, Tower bridge and much much more. On arrival visit the Tower of London, where we will see the 'Kohinoor Diamond'. You can also photograph yourself with the 'Yeomen Warders', the ceremonial guardians of the Tower of London. Next visit the famous Madame Tussaud's wax museum. This museum houses a unique collection of world's largest wax works of famous personalities, including India's top leaders and film stars.",
  //           meal: "Dinner",
  //         },
  //         {
  //           Dayname: "Day5",
  //           header: "London",
  //           description:
  //             "Arrive in London – City of Queen’s land, world class museums, Buckingham palace, Big Ben, Tower bridge and much much more. On arrival visit the Tower of London, where we will see the 'Kohinoor Diamond'. You can also photograph yourself with the 'Yeomen Warders', the ceremonial guardians of the Tower of London. Next visit the famous Madame Tussaud's wax museum. This museum houses a unique collection of world's largest wax works of famous personalities, including India's top leaders and film stars.",
  //           meal: "Dinner",
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     title: "steps",
  //     content: [
  //       "Destination Name 1",
  //       "Destination Name 2",
  //       "Destination Name 3",
  //       "Destination Name 4",
  //       "Destination Name 5",
  //       "Destination Name 6",
  //     ],
  //   },
  //   {
  //     title: "imagesMainTopcarousel",
  //     content: [
  //       {
  //         carousel_ima: "/TreeHousepicTOURnic.webp",
  //         text: "A",
  //       },
  //       {
  //         carousel_ima: "/AdventurepicTOURnic.webp",
  //         text: "B",
  //       },
  //       {
  //         carousel_ima: "/TreeHousepicTOURnic.webp",
  //         text: "C",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Package Inclusion",
  //     content: [
  //       {
  //         header: "Swimming Pool",
  //       },
  //       {
  //         header: "House Boat",
  //       },
  //       {
  //         header: "Cruise",
  //       },
  //       {
  //         header: "Ayurveda and Yoga Service",
  //       },
  //       {
  //         header: "Facilities in Room",
  //         subDetail: [
  //           "Cycling",
  //           "Campfire",
  //           "Trekking",
  //           "Bird Watching",
  //           "Nature Walk to River Stream",
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     title: "Package Exclusion",
  //     content: [
  //       {
  //         header: "Any Kind of Air Fare",
  //       },
  //       {
  //         header: "Tips, Drinks, Laundry, Telephon Charges",
  //       },
  //       {
  //         header: "Union Cars, Gandola RIde",
  //       },
  //       {
  //         header: "Horse Ride, Chain Cars, Jeeps, ATV Cars",
  //       },
  //       {
  //         header: "Snow Activities",
  //       },
  //       {
  //         header: "Travel Insurance ",
  //         subDetail: ["A", "B", "C"],
  //       },
  //     ],
  //   },
  // ];

  const { pathname } = props.location;
  const parts = pathname.split("/");
  const packageid = parts[parts.length - 1];
  // console.log(packageid);

  const packageData = findPackageById(packageid);
  // console.log(packageData);
  const sections = packageData;
  return (
    <>
      <Helmet>
        <title>Packages</title>
        <meta
          name="keywords"
          content="Local Tours, Inland Trips, National Excursions, Internal Getaways, Homebound Tours, Overseas Journeys, Global Adventures, Foreign Expeditions, International Escapes, Abroad Tours, Heritage Circuit, Cultural Triangle, Historical Triangle, Golden Triangle Tour, Indian Heritage Route."
        />
      </Helmet>
      <br></br>
      <PackageCarousel data={sections} />
    </>
  );
}

export default withRouter(PackageDetail);
