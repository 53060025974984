import React, { useState, useEffect } from "react";
import '../../components/css/UserEdit.css'
require("dotenv").config();
function UserDetailEditModal({ userId, onClose }) {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phoneNo: "",
    countryCode: "",
    mailId: "",
    birthdate: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    if (userId) {
      fetch(`${process.env.REACT_APP_DB_URL}/users/target/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.birthdate && !isNaN(new Date(data.birthdate).getTime())) {
            const formattedDate = new Date(data.birthdate)
              .toISOString()
              .split("T")[0];
            setUserData({ ...data, birthdate: formattedDate });
          } else {
            setUserData({ ...data, birthdate: "1970-01-01" });
          }
        })
        .catch((error) => console.error("Error fetching user data:", error));
    }
  }, [userId]);

  useEffect(() => {}, [userData]);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_DB_URL+`/editusers/target/${userId}`;

    fetch(url, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then(() => {
        onClose(); // Close the modal on success
      })
      .catch((error) => console.error("Error saving user:", error));
    localStorage.setItem("user", JSON.stringify(userData));
  };

  return (
    <div
      className="modal show d-flex align-items-center"
      tabIndex="-1"
      style={{
        display: "block",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div className="modal-dialog modal-xl" style={{ backgroundColor: "white" }}>
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title text-light">
              {userId ? "Edit Your Details" : "User Details"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div
                style={{ justifyContent: "space-around" }}
                className="mb-3 d-flex "
              >
                <label htmlFor="firstName" className="form-label mx-2">
                  First Name
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="firstName"
                    name="firstName"
                    value={userData.firstName}
                    onChange={handleChange}
                    disabled={!isEditing}
                    required
                  />
                </label>
                <label htmlFor="lastName" className="form-label mx-2">
                  Last Name
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="lastName"
                    name="lastName"
                    value={userData.lastName}
                    onChange={handleChange}
                    disabled={!isEditing}
                    required
                  />
                </label>
              </div>
              <div className="mb-3"></div>
              <div
                style={{ justifyContent: "space-around" }}
                className="mb-3 d-flex "
              >
                <label htmlFor="countryCode" className="form-label">
                  Country Code
                  <input
                    type="text"
                    className="form-control mt-2 "
                    id="countryCode"
                    name="countryCode"
                    value={userData.countryCode}
                    onChange={handleChange}
                    disabled={!isEditing}
                    required
                  />
                </label>
                <label htmlFor="phoneNo" className="form-label">
                  Phone Number
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="phoneNo"
                    name="phoneNo"
                    value={userData.phoneNo}
                    onChange={handleChange}
                    disabled={!isEditing}
                    required
                  />
                </label>
              </div>
              <div className="mb-3"></div>
              <div className="mb-3">
                <label htmlFor="mailId" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="mailId"
                  name="mailId"
                  value={userData.mailId}
                  onChange={handleChange}
                  disabled={!isEditing}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="birthdate" className="form-label">
                  Birthdate
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="birthdate"
                  name="birthdate"
                  value={userData.birthdate}
                  onChange={handleChange}
                  disabled={!isEditing}
                  required
                />
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Close
                </button>
                {userId && !isEditing && (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setIsEditing(true)}
                  >
                    Edit
                  </button>
                )}
                {isEditing && (
                  <button type="submit" className="btn btn-primary ">
                    Save Changes
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetailEditModal;
