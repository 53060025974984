/* eslint-disable */
import React from "react";
import "../components/CarouselElastic.css";
import "../components/Quotes.css";
import "../components/multiCarousel.css";
import "../components/css/LocationCarousel.css";
import { Card } from "reactstrap";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
const LocationCarousel = ({ options }) => {
  // console.log("In teh location page", options);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 9,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      <div className="container-fluid heading-section">
        <br />
        {/* <hr /> */}

        <h2 className="heading-primary">
          <span className="big-letter">E</span>XPLORE Locations Across INDIA
          with picTOURnic
        </h2>
        <h3 className="heading-secondary">
          Handpicked most beautiful places for Weekend Travel, Stays, Corporate
          Events, Pre-wed Shoots &amp; many more.
          <br />
          Explore to the Extreme with Us 
          {/* <i className="fas fa-grin-wink"></i> */}
        </h3>

        <div
          className="container"
          style={{ textAlign: "center", boxShadow: "none" }}
        >
          <a href="/locations/all">
            <button
              type="button"
              className="btn btn-primary btn-lg active"
              style={{
                marginRight: "2px",
                padding: "20px",
                borderRadius: "3%",
                border: "none",
                boxShadow: "-0.6em 0.5em .2em grey",
              }}
            >
              <h3 style={{ color: "white", fontSize: "larger" }}>
                Explore All Locations
              </h3>
            </button>
          </a>
        </div>

        <br></br>
        <br></br>
        <Carousel
          responsive={responsive}
          infinite={true}
          swipeable={true}
          showDots={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          autoPlay={true}
          autoPlaySpeed={300}
          slideTransition={"linear"}
          // dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-00-px"
          containerClass="carousel-container"
          transitionDuration={1000}
          centerMode={true}
        >
          {options.map((item, index) => (
            <a
              href={"filter/locationdetails/" + item}
              key={index}
              style={{ textDecoration: "none" }}
            >
              <Card
                key={index}
                style={{
                  width: "160px",
                  height: "160px",
                  margin: "5px",
                  backgroundImage:
                    "url(/" + item.replace(/ /g, "_") + "_picTOURnic.webp)",
                  backgroundSize: "cover",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.4)", // Darker background
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h6
                    style={{
                      color: "white",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                  >
                    {item === "Andaman and Nicobar Islands"
                      ? "Andaman & Nicobar"
                      : item}
                  </h6>
                </div>
              </Card>
            </a>
          ))}
        </Carousel>
      </div>
      {/* <div className="banner_div"> */}
      {/* </div> */};
    </>
  );
};

export default LocationCarousel;
