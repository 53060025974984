import React from 'react';
// import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const ReturnHomeButton = () => {
//   const history = useHistory();

  const returnHome = () => {
    window.location.href = "/";
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        right: '0',
        transform: 'translateY(-50%)',
        zIndex: '9999',
       
      }}
    >
      <Button style={{ padding: '5px', minWidth: 'unset' , borderRadius:"10px 0 0 10px"}} onClick={returnHome}>
        Back
      </Button>
    </div>
  );
};

export default ReturnHomeButton;