import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../components/css/CarouselSlick.css";
import { FaRegArrowAltCircleRight  } from "react-icons/fa";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
// const teamMembers = [
//     {
//       name: "Tim Spence",
//       role: "Head of Marketing",
//       workAt: "Markson Inc",
//       imgSrc: "https://user-images.githubusercontent.com/78242022/283794670-008f009c-7a9c-41a0-a9bc-38826bfbe9f1.jpg",
//       link: "https://example.com/tim-spence" // Add a link for each member
//     },
//     {
//       name: "Alan Shin",
//       role: "Co-Founder",
//       workAt: "Duper Duper",
//       imgSrc: "https://user-images.githubusercontent.com/78242022/283794663-5447181d-8621-4a1d-8b4f-15a10917be12.jpg",
//       link: "https://example.com/alan-shin"
//     },
//     {
//       name: "Laurel Peng",
//       role: "Head of Corporate",
//       workAt: "Development, MCX",
//       imgSrc: "https://user-images.githubusercontent.com/78242022/283794643-4cea087e-3214-4331-84f8-fde21a8f22db.jpg",
//       link: "https://example.com/laurel-peng"
//     },
//     {
//       name: "Alex Kovosksky",
//       role: "Overseas Sales",
//       workAt: "Newness Golf",
//       imgSrc: "https://user-images.githubusercontent.com/78242022/283794606-8b10b1c2-16e0-431d-88ca-3d6c9fca4839.jpg",
//       link: "https://example.com/alex-kovosksky"
//     },
//     {
//       name: "JoJo Kinis",
//       role: "DevOps Engineer",
//       workAt: "Ylyeer",
//       imgSrc: "https://user-images.githubusercontent.com/78242022/283794595-9f1358ea-5af5-4b69-8295-bde8961fed84.jpg",
//       link: "https://example.com/jojo-kinis"
//     },
//     {
//       name: "Violet Dynk",
//       role: "Content Writer",
//       workAt: "GreenShock",
//       imgSrc: "https://user-images.githubusercontent.com/78242022/283794700-f2f6cadc-0652-4f82-a77a-5bd8286fec42.jpg",
//       link: "https://example.com/violet-dynk"
//     },
//   ];
  const teamMembers = [
    { name: "International Package", imgSrc: "/A_international.webp" ,link:"package/International"},
    { name: "Domestic package", imgSrc: "/A_domestic.webp",link:"package/domestic" },
    { name: "Stays", imgSrc: "/A_stay.webp",link:"Nature Stays" },
    { name: "Picnic", imgSrc: "/A_picnic.webp",link:"Day Picnic" },
    { name: "Cruise", imgSrc: "/A_cruise.webp",link:"Houseboat or Cruise" },
    { name: "One Day Trip", imgSrc: "/A_oneDayTrip.webp",link:"OneDaypackage/trip" },
    { name: "Trending", imgSrc: "/A_trending.webp" ,link:"trending-places"},
   
    
  ];

function CarouselSlick() {
    const sliderRef = React.useRef(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: false,
    autoplay:true,
    autoplaySpeed:2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <>
      <br></br>
      <div className="slider-container">
        <div className="controll-btn">
          <button className="carousessl-control-prev" onClick={previous}>
          <FaRegArrowAltCircleLeft/>
          </button>
          <button className="carousessl-control-next" onClick={next}>
          
          <FaRegArrowAltCircleRight />
          </button>
        </div>
        
        <br></br>
        <Slider ref={sliderRef} {...settings}>
        {teamMembers.map((member, index) => (
            <div key={index}>
              <a href={member.link} style={{textDecoration:"none", color:"black"}}>
                <div className="slickcard">
                  <img src={member.imgSrc} alt={member.name} />
                  <div className="caption">
                    <h2 className="name">{member.name}</h2>
                    <p className="role">{member.role}</p>
                    <p className="work-at">{member.workAt}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default CarouselSlick;
