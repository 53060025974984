import React,{Component} from 'react'
import {withRouter} from 'react-router-dom'
import './Back.css'
class Back extends Component{
// constructor(props){
//     super(props);

// }
render(){
    return(
        <>
        <div className="desktop_back" style={{zoom:"85%"}}>
            <button id = "Go_Back" type="button" className= "btn btn-danger"  onClick={this.props.history.goBack}
            >Go Back
            {/* <i  className="fas fa-hand-point-left"></i>  */}
            </button>
        </div>

         <div className="mob">
            <button type="button" className= "btn btn-danger"  id = "Go_Back" onClick={this.props.history.goBack}
            // style={{position:"fixed", right: 0, zIndex: 1000000, fontSize:"18px", borderRadius:"20px"}}
            >Go Back
            {/* <i  className="fas fa-hand-point-left"></i> */}
            </button>
        </div>
        </>
    )
}
}

    export default withRouter(Back)


