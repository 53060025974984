/* eslint-disable */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "../axios";
import SingleCard from "./Card";
import { Row } from "reactstrap";
import Back from "../components/Back.js";
import Throbber from "../components/Pages/Throbber.js";
// import MainPageCard from "./MainPageCard";
// import PartnerPageMainCard from "./PartnerPageMainCard";

function PartnerCards(props) {
  var partnerName_ = props.match.params.partnerName;
  // console.log(partnerName_);
  const [mainPageOptions, setmainPageOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [mainPageOptions_, setmainPageOptions_] = useState([]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       // console.log('Calling DB')
  //       const req = await axios.get(process.env.REACT_APP_DB_URL + "/");
  //       // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  //       // await sleep(1000);
  //       // const normarray = ;
  //       // console.log()
  //       setmainPageOptions_(
  //         req.data.sort((a, b) => a.displayOrder - b.displayOrder)
  //       );
  //       setLoading(false);
  //       // console.log("The data set here is ", req.data)
  //     } catch (error) {
  //       // console.log('Error is')
  //       console.error(error);
  //     }
  //   }
  //   fetchData();
  // }, []);

  useEffect(() => {
    async function fetchPartnersDataSet() {
      try {
        const req = await axios.get(
          process.env.REACT_APP_DB_URL +
            "/extract/propidinformation/result/partnerName/" +
            partnerName_
        );
        // console.log(req.data);
        const dataset = req.data.sort((a, b) => b.raiting - a.rating);
        const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await sleep(2000);
        setLoading(true);
        setmainPageOptions(dataset);
      } catch (error) {
        // console.log('Error is')
        console.error(error);
      }
    }
    fetchPartnersDataSet();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="conatiner-fluid">
            <br></br>
            <center>
              <h2>
                <i
                  className="fas fa-hand-holding-heart"
                  style={{ fontSize: "xx-large", color: "green" }}
                ></i>
                Our Travel Partner -{partnerName_}
              </h2>
            </center>
          </div>
          <div className="container-fluid">
            <Row xs="1" sm="3" md="3" lg="4">
              <SingleCard options={mainPageOptions} />
            </Row>
          </div>
          <Back />
        </>
      ) : (
        <Throbber />
      )}
    </>
  );
}

export default withRouter(PartnerCards);
