/* eslint-disable */ 
import React from "react";
import { withRouter } from "react-router-dom";
import axios from "../../axios";
import { useEffect, useState } from "react";
import "../../components/Pages/PropertiesListing.css"
import {
  Row,
  // , CardSubtitle
} from "reactstrap";
import { Helmet } from "react-helmet";
import Throbber from "../Pages/Throbber";
import "../Card.css";
import SingleCard from "../Card.js";
import PropertiesCard from "../PropertiesCard";
import Back from "../Back";
import AllLocationCards from "./AllLocationCards";
require('dotenv').config();
function PropertiesListing(props) {
  // console.log(props);

  var mainlocation = props.match.params.locationname;
  var sublocation = props.match.params.sublocation;
  var categoryid = props.match.params.categoryid;
  var categoryname = props.match.params.categoryname;
  const [loading, setLoading] = useState(false);
  const [propertiesList, setPropertiesList] = useState([]);
  useEffect(() => {
    async function fetchPropertyDataData() {
      try {
        // console.log("Calling DB for CategoryNames Page");
        const req = await axios.get(
          process.env.REACT_APP_DB_URL +"/filter/locationdetails/" +
            mainlocation +
            "/" +
            categoryname +
            "/" +
            sublocation +
            "/" +
            categoryid
        );
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // console.log('the Sublocationdats set is ')
        // console.log(req.data)
        // setPropLocation(dataset)
        var dataset_ = req.data;
        // console.log("Final Stage", dataset_);

        var propertiesvalues = [];
        dataset_.map((item) => {
          // console.log("Now", item[0]);
          propertiesvalues.push(item[0]);
        });
        setPropertiesList(propertiesvalues.sort((a, b) => b.rating - a.rating));

        // setLoading(true);
        // console.log(propertiesvalues.sort((a, b) => b.rating - a.rating));
      } catch (error) {
        // console.log("Error is", error.response.status);
        // console.error(error);
        // setLoading(true);
        // seterrorPage(error.response.status);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchPropertyDataData();
    //eslint-disable-next-line
  }, []);
  const [mainPageOptions, setmainPageOptions] = useState([]);
  useEffect(() => {
    async function fetchDataForLocationaAll() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(process.env.REACT_APP_DB_URL +"/locationsall");
        // console.log("For all locations", req.data);
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(2000);
        // console.log("Earlier Array", req.data);
        var req_arr = req.data;
        var temp_arr = [];
        req_arr.map((item) => {
          if (item !== mainlocation) {
            temp_arr.push(item);
          }
        });
        // console.log("Now the Array is",temp_arr )

        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(2000);
        // setmainPageOptions(req.data);
        // setLoading(true);
        setmainPageOptions(temp_arr);
        // setLoading(true);
      } catch (error) {
        // console.log('Error is')
        // console.error(error);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchDataForLocationaAll();
  }, []);

  const [propMainPageOptions, propSetmainPageOptions] = useState([]);
  // To Specify all property ids below the main locations
  useEffect(() => {
    async function fetchAllPropertyDataForLocation() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(
          process.env.REACT_APP_DB_URL +"/for/allproperties/getdata/" + mainlocation
        );
        // console.log("For all locations", req.data);
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // console.log("Earlier Array", req.data)
        var req_arr = req.data;

        // console.log("Now the Array is", req_arr);

        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(3000);
        // setmainPageOptions(req.data);
        // setLoading(true);

        propSetmainPageOptions(req_arr.sort((a, b) => b.rating - a.rating));
        setLoading(true);
      } catch (error) {
        // console.log('Error is')
        // console.error(error);
        setLoading(false);
        window.location = "/";
        alert(
          "Apologies from picTournic. This Property is not available for booking. Redirecting to home page"
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchAllPropertyDataForLocation();
  }, []);

  return (
    <>
    <Helmet>
        <title>{mainlocation} {sublocation} {categoryname}</title>
      </Helmet>
      {loading ? (
        <>
        <br></br>
          <div className="container-fluid" style={{ boxShadow: "none" }}>
            <center>
              <h1 style={{ color: "brown" }}>
                Let's Explore {categoryname}
                <br></br>
                <span style={{ color: "green", fontSize: "30px" }}>
                <i className="fas fa-globe-asia" style={{ color: "black", fontSize: "40px" }}></i>
                  {sublocation}, {mainlocation}
                </span>
              </h1>
              {/* <h1>{location_page}</h1> */}
            </center>
          </div>
          
          <div className="container-fluid">
            <Row xs="1" sm="2" md="3" lg="5">
              {/* <SingleCard  options={propertiesList}/> */}

              <PropertiesCard options={propertiesList} />
            </Row>
          </div>

          {/* <div className="container" style={{ boxShadow: "none" }}></div> */}
          <div className="container" style={{ boxShadow: "none" }}>
            <center>
              
              <hr></hr>
              <br></br>
              <h1 style={{ color: "brown" }}>Explore More!!</h1>
              <h3 style={{ color: "green" }}>
              <i className="fas fa-globe-asia" style={{ color: "black", fontSize: "40px" }}></i> {mainlocation}
              </h3>
            </center>
          </div>
          <div className="container-fluid">
            <Row xs="1" sm="2" md="3" lg="5">
              <SingleCard options={propMainPageOptions} />
            </Row>
          </div>

          <div className="container-fluid" style={{ boxShadow: "none" }}>
            <div className="row text-center">
              <AllLocationCards options={mainPageOptions.sort()} />
            </div>
          </div>
          <Back />
        </>
      ) : (
        <Throbber />
      )}
    </>
  );
}

export default withRouter(PropertiesListing);
