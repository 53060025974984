import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import '../../components/css/FloatingLable.css';

const FloatingLabel = ({ id, label, type, autoComplete, required, handleChange, value, borderColor }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <input
                id={id}
                name={id}
                type={showPassword ? "text" : type}
                autoComplete={autoComplete}
                required={required}
                value={value}
                onChange={handleChange}
                className="input-field"
                placeholder=" "
                style={{ border: `1px solid ${borderColor || "lightgrey"}` }}
                inputMode={type === "tel" ? "numeric" : "text"} 
            />
            <label
                htmlFor={id}
                className="input-label"
            >
                {label}
            </label>
            {type === "password" && (
                <div
                    onClick={togglePasswordVisibility}
                    className="password-icon"
                >
                    {showPassword ? <FaEye color="grey" /> : <FaEyeSlash color="grey" />}
                </div>
            )}
        </>
    );
};

export default FloatingLabel;
