import React from "react";
import "../../components/css/Seperators.css";
import { Helmet } from "react-helmet";
import { FaCanadianMapleLeaf } from "react-icons/fa";
import { GiChestnutLeaf } from "react-icons/gi";
import { GiLindenLeaf } from "react-icons/gi";
// import { GiSolidLeaf } from "react-icons/gi";
import { GiOakLeaf } from "react-icons/gi";

function Seperators() {
  return (
    <>
      <Helmet>
        <title>Packages Selection</title>
        <meta
          name="keywords"
          content="International,Meghalaya tourLocal Tours, Inland Trips, National Excursions, Internal Getaways, Homebound Tours, Overseas Journeys, Global Adventures, Foreign Expeditions, International Escapes, Abroad Tours, Heritage Circuit, Cultural Triangle, Historical Triangle, Golden Triangle Tour, Indian Heritage Route."
        />
      </Helmet>
      <div style={{ margin: "10px", borderRadius: "10px" }}>
        <center>
          <h4 className="TitlePackagessss">PACKAGES</h4>
        </center>
        <div className="container-fluid_">
          <div className="box">
            <div className="image-container">
              <img src="/incredibleindia.webp" alt="pictournic package" />
              <div className="overlay-text left-text">INCREDIBLE INDIA</div>
              <div className="overlay-text right-text">
                <a href="/package/domestic" className="view-all-button">
                  View All
                </a>
              </div>
            </div>
            <div className="text-columns">
            <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <FaCanadianMapleLeaf style={{ marginRight: "8px", color:"green" }} />
                <a href="/package/packageid/PTN_PkG_0010" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Kashmir
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiChestnutLeaf style={{ marginRight: "8px", color:"orange" }} />
                <a href="/package/packageid/PTN_PkG_0007" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Kerela
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiLindenLeaf style={{ marginRight: "8px", color:"green" }} />
                <a href="/package/packageid/PTN_PkG_0005" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Hampi
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiOakLeaf style={{ marginRight: "8px", color:"orange" }} />
                <a href="/package/packageid/PTN_PkG_0034" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Jyotirlingas Of Madhaya Pradesh
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <FaCanadianMapleLeaf style={{ marginRight: "8px", color:"orange" }} />
                <a href="/package/packageid/PTN_PkG_0036" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Golden Triangle
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiChestnutLeaf style={{ marginRight: "8px", color:"green" }} />
                <a href="/package/packageid/PTN_PkG_0001" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Green Wayanad
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiLindenLeaf style={{ marginRight: "8px", color:"orange" }} />
                <a href="/package/packageid/PTN_PkG_0004" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Majestic Karnataka
                  </li>
                </a>
              </div>
              {/* <a href="/package/packageid/PTN_PkG_0010" >
                <li>Kashmir</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0007" >
                <li>Kerela</li>
              </a>

              <a href="/package/packageid/PTN_PkG_0005" >
                <li>Hampi</li>
              </a>

              <a href="/package/packageid/PTN_PkG_0034" >
                <li>Jyotirlingas Of Madhaya Pradesh</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0036" >
                <li>Golden Triangle</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0001" >
                <li>Green Wayanad</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0004" >
                <li>Majestic Karnataka</li>
              </a> */}
            </div>
          </div>
          <div className="box">
            <div className="image-container">
              <img src="/international.webp" alt="pictournic package" />
              <div className="overlay-text left-text">INTERNATIONAL</div>
              <div className="overlay-text right-text">
                <a href="/package/International" className="view-all-button">
                  View All
                </a>
              </div>
            </div>
            <div className="text-columns">
            <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <FaCanadianMapleLeaf style={{ marginRight: "8px", color:"green" }} />
                <a href="/package/packageid/PTN_PkG_0033" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Vietnam 10 Days
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiChestnutLeaf style={{ marginRight: "8px", color:"orange" }} />
                <a href="/package/packageid/PTN_PkG_0024" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Grand Wonders of Europe
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiLindenLeaf style={{ marginRight: "8px", color:"orange" }} />
                <a href="/package/packageid/PTN_PkG_0027" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Portugal
                  </li>
                </a>
              </div>
              {/* <a href="/package/packageid/PTN_PkG_0017" >
                <li>Best Of Europe</li>
              </a> */}
              {/* <a href="/package/packageid/PTN_PkG_0033" >
                <li>Vietnam 10 Days</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0024" >
                <li>Grand Wonders of Europe</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0027" >
                <li>Portugal</li>
              </a> */}
            </div>
          </div>
          <div className="box">
            <div className="image-container">
              <img src="/Europe_pan.webp" alt="pictournic package" />
              <div className="overlay-text left-text">Europe</div>
              <div className="overlay-text right-text">
                <a href="/package/Europe" className="view-all-button">
                  View All
                </a>
              </div>
            </div>
            <div className="text-columns">

            <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <FaCanadianMapleLeaf  style={{ marginRight: "8px", color:"green" }} />
                <a href="/package/packageid/PTN_PkG_0016" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Adorable Europe
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiChestnutLeaf  style={{ marginRight: "8px", color:"orange" }} />
                <a href="/package/packageid/PTN_PkG_0022" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Glimpse of United Kingdom
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiLindenLeaf  style={{ marginRight: "8px", color:"orange" }} />
                <a href="/package/packageid/PTN_PkG_0024" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Grand Wonders of Europe
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiOakLeaf  style={{ marginRight: "8px", color:"green" }} />
                <a href="/package/packageid/PTN_PkG_0027" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  panish Fiesta with Portugal
                  </li>
                </a>
              </div>
              {/* <a href="/package/packageid/PTN_PkG_0016" >
                <li>Adorable Europe</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0017" >
                <li>Best Of Europe</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0022" >
                <li>Glimpse of United Kingdom</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0024" >
                <li>Grand Wonders of Europe</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0027" >
                <li>Spanish Fiesta with Portugal</li>
              </a> */}
            </div>
          </div>

          <div className="box">
            <div className="image-container">
              <img src="/Asia_pan.webp" alt="pictournic package" />
              <div className="overlay-text left-text">Asia</div>
              <div className="overlay-text right-text">
                <a href="/package/Asia" className="view-all-button">
                  View All
                </a>
              </div>
            </div>
            <div className="text-columns">
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <FaCanadianMapleLeaf style={{ marginRight: "8px", color:"green" }} />
                <a href="/package/packageid/PTN_PkG_0015" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                    Bhutan
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiChestnutLeaf style={{ marginRight: "8px", color:"orange" }} />
                <a href="/package/packageid/PTN_PkG_0033" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Vitenam 10 Days
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiLindenLeaf  style={{ marginRight: "8px", color:"orange" }} />
                <a href="/package/packageid/PTN_PkG_0032" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Vitenam 7 Days
                  </li>
                </a>
              </div>
              <div className="listdownPackages" style={{ display: "flex", alignItems: "center" }}>
                <GiOakLeaf  style={{ marginRight: "8px", color:"green" }} />
                <a href="/package/packageid/PTN_PkG_0031" >
                  <li style={{ listStyle: "none", padding: "2px", margin: "0", fontSize:"14px" }}>
                  Vitenam 5 Days
                  </li>
                </a>
              </div>
              {/* <a href="/package/packageid/PTN_PkG_0033" >
                <li>Vietnam 10 Days</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0032" >
                <li>Vitenam 7 Days</li>
              </a>
              <a href="/package/packageid/PTN_PkG_0031" >
                <li>Vitenam 5 Days</li>
              </a> */}
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
}

export default Seperators;
