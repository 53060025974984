import React from "react";
import {
  Card,
  CardText,
  CardBody,
  CardLink,
  CardTitle,
  // , CardSubtitle
} from "reactstrap";
import "aos/dist/aos.css";
import "../components/Card";
import { Rating } from "react-simple-star-rating";

function PropertiesCard({ options }) {
  // const [visited, setVisited] = useState(false);
  // const handleVisited = () => {
  //   setVisited(true);
  // };
  return (
    <>
      {options.map((everyoption) => (
        <Card
          className={"singlecard singlecard" + everyoption.propertyid}
          key={everyoption.propertyid}
          style={{ width: "320px", height: "auto" }}
        >
          <CardText className="singlecard-text singlecard-rating">
            <Rating
              initialValue={everyoption.rating}
              transition
              size={30}
              allowHalfIcon
              readonly={true}
              allowHover={false}
            />
          </CardText>

          <CardText className="singlecard-text singlecard-star-rating">
            ({everyoption.rating} Star rating from {everyoption.number_of_users}{" "}
            Reviews)
          </CardText>

          <CardText className="singlecard-text singlecard-mainloc">
            {everyoption.mainloc}
          </CardText>

          <img
            className="singlecard-image"
            src={"/Prop" + everyoption.propertyid + ".webp"}
            alt={everyoption.alt_image_name}
          />

          <CardBody>
            <CardLink
              className="singlecard-link"
              href={`/${everyoption.propertyid}?userRating=${everyoption.rating}&locationDetails=${everyoption.location}`}
            >
              EXPLORE #{everyoption.propertyid}
            </CardLink>

            <CardText className="singlecard-text singlecard-location">
              <i className="singlecard-icon fas fa-location-arrow"></i>
              {everyoption.location}
            </CardText>

            <CardTitle className="singlecard-title" tag="h5">
              {everyoption.title}
            </CardTitle>
          </CardBody>
        </Card>
      ))}
    </>
  );
}

export default PropertiesCard;
