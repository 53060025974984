import React from "react";
import emailjs from "emailjs-com";
import ReactGa from "react-ga";
import "../../components/css/userEnquiryForm.css";

require("dotenv").config();
// import "..//components/DetailRequestForm.css";
// import "../../components/DetailsRequestForm.css";
// import "./ContactUs.css";

function Packagerequest() {
  function handlePackageEvent() {
    // e.preventDefault();

    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    ReactGa.event({
      category: "Package request From User",
      action: "package request",
      label: "User Needs package Details",
      value: 1,
    });
  }
  function sendEmail(e) {
    e.preventDefault();
    // console.log(e.target.checkin.value)
    // console.log(e.target.username.value)
    // console.log(e.target.checkout.value)
    // console.log(e.target.phonenumber.value)
    // console.log(e.target.altphonenumber.value)
    // console.log(e.target.email.value)
    // console.log(e.target.adults.value)
    // console.log(e.target.children.value)
    // console.log(e.target.rooms.value)
    // console.log(e.target.foodprefrence.value)
    // console.log(e.target.mealplan.value)
    // console.log(e.target.message.value)
    // console.log(e.target.destination.value)

    if (e.target.checkin.value <= e.target.checkout.value) {
      // console.log("This form will be submitted");

      emailjs
        .sendForm(
          process.env.REACT_APP_MAIL_SERVICE,
          process.env.REACT_APP_MAIL_TEMPLATE,
          e.target,
          process.env.REACT_APP_MAIL_USER
        )
        .then(
          (result) => {
            // console.log(result.text);
            if (result.text === "OK") {
              handlePackageEvent();
              alert(
                "Your mail has been Sent Succesfully. Our Executive will get in touch soon for your Booking updates."
              );
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    } else {
      alert(
        "Check-Out Date cannot be earlier than Check-In date. Requesting you to Please update dates. Thanks!"
      );
    }
  }

  return (
    <>
      {/* <h1 style={{ textAlign: "center", color: "brown" }}>
          Packages available for<br></br>
          <i className="fas fa-handshake" style={{ color: "brown" }}></i>{" "}
        </h1> */}
      <div
        className="container"
        style={{ boxShadow: "none", margin: "0px!important" }}
      >
        {/* <h2 style={{textAlign:"center"}}>
              Singapore, Malaysia, Thailand, Indonesia, United Arab Emirates,
              Australia, Greece
            </h2>
          
            
          <br></br><br></br> */}
        <br></br>
        {/* <hr></hr> */}

        <div className="conatiner-fluid" style={{backgroundColor:"#fff8c2", padding:"20px", borderRadius:"5px",boxShadow:"rgba(0, 0, 0, 0.5) 7px -7px 16px 0px inset"}}>
          <center>
            <h4 style={{ color: "black" , fontSize:"16px"}}>
              Best Deals with picTOURnic Packages <br></br> Please send below
              details to us
              <i className="fas fa-handshake" style={{ color: "brown" }}></i>
            </h4>
          </center>
        </div>

       
        <div
          className="container-fluid"
          style={{
            // backgroundColor: "#2a495c8a",
            // borderRadius: "10px",
            // boxSizing: "content-box",
            boxShadow: "none",
            textAlign:"left",
           
          }}
        >
          <p className="right" style={{ paddingTop: "20px", color: "black", textAlign:"right" }}>
            <span className="color">* </span>Feilds are Mandatary!
          </p>
          <form className="contact-form" onSubmit={sendEmail}>
            <div className="row">
              <div className="col-lg-6 font_size">
                <div className="mb-3 font_size">
                  <strong>
                    {" "}
                    <label style={{ color: "black", fontWeight:"100" }}>
                      <span className="color">* </span>Date From :
                    </label>
                  </strong>
                  <input
                    type="Date"
                    name="checkin"
                    className="form-control"
                    required
                    min={new Date().toISOString().split("T")[0]}
                    style={{fontSize:"25px"}}
                  />
                </div>
                <div className="mb-3" hidden>
                  <strong>
                    <label style={{ color: "black" , fontWeight:"100"}}>Property Id :</label>
                  </strong>
                  <input
                    type="text"
                    className="form-control"
                    name="destination"
                    value={"PACKAGE REQUEST"}
                    readOnly
                    style={{fontSize:"25px"}}
                  />
                </div>

                <div className="mb-3">
                  <strong>
                    <label style={{ color: "black", fontWeight:"100" }}>
                      <span className="color">* </span>Phone number :
                    </label>
                  </strong>
                  {/* <input type="number"  name="" className="form-control" placeholder="Enter Contact Detail" required onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() } />	 */}
                  <input
                    type="text"
                    className="form-control"
                    name="phonenumber"
                    pattern="[0-9]{10}"
                    title="Please enter exactly 10 digits of your phone number"
                    style={{fontSize:"25px"}}
                    // onKeyDown={(e) =>
                    //   /[+\-.,]$/.test(e.key) && e.preventDefault()
                    // }
                    required
                  />
                </div>

                <div className="mb-3">
                  <strong>
                    <label style={{ color: "black", fontWeight:"100" }}>
                      <span className="color">* </span>Number of adults :
                    </label>
                  </strong>
                  <input
                    type="number"
                    className=" form-control"
                    name="adults"
                    required
                    min="0"
                    style={{fontSize:"25px"}}
                    onKeyDown={(e) =>
                      /[+\-.,]$/.test(e.key) && e.preventDefault()
                    }
                  />
                </div>
                <div className="mb-3">
                  <strong>
                    <label style={{ color: "black" , fontWeight:"100"}}>Email :</label>
                  </strong>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="user@gmail.com"
                    style={{fontSize:"25px"}}
                  />
                </div>
                <div className="mb-3">
                  <strong>
                    <span className="color">* </span>
                    <label style={{ color: "black" , fontWeight:"100"}}>
                      Preffered Location:
                    </label>
                  </strong>
                  <input
                    type="text"
                    name="rooms"
                    className="form-control"
                    required
                    style={{fontSize:"25px"}}
                    // min="0"
                    // onKeyDown={(e) => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                  />
                </div>

                <div className="mb-3" hidden>
                  <strong>
                    <label style={{ color: "black", fontWeight:"100" }}>Meal plan :</label>
                  </strong>
                  <select name="mealplan" className="form-control" style={{fontSize:"25px"}}>
                    <option>Please select...</option>
                    <option>Breakfast Only</option>
                    <option>Breakfast & Lunch Only</option>
                    <option>Breakfast & Dinner Only</option>
                    <option>Brakfast, lunch & Dinner</option>
                    <option>No Meal Needed</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6 font_size">
                <div className="mb-3 font_size">
                  <strong>
                    {" "}
                    <label style={{ color: "black" , fontWeight:"100"}}>
                      <span className="color">* </span>Date To :
                    </label>
                  </strong>
                  <input
                    type="Date"
                    name="checkout"
                    className="form-control"
                    required
                    style={{fontSize:"25px"}}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>

                <div className="mb-3">
                  <strong>
                    <label style={{ color: "black", fontWeight:"100" }}>
                      <span className="color">* </span>Name :
                    </label>
                  </strong>
                  <input
                    type="text"
                    name="username"
                    placeholder="Guest Name"
                    className="form-control"
                    style={{fontSize:"25px"}}
                    onKeyDown={(e) =>
                      /[{"<>':;}|+\-_.*,()!@#$%^&=0-9]$/.test(e.key) &&
                      e.preventDefault()
                    }
                    required
                  />
                </div>
                <div className="mb-3">
                  <strong>
                    <label style={{ color: "black", fontWeight:"100" }}>
                      Number of Children :
                    </label>
                  </strong>
                  <input
                    type="number"
                    name="children"
                    className="form-control"
                    min="0"
                    style={{fontSize:"25px"}}
                    onKeyDown={(e) =>
                      /[+\-.,]$/.test(e.key) && e.preventDefault()
                    }
                  />
                </div>
                <div className="mb-3" hidden>
                  <strong>
                    <label style={{ color: "black" , fontWeight:"100"}}>Alternate number :</label>
                  </strong>
                  <input
                    type="text"
                    className="form-control"
                    name="altphonenumber"
                    style={{fontSize:"25px"}}
                    title="Please enter exactly 10 digits of your alternate number"
                    placeholder="if any (optional)"
                    pattern="[0-9]{10}"
                    // onKeyDown={(e) =>
                    //   /[+\-.,]$/.test(e.key) && e.preventDefault()
                    // }
                  />
                </div>

                <div className="mb-3">
                  <strong>
                    <label style={{ color: "black", fontWeight:"100" }}>Food Preference :</label>
                  </strong>
                  <select name="foodprefrence" className="form-control" style={{fontSize:"25px"}}>
                    <option>Please select...</option>
                    <option>Veg</option>
                    <option>Non-Veg</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="container" style={{ boxShadow: "none" }}>
              <strong>
                <label style={{ color: "black", fontWeight:"100" }}>Message (Optional) : </label>
              </strong>
              <textarea
                className="form-control"
                name="message"
                rows="4"
              ></textarea>
            </div>
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-2">
                <center>
                  <input type="submit" className="btn btn-primary" name="" />
                </center>
              </div>
              <div className="col-lg-5"></div>
            </div>
          </form>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default Packagerequest;

// export default Packagerequest

// // export default function ContactUs({ propertyid }) {

// // }
