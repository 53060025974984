
import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

// class NotFoundPage extends React.Component{
//     render(props){
//         var par = props.match.params.propid;
//         alert(par)
//         return (
//         <div>
//             <div className="container" style={{boxShadow:"none"}}>
//                 <div className="row" style={{ borderRadius: "10px"}}>

//                     <div className="col-md-12">
//                         <p style={{fontSize:"90px", textAlign:"center", fontWeight:"600", color:"black"}}>OOPS!</p>
//                     </div>
//                     <div className="col-md-12" >
//                         <p style={{fontSize:"90px", textAlign:"center", fontWeight:"900", color:"black"}}>400</p>
//                     </div>
//                     <div className="col-md-12"  style={{marginBottom:"40px"}}>
//                         <p style={{fontSize:"30px", textAlign:"center", fontWeight:"900", color:"black"}}>BAD REQUEST!!</p>

//                     </div>
//                     <div className="col-md-12" >
//                         <p style={{fontSize:"20px", textAlign:"center", fontWeight:"700", color:"black"}}>Requested URL is not available!</p>
//                     </div>

//                 </div>
//                 <div className="row" style={{margin:"40px"}}>
//                     <div className="col-md-12"  style={{textAlign:"center"}}>
//                             <Link to="/" style={{backgroundColor:"brown", padding: "20px", color:"white", borderRadius:"10px"}}>Go to Home </Link>
//                     </div>
//                 </div>
//             </div>
//           </div>
//         )
//     }
// }
// export default withRouter(NotFoundPage);

function NotFoundPage(props) {
  var par = props.match.params.id;
  //   alert(par);
  //   console.log(par);
  // eslint-disable-next-line
  if (par == "Bangalore") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Bengaluru/2";
  }// eslint-disable-next-line
   else if (par == "bangalore") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Bengaluru/2";
  }// eslint-disable-next-line
  else if (par == "Kollur") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Udupi/2";
  }// eslint-disable-next-line
   else if (par == "Waynad") {
    window.location.href =
      "/filter/locationdetails/Kerala/Nature%20Stays/Wayanad/2";
  } // eslint-disable-next-line
  else if (par == "Chikmagalur") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Chikkamagaluru/2";
  } // eslint-disable-next-line
  else if (par == "Coimbatore") {
    window.location.href =
      "/filter/locationdetails/Tamil%20Nadu/Nature%20Stays/Coimbatore/2";
  }// eslint-disable-next-line
   else if (par == "Coorg") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Coorg/2";
  } // eslint-disable-next-line
  else if (par == "Sakleshpur") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Sakleshpura/2";
  }// eslint-disable-next-line
   else if (par == "mysore") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Mysore/2";
  }
  // eslint-disable-next-line
  else if (par == "Mysore") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Mysore/2";
  } // eslint-disable-next-line
  else if (par == "Shimoga") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Shivamogga/2";
  } // eslint-disable-next-line
  else if (par == "udupi") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Udupi/2";
  }// eslint-disable-next-line
  else if (par == "Udupi") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Udupi/2";
  } // eslint-disable-next-line
  else if (par == "Sirsi") {
    window.location.href =
      "/filter/locationdetails/Karnataka/Nature%20Stays/Sirsi/2";
  } // eslint-disable-next-line
  else if (par == "Thekkady") {
    window.location.href =
      "/filter/locationdetails/Kerala/Nature%20Stays/Thekkady/2";
  }// eslint-disable-next-line
   else if (par == "Goa") {
    window.location.href =
      "/filter/locationdetails/Goa";
  }// eslint-disable-next-line
   else if (par == "Kullu") {
    window.location.href =
      "/filter/locationdetails/Himachal%20Pradesh";
  }// eslint-disable-next-line
   else if (par == "Ladakh") {
    window.location.href =
      "/filter/locationdetails/Ladakh";
  } // eslint-disable-next-line
  else if (par == "Maharashtra") {
    window.location.href =
      "/filter/locationdetails/Maharashtra";
  } // eslint-disable-next-line
  else if (par == "Nainital") {
    window.location.href =
      "/filter/locationdetails/Uttarakhand";
  }// eslint-disable-next-line
   else if (par == "Darjeeling") {
    window.location.href =
      "/filter/locationdetails/West%20Bengal/Luxury/Darjeeling/13";
  } // eslint-disable-next-line
  else if (par == "Madhyapradesh") {
    window.location.href =
      "/filter/locationdetails/Madhya%20Pradesh";
  } // eslint-disable-next-line
  else if (par == "Tirthanvalley") {
    window.location.href =
      "/filter/locationdetails/Himachal%20Pradesh";
      // eslint-disable-next-line
  } else if (par == "DESTINATION TOURS") {

    window.location.href =
      "/locations/all";
  } // eslint-disable-next-line
  else if (par == "CORPORATE EVENTS") {

    window.location.href =
      "/locations/all";
  } // eslint-disable-next-line
  else if (par == "PRE WEDDING SHOOT") {    
    window.location.href =
      "/locations/all";
  }// eslint-disable-next-line
  // else if(par == "Package%20Tour"){
  //   window.location.href =
  //   "/packages/package%20tour";
  // }
  else {
    return (
      <>
        <div>
          <div className="container" style={{ boxShadow: "none" }}>
            <div className="row" style={{ borderRadius: "10px" }}>
              <div className="col-md-12">
                <p
                  style={{
                    fontSize: "90px",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  OOPS!
                </p>
              </div>
              <div className="col-md-12">
                <p
                  style={{
                    fontSize: "90px",
                    textAlign: "center",
                    fontWeight: "900",
                    color: "black",
                  }}
                >
                  400
                </p>
              </div>
              <div className="col-md-12" style={{ marginBottom: "40px" }}>
                <p
                  style={{
                    fontSize: "30px",
                    textAlign: "center",
                    fontWeight: "900",
                    color: "black",
                  }}
                >
                  BAD REQUEST!!
                </p>
              </div>
              <div className="col-md-12">
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  Requested URL is not available!
                </p>
              </div>
            </div>
            <div className="row" style={{ margin: "40px" }}>
              <div className="col-md-12" style={{ textAlign: "center" }}>
                <Link
                  to="/"
                  style={{
                    backgroundColor: "brown",
                    padding: "20px",
                    color: "white",
                    borderRadius: "10px",
                  }}
                >
                  Go to Home{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
        x
      </>
    );
  }
}
export default withRouter(NotFoundPage);
