/* eslint-disable */
import React, { useState, useEffect } from "react";
import ScrollUpButton from "react-scroll-up-button";
import "./App.css";
import Navbar from "./components/Navbar";
import "./components/ImageSlider.css";
import Footer from "./components/Footer";
// import FeedbackForm from "./components/Pages/FeedbackForm";
import Partner from "./components/Pages/Partner";

import ReactWhatsapp from "react-whatsapp";
import "bootstrap/dist/css/bootstrap.min.css";
import SvgComponent from "./components/Pages/SvgComponent";
import { Toaster } from "react-hot-toast";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import '@fortawesome/fontawesome-free/css/all.min.css';

// import SubHeaderNav from "./components/Pages/SubHeaderNav";

// import "bootstrap/dist/css/bootstrap.min.css";

function Header() {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if the user has previously denied geolocation access
    const userDenied = localStorage.getItem("userCountry") === "User Denied";
  
    if (userDenied) {
      setError("User has previously denied geolocation access");
      return;
    }
  
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser");
      return;
    }
  
    const successHandler = async (position) => {
      const { latitude, longitude } = position.coords;
      try {
        const response = await fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
  
        if (response.status === 200) {
          const data = await response.json();
          setLocation(data.countryName);
          localStorage.setItem("userCountry", data.countryName);
        } else {
          setError(`Error fetching location data: ${response.statusText}`);
        }
      } catch (error) {
        setError(`Error fetching location data: ${error.message}`);
      }
    };
  
    const errorHandler = (error) => {
      setError(`Error accessing geolocation: ${error.message}`);
      localStorage.setItem("userCountry", "User Denied");
    };
  
    navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
  }, []);
  
  
  return (
    <>
      <Toaster position="top-right" />
      {/* <SvgComponent /> */}
      <ScrollUpButton
        StopPosition={0}
        ShowAtPosition={150}
        EasingType="easeOutCubic"
        AnimationDuration={500}
        ContainerClassName="ScrollUpButton__Container"
        TransitionClassName="ScrollUpButton__Toggled"
      />
      {/* <Popup/> */}
      <Navbar />
      {/* <SubHeaderNav/> */}
      {/* <br></br> */}
      {/* <FeedbackForm /> */}
      <center>
        <h5>picTOURnic - Travel Partner with</h5>
      </center>
      <div
        className="container-fluid"
        style={{ boxShadow: "none", backgroundColor: "#feffec" }}
      >
        <div className="row text-center">
          <Partner />
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "0",
          right: "0",
          display: "flex",
          flexDirection: "column", // Updated to column
          alignItems: "flex-end", // Align items to the end horizontally
          zIndex: "1020",
        }}
      >
        <div
          className="bottomright"
          style={{
            display: "flex",
            flexDirection: "column", // Updated to column
            alignItems: "flex-end", // Align items to the end horizontally
            marginBottom: "10px",
          }}
        >
          <ReactWhatsapp
          id="fixed-contact-tags"
            style={{
              backgroundColor: "#3cb371",
              color: "white",
              padding: "7px",
              borderRadius: "20px",
              border: "none",
              fontSize: "15px",
              marginBottom: "10px", // Margin bottom to separate the buttons
            }}
            number="+919663803335"
            message="Hi picTOURnic!!!"
          >
            <i className="fab fa-whatsapp" style={{ fontSize: "20px" }}></i>{" "}
            Chat With US!
          </ReactWhatsapp>
          <ReactWhatsapp
          id="fixed-contact-tags"
            style={{
              backgroundColor: "brown",
              color: "white",
              padding: "7px",
              borderRadius: "20px",
              border: "none",
              fontSize: "15px",
            }}
            number="+919606022991"
            message="Hi Plan My Trip!!!"
          >
            <i className="fab fa-whatsapp" style={{ fontSize: "20px" }}></i>{" "}
            Package planner!
          </ReactWhatsapp>
        </div>
      </div>

      {/* <SvgComponent /> */}
      <Footer />
      {/* <Introduction />  */}
      {/* <IntroAnswer/> */}
      {/* <CardDecks /> */}
      {/* <ImageSlider slides={SliderData} />   */}
      {/* <img src={wavfile} alt="wavdd" style={{"width":"100%"}} ></img>*/}
      {/* </div> */}
    </>
  );
}

export default Header;
