import React from 'react'
import BookPage from './BookPage'
import { withRouter } from "react-router-dom";
function BookPageDetails() {
    return (
        // <div>
            <>
            <div className='container-fluid'>
            <BookPage />
            </div>
            </>
        // </div>
    )
}

export default withRouter(BookPageDetails);
