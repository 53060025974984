import React from "react";
import "../../../components/css/Routemap.css";
import { FaCanadianMapleLeaf } from "react-icons/fa";
function Routemap({ title, steps }) {
  // console.log("Titele and Steps",title, steps  )
  function toProperCase(sentence) {
    return sentence.replace(/\w\S*/g, function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  }
  return (
    <>
    <div className="landingPackDestination">
      <h2 className="routemap-title">{toProperCase(title)}</h2>
      <div className="routemap-container" style={{ padding: "20px" }}>
        <ul className="step-list">
          {steps.map((step, index) => (
            <li key={index} className="step-item">
              {/* <div className="step-circle"></div> */}
              <FaCanadianMapleLeaf style={{color:"orange"}}/>
              <div className="step-text">{step}</div>
            </li>
          ))}
        </ul>
      </div>
      </div>
    </>
  );
}

export default Routemap;
