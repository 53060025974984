const countryCodes = [
    { code: "+1", country: "United States" },
    { code: "+44", country: "United Kingdom" },
    { code: "+1", country: "Canada" },
    { code: "+61", country: "Australia" },
    { code: "+49", country: "Germany" },
    { code: "+33", country: "France" },
    { code: "+81", country: "Japan" },
    { code: "+86", country: "China" },
    { code: "+91", country: "India" },
    { code: "+7", country: "Russia" },
    { code: "+55", country: "Brazil" },
    { code: "+92", country: "Pakistan" },
    { code: "+880", country: "Bangladesh" },
    { code: "+234", country: "Nigeria" },
    { code: "+62", country: "Indonesia" },
    { code: "+90", country: "Turkey" },
    { code: "+20", country: "Egypt" },
    { code: "+27", country: "South Africa" },
    { code: "+82", country: "South Korea" },
    { code: "+39", country: "Italy" },
    { code: "+34", country: "Spain" },
    { code: "+86", country: "China" },
    { code: "+81", country: "Japan" },
    { code: "+92", country: "Pakistan" },
    { code: "+91", country: "India" },
    { code: "+52", country: "Mexico" },
    { code: "+1", country: "Brazil" },
    { code: "+63", country: "Philippines" },
    { code: "+84", country: "Vietnam" },
    { code: "+81", country: "Japan" },
    { code: "+66", country: "Thailand" },
    { code: "+1", country: "Canada" },
    { code: "+62", country: "Indonesia" },
    { code: "+81", country: "Japan" },
    { code: "+55", country: "Brazil" },
    { code: "+234", country: "Nigeria" },
    { code: "+92", country: "Pakistan" },
    { code: "+880", country: "Bangladesh" },
    { code: "+7", country: "Russia" },
    { code: "+86", country: "China" },
    { code: "+20", country: "Egypt" },
    { code: "+27", country: "South Africa" },
    { code: "+82", country: "South Korea" },
    { code: "+90", country: "Turkey" },
    { code: "+39", country: "Italy" },
    { code: "+98", country: "Iran" },
    { code: "+49", country: "Germany" },
    { code: "+33", country: "France" },
    { code: "+44", country: "United Kingdom" },
    { code: "+86", country: "China" },
    { code: "+81", country: "Japan" },
    { code: "+92", country: "Pakistan" },
    { code: "+91", country: "India" },
    { code: "+52", country: "Mexico" },
    { code: "+1", country: "Brazil" },
    { code: "+63", country: "Philippines" },
    { code: "+84", country: "Vietnam" },
    { code: "+81", country: "Japan" },
    { code: "+66", country: "Thailand" },
    { code: "+1", country: "Canada" },
    { code: "+62", country: "Indonesia" },
    { code: "+81", country: "Japan" },
    // Add more country codes as needed
  ];

  export default countryCodes;