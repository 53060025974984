/* eslint-disable */
import Carousel from "react-bootstrap/Carousel";
import "../components/CarouselElastic.css";
import { Animated } from "react-animated-css";
import * as ReactBootstrap from "react-bootstrap";
// import ElasticCircularDivs from "./Pages/ElasticCircularDivs";

import CarouselSlick from "./CarouselSlick";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";
// import mothernature from "../assests/mothernature.jpeg";
// import horse from "../assests/horse.jpeg";
// import fields from "../assests/fields.jpg";
// import agri from '../assests/agri.jpeg'
// import cottage from "../assests/cottages.jpg";
// import treehouse from "../assests/treehouse.jpg";
// import tent from "../assests/tent.jpg";
/*eslint eqeqeq: "off"*/
function CarouselElastic({ options }) {
  if (options == "") {
    // console.log("its empty");
    var data_set = [];
    // console.log(options)
  } else {
    // console.log(options)
    data_set = options;
    // console.log("Its not empty");
    // console.log(data_set);
  }

  return (
    <>
      <div className="container-fluid crousel-wrapper">
        <Carousel indicators={false}>
          {data_set.map((item, index) => {
            return (
              <Carousel.Item
                key={index}
                interval={3000}
                style={{ position: "relative" }}
              >
                <ReactBootstrap.Nav.Link href={item.text}>
                  {/* <Carousel.Caption className="HeadingSet"> */}
                  <div className="d-flex justify-content-center" >
                    <p
                      id="carousel-main-image-text"
                      style={{
                        fontSize: "calc(1.8vw + 20px)",
                        textShadow: "-1px -1px 18px rgba(0, 0, 0, 0.54)",
                        width:'50%'
                      }}
                    >
                      {item.text}
                    </p>
                  </div>
                  {/* </Carousel.Caption> */}
                  <img
                    className="d-block w-100"
                    src={item.carousel_ima}
                    alt="First slide"
                    width="100%"
                  />
                  <Animated
                    animationIn="rotateInUpLeft"
                    isVisible={true}
                  ></Animated>
                </ReactBootstrap.Nav.Link>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>

      {/* <ElasticCircularDivs /> */}
      <CarouselSlick />
    </>
  );
}

export default CarouselElastic;
