import React, { useState } from "react";
import "../DetailPages/Details.css";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import { Rating } from "react-simple-star-rating";
// import {
//   Row,
//   // , CardSubtitle
// } from "reactstrap";
function Title({ options, image, photos, userRating, locationDetails }) {
  const [isOpen, setIsOpen] = useState(false);
  const showGallery = () => {
    setIsOpen(true);
  };
  // const YourComponent = ({ title, imageUrl }) => {
  //   const [isZoomed, setIsZoomed] = useState(false);

  //   const handleZoomClick = () => {
  //     setIsZoomed(!isZoomed);
  //   };
  // };
console.log(photos)
  return (
    <>
      <div className="container-pic">
        <div className="image-container-pic">
          <div className="image-overlay-container-pic">
            <img src={"/" + image} alt="Sample" className="image-pic" />
            <div className="overlay-pic" onClick={showGallery}>
              {/* <p className="overlay-text-pic">{options.trim()}</p> */}
              <div className="bullet-button" onClick={() => setIsOpen(true)}>
                GALLERY
              </div>
            </div>
            <ReactBnbGallery
              show={isOpen}
              photos={photos}
              onClose={() => setIsOpen(false)}
            />
          </div>
        </div>

        <div className="text-container">
          <div>{options.trim()}</div>
          <hr></hr>
          {userRating && (
            <>
              <Rating
                initialValue={userRating}
                transition
                size={30}
                allowHalfIcon
                readonly={true}
                allowHover={false}
              />
              <div className="additional-text1">{userRating} User Ratings</div>
            </>
          )}

          {locationDetails && (
            <div className="additional-text2">{locationDetails}</div>
          )}
        </div>
      </div>
      <br></br>
      {/* <div className="funky-background">
        <div
          className="container"
          style={{ boxShadow: "none" }}
        >
          <div className="row">
            <div className="col-md-7">
              <h2
                style={{
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "relative",
                  top: "50%",
                  transform: "translateY(-50%)",
                  padding: "15px",
                  fontFamily: "sans-serif",
                  color: "white",
                  fontSize: "25px",
                  fontWeight: "600",
                  backgroundColor: "darkcyan",
                }}
              >
                {options.trim()}
              </h2>
            </div>
            <div className="col" style={{ cursor: "pointer" }}>
              <img
                src={"/" + image}
                className="gallery__img"
                alt="Not available"
                onClick={showGallery}
                style={{ width: "100%" }}
              />
              <button className="btn" onClick={() => setIsOpen(true)}>
                <i className="far fa-images"></i>Gallery
              </button>
              <ReactBnbGallery
                show={isOpen}
                photos={photos}
                onClose={() => setIsOpen(false)}
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
// function Title({ options, image, photos }) {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isHovered, setIsHovered] = useState(false);

//   const showGallery = () => {
//     setIsOpen(true);
//   };

//   const handleZoomClick = () => {
//     setIsOpen(true);
//   };

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   return (
//     <div
//       className={`container funky-background ${isHovered ? "zoomed" : ""}`}
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//     >
//       <div className="row">
//         <div className="col-md-7">
//           <h2 className="title">{options.trim()}</h2>
//         </div>
//         <div className="col" style={{ cursor: "pointer" }}>
//           <img
//             src={"/" + image}
//             alt={'package'}
//             className={`image ${isHovered ? "zoom" : ""}`}
//             onClick={handleZoomClick}
//           />
//           <button onClick={handleZoomClick} className="gallery-button">
//             Gallery
//           </button>
//         </div>
//       </div>
//       <ReactBnbGallery
//         show={isOpen}
//         photos={photos}
//         onClose={() => setIsOpen(false)}
//       />
//     </div>
//   );
// }

export default Title;
