import React from "react";
import "../components/css/Aboutus.css";
import { Row, Col, Container } from "reactstrap";
// import Quotes from "../components/Quotes";
// import pictournicintro from "../assests/bonfire.png";
import quotebck2 from "../assests/backgr1.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import abck from "../assests/backgr.jpg";
import Back from "./Back";
// import AboutusVideo from "./Pages/AboutusVideo";
import { Helmet } from "react-helmet";
import "../components/Home.css";
// import { Animated } from "react-animated-css";

function AboutUs() {
  // const stats = [
  //   "Offers picnic and stays truly in nature, not in between concrete walls or in cities.",
  //   "Guide you for a day or weekend picnic, nearby places to visit  during picnic",
  //   "Take you back to good olden days picnic to sit under a tree over  a mat away from the crowd in the lap of nature.",
  //   "Provide you a place in nature to sit, relax and enjoy a day with  family, friends with privacy.",
  //   "Be it a pilgrimage tour or sightseeing tour to waterfalls,  river, national parks or historic places, we provide you a place  to enjoy your rest of the day and have food at a hygiene place  away from the crowd, making your day stress free and relaxing.",
  //   "Stay in Houseboat, Enjoy Shikara, Build mesmerizing memories with Cruise",
  //   "Providing services on Pre-wedding photo shoot in diverse nature",
  //   "Destination wedding in nature: Keeping safe distance from crowd  and getting blessed from mother nature. Making marriage a unique  memorable event with less cost for everyone.",
  //   "Camping with tent stays,Learn Horse riding - bringing dream of many to reality with  affordable cost.",
  //   "Corporate events in nature to make them learn from nature and  get productive.",

  //   "Make you to Celebrate Life in nature, Be an artist to portrait  nature, carry your music instruments to nature and indulge.",
  // ];
  return (
    <>
      <Helmet>
        <title>AboutUs</title>
        <meta
          name="keywords"
          content="Pictournic DPIIT Recognized,Cordelia cruise,Jehan numa, ITC Gurkha, Windflower, Java rain resort, The Leaf, Khyber, Punnamada, Plantation stay, Estate stay,nature ,photography ,naturephotography ,love ,travel ,photooftheday ,instagood ,beautiful ,picoftheday ,photo ,art ,instagram ,naturelovers ,landscape ,like ,follow ,happy ,travelphotography ,sunset ,summer ,life ,ig ,wildlife ,beauty ,instadaily ,sky ,flowers ,photographer,
          family ,love ,friends ,happy ,instagood ,photography ,life ,baby ,familytime ,photooftheday ,fun ,like ,instagram ,kids ,follow ,cute ,beautiful ,smile ,nature ,travel ,picoftheday ,summer ,fashion ,happiness ,art ,food ,lifestyle ,momlife ,home,
          travel,guesthouse ,beach stays ,travel ,hotel ,penginapan ,penginapanmurah ,homestayjogja ,villa  ,holiday ,nature ,vacation ,hotelmurah ,homestays ,hoteljogja ,cuticutimalaysia ,malaysia ,staycation
          "
        />
      </Helmet>
     <Back />
      {/* <AboutusVideo /> */}
      <div>
        <link
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
          rel="stylesheet"
        />
        <br></br>
        
      <div className="container" style={{ boxShadow: "none" }}>
        
        
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="section-title">
              <h2>Welcome to Our Story</h2>
              <p>
                picTOURnic is a dream project from a group of nature lovers to
                connect people with nature, feel nature and learn from nature.
                Our team makes this journey of picnic an endless source of
                inspiration. Our concept is unique and the most distinctive way
                to connect with nature.
              </p>
            </div>
          </div>
        </div>

        <section className="section services-section" id="services">
          <div className="container" style={{ boxShadow: "none" }}>
            <div className="row">
              <div className="col-sm-6 col-lg-4">
                <div className="feature-box-1">
                  <div className="icon">
                    <i className="fas fa-luggage-cart"></i>
                  </div>
                  <div className="feature-content">
                    <h5>Comprehensive Travel Hub</h5>
                    <p>
                      Explore & Discover a world of diverse travel possibilities
                      under one umbrella. From luxurious stays to thrilling
                      cruises, curated packages, and unforgettable domestic or
                      international tours – we cater to every wanderlust.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4">
                <div className="feature-box-1">
                  <div className="icon">
                    <i className="fas fa-plane"></i>
                  </div>
                  <div className="feature-content">
                    <h5>Domestic and International Tours</h5>
                    <p>
                      Crafting seamless domestic and international tours. Your
                      dedicated travel partner for diverse and memorable
                      experiences. Explore with confidence and joy. Create
                      lasting moments and cherish the journey.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4">
                <div className="feature-box-1">
                  <div className="icon">
                    <i className="fas fa-users-cog"></i>
                  </div>
                  <div className="feature-content">
                    <h5>Customized Packages</h5>
                    <p>
                      Crafting tailored packages to meet diverse user needs. Our
                      curated offerings blend comfort, adventure, and
                      affordability, ensuring a personalized experience. Your
                      journey, your way – because every traveler is unique.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4">
                <div className="feature-box-1">
                  <div className="icon">
                    <i className="fa fa-comment"></i>
                  </div>
                  <div className="feature-content">
                    <h5>Make it Simple</h5>
                    <p>
                      Simplify your travel desires with ease. Our user-friendly
                      approach ensures a seamless experience. From booking to
                      exploring, we make it effortless for you to fulfill all
                      your travel needs effortlessly.Hassle-free travel with us.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4">
                <div className="feature-box-1">
                  <div className="icon">
                    <i className="fas fa-arrows-alt"></i>
                  </div>
                  <div className="feature-content">
                    <h5>Versatile</h5>
                    <p>
                      Indulge in a tapestry of travel experiences. From iconic
                      landmarks to hidden gems, our diverse array of tours
                      promises enriching adventures. We cater to your wanderlust
                      with unparalleled tours as we bring your dreams to life.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4">
                <div className="feature-box-1">
                  <div className="icon">
                    <i className="fas fa-motorcycle"></i>
                  </div>
                  <div className="feature-content">
                    <h5>Bike Expeditions</h5>
                    <p>
                      Embark on thrilling bike journeys with us. Our curated
                      bike trips blend adrenaline and exploration. From scenic
                      landscapes to thrilling routes, we provide an
                      unforgettable two-wheeled adventure, catering to all
                      enthusiasts
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4">
                <div className="feature-box-1">
                  <div className="icon">
                    <i className="fas fa-gem"></i>
                  </div>
                  <div className="feature-content">
                    <h5>Enclaves</h5>
                    <p>
                      Discover nature's hidden gems with us. Our curated
                      experiences unveil secluded wonders, from hidden
                      waterfalls to pristine forests. Immerse yourself in the
                      serenity of untouched landscapes, revealing the Earth's
                      secrets.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4">
                <div className="feature-box-1">
                  <div className="icon">
                    <i className="fas fa-phone-alt"></i>
                  </div>
                  <div className="feature-content">
                    <h5>Travel Care</h5>
                    <p>
                      Customer support, ensuring a seamless
                      journey from start to finish. Our dedicated team is
                      committed to assisting you at every step, making your trip
                      worry-free, memorable, and extraordinary.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Container
        style={{
          height: "auto",
          color: "white",
          marginTop: "5px",
          marginBottom: "10px",
          boxShadow: "none",
        }}
      >
        <div style={{ borderRadius: "10px" }}>
          <Row>
            <Col sm={12}>
              <blockquote
                style={{
                  textAlign: "center",
                  color: "black",
                  fontFamily: "sans-serif",
                  padding: "10px",
                  fontSize: "xx-large",
                }}
              >
                <span style={{borderLeft:"10px solid orange", paddingLeft:"10px", fontSize:"60px", color:"black"}}>S</span>tandout feature of our business?
                <br />{" "}
                <span style={{ color: "#bd2130", fontFamily: "fantasy" }}>
                  The Honesty
                </span>
              </blockquote>
            </Col>
          </Row>
        </div>
      </Container>
        <div className="row align-items-center">
          <div className="col-md-6">
            <img src={quotebck2} alt="tourandpackages" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <div className="section-title">
              <p>
                picTOURnic, tailored for nature enthusiasts, unveils a unique
                farm directory. Delve into day picnics, treehouse stays, farm
                tents, and idyllic spots amid coffee/tea plantations, forests,
                and riverside/bayside havens. Embark on experiential journeys
                featuring horse riding lessons, coupled with culinary delights,
                ensuring unforgettable memories.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="container" style={{ boxShadow: "none" }}>
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="section-title">
              <p>
                picTOURnic is for nature loving travellers. It is a Farm
                directory that allows people to discover and choose places for a
                day picnic, stay in tree houses, Tents in farms, coffee/tea
                plantations, forests, rustic houses near river and beach front
                houses.We have curated places to learn Horse riding along with
                picnic and culinary masterpieces to create a memorable
                experience.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img src={abck} alt="customizedpackages" className="img-fluid" />
          </div>
        </div>
      </div>
      </div>

      
      {/* <Animated animationIn="zoomInUp" isVisible={true}>
        <div className="conatiner-fluid">
          <br></br>
          {stats.map((item, index) => (
            <div
              className="container"
              style={{
                textAlign: "left",
                paddingTop: "1px",
                padding: "10px",
                // backgroundColor: "#459bcdba",
                borderRadius: "1px 5px 3px",
                fontFamily: "sans-serif",
                color: "black",
                // boxShadow: "rgba(0, 0, 0, 0.8) 8px 2px 6px 3px",
                boxShadow:
                  index % 2 !== 0
                    ? "rgb(0 0 0 / 14%) -8px -2px 6px 3px"
                    : "rgb(232 93 93 / 28%) 7px -1px 6px 3px",
                // padding:"30px"
              }}
              key={index}
            >
              <p style={{ color: "black", fontWeight: "100" }}>{item}</p>
            </div>
          ))}
          <br></br>
        </div>
      </Animated> */}
    </>
  );
}

export default AboutUs;
