import React, { useState } from "react";
import "../../components/css/Collapsible.css"; // Import your CSS file for styling
import collapsibleData from "../../components/Pages/CollapsibleData .json";
import ReactWhatsapp from "react-whatsapp";

const Section = ({ title, content, images }) => (
  <div className="section">
    <h5>{title}</h5>
    {images &&
      images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`${title}-${index}`}
          className="responsive-image"
        />
      ))}
      <br></br>
      <br></br>
    <p>{content}</p>
  </div>
);

const NestedCollapsible = ({ data, onMainCollapse }) => {
  const [isNestedCollapsed, setIsNestedCollapsed] = useState(false);

  const handleToggleNestedCollapse = () => {
    setIsNestedCollapsed(!isNestedCollapsed);
    // Notify the parent (main) collapsible to collapse
    onMainCollapse();
  };

  return (
    <div className="collapsible">
      <div className="collapsible-header" onClick={handleToggleNestedCollapse}>
        <h4>{data.title}</h4>
        <hr></hr>
        <div className={`arrow ${isNestedCollapsed ? "" : "open"}`}>
          &#9660;
        </div>
      </div>
      <div
        className={`collapsible-content ${
          isNestedCollapsed ? "collapsed" : ""
        }`}
      >
        <hr></hr>
        {data.sections &&
          data.sections.map((section, index) => (
            <Section key={index} {...section} />
          ))}
        {data.nestedCollapsibles &&
          data.nestedCollapsibles.map((nestedData, index) => (
            <NestedCollapsible
              key={index}
              data={nestedData}
              onMainCollapse={onMainCollapse}
            />
          ))}
        {/* Add another level of nesting if needed */}
        {data.additionalNestedCollapsibles &&
          data.additionalNestedCollapsibles.map(
            (additionalNestedData, index) => (
              <NestedCollapsible
                key={index}
                data={additionalNestedData}
                onMainCollapse={onMainCollapse}
              />
            )
          )}
      </div>
      <br></br>
      
    </div>
  );
};
const CollapsibleDivv = ({ collapsiblesData }) => {
  // Determine the index of the main collapsible you want to be initially expanded
  const initialExpandedIndex = 0;

  // Initialize the state with the index of the main collapsible
  const [collapsedIndices, setCollapsedIndices] = useState([
    initialExpandedIndex,
  ]);

  const handleToggleCollapse = (index) => {
    setCollapsedIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  const handleMainCollapse = () => {
    // You can add any additional logic here if needed
  };

  return (
    <>
      <br />
      <div className="collapsible-container">
        {collapsiblesData.mainCollapsibles.map((mainCollapsible, index) => (
          <div key={index} className="collapsible">
            <div
              className="collapsible-header"
              onClick={() => handleToggleCollapse(index)}
            >
              <h3>{mainCollapsible.side}</h3>
              <div
                className={`arrow ${
                  collapsedIndices.includes(index) ? "open" : ""
                }`}
              >
                &#9660;
              </div>
            </div>
            <div
              className={`collapsible-content ${
                collapsedIndices.includes(index) ? "collapsed" : ""
              }`}
            >
              <NestedCollapsible
                data={mainCollapsible}
                onMainCollapse={handleMainCollapse}
              />
            </div>
          </div>
          
        ))}
      </div>
      <ReactWhatsapp
        style={{
          backgroundColor: "#3cb371",
          color: "white",
          padding: "7px",
          borderRadius: "20px",
          border: "none",
          fontSize: "18px",
          margin:"10px",
        }}
        number="+919663803335"
        message="Hi picTOURnic!!More Packages!"
      >
        <i className="fab fa-whatsapp" style={{ fontSize: "25px" }}></i> Contact
        Us for More Packages
      </ReactWhatsapp>
      <br></br>
    </>
  );
};

const CollapsibleDiv = () => {
  return <CollapsibleDivv collapsiblesData={collapsibleData} />;
};
export default CollapsibleDiv;
