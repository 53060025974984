import React from "react";
import '../Pages/Pagination.css'
function Pagination({ postsPerPage, totalPosts, paginate }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    
    <div className="container-fluid">       
      
        <ul className="pagination justify-content-center">
          {pageNumbers.map((number) => (
            <li key={number} className="page-item">
              {/* <button
                onClick={() => {
                  window.scroll({ top: 0, behavior: "smooth" });
                  paginate(number);
                }}
                className="page-link"
              >
                {number}
              </button> */}
            </li>
          ))}
        </ul>
    </div>
      
  );
}

export default Pagination;
