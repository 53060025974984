import React from "react";
import emailjs from "emailjs-com";
import "../components/DetailRequestForm.css";
import "./ContactUs.css";
require('dotenv').config();

export default function ContactusForm() {
  function sendEmail(e) {
    e.preventDefault();
    // console.log(e.target.checkin.value)
    // console.log(e.target.username.value)
    // console.log(e.target.checkout.value)
    // console.log(e.target.phonenumber.value)
    // console.log(e.target.altphonenumber.value)
    // console.log(e.target.email.value)
    // console.log(e.target.adults.value)
    // console.log(e.target.children.value)
    // console.log(e.target.rooms.value)
    // console.log(e.target.foodprefrence.value)
    // console.log(e.target.mealplan.value)
    // console.log(e.target.message.value)
    // console.log(e.target.destination.value)

    if (e.target.checkin.value <= e.target.checkout.value) {
      // console.log("This form will be submitted");

      emailjs
        .sendForm(
          process.env.REACT_APP_MAIL_SERVICE,
          process.env.REACT_APP_MAIL_TEMPLATE,
          e.target,
          process.env.REACT_APP_MAIL_USER
        )
        .then(
          (result) => {
            // console.log(result.text);
            if (result.text === "OK") {
              alert(
                "Your mail has been Sent Succesfully . Our Executive will get in touch soon related to your query."
              );
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    } else {
      alert(
        "Check-Out Date cannot be earlier than Check-In date. Requesting you to Please update dates. Thanks!"
      );
    }
  }

  return (
    <div className="container" style={{ boxShadow: "none" }}>

        {/* <div
          className="container"
          style={{
            backgroundColor: "rgb(212 225 239",
            borderRadius: "10px",
            boxSizing: "content-box",
            boxShadow: "none"
          }}
        > */}
          <p className="right" >
            <span className="color">* </span>Feilds are Mandatary!
          </p>
          <form className="contact-form" onSubmit={sendEmail}>
            <div className="row">
              <div className="col-lg-6 font_size">
                <div className="mb-3 font_size" style={{textAlign:"left"}}>
                  <strong>
                    {" "}
                    <label>
                      <span className="color">* </span>Check-In Date :
                    </label>
                  </strong>
                  <input
                    type="Date"
                    style={{fontSize: "18px"}}
                    name="checkin"
                    className="form-control"
                    required
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div className="mb-3" style={{textAlign:"left"}}>
                  <strong>
                    <label >Location :</label>
                  </strong>
                  <input
                  style={{fontSize: "18px"}}
                    type="text"
                    className="form-control"
                    name="destination"
                  />
                </div>

                <div className="mb-3"  style={{textAlign:"left"}}>
                  <strong>
                    <label>
                      <span className="color">* </span>Phone number :
                    </label>
                  </strong>
                  {/* <input type="number"  name="" className="form-control" placeholder="Enter Contact Detail" required onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() } />	 */}
                  <input
                    type="text"
                    style={{fontSize: "18px"}}
                    className="form-control"
                    name="phonenumber"
                    pattern="[0-9]{10}"
                    title="Please enter exactly 10 digits of your phone number"
                    // onKeyDown={(e) =>
                    //   /[+\-.,]$/.test(e.key) && e.preventDefault()
                    // }
                    required
                  />
                </div>

                <div className="mb-3" style={{textAlign:"left"}}>
                  <strong>
                    <label>
                      <span className="color">* </span>Number of adults :
                    </label>
                  </strong>
                  <input
                    type="number"
                    style={{fontSize: "18px"}}
                    className=" form-control"
                    name="adults"
                    required
                    min="0"
                    onKeyDown={(e) =>
                      /[+\-.,]$/.test(e.key) && e.preventDefault()
                    }
                  />
                </div>
                <div className="mb-3" style={{textAlign:"left"}}>
                  <strong>
                    <label>Email :</label>
                  </strong>
                  <input
                  style={{fontSize: "18px"}}
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="user@gmail.com"
                  />
                </div>

                <div className="mb-3" style={{textAlign:"left"}} hidden>
                  <strong>
                    <label>Meal plan :</label>
                  </strong>
                  <select name="mealplan" className="form-control" style={{fontSize: "18px"}}>
                    <option>Please select...</option>
                    <option>Breakfast Only</option>
                    <option>Breakfast & Lunch Only</option>
                    <option>Breakfast & Dinner Only</option>
                    <option>Brakfast, lunch & Dinner</option>
                    <option>No Meal Needed</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6 font_size">
                <div className="mb-3 font_size" style={{textAlign:"left"}}>
                  <strong>
                    {" "}
                    <label>
                      <span className="color">* </span>Check-Out Date :
                    </label>
                  </strong>
                  <input
                    type="Date"
                    
                    style={{fontSize: "18px"}}
                    name="checkout"
                    className="form-control"
                    required
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>

                <div className="mb-3" style={{textAlign:"left"}}>
                  <strong>
                    <label>
                      <span className="color">* </span>Name :
                    </label>
                  </strong>
                  <input
                    type="text"
                    style={{fontSize: "18px"}}
                    name="username"
                    placeholder="Guest Name"
                    className="form-control"
                    onKeyDown={(e) =>
                      /[{"<>':;}|+\-_.*,()!@#$%^&=0-9]$/.test(e.key) &&
                      e.preventDefault()
                    }
                    required
                  />
                </div>
                <div className="mb-3" style={{textAlign:"left"}} hidden>
                  <strong>
                    <label>Alternate number :</label>
                  </strong>
                  <input
                    type="text"
                    style={{fontSize: "18px"}}
                    className="form-control"
                    name="altphonenumber"
                    title="Please enter exactly 10 digits of your alternate number"
                    placeholder="if any (optional)"
                    pattern="[0-9]{10}"
                    // onKeyDown={(e) =>
                    //   /[+\-.,]$/.test(e.key) && e.preventDefault()
                    // }
                  />
                </div>
                <div className="mb-3" style={{textAlign:"left"}}>
                  <strong>
                    <label>Number of Children :</label>
                  </strong>
                  <input
                    type="number"
                    style={{fontSize: "18px"}}
                    name="children"
                    className="form-control"
                    min="0"
                    onKeyDown={(e) =>
                      /[+\-.,]$/.test(e.key) && e.preventDefault()
                    }
                  />
                </div>
                <div className="mb-3" style={{textAlign:"left"}}>
                  <strong>
                    <label>Number of rooms :</label>
                  </strong>
                  <input
                    type="number"
                    name="rooms"
                    style={{fontSize: "18px"}}
                    className="form-control"
                    min="0"
                    onKeyDown={(e) =>
                      /[+\-.,]$/.test(e.key) && e.preventDefault()
                    }
                  />
                </div>
                <div className="mb-3" style={{textAlign:"left"}}>
                  <strong>
                    <label>Food Preference :</label>
                  </strong>
                  <select name="foodprefrence" className="form-control" style={{fontSize: "18px"}}>
                    <option>Please select...</option>
                    <option>Veg</option>
                    <option>Non-Veg</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="container" style={{ boxShadow: "none" }}>
              <strong>
                <label>Message (Optional) : </label>
              </strong>
              <textarea
                className="form-control"
                name="message"
                rows="4"
              ></textarea>
            </div>
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-2">
                <center>
                  <input type="submit" className="btn btn-secondary" name="" />
                </center>
              </div>
              <div className="col-lg-5"></div>
            </div>
          </form>
        {/* </div> */}
      
    </div>
  );
}
