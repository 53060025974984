import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import emailjs from "emailjs-com";
import "../components/PackageInformation.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGa from "react-ga4";
require("dotenv").config();
function PackageInformation() {
  const [show, setShow] = useState(false);
  const [loc, setLoc] = useState("");
  const [travelDate, setTravelDate] = useState("");
  const [guestNumber, setGuestNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [guestName, setGuestName] = useState("");
  const [email, setEmail] = useState("");

  const handleClose = () => {
    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    ReactGa.event({
      category: "Foreign Package Closed ",
      action: "Request Form open and closed",
      label: "User Did not fill the foreign package form",
    });
    setTravelDate("");
    setGuestNumber("");
    setMobile("");
    setGuestName("");
    setEmail("");
    setShow(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(event.target);
    var location = loc;
    var travel_date = travelDate;
    var guest_number = guestNumber;
    var mobile_number = mobile;
    var guest_name = guestName;
    var email_address = email;
    const formdata = {
      destination: "FOREIGN PACKAGE REQUEST" + location,
      checkin: travel_date,
      adults: guest_number,
      phonenumber: mobile_number,
      username: guest_name,
      email: email_address,
    };
    // eslint-disable-next-line
    if (
      // eslint-disable-next-line
      location != "" &&
      // eslint-disable-next-line
      travel_date != "" &&
      // eslint-disable-next-line
      guest_number !== "" &&
      // eslint-disable-next-line
      mobile_number.length == 10 &&
      // eslint-disable-next-line
      guest_name != ""
    ) {
      emailjs
        .send(
          process.env.REACT_APP_MAIL_SERVICE,
          process.env.REACT_APP_MAIL_TEMPLATE,
          formdata,
          process.env.REACT_APP_MAIL_USER
        )
        .then(
          (result) => {
            if (result.text === "OK") {
              ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "Foreign Package RequestRaised ",
                action: mobile_number + " for " + location,
                label: "User Filled and submitted Foreign Package request",
              });
              alert(
                "Your request has been Sent Succesfully . Our Executive will get in touch soon related to your query."
              );
            }
          },
          (error) => {
            ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "ERROR Foreign Request",
                action: mobile_number + " for " + location,
                label: error.text,
              });
            // console.log(error.text);
            //  ERROR HANDLING
          }
        );

      setTravelDate("");
      setGuestNumber("");
      setMobile("");
      setGuestName("");
      setEmail("");
      setShow(false);
    } else {
      alert("please fill Requested Fields");
    }
  };
  // function PackageFor(props) {
  //   // alert(props);

  // }

  return (
    <>
      
      
      <div className="conatiner-fluid" >
        <center>
          <h4 style={{ color: "brown" }}>
            <span style={{ fontSize: "x-large", color: "green" }}>
              International
            </span>{" "}
            curated packages with the concept of "Tour For All".<br></br>Best in
            Class customized tours.{" "}
          </h4>
          
        </center>
      </div>
      
      <Container style={{ boxShadow: "none" }}>
        <Row>
          <Col
            onClick={() => {
              setShow(true);
              ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "Foreign Package Clicked ",
                action: "for Singapore",
                label: "Singapore Option Clicked",
              });
              setLoc("Singapore");
            }}
            xs={4}
          >
            <div className="box1">
              <div className="Imagebox">
                {/* <img src="Singapore.webp"></img> */}
                <LazyLoadImage
                  style={{ objectFit: "cover", width: "100%" }}
                  alt={"Singapore Package"}
                  effect="blur"
                  src="Singapore.webp"
                />
              </div>
              <div className="centered">
                <h4>Singapore</h4>
              </div>
            </div>
          </Col>

          <Col
            xs={4}
            onClick={() => {
              setShow(true);
              ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "Foreign Package Clicked ",
                action: "for Malaysia",
                label: "Malaysia Option Clicked",
              });
              setLoc("Malaysia");
            }}
          >
            <div className="box1">
              <div className="Imagebox">
                {/* <img src="Malaysia.webp"></img> */}
                <LazyLoadImage
                  style={{ objectFit: "cover", width: "100%" }}
                  alt={"Malaysia Package"}
                  effect="blur"
                  src="Malaysia.webp"
                />
              </div>
              <div className="centered">
                <h4>Malaysia</h4>
              </div>
            </div>
          </Col>
          <Col
            xs={4}
            onClick={() => {
              setShow(true);
              ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "Foreign Package Clicked ",
                action: "for Thailand",
                label: "Thailand Option Clicked",
              });
              setLoc("Thailand");
            }}
          >
            <div className="box1">
              <div className="Imagebox">
                {/* <img src="Thailand.webp"></img> */}
                <LazyLoadImage
                  style={{ objectFit: "cover", width: "100%" }}
                  alt={"Thailand Package"}
                  effect="blur"
                  src="Thailand.webp"
                />
              </div>
              <div className="centered">
                <h4>Thailand</h4>
              </div>
            </div>
          </Col>
          
          {/* <Col xs={3}><img src="TeacoffeeplantationStaypicTOURnic.webp"></img></Col> */}
        </Row>
        <br></br>
        <Row>
          
          <Col
            xs={4}
            onClick={() => {
              setShow(true);
              ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "Foreign Package Clicked ",
                action: "United Arab Emirates",
                label: "UAE Option Clicked",
              });
              setLoc("United Arab Emirates");
            }}
          >
            <div className="box1">
              <div className="Imagebox">
                {/* <img src="Dubai.webp"></img> */}
                <LazyLoadImage
                  style={{ objectFit: "cover", width: "100%" }}
                  alt={"Dubai Package"}
                  effect="blur"
                  src="Dubai.webp"
                />
              </div>
              <div className="centered">
                <h4>UAE</h4>
              </div>
            </div>
          </Col>
          <Col
            xs={4}
            onClick={() => {
              setShow(true);
              ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "Foreign Package Clicked ",
                action: "for Indonesia",
                label: "Indonesia Option Clicked",
              });
              setLoc("Indonesia");
            }}
          >
            <div className="box1">
              <div className="Imagebox">
                {/* <img src="Indonesia.webp"></img> */}
                <LazyLoadImage
                  style={{ objectFit: "cover", width: "100%" }}
                  alt={"Indonesia Package"}
                  effect="blur"
                  src="Indonesia.webp"
                />
              </div>
              <div className="centered">
                <h4>Indonesia</h4>
              </div>
            </div>
          </Col>
          
          <Col
            xs={4}
            onClick={() => {
              setShow(true);
              ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "Foreign Package Clicked ",
                action: "Greece",
                label: "Greece Option Clicked",
              });
              setLoc("Greece");
            }}
          >
            <div className="box1">
              <div className="Imagebox">
                {/* <img src="Greece.webp"></img> */}
                <LazyLoadImage
                  style={{ objectFit: "cover", width: "100%" }}
                  alt={"Greece Package"}
                  effect="blur"
                  src="Greece.webp"
                />
              </div>
              <div className="centered">
                <h4>Greece</h4>
              </div>
            </div>
          </Col>
          {/* <Col xs={3}><img src="TeacoffeeplantationStaypicTOURnic.webp"></img></Col> */}
          
        </Row>
        <br></br>
        <Row>
        <Col
            xs={3}
            onClick={() => {
              setShow(true);
              ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "Foreign Package Clicked ",
                action: "Austraila",
                label: "Austraila Option Clicked",
              });
              setLoc("Austraila");
            }}
          >
            <div className="box1">
              <div className="Imagebox">
                {/* <img src="Austraila.webp"></img> */}
                <LazyLoadImage
                  style={{ objectFit: "cover", width: "100%" }}
                  alt={"Austraila Package"}
                  effect="blur"
                  src="Austraila.webp"
                />
              </div>
              <div className="centered">
                <h4>Australia</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Header closeButton>
            Please Share your details! Our Executive will connect with you ASAP.
          </Modal.Header>
          <br></br>
          <Label style={{ position: "absolute", right: "10%" }}>
            <span style={{ color: "red", position: "relative" }}>
              * Fields are mandatory
            </span>
          </Label>
          <br></br>
          <Form onSubmit={handleSubmit}>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                Your Location
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="location"
                  value={loc}
                  id="location"
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                <span
                  style={{ color: "red", position: "absolute", left: "0%" }}
                >
                  *
                </span>
                Travel Date
              </Label>
              <Col sm={10}>
                <Input
                  type="date"
                  name="date"
                  id="date"
                  placeholder="Enter Travel date"
                  required
                  min={new Date().toISOString().split("T")[0]}
                  value={travelDate}
                  onChange={(e) => {
                    setTravelDate(e.target.value);
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                <span
                  style={{ color: "red", position: "absolute", left: "0%" }}
                >
                  *
                </span>
                Guests#
              </Label>
              <Col sm={10}>
                <Input
                  type="number"
                  name="guests"
                  id="guests"
                  placeholder="Number of guests"
                  required
                  value={guestNumber}
                  onChange={(e) => {
                    setGuestNumber(e.target.value);
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="examplePassword" sm={2}>
                <span
                  style={{ color: "red", position: "absolute", left: "0%" }}
                >
                  *
                </span>{" "}
                Mobile
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="mobile"
                  id="mobile"
                  placeholder="Please enter your 10 digit Mobile number"
                  required
                  pattern="[0-9]{10}"
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                <span
                  style={{ color: "red", position: "absolute", left: "0%" }}
                >
                  *
                </span>
                Guest Name
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="guestname"
                  id="guestname"
                  placeholder="Guest Name"
                  required
                  value={guestName}
                  onChange={(e) => {
                    setGuestName(e.target.value);
                  }}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                Email
              </Label>
              <Col sm={10}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="user@gmail.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
            </FormGroup>

            {/* <FormGroup check row> */}
            <Col sm={{ size: 10, offset: 2 }}>
              {/* <Button
                style={{ margin: "2px", backgroundColor: "mediumseagreen" ,border:"none"}}
                onClick={handleSubmit}
              >
                Submit
              </Button> */}
              <input
                style={{
                  margin: "2px",
                  backgroundColor: "mediumseagreen",
                  fontWeight: "400",
                  border: "none",
                  color: "white",
                  borderRadius: "0.25rem",
                  lineHeight: "1.5",
                  fontSize: "1rem",
                  padding: "0.375rem 0.75rem",
                }}
                type="submit"
              ></input>
              <Button
                style={{
                  margin: "2px",
                  backgroundColor: "red",
                  border: "none",
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Col>
            {/* </FormGroup> */}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PackageInformation;
