/* eslint-disable */
import React from "react";
import { withRouter } from "react-router-dom";
import PropertiesDetails from "./PropertiesDetails";
import PropertiesList from './PropertiesList';



var SelectPage = (props) => {
    var parameter_decision = props.match.params.id;
    if (parameter_decision== parseInt(parameter_decision, 10)){
        return <PropertiesDetails/>
    }
    else{

        return (
             <PropertiesList/>
             )
    }
    // console.log(props)
}

export default withRouter(SelectPage);
