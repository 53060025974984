import React from "react";
import { useState, useEffect } from "react";

import "../../components/Pages/SearchFilter.css";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "../../axios";
import Throbber from "../Pages/Throbber";
import Back from "../Back";

require("dotenv").config();

// const handleOnSearch = (string, results) => {
//   // onSearch will have as the first callback parameter
//   // the string searched and for the second the results.
//   // console.log(string, results, "abccc");
//   alert(string);
// };

// const handleOnHover = (result) => {
//   // the item hovered
//   console.log(result);
// };

const handleOnSelect = (item) => {
  // the item selected
  // console.log(item);
  // alert(item.name);
  window.location.href = "/filteredlocation/" + item.name;
};

// const handleOnFocus = () => {
//   console.log("Focused");
// };
const formatResult = (item) => {
  return (
    <>
      {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
      <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
    </>
  );
};

function SearchFilter() {
  // eslint-disable-next-line
  const [capturedSearch, setCapturedSearch] = useState(null);

  // Being used for fetching data for the home page .
  //Here the main Categories will be extracted from backend server
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(
          process.env.REACT_APP_DB_URL + "/getloc/getsubloc/search"
        );
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // const normarray = ;
        // console.log()
        setItems(req.data);
        setLoading(true);
        // console.log("The data set here is ", req.data)
      } catch (error) {
        // console.log('Error is')
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <br></br>
          <div
            className="container"
            style={{
              boxShadow: "none",
              backgroundColor: "#2a2e2f21",
              borderRadius: "15px",
            }}
          >
            <br></br>
            <br></br>
            <center>
              <h3>
                IF YOU DIDN'T GET YOUR TOUR LOCATION BELOW<br></br>{" "}
              </h3>
              <br></br>

              {/* <button type="button" className="btn btn-info">
            Arrange Location!
          </button> */}

              <h6>
                Please Chat directly on Whats App with Us and Share details! .
                We will arrange it for you.<br></br>
                <a
                  target="blank"
                  href="https://api.whatsapp.com/send/?phone=+919663803335&text=Hi+picTOURnic+Arrange+Location%21%21%21&type=phone_number&app_absent=0"
                >
                  Click Here to Chat with Us!
                </a>{" "}
              </h6>
              <hr></hr>
              <br></br>

              {/* <div className="App"> */}
              {/* <header className="App-header"> */}
              <div style={{ width: "auto", padding: "10px" }}>
                <ReactSearchAutocomplete
                  items={items}
                  // onSearch={handleOnSearch}
                  // onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  // onFocus={handleOnFocus}
                  autoFocus
                  formatResult={formatResult}
                  maxResults={5}
                  placeholder={"Search Tour Location"}
                  showNoResultsText={"No Result Found"}
                  styling={{ hoverBackgroundColor: "#4c585c21" }}
                />
                <div className="container" style={{ boxShadow: "none" }}></div>
              </div>
            </center>
            <Back/>
            <br></br>
            <br></br>
          </div>
        </>
      ) : (
        <>
          <Throbber />
        </>
      )}
    </>
  );
}

export default SearchFilter;
