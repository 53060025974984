import React, { useState} from "react";
import "../css/Description.css";
function Description({ options }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const maxLength = 120; // Maximum characters to display initially

  const truncatedOptions =
    options.length > maxLength
      ? options.slice(0, maxLength)
      : options;
  return (
    <>
      {/* <div
        className="container"
        style={{ boxShadow: "none", backgroundColor: "#ee932c14" }}
      >
        <div>
          <h4 style={{ color: "brown" }}>Description</h4>
        </div>
        
        <p style={{fontSize: "large", color:"black", fontWeight:"300"}}>
          {options.trim()}
        </p>
      </div> */}
      <div className={`description-container ${isExpanded ? "expanded" : ""}`}>
      <div>
        <h4 style={{ color: "brown" }}>Description</h4>
      </div>
      <div
        style={{
          position: "relative",
          fontSize: "large",
          color: "black",
          fontWeight: "300",
        }}
      >
        {isExpanded ? options : truncatedOptions}
        {!isExpanded && options.length > maxLength && (
          <>
            {"...... "}
            <button
              className={`badge ${isExpanded ? "expanded" : ""}`}
              onClick={toggleExpand}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#007bff",
                cursor: "pointer",
                padding: "0",
              }}
            >
              {isExpanded ? "See Less" : "See More"}
            </button>
          </>
        )}
      </div>
      {isExpanded && options.length > maxLength && (
        <button
          className={`badge expanded ${isExpanded ? "expanded" : ""}`}
          onClick={toggleExpand}
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "#007bff",
            cursor: "pointer",
            padding: "0",
          }}
        >
          See Less
        </button>
      )}
    </div>
    </>
  );
}

export default Description;
