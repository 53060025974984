import React from "react";

import "./BookPage.css";
// import { useLocation } from "react-router-dom";
// import picnicandtournearby from '../assests/picnic_and_Tour_weekend_Plan_Nearby_place.jpeg'
import Back from "./Back";
import Forbidden403 from "./Forbidden403";
import DetailsRequestForm from "./DetailsRequestForm";
import { Helmet } from "react-helmet";
// import ReactGa from "react-ga4";
import { withRouter } from "react-router-dom";
function BookPage(props) {
  // console.log(props);
  var par = props.match.params.propid;
  // let location = useLocation();

  if (par === undefined) {
    return <Forbidden403 />;
  } else {
    const propid = par;
    return (
      <>
        <Helmet>
          <title>Booking {propid}</title>
        </Helmet>
        <DetailsRequestForm propertyid={propid} />

        {/* <div className="bookingpage"> */}

        <div className="row">
          <div
            className="col-md-4"
            style={{
              backgroundColor: "rgb(255 243 212 / 30%)",
              boxShadow: "0 1.4rem 8rem rgb(102, 34, 34,0.36)",
              marginBottom: "15px",
              marginTop: "15px",
            }}
          >
            <div className="col-md-12">
              <div className="single-box">
                <div className="img-area">
                  <i className="fas fa-phone" style={{ color: "blue" }}></i>
                </div>
                <div className="img-text">
                  <h3 style={{ textAlign: "center" }}>Get In Touch with us</h3>
                  <hr />
                  <div
                    style={{
                      padding: "5px",
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      Call us at 9668303335 or send email to{" "}
                      <a href="mailto:contact@pictournic.com">
                        contact@pictournic.com
                      </a>{" "}
                      or text us through Whatsapp.
                      <br />
                      OR <br />
                      Give us a miss Call on +91-9663803335, our team executive will
                      get back asap to you, requesting your Booking Details
                    </p>
                  </div>
                  {/* <div style={{padding:"5px",marginTop:"5px", border:"1px solid black", borderRadius:"10px"}}>
                                    <p style={{textAlign:"center"}}>Need below Details For Booking</p>
                                    <ul>
                                        <li>Please Share property ID <strong style={{color:"red"}}>{propid }</strong> with our executive</li>
                                        <li>Name</li>
                                        <li>Phone Number</li>
                                        <li>Number of People</li>
                                        <li>Children</li>
                                        <li>Date of Visit</li>
                                    </ul>
                                </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              backgroundColor: "rgb(255 243 212 / 30%)",
              boxShadow: "0 1.4rem 8rem rgb(102, 34, 34,0.36)",
              marginBottom: "15px",
              marginTop: "15px",
            }}
          >
            <div className="col-md-12">
              <div className="single-box">
                <div className="img-area">
                  <i
                    className="far fa-calendar-alt"
                    style={{ color: "grey" }}
                  ></i>
                </div>
                <div className="img-text">
                  <h3 style={{ textAlign: "center" }}>Fixing Calender</h3>

                  <hr />
                  <p style={{ textAlign: "center" }}>
                    Will fix calender for your visit & you will be recieving
                    confirmation of your booking via text message, whats App and
                    mail from our Team Executive.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              backgroundColor: "rgb(255 243 212 / 30%)",
              boxShadow: "0 1.4rem 8rem rgb(102, 34, 34,0.36)",
              marginBottom: "15px",
              marginTop: "15px",
            }}
          >
            <div className="col-md-12">
              <div className="single-box">
                <div className="img-area">
                  <i className="fas fa-rupee-sign"></i>
                </div>
                <div className="img-text">
                  <h3 style={{ textAlign: "center" }}>Payment Details</h3>

                  <hr />
                  <p style={{ textAlign: "center" }}>
                    Our executive will be in touch with you through out the
                    Procedure. A flexible way of payment options will be
                    provided to you such as Netbanking, Google-Pay, Phone-Pay,
                    Paytm,{" "}
                    <span style={{ color: "red" }}>
                     Debit Crads and Credit Cards.
                    </span>
                    <br />
                    You will also have an option to pay at property site as
                    well.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-6 "
            style={{
              backgroundColor: "rgb(255 243 212 / 30%)",
              boxShadow: "0 1.4rem 8rem rgb(102, 34, 34,0.36)",
              marginBottom: "15px",
              marginTop: "15px",
            }}
          >
            <div className="col-md-12">
              <div className="singl-box">
                <div className="img-area">
                  <i className="fas fa-route" style={{ color: "red" }}></i>
                </div>
                <div className="img-text">
                  <h3 style={{ textAlign: "center" }}>Route Map</h3>
                  <hr />
                  <p style={{ textAlign: "center" }}>
                    Our Team Executive will be in Touch with You. After
                    completion of Payment, we will be sharing
                  </p>
                  <br />
                  <br />
                  <ol>
                    <li>
                      Route Map for reaching at Property site without any issue.
                    </li>
                    <li>Food Spots, Hand picked places for you.</li>
                    <li>
                      Near By Properties, where you can visit during your
                      property visit or stay.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6"
            style={{
              backgroundColor: "rgb(255 243 212 / 30%)",
              boxShadow: "0 1.4rem 8rem rgb(102, 34, 34,0.36)",
              marginBottom: "15px",
              marginTop: "15px",
            }}
          >
            <div className="col-md-12">
              <div className="single-box">
                <div className="img-area">
                  <i
                    className="fas fa-hands-helping"
                    style={{ color: "Green" }}
                  ></i>
                </div>
                <div className="img-text">
                  <h3 style={{ textAlign: "center" }}>Share Your Experience</h3>
                  <hr />
                  <p style={{ textAlign: "center" }}>
                    Please Snap and Share your visit experience with us!. <br />
                    You can share your Story, Pictures , Videos and Reviews by
                    writing us at{" "}
                    <a href="mailto:support@pictournic.com">
                      support@pictournic.com
                    </a>{" "}
                    . We will use your Snaps, videos and story on our website.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <Back />
      </>
    );
  }
}

export default withRouter(BookPage);
