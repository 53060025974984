/* eslint-disable */
import React from "react";
import "../../components/css/OneDayTrip.css"


const OneDayTrip = () => {
  
  return (
    <div className="onedaytour-unique-container">
      <div className="onedaytour-section">
        <div className="image-container onedaytour-image-container ">
          <img src={"/wonderela.3.webp"} alt="Image 1" className="image onedaytour-image" />
        </div>
        <div className="image-container onedaytour-image-container">
          <img src={"/wonderela2.webp"} alt="Image 2" className="image onedaytour-image" />
        </div>
        <div className="image-container onedaytour-image-container">
          <img src={"/wonderela1.webp"} alt="Image 3" className="image onedaytour-image" />
        </div>
        <div className="image-container onedaytour-image-container">
          <img src={"/wonderela.webp"} alt="Image 4" className="image onedaytour-image" />
        </div>
        <div className="overlay-textt">
          <h1>Adventure of Wonderela</h1>
          <div>Have a fun weekend with your loved ones</div>
          <a className="onedaytour-book-now-button" target = "_blank" href="https://api.whatsapp.com/send/?phone=+919663803335&text=Hi+picTOURnic+Wonderlla+Query%21%21%21&type=phone_number&app_absent=0">Enquiry</a>
        </div>
      </div>
      <div className="onedaytour-section">
        <div className="image-container onedaytour-image-container">
          <img src={"/jollywood.webp"} alt="Image 5" className="image onedaytour-image" />
        </div>
        <div className="image-container onedaytour-image-container">
          <img src={"/jollywood1.webp"} alt="Image 6" className="image onedaytour-image" />
        </div>
        <div className="image-container onedaytour-image-container">
          <img src={"/jollywood2.webp"} alt="Image 7" className="image onedaytour-image" />
        </div>
        <div className="image-container onedaytour-image-container">
          <img src={"/jollywood3.webp"} alt="Image 8" className="image onedaytour-image" />
        </div>
        <div className="overlay-textt">
          <h1>Joy of Jollywood</h1>
          <div>Have a fun weekend with your loved ones</div>
          {/* <button >Book Now</button> */}
          <a className="onedaytour-book-now-button" target = "_blank" href="https://api.whatsapp.com/send/?phone=+919663803335&text=Hi+picTOURnic+Jollywood+Query%21%21%21&type=phone_number&app_absent=0">Enquiry</a>
        </div>
      </div>
    </div>
  );
};


export default OneDayTrip;
