import "../components/ContactUs.css";
import Back from "./Back";
import ContactusForm from "./ContactusForm";
import { Helmet } from "react-helmet";
import "../components/Home.css";

// import $ from "jquery";
function ContactUs() {
  return (
    <div>
      <Helmet>
        <title>ContactUs</title>
        <meta
          name="keywords"
          content="picnics ,outdoors ,fun ,luxurypicnic ,os ,birthdayparty ,follow ,park ,picnicideas ,wine ,beautiful ,sunset ,weekend ,picnicbasket ,datenight ,relax ,happybirthday ,charcuterie ,naturephotography ,flowers ,instafood ,piquenique ,adventure ,spring ,lunch ,cheese ,fashion ,travelphotography,Stays, Day Picnic, Picnic, Bangalore, Bangalore Picnic,Home Stays, Stays, Forest Stays, Plan Travel nearby, nearby Locations,
          Places near me to Stay, Places near me for Day Picnic, Celebrations, BirthDaty Parties,travelling ,beach ,
          photographer ,style ,sunset ,instadaily ,holiday ,photography ,life ,baby ,familytime ,photooftheday ,fun ,
          like ,instagram ,kids ,follow ,cute ,beautiful ,smile ,nature ,travel ,picoftheday ,summer ,fashion ,happiness ,art ,food ,lifestyle ,momlife ,home
          ,homestay ,guesthouse ,beach stays ,travel ,hotel ,penginapan ,penginapanmurah ,homestayjogja ,villa  ,holiday ,nature ,vacation ,hotelmurah ,homestays ,hoteljogja ,cuticutimalaysia ,malaysia ,staycation
          "
        />
      </Helmet>
      <br></br>
      <h5 style={{ textAlign: "center", color: "brown", marginTop: "5px" , fontSize:"16px"}}>
        Not sure where to go or need help in booking or wanna know more about
        properties?<hr></hr> We are here to help you!! Please Submit your
        Request Below...
        {/* <i
                    style={{ color: "#8b0000", fontSize: "60px" }}
                    className="fas fa-grin-wink"
                  ></i> */}
      </h5>{" "}
      
      {/* <center>
            <div className="banner_div"><div
            className="container-fluid"
            
          >
            
            <span className="stamp is-approved">
              Every booking
              <br></br>gain Reward points
            </span>
            <br></br>
          </div>
          </div></center> */}
      <br></br>
      <Back />
      {/* <div></div> */}
      <ContactusForm />
      <div className="container-fluid">
        <div className="row" style={{ padding: "20px" }}>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <div id="title">
              <h3 style={{fontSize:"18px"}}>Call US</h3>
            </div>
            <div className="phoneIcon">
              <i
                className="fas fa-phone-square-alt"
                style={{ fontSize: "80px", color: "rgb(23, 170, 237)" }}
              ></i>
            </div>
            <hr></hr>
            <div id="phonenumber">
              <h5 style={{fontWeight:"100"}}>+91 - 9663803335</h5>
              <h5 style={{fontWeight:"100"}}>+91 - 9606022990</h5>
              <h5 style={{fontWeight:"100"}}>+91 - 9606022991</h5>
              <h5 style={{fontWeight:"100"}}>+91 - 9606022992</h5>
              <h5 style={{fontWeight:"100"}}>+91 - 9606022993</h5>
            </div>
          </div>

          <div className="col-md-6" style={{ textAlign: "center" }}>
            <div id="title">
              <h3 style={{fontSize:"18px"}}>Chat with Us</h3>
            </div>
            <div className="whatsappIcon">
              <i
                className="fab fa-whatsapp-square"
                style={{ fontSize: "80px", color: "rgb(36, 204, 99)" }}
              ></i>
            </div>
            <hr></hr>
            <div id="whtsappnumber">
              <h5 style={{fontWeight:"100"}}>+91 - 9663803335</h5>
              <h5 style={{fontWeight:"100"}}>+91 - 9606022990</h5>
              <h5 style={{fontWeight:"100"}}>+91 - 9606022991</h5>
              <h5 style={{fontWeight:"100"}}>+91 - 9606022992</h5>
              <h5 style={{fontWeight:"100"}}>+91 - 9606022993</h5>
            </div>
          </div>
        </div>

        <div className="row" style={{ padding: "40px" }}>
          <div className="col-md-4" style={{ textAlign: "center" }}>
            <div id="title">
              <h3 style={{fontSize:"18px"}}>For Booking</h3>
            </div>
            <div className="mailIcon">
              <i
                className="fas fa-envelope-square"
                style={{ fontSize: "80px", color: "rgb(212, 72, 58)" }}
              ></i>
            </div>
            <hr></hr>
            <div>
              <h5 style={{fontWeight:"100"}}>booking@pictournic.com</h5>
            </div>
          </div>
          <div className="col-md-4" style={{ textAlign: "center" }}>
            <div id="title">
              <h3 style={{fontSize:"18px"}}>For Property Owners</h3>
            </div>
            <div className="mailIcon">
              <i
                className="fas fa-envelope-square"
                style={{ fontSize: "80px", color: "rgb(212, 72, 58)" }}
              ></i>
            </div>
            <hr></hr>
            <div>
              <h5 style={{fontWeight:"100"}}>contact@pictournic.com</h5>
            </div>
          </div>

          <div className="col-md-4" style={{ textAlign: "center" }}>
            <div id="title">
              <h3 style={{fontSize:"18px"}}>Share Travel Experience</h3>
            </div>
            <div className="mailIcon">
              <i
                className="fas fa-envelope-square"
                style={{ fontSize: "80px", color: "rgb(212, 72, 58)" }}
              ></i>
            </div>
            <hr></hr>
            <div>
              <h5 style={{fontWeight:"100"}}>support@pictournic.com</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
