import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import axios from "../../axios";
import "../css/Partners.css"

function Partner() {
  const [mainPageOptions, setmainPageOptions] = useState([]);
  useEffect(() => {
    async function fetchPartnersData() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(process.env.REACT_APP_DB_URL+ "/extract/propidinformation/result/partners");
        // console.log(req.data);
        setmainPageOptions(req.data);

      } catch (error) {
        // console.log('Error is')
        console.error(error);
      }
    }
    fetchPartnersData();
  }, []);


  return (
    <>
      <div className="container" style={{ boxShadow: "none" }}></div>
      <br></br>
      <div className="container-fluid"></div>
      {mainPageOptions.map((everypartner, index) => (
        <a
          href={"/extract/propidinformation/result/partners/" + everypartner.partner}
          style={{margin:"0px", textDecoration: "none"}}
          key={index}
        >
          <Card
            key={index}
            style={{
              padding: "1px",
              width: "120px",
              height: "120px",
              margin: "10px",
            }}
          >
            <img
              src={"/" + everypartner.image}
              style={{ borderRadius: "10px" }}
              alt={everypartner.partner}
              
            ></img>
          </Card>
        </a>
      ))}
    </>
  );
}

export default Partner;
