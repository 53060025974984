import React from "react";
// import { Row } from "reactstrap";
import startupindia from "../assests/DPIIT_Recognized_picTOURnic.webp";
import "../components/css/footer.css";
import {
  // FaPhone,
  FaEnvelope,
  FaComments,
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaWhatsapp,
  FaTree,
  FaMountain,
  FaHome,
  FaCampground,
  FaWater,
  FaLeaf,
  FaShip,
  FaGem,
  FaMapMarker,
  FaHiking,
  FaBriefcase,
  FaBirthdayCake,
  FaSun,
  FaUsers,
  FaUmbrellaBeach,
} from "react-icons/fa";
import { GrPhone } from "react-icons/gr";

const Footer = () => {
  const services = [
    { name: "Nature Stays", icon: FaTree, reflin: "Nature Stays" },
    { name: "Beach Stays", icon: FaUmbrellaBeach, reflin: "Beach Stays" },
    { name: "Tree Houses", icon: FaHome, reflin: "Tree Houses" },
    { name: "Hill Top", icon: FaMountain, reflin: "Hill top" },
    { name: "Tent Stays", icon: FaCampground, reflin: "Tent Stays" },
    {
      name: "River Front Stays",
      icon: FaWater,
      reflin: "River Front or Lake Stays",
    },
    { name: "Lake Stays", icon: FaWater, reflin: "River Front or Lake Stays" },
    { name: "Group Stay", icon: FaUsers, reflin: "Group Stay" },
    { name: "Forest Stay", icon: FaLeaf, reflin: "Forest Stay" },
    { name: "Houseboat/Cruise", icon: FaShip, reflin: "Houseboat or Cruise" },
    { name: "Luxury", icon: FaGem, reflin: "Luxury" },
    {
      name: "Tea Plantation",
      icon: FaLeaf,
      reflin: "Tea coffee Spice Plantation Stay",
    },
    { name: "Day Picnic", icon: FaSun, reflin: "Day Picnic" },
    { name: "Adventure", icon: FaHiking, reflin: "Adventure" },
    { name: "Workation", icon: FaBriefcase, reflin: "Workation" },
    { name: "Celebrations", icon: FaBirthdayCake, reflin: "Celebrations" },
    {
      name: "Package Tour",
      icon: FaMapMarker,
      reflin: "package%20tour",
    },
    {
      name: "Bike Trip",
      icon: FaMapMarker,
      reflin: "roadtrip/rides",
    },
  ];

  const columnCount = 3;
  const columnSize = Math.ceil(services.length / columnCount);
  const serviceColumns = [];
  for (let i = 0; i < columnCount; i++) {
    serviceColumns.push(services.slice(i * columnSize, (i + 1) * columnSize));
  }

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="StartupLogoDefined">
          <img src={startupindia} alt="start up india pictournic" />
        </div>
        {/* <section className="picTOURnic-section">
          <div className="container" style={{ boxShadow: "none" }}>
            <p className="footer-quote">
              "Indulge in a serene nature retreat, far from the hustle and bustle.
              Immerse yourself in tranquil surroundings, surrounded by lush
              landscapes and soothing sounds. Reconnect with the essence of nature
              on our refreshing tour, designed to invigorate your mind and spirit.
              Join us for an unforgettable journey of relaxation and renewal."
            </p>
          </div>
        </section> */}
      </div>

      <div className="container_">
        <div className="row d-flex justify-content-around pt-5">
          <div className="col-12 text-center">
            <h5 className="footer-heading">Explore</h5>
          </div>
          {serviceColumns.map((column, index) => (
            <div key={index} className="footer-column">
              <ul className="footer-list">
                {column.map((service, index) => (
                  <a
                    tabIndex="0"
                    href={"/" + service.reflin}
                    role="button"
                    key={index}
                  >
                    <li key={index}>
                      <service.icon /> &nbsp; {service.name}
                    </li>
                  </a>
                ))}
              </ul>
            </div>
          ))}
          <div className="footer-column">
            <ul className="footer-list">
              <a href="/contactus">
                <li>
                  <GrPhone />
                  &nbsp; Contacts
                </li>
              </a>
              <a href="/contactus">
                <li>
                  <FaEnvelope />
                  &nbsp; Email Us
                </li>
              </a>
              <a
                target="blank"
                href="https://api.whatsapp.com/send/?phone=+919663803335&text=Hi+picTOURnic+Live+Chat!%21%21%21&type=phone_number&app_absent=0"
              >
                <li>
                  <FaComments />
                  &nbsp; Live Chat
                </li>
              </a>
            </ul>
            <span className="d-flex social-icons social-icon-container">
            {/* <Row xs="1" sm="2" md="3" lg="4"> */}
              <a href="https://www.facebook.com/" rel="noreferrer" target="_blank">
                <FaFacebook />
              </a>
              <a href="https://www.instagram.com/" rel="noreferrer" target="_blank">
                <FaInstagram />
              </a>
              <a href="https://www.youtube.com/" rel="noreferrer" target="_blank">
                <FaYoutube />
              </a>
              <a href="https://www.pinterest.com/" rel="noreferrer" target="_blank">
                <FaWhatsapp />
              </a>
            {/* </Row> */}
            </span>
            <br />
            Payment Methods
            <div className="d-flex justify-content-left ">
              <img src="/mastercard.svg" className="mx-2" alt="masterCard debit card credit card accepted"/>
              <img src="/visa.svg" className="mx-2" alt="masterCard debit card credit card accepted"/>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />

      <div className={`wave`}></div>
      <div className={`wave`}></div>
      <div className={`wave`}></div>
      <p className="text-center copyright-tag">
        &copy; 2021-2024 Pictournic. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
