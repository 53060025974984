import React from "react";
import ReactGa from "react-ga4";
import { withRouter } from "react-router-dom";
import "../components/css/Book.css"
require("dotenv").config();
// import Aos from 'aos';
// import "aos/dist/aos.css"

function Book({ propertyid }) {
  function handleBookingEvent(e) {
    e.preventDefault();

    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    ReactGa.event({
      category: "Book Now button Clicked " + propertyid,
      action: "Booking Page Access to user",
      label: "User Must Fill in the form to proceed for Booking",
      value: propertyid,
    });
    direct();
  }

  function direct() {
    window.location.href = "/Booking/" + propertyid;
  }

  return (
    // <div className="container-fluid">
    //   <div style={{ textAlign: "center", margin: "30px" }}>
    //     <button className="blog-post__cta" onClick={handleBookingEvent}>
    //       Availability Enquiry
    //     </button>
    //   </div>
    // </div>
    <div className="container-fluid">
      <div style={{ textAlign: "center", margin: "30px" }}>
        <button className="book_button" onClick={handleBookingEvent}>
          Availability Enquiry
          <span className="button__effect"></span>
        </button>
      </div>
    </div>
  );
}

export default withRouter(Book);
