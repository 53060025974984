import React from 'react'
import "../css/Facilities.css";
function Timing({options}) {
  
  return (
    <>
    {/* <div className='container' style={{boxShadow:"none", backgroundColor:"#ee932c14"}}>
        <h4 style={{color:"brown"}}>Timings</h4>
        {options.map((items,index)=>(
          <p key={index} style={{fontSize:"large", color:"Black", fontWeight:"300"}}><i className="fas fa-stopwatch" style={{color:"green", margin:"5px"}}></i>{items}</p>
        ))}
        
    </div> */}
    <div className={'description-container'}>
        <div>
          <h4 style={{ color: "brown" }}>Timings</h4>
        </div>

        <div className="option">
          <i
            className="fas fa-stopwatch"
            style={{ color: "black", margin: "5px"  , display: "inline-block", }}
          ></i>
          <div
            style={{
              fontSize: "large",
              color: "black",
              fontWeight: "300",   display: "inline-block",
            }}
          >
            {options}
          </div>
        </div>
      </div>
    </>
  )
}

export default Timing