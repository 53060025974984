/* eslint-disable */
import React, { useState, useEffect } from "react";
import "../../components/css/CategorySelect.css";
import { IoMdSearch } from "react-icons/io";
import { FaCompass } from "react-icons/fa";

const jinniOptions = [
  { value: "Day Picnic", label: "Day Picnic" },
  { value: "Nature Stays", label: "Nature Stays" },
  { value: "Beach Stays", label: "Beach Stays" },
  { value: "Tree Houses", label: "Tree Houses" },
  { value: "Tent Stays", label: "Tent Stays" },
  { value: "Lake Stays", label: "River Front or Lake Stays" },
  { value: "Hill Top", label: "Hill top" },
  { value: "Forest Stay", label: "Forest Stay" },
  { value: "Luxury", label: "Luxury" },
  { value: "Tea Plantation", label: "Tea coffee Spice Plantation Stay" },
  { value: "Group Stay", label: "Group Stay" },
  { value: "Adventure", label: "Adventure" },
  { value: "Workation", label: "Workation" },
  { value: "Celebrations", label: "Celebrations" },
  { value: "House Boat/ Cruise", label: "Houseboat or Cruise" },
  { value: "Packages", label: "Package Tour" },
  { value: "Road Trips", label: "roadtrip/rides" },
];

const CategorySelect = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  };
  // const [alertShown, setAlertShown] = useState(false);
  const handleItemClick = (value) => {
    // alert(value);
    window.location.href = "/" + value;
  };

  return (
    <>
      <div
        className="react-dropdown dropdown-center"
        onClick={toggleDropdown}
        onMouseEnter={!isMobile ? toggleDropdown : undefined}
        onMouseLeave={!isMobile ? closeDropdown : undefined}
      >
        <p
          className="btn react-dropdown-toggle p-0 m-0 "
          type="button"
          onClick={!isMobile ? undefined : toggleDropdown}
          style={{
            width: isMobile ? "calc(100% )" : "",
            textAlign: "left",
            color: "white",
            fontSize: "20px",
            fontWeight: "400",
          }}
        >
          Discover <FaCompass />
        </p>
        <div
          className={`react-dropdown-menu ${isDropdownOpen ? "show" : ""}`}
          style={{
            textShadow: "none",
            position: isMobile ? "relative" : "absolute",
            boxShadow: isMobile ? "none" : "",
            border: isMobile ? "none" : "",
            backgroundColor: isMobile ? "transparent" : "",
            width: isMobile ? "100%" : "",
            height: "auto",
            padding: "10px", // Add padding
            overflowY: isMobile ? "auto" : "visible", // Add vertical scrollbar on mobile
            maxHeight: isMobile ? "200px" : "400px", // Limit height on mobile for scrollbar
            color: "white",
          }}
        >
          <div className="category-list">
            {jinniOptions.map((option, index) => (
              <div
                key={option.value}
                className="category-select-option"
                style={{
                  width: isMobile ? "100%" : "30%", // Set width for mobile and desktop
                  textAlign: "left",
                  color: "white", // Set default font color to white
                }}
              >
                <div
                  // onClick={handleItemClick(option.value)}
                  onClick={() => handleItemClick(option.label)}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    display: "block",
                  }}
                >
                  {option.value === "Road Trips" ||  option.value === "Packages" ? (
                    <span>
                      {option.value}
                      <sup style={{ color: "yellow", fontWeight: "700" }}>
                        {" "}
                        NEW
                      </sup>
                    </span>
                  ) : (
                    option.value
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default CategorySelect;
