import React,{useState} from 'react'
import "../css/Facilities.css"
function KnowBeforeVisit({options}) {
  const [isExpanded, setIsExpanded] = useState(false);

const toggleExpand = () => {
  setIsExpanded(!isExpanded);
};



const maxLength = 2; // Maximum characters to display initially

const truncatedOptions =
  options.length > maxLength
    ? options.slice(0, maxLength)
    : options;
  return (
    <>
    {/* <div className="container" style={{boxShadow:"none", backgroundColor:"#ee932c14"}}>
      <div>
        <h4 style={{color:"brown"}}>Know Before Your Visit</h4>
      </div>
      <div className="col">
        {options.map((item, index) => (
          <p key={index} style={{fontSize:"large", color:"Black", fontWeight:"300"}}>
            <i className="fas fa-praying-hands" style={{ color: "black", margin: "5px" }}></i>
            {item.trim()}
          </p>
        ))}
      </div>
      </div> */}
      <div className={`description-container ${isExpanded ? 'expanded' : ''}`}>
      <div>
        <h4 style={{ color: 'brown' }}>Know before your visit</h4>
      </div>
      <div
        className="options-grid"
        style={{
          fontSize: 'large',
          color: 'black',
          fontWeight: '300',
          // display: 'grid',
          // gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          // gap: '5px',
        }}
      >
        {(isExpanded ? options : truncatedOptions).map((option, index) => (
          <div key={index} className="option">
            <i className="fas fa-praying-hands" style={{ color: "orange", marginRight: "5px" }}></i>
            {option.trim()}
          </div>
        ))}
        {!isExpanded && options.length > maxLength && (
          <div className="option">
            <i className="fas fa-ellipsis-h" style={{ color: "green", marginRight: "5px" }}></i>
            
          </div>
        )}
      </div>
      {!isExpanded && options.length > maxLength && (
        <button
          className={`badge ${isExpanded ? 'expanded' : ''}`}
          onClick={toggleExpand}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: '#007bff',
            cursor: 'pointer',
            padding: '0',
            alignSelf: 'center',
          }}
        >
          See More
        </button>
      )}
      {isExpanded && options.length > maxLength && (
        <button
          className={`badge expanded ${isExpanded ? 'expanded' : ''}`}
          onClick={toggleExpand}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: '#007bff',
            cursor: 'pointer',
            padding: '0',
            alignSelf: 'center',
          }}
        >
          See Less
        </button>
      )}
    </div>
    </>
  )
}

export default KnowBeforeVisit