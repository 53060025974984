import React from "react";
import { Card } from "reactstrap";
// import "../css/Alllocationscard.css";

function AllLocationCards({ options }) {
  return (
    <>
    {/* <div className="container" style={{ boxShadow: "none" }}></div> */}
      <br></br>

          {options.map((everyoption, index) => (
            <a href={"/filter/locationdetails/" + everyoption} style={{ textDecoration: "none" }} key={index}>
              <div className="card-container">
              <Card
            key={index}
            style={{
              width: "150px",
              height: "150px",
              margin: "5px",
              backgroundImage:
                "url(/" + everyoption.replace(/ /g, "_") + "_picTOURnic.webp)",
              backgroundSize: "cover",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.4)", // Darker background
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  color: "white",
                  textAlign: "center",
                  zIndex: 1,
                }}
              >
                {everyoption === "Andaman and Nicobar Islands"
                  ? "Andaman & Nicobar"
                  : everyoption}
              </h6>
            </div>
          </Card>
              </div>
            </a>
          ))}

      <br></br>
      <br></br>
    </>
  );
}

export default AllLocationCards;
