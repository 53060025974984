import React from "react";
// import { BsHighlights } from "react-icons/bs";
import "../../../components/css/Highlight.css";
import { FaCanadianMapleLeaf } from "react-icons/fa";
function HighlightsSection({ data }) {
  // console.log("In Highlight Page", data);
  return (
    <>
      <div className="highlights-container">
        <h4 className="highlight" id="highlight">
          Highlights
        </h4>
        <ul className="highlight-list">
          {data.map((item, i) => (
            <li key={i} className="highlight-item">
              <FaCanadianMapleLeaf  className="highlight-icon" />
              {item}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default HighlightsSection;
