import '../components/Forbidden403.css'


function Forbidden403() {
    return (
        <div className="container" style={{boxShadow:"none"}}>
            <br/>
            <h1 style={{color:"red", textAlign:"center"}}> 403 Forbidden </h1>
            <br/>
            <h2 style={{color:"black", textAlign:"center"}}> This is a Booking Page. You do not have permission to access this page directly on this Server yet.</h2>
            <br/>
            <h4 style={{textAlign:"center"}}>Please explore all our options available for you and please procced for booking</h4>
            <br/><br/>
            <h4 style={{textAlign:"center"}}><a href="/">Explore What picTOURnic have for You <span className="wave">👋</span></a></h4>
            <br/>
          

            
            
        </div>

     )
}

export default Forbidden403
