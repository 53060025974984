import React from "react";
// import '../../components/css/SvgComponent.css'

const SvgComponent = () => {
  return (
    <div className="bubble-container">
      <div className="bubble bubble-float1" />
      <div className="bubble bubble-float2" />
      <div className="bubble bubble-float3" />
      <div className="bubble bubble-float4" />
    </div>
  );
};

export default SvgComponent;
