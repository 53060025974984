/* eslint-disable */
import React, { useState, useEffect } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { withRouter } from "react-router-dom";
// import PropertiesDetails from "./PropertiesDetails";
// import PropertiesList from "./PropertiesList";
import axios from "../../axios";
import { Helmet } from "react-helmet";

import {
  Row,
  // , CardSubtitle
} from "reactstrap";
require("dotenv").config();
import MainCategories from "../MainCategories";
import Back from "../Back";
import Throbber from "../Pages/Throbber";
import AllLocationCards from "./AllLocationCards";
import SingleCard from "../Card";


var SelectLocationPage = (props) => {
  var parLoc = props.match.params.locationname;
  // console.log("parloc is ", parLoc);
  var derivedUrl = props.match.url;
  // console.log("derived URL is  ", derivedUrl);
  const [loading, setLoading] = useState(false);
  const [proplocation, setPropLocation] = useState([]);
  const [errorPage, seterrorPage] = useState();
  const [mainPageOptions, setmainPageOptions] = useState([]);
  useEffect(() => {
    async function fetchLocationData() {
      try {
        // console.log("Calling DB for CategoryNames Page");
        const req = await axios.get(
          process.env.REACT_APP_DB_URL + "/filter/locationdetails/" + parLoc
        );

        // console.log(req.data)
        // setproperties(data_var);
        var dataset = [];
        req.data.map((item) => {
          if (typeof item[0] !== "undefined") {
            // console.log(item[0]);
            dataset.push(item[0]);
          }
        });
        // console.log(req.dataset)
        // console.log("the dats set is ");
        // console.log(dataset);
        setPropLocation(dataset.sort((a, b) => a.displayOrder - b.displayOrder));
        setLoading(true);
      } catch (error) {
        // console.log("Error is", error.response.status);
        // console.error(error);
        setLoading(false);
        seterrorPage(error.response.status);
      }
    }
    fetchLocationData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchDataForLocationaAll() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(
          process.env.REACT_APP_DB_URL + "/locationsall"
        );
        // console.log("For all locations", req.data);
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // console.log("Earlier Array", req.data)
        var req_arr = req.data;
        var temp_arr = [];
        req_arr.map((item) => {
          if (item !== parLoc) {
            temp_arr.push(item);
          }
        });
        // console.log("Now the Array is",temp_arr )

        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(2000);
        // setmainPageOptions(req.data);
        // setLoading(true);
        setmainPageOptions(temp_arr);
        setLoading(true);
      } catch (error) {
        // console.log('Error is')
        // console.error(error);
        setLoading(false);
      }
    }
    fetchDataForLocationaAll();
  }, []);

  const [propMainPageOptions, propSetmainPageOptions] = useState([]);
  // To Specify all property ids below the main locations
  useEffect(() => {
    async function fetchAllPropertyDataForLocation() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(
          process.env.REACT_APP_DB_URL + "/for/allproperties/getdata/" + parLoc
        );
        // console.log("For all locations", req.data);
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // console.log("Earlier Array", req.data)
        var req_arr = req.data.sort((a, b) => b.raiting - a.rating);

        // console.log("Now the Array is", req_arr);

        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(2000);
        // setmainPageOptions(req.data);
        // setLoading(true);
        propSetmainPageOptions(req_arr);
        setLoading(true);
      } catch (error) {
        // console.log('Error is')
        // console.error(error);
        setLoading(false);
        window.location = "/";
        alert(
          "Apologies from picTournic. This Property is not available for booking. Redirecting to home page"
        );
      }
    }
    fetchAllPropertyDataForLocation();
  }, []);

  var location_page = props.match.params.locationname;
  // console.log(props);
  {
    return (
      <>
        <Helmet>
          <title>{parLoc}</title>
        </Helmet>
        {loading ? (
          <>
            <Back />
            {/* <hr></hr> */}
            <div className="container" style={{ boxShadow: "none" }}>
              <center>
                <h2 style={{ color: "brown" }}>
                {/* <br></br> */}
                  pic <span style={{ color: "green" }}>{location_page}</span>
                </h2>
              </center>
            </div>
            <div className="container-fluid">
            <Row xs="1" sm="2" md="3" lg="5">
                <MainCategories
                  options={proplocation}
                  derivedurl={derivedUrl}
                />
              </Row>
            </div>
            <br>
            </br>
            <hr></hr>
            
            <h4 style={{ boxShadow:"none",textAlign: "center", color: "brown" }}>
          EXPLORE Locations Across INDIA with picTOURnic <br />
        </h4>
        <h5 style={{ textAlign: "center", color: "brown" , fontSize:"12px"}}>
          Hand picked most beautiful places for Weekend Travel, Stays, Corporate
          Events, Pre wed Shoots & many more.<br></br> Explore to Extreme with
          Us{" "}
          {/* <i
            style={{ color: "#8b0000", fontSize: "20px" }}
            className="fas fa-grin-wink"
          ></i> */}
        </h5>{" "}
            {/* <div className="container-fluid">
            
        </div> */}

            <div className="container-fluid" style={{ boxShadow: "none" }}>
              <div className="row text-center">
                <AllLocationCards options={mainPageOptions.sort()} />
              </div>
            </div>

            <div className="container" style={{ boxShadow: "none" }}></div>
            
            <hr></hr>
            
            <div className="container" style={{ boxShadow: "none" }}>
              <center>
                <h2 style={{ color: "brown" }}>{parLoc} Properties</h2>
              </center>
            </div>
            
            <div className="container-fluid">
              <Row xs="1" sm="2" md="4" lg="5">
                <SingleCard options={propMainPageOptions} />
              </Row>
            </div>
          </>
        ) : (
          <Throbber />
        )}
      </>
    );

    // console.log(props)
  }
};

export default withRouter(SelectLocationPage);
