import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import '../Pages/Throbber.css'
function Throbber() {
  return (
    <div className="container-throbber">
      <div className="spinner">
        {/* <ReactBootstrap.Spinner animation="grow" variant="primary" />
        <ReactBootstrap.Spinner animation="grow" variant="secondary" />
        <ReactBootstrap.Spinner animation="grow" variant="success" />
        <ReactBootstrap.Spinner animation="grow" variant="danger" />
        <ReactBootstrap.Spinner animation="grow" variant="warning" />
        <ReactBootstrap.Spinner animation="grow" variant="info" />
        <ReactBootstrap.Spinner animation="grow" variant="light" />
        <ReactBootstrap.Spinner animation="grow" variant="dark" /> */}
        <ReactBootstrap.Spinner
      as="span"
      animation="grow"
      size="lg"
      role="status"
      aria-hidden="true"
    />
    <h5>...picTOURnic...</h5>
      </div>
    </div>
  );
}

export default Throbber;
