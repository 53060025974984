import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "../components/CarouselElastic.css";
import "../components/Quotes.css";
import startupindia from "../assests/startup-india-hub-logo-vector.svg";
const UserReview = ({ reviews }) => {

  return (
    <>
    <hr></hr>
      <div
        className="container"
        style={{ boxShadow: "none", margin: "0px!important" }}
      >
        {/* <br></br> */}
        <h2 style={{ textAlign: "center", color: "brown" }}>
          Client Testimonials{" "}
          <i className="fas fa-handshake" style={{ color: "brown" }}></i>{" "}
        </h2>
        
      </div>

      <div className="userReviews">
        <div
          className="container"
          style={{ boxShadow: "none", backgroundColor: "black" }}
        >
          <Carousel controls={true} interval={6000} indicators={false}>
            {reviews.map((eachreview, index) => (
              <Carousel.Item key={index}>
                <img src={startupindia} alt="start up india pictournic" />

                <p>
                  {eachreview.name} <br></br>
                  <br></br>
                  {eachreview.location}
                  <br></br>
                  <br></br>“ {eachreview.comment} ”
                </p>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default UserReview;
