import React from "react";
import "../../components/css/CustomToast.css"

const CustomToast = ({ userName, onClose , message, userImage}) => {
  return (
    <div className="custom-toast-container">
      <div className="custom-toast-user-body">
        <div className="user-body">
          <div className="custom-toast-image-container ">
            <img
              className="custom-toast-img"
              src= {userImage || "https://www.iconpacks.net/icons/2/free-user-icon-3296-thumb.png"}
              alt="User"
            />
          </div>  
          <div className="custom-toast-text-body">
            <p className="custom-toast-greetings">
              Hi {userName}
            </p>
            <p className="custom-toast-message">
              {message}
            </p>
          </div>
        </div>
      </div>
      <div className="custom-toast-button-container">
        <button
          onClick={onClose}
          className="toast-button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CustomToast;
