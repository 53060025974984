import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from "react";
import axios from "../axios";
import Back from "./Back";
import AllLocationCards from "./Pages/AllLocationCards";
import Throbber from "./Pages/Throbber";
require("dotenv").config();

// import ReactGa from 'react-ga';
// import CardsSubLocation from "./Pages/CardsSubLocation";
function Alllocations() {
  const [mainPageOptions, setmainPageOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchDataForLocationaAll() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(
          process.env.REACT_APP_DB_URL + "/locationsall"
        );

        // console.log("For all locations", req.data);
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(2000);
        // setmainPageOptions(req.data);
        // setLoading(true);
        setmainPageOptions(req.data);
        setLoading(true);
      } catch (error) {
        // console.log('Error is')
        // console.error(error);
        setLoading(false);
      }
    }
    fetchDataForLocationaAll();
  }, []);
  return (
    <>
      {loading ? (
        <>
          <Back />
          <hr></hr>
          <h4 className="MainpackHeading"
            style={{ boxShadow: "none", textAlign: "center", color: "black" }}
          >
            EXPLORE Locations Across INDIA with picTOURnic <br />
          </h4>
          <h5 className="subpackheading"
          // style={{ textAlign: "center", color: "black", fontSize:"14px" }}
          >
            Hand picked most beautiful places for Weekend Travel, Stays,
            Corporate Events, Pre wed Shoots & many more.<br></br> Explore to
            Extreme with Us{" "}
            {/* <i
              style={{ color: "black", fontSize: "20px" }}
              className="fas fa-grin-wink"
            ></i> */}
          </h5>{" "}
          <div className="container-fluid" style={{ boxShadow: "none" }}>
            <div className="row text-center">
              <AllLocationCards options={mainPageOptions.sort()} />
            </div>
          </div>
        </>
      ) : (
        <Throbber />
      )}
    </>
  );
}

export default Alllocations;
