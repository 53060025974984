import React from "react";
import "../components/Bikerides.css";
import Gallery from "./Pages/Gallery";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import Back from "./Back";


function Bikerides() {
  const images_ = [
    {
      id: 1,
      src: "/Malallai_Bike_trip_1.webp",
      alt: "bike tour",
    },
    {
      id: 2,
      src: "/Malallai_Bike_trip_2.webp",
      alt: "road trip",
    },
    {
      id: 3,
      src: "/Malallai_Bike_trip_3.webp",
      alt: "karnataka trips",
    },
    {
      id: 4,
      src: "/Malallai_Bike_trip_4.webp",
      alt: "domestic and international trips",
    },
    {
      id: 5,
      src: "/Malallai_Bike_trip_5.webp",
      alt: "packages",
    },
    {
      id: 6,
      src: "/Malallai_Bike_trip_6.webp",
      alt: "tour and picnic",
    },
    {
      id: 7,
      src: "/Malallai_Bike_trip_7.webp",
      alt: "bike rides",
    },
    {
      id: 8,
      src: "/Malallai_Bike_trip_8.webp",
      alt: "journey to happiness",
    },
  ];
  const images__ = [
    {
      id: 1,
      src: "/Kudremukh_Bike_trip_1.webp",
      alt: "bike tour",
    },
    {
      id: 2,
      src: "/Kudremukh_Bike_trip_2.webp",
      alt: "road trip",
    },
    {
      id: 3,
      src: "/Kudremukh_Bike_trip_3.webp",
      alt: "karnataka trips",
    },
    {
      id: 4,
      src: "/Kudremukh_Bike_trip_4.webp",
      alt: "domestic and international trips",
    },
    {
      id: 5,
      src: "/Kudremukh_Bike_trip_5.webp",
      alt: "packages",
    },
    {
      id: 6,
      src: "/Kudremukh_Bike_trip_6.webp",
      alt: "tour and picnic",
    },
    {
      id: 7,
      src: "/Kudremukh_Bike_trip_7.webp",
      alt: "bike rides",
    },
    {
      id: 8,
      src: "/Kudremukh_Bike_trip_8.webp",
      alt: "journey to happiness",
    },
  ];
  const images_agumbe = [
    {
      id: 1,
      src: "/agumbe_kantara.webp",
      alt: "Kantara trail",
    },
    {
      id: 2,
      src: "/Agumbe_kantara_1.webp",
      alt: "road trip",
    },
    {
      id: 3,
      src: "/Agumbe_kantara_2.webp",
      alt: "karnataka trips",
    },
    {
      id: 4,
      src: "/Agumbe_kantara_3.webp",
      alt: "domestic and international trips",
    },
    {
      id: 5,
      src: "/Agumbe_kantara_4.webp",
      alt: "packages",
    },
    {
      id: 6,
      src: "/Agumbe_kantara_5.webp",
      alt: "tour and picnic",
    },
    {
      id: 7,
      src: "/Agumbe_kantara_6.webp",
      alt: "bike rides",
    },
    {
      id: 8,
      src: "/Agumbe_kantara_7.webp",
      alt: "journey to happiness",
    },
  ];
  const images_kalhatty = [
    {
      id: 1,
      src: "/kalhaty.webp",
      alt: "kalhaty ooty trail",
    },
    {
      id: 2,
      src: "/masinagudi.webp",
      alt: "masinagudi",
    },
    {
      id: 3,
      src: "/mithun_chakroboty_farm.webp",
      alt: "mithun_chakroboty_farm",
    },
    {
      id: 4,
      src: "/mystiqueville.webp",
      alt: "mystiqueville ooty",
    },
    {
      id: 5,
      src: "/night_view_awesomestay.webp",
      alt: "night_view_awesomestay",
    },
    {
      id: 6,
      src: "/temple_masinagudi.webp",
      alt: "tour CUstomized package",
    },
    {
      id: 7,
      src: "/ooty.webp",
      alt: "bike rides",
    },
    {
      id: 8,
      src: "/trek.webp",
      alt: "trek pictournic",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Road Trips</title>
        <meta
          name="keywords"
          content="Adventure tourism, Bike tours, Scenic routes, Eco-tourism, Sustainable tourism, Motorcycle trips, Outdoor recreation, Travel, Road trips, Tourism industry, Nature-based tourism, Mountain biking, Cycling, Backpacking, Camping, Sightseeing, Cultural tourism, Historical landmarks, Local cuisine."
        />
      </Helmet>
      <Back />
      <div className="container-fluid">
        <br></br>
        <div className="page-title">
          <h1>picTOURnic RIDES</h1>
          <p style={{ fontSize: "30px" }}>
            More than <CountUp end={301} duration={5} /> Rider's Community
          </p>
          <p style={{ fontSize: "30px" }}>
            <CountUp end={32} duration={8} /> Rides
          </p>
        </div>
        {/* <div className="page-title">
          <h1>YEAR 2023</h1>
        </div> */}
        

        <div className="container-fluid">
          <center>
            <h3>
              <a
                target="blank"
                href="https://api.whatsapp.com/send/?phone=+919663803335&text=Hi+picTOURnic+Upcoming Bike Ride+Query%21%21%21&type=phone_number&app_absent=0"
              >
                Upcoming schedule Enquire Now!
              </a>
            </h3>
          </center>
        </div>
        <div className="content-container">
          <img
            src="/Bike_Road_Trip.webp"
            alt="pictournic bike rides tour and traveles domestic and international"
            className="content-image"
          />

          <p className="content-paragraph">
            Looking for a thrilling adventure? Why not hit the open road on a
            motorbike road trip! Our road trips offer the perfect opportunity to
            explore new places, meet new people, and experience the freedom of
            the open road. With our well-planned routes and expert guides,
            you'll be able to soak up the sights and sounds of the countryside
            while enjoying the thrill of riding a motorbike. We offer a range of
            options to suit your preferences and skill level, whether you're a
            seasoned rider or a beginner. And with our top-of-the-line bikes and
            equipment, you'll be riding in style and comfort. So why wait? Book
            a motorbike road trip with us today and get ready for an
            unforgettable adventure!
          </p>
        </div>

        <hr></hr>

        <br></br>

        <center>
          <h3>Mallalli Falls! (Dated 11 Feb, 2023)</h3>
        </center>

        <div className="content-container">
          <img
            src="/Mallalli_Waterfalls.webp"
            alt="pictournic bike rides tour and traveles domestic and international"
            className="content-image"
          />
          <p className="content-paragraph">
            We Organized motorbike trip to this stunning location was an
            incredible experience for all involved. We rode through some of the
            most picturesque landscapes in Karnataka, with winding roads and
            rolling hillsides creating an ever-changing backdrop to our journey.
            As we approached the falls, the roar of the cascading water grew
            louder and louder, and the view of the waterfall was nothing short
            of breathtaking. We took in the sights and sounds of the falls,
            feeling the cool spray of the water on our faces. It was a truly
            unforgettable experience that we will cherish forever. With our
            expert guides , we were able to enjoy the journey with peace of
            mind, knowing that everything was taken care of. If you're looking
            for an adventure like no other, book your future motorbike trip with
            picTournic and get ready to create your own unforgettable memories!
          </p>
        </div>

        <Gallery images={images_} />

        <br></br>
        <hr></hr>
        <br></br>
        <center>
          <h3>KUDUREMUKHA (Dated 11-13 MAR, 2023)</h3>
        </center>

        <div className="content-container">
          <img
            src="/Mallalli_Waterfalls.webp"
            alt="pictournic bike rides tour and traveles domestic and international"
            className="content-image"
          />
          <p className="content-paragraph">
            We organized an exhilarating motorbike trip to Kudremukh Kalasa, and
            it was a huge success. The ride was filled with scenic beauty,
            adventure, and a rush of adrenaline. The journey took us through
            winding roads, lush green forests, and picturesque hills. Kudremukh
            Kalasa was the highlight of the trip. The town was nestled amidst
            the hills, and the view was simply breathtaking. We explored the
            local attractions, enjoyed the delicious cuisine, and visited the
            Kudremukh National Park. The park was a nature lover's paradise and
            we got to witness some stunning wildlife up close. Our team ensured
            that all safety measures were taken care of, and the riders were
            provided with top-quality motorcycles and equipment. The trip was
            led by experienced guides who were familiar with the terrain and
            ensured that everyone had a safe and enjoyable journey. Overall, the
            motorbike trip to Kudremukh Kalasa was an unforgettable experience
            that left us with memories that we'll cherish for a lifetime. We
            highly recommend this trip to anyone who's looking for an adventure
            and wants to experience the beauty of nature from the seat of a
            motorcycle.
          </p>
        </div>
        <Gallery images={images__} />

        <br></br>
        <br></br>
        <hr></hr>
        <center>
          <h3>Agumbe Kantra Trails! (Dated 12 August, 2023)</h3>
        </center>

        <div className="content-container">
          <img
            src="/Agumbe_View_point.webp"
            alt="pictournic bike rides tour and traveles domestic and international"
            className="content-image"
          />
          <p className="content-paragraph">
            The Agumbe Kantara Trails Motorbike trip, which took place during
            the monsoon season , was nothing short of a heart-pounding
            adventure. Venturing off the beaten path and into the wild, we
            embraced the challenges of off-roading amidst the lush rainforest of
            Agumbe. The roaring engines of our bikes echoed through the serene
            canopy as we navigated through slippery mud tracks and rocky
            terrain, experiencing the sheer thrill of conquering nature's
            obstacles. The monsoon rains added an extra layer of excitement to
            our journey, making every twist and turn an exhilarating challenge.
            When adventure comes to an end, we're only left with memories of the
            incredible landscapes, camaraderie, and the adrenaline rush of
            off-roading in one of India's most beautiful regions. Until the next
            adventure calls, the memories of our Agumbe Kantara Trails Motorbike
            trip will remain etched in our hearts.
          </p>
        </div>

        <Gallery images={images_agumbe} />

        <br></br>
        <hr></hr>
        <br></br>

        <center>
          <h3>Masinagudi-Kalhaty Falls! (Dated 30 Sep, 2023)</h3>
        </center>

        <div className="content-container">
          <img
            src="/kalahatti-waterfalls-ooty-tourism.webp"
            alt="pictournic bike rides tour and traveles domestic and international"
            className="content-image"
          />
          <p className="content-paragraph">
            Our recent adventure unfurled in the pristine beauty of Masinagudi,
            a haven for nature enthusiasts. The journey commenced with an
            exhilarating trek through the lush expanses, where every step
            unveiled the rich biodiversity of the region. The air was alive with
            the calls of exotic birds, and the dense foliage whispered tales of
            the wild. Following the immersive trek, our expedition led us to the
            iconic Kalhatty Falls. The cascading waters, surrounded by verdant
            landscapes, provided a serene interlude. The journey continued with
            an adrenaline-pumping Ooty bike ride towards Kalhatty, navigating
            winding roads that offered breathtaking views of the Nilgiri Hills.
            The hum of engines harmonized with the natural symphony, creating a
            unique soundtrack for our adventure.This escapade seamlessly wove
            together the tranquility of Masinagudi's trek, the awe-inspiring
            beauty of Kalhatty Falls, and the thrill of the Ooty bike ride. Each
            leg of the trip offered a distinct flavor, from the meditative
            communion with nature to the exhilarating pulse of the ride. Our
            recent expedition, a symphony of exploration and adventure, etched
            indelible memories against the backdrop of these enchanting South
            Indian landscapes.
          </p>
        </div>

        <Gallery images={images_kalhatty} />

        <br></br>
        <hr></hr>
        <br></br>
      </div>

      <center></center>
      <div className="container-fluid">
        <center>
          <h4>
            "Experience the freedom of the open road - book your next bike ride
            adventure with picTOURnic!"
          </h4>
          <h3>
            <a
              target="blank"
              href="https://api.whatsapp.com/send/?phone=+919663803335&text=Hi+picTOURnic+Bike Ride+Query%21%21%21&type=phone_number&app_absent=0"
            >
              Enquire Now!
            </a>
          </h3>
        </center>
      </div>
    </>
  );
}

export default Bikerides;
