import React from "react";

// function PlayVideo() {

const PlayVideo = ({ option }) => {
    // console.log(option)
    var url = option.replace("watch?v=","embed/")
    // console.log(url)
  return (
    <>
   
      <div className="container"  style={{ boxShadow: "none" }}>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src={url.trim() + `?rel=0`}
            title="youtubelinks"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
      
    </>
  );
};
export default PlayVideo;
