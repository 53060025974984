import React,{useState} from "react";
// import "../Card.css";
import "../css/Activities.css"


function Activities({ options }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };



  const maxLength = 3; // Maximum characters to display initially

  const truncatedOptions =
    options.length > maxLength
      ? options.slice(0, maxLength)
      : options;
  return (
    <>
    <div className={`description-container ${isExpanded ? 'expanded' : ''}`}>
      <div>
        <h4 style={{ color: 'brown' }}>Activities</h4>
      </div>
      <div
        className="options-grid"
        style={{
          fontSize: 'large',
          color: 'black',
          fontWeight: '300',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: '10px',
        }}
      >
        {(isExpanded ? options : truncatedOptions).map((option, index) => (
          <div key={index} className="option">
            <i className="fas fa-star-half" style={{ color: "green", marginRight: "5px" }}></i>
            {option}
          </div>
        ))}
        {!isExpanded && options.length > maxLength && (
          <div className="option">
            <i className="fas fa-ellipsis-h" style={{ color: "green", marginRight: "5px" }}></i>
            
          </div>
        )}
      </div>
      {!isExpanded && options.length > maxLength && (
        <button
          className={`badge ${isExpanded ? 'expanded' : ''}`}
          onClick={toggleExpand}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: '#007bff',
            cursor: 'pointer',
            padding: '0',
            alignSelf: 'center',
          }}
        >
          See More
        </button>
      )}
      {isExpanded && options.length > maxLength && (
        <button
          className={`badge expanded ${isExpanded ? 'expanded' : ''}`}
          onClick={toggleExpand}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: '#007bff',
            cursor: 'pointer',
            padding: '0',
            alignSelf: 'center',
          }}
        >
          See Less
        </button>
      )}
    </div>
      {/* <div
        className="container"
        style={{ boxShadow: "none", backgroundColor:"#ee932c14" }}
      >
        <div>
          <h4 style={{ color: "brown" }}>Activities</h4>
        </div>
        <div className="listing">
        <ul style={{fontSize:"large", color:"Black", fontWeight:"300"}}>
          {options.map((item, index) => (
            <li key={index}>
              <i
                className="fas fa-star-half"
                style={{ color: "green", margin: "2px" }}
              ></i>
              {item.trim()}
            </li>
          ))}

        </ul>
        </div>
      </div> */}
    </>
  );
}

export default Activities;
