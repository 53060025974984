/* eslint-disable */
import React, { useState } from "react";
import ReactBnbGallery from "react-bnb-gallery";
// import { withRouter } from "react-router-dom";
import "react-bnb-gallery/dist/style.css";
import Back from "../Back";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import dataseet from "./dataSetOnlyImages.json";
function RouteImage(props) {
  const dataSet = dataseet;
  var pathSegments = props.location.pathname.split("/");
  const previousLocation = pathSegments[pathSegments.length - 1];
  const id = pathSegments[pathSegments.length - 2];
  // console.log(previousLocation, id);
  const [isOpen, setIsOpen] = useState(true);

  const findImagesById = (webnum) => {
    const webnumToFind = Number(webnum);
  
    if (isNaN(webnumToFind)) {
      console.error("Invalid webnum:", webnum);
      return [];
    }
  
    // console.log("Searching for webnum:", webnumToFind);
  
    const property = dataSet.find((item) => item.webnum === webnumToFind);
  
    // console.log("Found property:", property);
  
    return property ? property.images.map((image) => `/${image}`) : [];
  };

  const imagesForWebnum = findImagesById(id);
  // console.log("Images for Webnum", id, ":", imagesForWebnum);
  const handleClose = () => {
    // Perform the redirection
    window.location.href = `/image/search/${previousLocation}`;
  };
  //   const showGallery = () => {
  //     setIsOpen(true);
  //   };
  // console.log(props);
//   const photos = ["/114_14.webp", "/114_11.webp", "/114_10.webp"];
  return (
    <>
      <Back />
      <div>RouteImage</div>
      <ReactBnbGallery
        show={isOpen}
        photos={imagesForWebnum}
        onClose={handleClose}
      />
    </>
  );
}

export default withRouter(RouteImage);
