import React from 'react'

function MealTiming({options}) {
  return (
    <>
    {/* <div className="container" style={{boxShadow:"none", backgroundColor:"#ee932c14"}}>
      <div>
        <h4 style={{color:"brown"}}>Meal/(s) Timing</h4>
      </div>
      <ul style={{fontSize:"large", color:"Black", fontWeight:"300"}}>
        {options.map((item, index) => (
          <li key={index} style={{fontSize:"large", color:"Black", fontWeight:"300"}}>
            <i className="fas fa-utensils" style={{ color: "black", margin:"5px" }}></i>
            {item}
          </li>
        ))}
      </ul>
      </div> */}
      <div className={'description-container'}>
        <div>
          <h4 style={{ color: "brown" }}>Meal/(s) Timing</h4>
        </div>

        <div className="option">
          <i
            className="fas fa-stopwatch"
            style={{ color: "black", margin: "5px"  , display: "inline-block", }}
          ></i>
          <div
            style={{
              fontSize: "large",
              color: "black",
              fontWeight: "300",   display: "inline-block",
            }}
          >
            {options}
          </div>
        </div>
      </div>
    </>
  )
}

export default MealTiming