import React from "react";
import "./MainPageCard.css"
import { withRouter } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function MainCategories({ options, derivedurl }) {
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 1) + '...';
    }
    return text;
  };
  return (
    
    <>
      

{options.map((everyoption) => (
        <div className="col-md-4" key={everyoption.categoryid}>
          <a href={derivedurl + "/" + everyoption.text} className="blog-link">
            <div className="blog">
              <center>
                <LazyLoadImage
                  style={{ objectFit: "cover", width: "100%" }}
                  alt={"not available"}
                  effect="blur"
                  src={"/" + everyoption.thumb_ima}
                />
              </center>

              <div className="blog-content">
                <center>
                  <p className="Text">{everyoption.text}</p>
                </center>
                <center>
                  <h6>{truncateText(everyoption.details,60)}</h6>
                </center>
              </div>

              <center>
                <div className="blog-post__cta">EXPLORE</div>
              </center>
            </div>
          </a>
        </div>
      ))}
    </>
  );
}

export default withRouter(MainCategories);
