/* eslint-disable */
import React, { useState, useEffect } from "react";
import "../../components/css/PackageFormat.css";
import { Link } from "react-router-dom";
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";
import Modal from "./Package/PackageModal";
import HighlightModal from "./Package/HighlightModal";
import { Helmet } from "react-helmet";
import ImageSection from "./ImageSection";
import Seperators from "./Seperators";
import Back from "../Back";
require('dotenv').config();
import ReactGa from "react-ga4";
function InternationalPackage({ direction, ...args }) {
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenHighlight, setIsModalOpenHighlight] = useState(false);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const [clickedPackageItem, setClickedPackageItem] = useState(null);

  const [showMoreStates, setShowMoreStates] = useState([]);
  const [showMoreListStates, setShowMoreListStates] = useState([]);

  // const handleClickReadMore = (index) => {
  //   setClickedPackageItem(packageData[index]);
  //   setIsModalOpenHighlight(true);
  // };
  const handleClickReadMore = (filteredIndex) => {
    const index = packageData.indexOf(filteredPackages[filteredIndex]);
    setClickedPackageItem(packageData[index]);
    setIsModalOpenHighlight(true);
  };


  const handleClickEnquireNow = (packageItem) => {
    
    setClickedPackageItem(packageItem);
    setIsModalOpen(true);
    // console.log(packageItem.packageId);
    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    ReactGa.event({
      category: "Package Enquiry User Form Open "+packageItem.packageId,
      action: "User Form Open",
      label: "User need to fill Form",
      value: packageItem.packageId,
    });
  };

  const maxLength = 60;
  const maxItemsToShow = 2; // Maximum number of items to show initially
  const explanation =
  "Embark on an unforgettable international tour that spans the serene kingdom of Bhutan, the captivating continent of Europe, and the enchanting landscapes of Vietnam. Begin your journey amidst the misty mountains and spiritual tranquility of Bhutan, where ancient monasteries cling to steep cliffs and prayer flags flutter in the Himalayan breeze. Explore the vibrant markets of Thimphu, hike through the pristine valleys of Paro, and immerse yourself in the rich cultural heritage of this mystical kingdom.From the serene realms of Bhutan, traverse to the diverse landscapes of Europe, where history, art, and architecture converge in a mesmerizing tapestry of experiences. Wander through the romantic streets of Paris, marvel at the grandeur of Rome's ancient ruins, and cruise along the scenic waterways of Amsterdam. Whether you're indulging in delectable cuisine in Barcelona or skiing in the Swiss Alps, Europe offers an endless array of adventures to delight every traveler.Finally, venture into the captivating landscapes of Vietnam, where bustling cities pulse with energy and tranquil rice paddies stretch to the horizon. Explore the bustling streets of Hanoi, cruise through the mystical beauty of Ha Long Bay, and discover the rich history of Ho Chi Minh City. Whether you're sampling street food in Hoi An or trekking through the lush mountains of Sapa, Vietnam promises an intoxicating blend of culture, cuisine, and natural beauty that will leave you spellbound.";
const pageTitle = "International";
const subPageTitlle = "Delightful International Tour";
const packageData =[
  {
    packageId: "PTN_PkG_0015",
    imageUrl: "/PTN_PkG_0015.webp",
    title: "Experience essence of spirituality in Bhutan",
    header: "Bhutan",
    cities: 5,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "6N/7D",
    startingPrice: 16500,
    text: "Per Person",
    destinations: "Siliguri/Bagdogra, Phuentsholing, Thimphu, Punakha, Paro",
    items: [
      "Transfer to Jaigaon/Phuentsholing at Bhutan border",
      " visit Zangto Pelri Lhakhang",
      "Proceed to Bhutan immigration for formalities",
      "Journey to Thimphu, capital of Bhutan",
      "Enjoy views of Bhutan Himalaya and waterfall en route",
      "Thimphu sightseeing including Buddha Point, Institute of Handicraft Emporium & National Memorial Chorten",
      "Drive to Punakha, en route Dochu La Pass",
      "Visit Punakha Dzong, hike to Chimi Lhakhang",
      "Explore Punakha Dzong",
      "Visit Chimmi Lhakhang",
      "Excursion to Taktsang Monastery (Tiger’s Nest)",
      "Visit Kichu Lhakhang",
      "OR Optional full day sightseeing including Drukgyal Dzong, Ta Dzong, Paro Rinpung Dzong, etc.",
      "Proceed to Phuentsholing from Paro",
      "Enjoy views of roadside temple and Paro riverside",
      "Check-out from hotel",
      "Proceed to Siliguri (NJP)/ Bagdogra (IXB)",
    ],
  },

  {
    packageId: "PTN_PkG_0031",
    title: "Vietnam Packages",
    header: "Vietnam",
    imageUrl: "/PTN_PkG_0031.webp",
    cities: 2,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Accommodation" },
      { icon: "fas fa-hamburger", label: "Meal Plan" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Airport Transfers" },
    ],
    duration: "4N/5D",
    startingPrice: 32988,
    text: "Per Person",
    destinations: "Ninh Binh, Halong Bay",
    items: [
      "Hanoi airport transfer (No Guide)",
      "Explore Hang Da market and night market",
      "Excursion to Ninh Binh with guide",
      "Visit Bai Dinh Pagoda complex and Trang An",
      "Climb to the top of Mua cave for panoramic views",
      "Transfer from Hanoi to Ha Long Bay (SIC)",
      "Enjoy kayaking and swimming at Ha Long Bay",
      "Visit Surprising Cave (Hang Sung Sot)",
      "Drive back to Hanoi and overnight stay",
      "Hanoi Airport Transfer (No Guide)",
    ],
  },
  {
    packageId: "PTN_PkG_0032",
    title: "Vietnam Packages",
    imageUrl: "/PTN_PkG_0032.webp",
    header: "Vietnam",
    cities: 4,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Accommodation" },
      { icon: "fas fa-hamburger", label: "Meal Plan" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Airport Transfers" },
    ],
    duration: "6N/7D",
    startingPrice: 47603,
    text: "Per Person",
    destinations: "Ninh Binh, Halong Bay, Hoi An, Bana Hills",
    items: [
      "Hanoi airport transfer (No Guide)",
      "Excursion to Ninh Binh with guide",
      "Transfer from Hanoi to Ha Long Bay (SIC)",
      "Enjoy kayaking and swimming at Ha Long Bay",
      "Visit Surprising Cave (Hang Sung Sot)",
      "Drive back to Hanoi and overnight stay",
      "Visit Hoi An with guide",
      "Explore Japanese Covered Bridge and Chinese Assembly Halls",
      "Visit Bana Hills with guide",
      "Experience cable car ride and visit Le Jardin",
      "Play games at Fantasy Park",
      "Visit Golden Bridge and Maize Garden",
      "Transfer to Danang airport",
    ],
  },
  {
    packageId: "PTN_PkG_0033",
    title: "Vietnam Packages",
    header: "Vietnam",
    imageUrl: "/PTN_PkG_0033.webp",
    cities: 7,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Accommodation" },
      { icon: "fas fa-hamburger", label: "Meal Plan" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Airport Transfers" },
    ],
    duration: "9N/10D",
    startingPrice: 66394,
    text: "Per Person",
    destinations:
      "Ninh Binh, Halong Bay , Hoi An , Bana Hills , Saigon , Cu Chi Tunnels , Mekong Delta",
    items: [
      "Hanoi airport transfer (No Guide)",
      "Excursion to Ninh Binh with guide",
      "Transfer from Hanoi to Ha Long Bay (SIC)",
      "Enjoy kayaking and swimming at Ha Long Bay",
      "Visit Surprising Cave (Hang Sung Sot)",
      "Drive back to Hanoi, Hanoi airport transfer, and Danang hotel drop (SIC)",
      "Visit Hoi An with guide",
      "Explore Japanese Covered Bridge and Chinese Assembly Halls",
      "Visit Bana Hills with guide",
      "Experience cable car ride and visit Le Jardin",
      "Play games at Fantasy Park",
      "Visit Golden Bridge and Maize Garden",
      "Danang Airport Transfer, Saigon hotel drop, and half day local sightseeing (With Guide for SS)",
      "Visit Cu Chi Tunnels with guide",
      "Day visit to Mekong Delta with guide",
    ],
  },
  {
    packageId: "PTN_PkG_0036",
    title: "Golden Triangle Tour - 5 Nights / 6 Days",
    header: "India",
    imageUrl: "/PTN_PkG_0036.webp",
    cities: 3,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
      // { icon: "fas fa-plane", label: "Flight" },
    ],
    duration: "5N/6D",
    startingPrice: 46600,
    text: "Per Couple",
    destinations: "Delhi, Agra,  Mathura,Fatehpur Sikri,Jaipur,",
    items: [
      "Arrive in Delhi",
      "Full Day Delhi Tour",
      "Delhi to Agra via Mathura",
      "Agra to Fatehpur Sikri to Jaipur",
      "Jaipur Sightseeing",
      "Jaipur Local Sightseeing and Departure",
    ],
  },
  {
    packageId: "PTN_PkG_0037",
    title: "Golden Triangle Tour - 6 Nights / 7 Days",
    header: "India",
    imageUrl: "/PTN_PkG_0037.webp",
    cities: 5,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
      // { icon: "fas fa-plane", label: "Flight" },
    ],
    duration: "6N/7D",
    startingPrice: 18700,
    text: "Per Person",
    destinations: "Delhi, Agra, Mathura, Fatehpur Sikri, Ranthambore, Jaipur",
    items: [
      "Arrival in Delhi, Half Day City Tour",
      "Mathura, Vrindavan, Taj Mahal Visit",
      "Fatehpur Sikri, Ranthambore",
      "Ranthambore Tiger Safari, Jaipur",
      "Amber Palace, Return to Delhi",
      "Delhi Sightseeing",
      "Departure",
    ],
  },
  {
    packageId: "PTN_PkG_0034",
    title: "Jyotirlingas Tour Of Madhya Pradesh",
    header: "India",
    imageUrl: "/PTN_PkG_0034.webp",
    cities: 3,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Accommodation" },
      { icon: "fas fa-hamburger", label: "Meal Plan" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "All Transfers" },
    ],
    duration: "3N/4D",
    startingPrice: 9700,
    text: "Per Person",
    destinations: "Indore , Ujjain , Maheshwar",
    items: [
      "Arrival in Indore, transfer to Ujjain",
      "Visit Harsiddhi, Bada Ganesh, Kaal Bhairav Temples",
      "Visit Ram ghat, Mangalnath, and Sandeepani ashram",
      "Proceed to Omkareshwar, visit Omkareshwar Temple",
      "Visit Mamleshwar Mahadev Temple",
      "Transfer to Maheshwar",
      "Visit Maheshwar Ahilya fort, Ahilyamata’s cenotaph",
      "Explore Baneshwar Mahadev Temple, Rajarajeshwari Temple, Ek Mukhi Datta Temple",
      "Return to Indore, visit Chappan gali (food Hub Street)",
      "Visit Khajrana Ganesh Mandir & Lalbagh",
      "Departure from Indore",
    ],
  },
  {
    packageId: "PTN_PkG_0035",
    title: "Exotic Pachmarhi Tour OF Madhya Pradesh",
    header: "India",
    imageUrl: "/PTN_PkG_0035.webp",
    cities: 2,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Accommodation" },
      { icon: "fas fa-hamburger", label: "Meal Plan" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "All Transfers" },
    ],
    duration: "3N/4D",
    startingPrice: 8799,
    text: "Per Person",
    destinations: "Pipariya | Pachmarhi | Pipariya",
    items: [
      "Arrival at Pipariya railway station, transfer to Pachmarhi hotel",
      "Check-in at Hotel, Overnight stay",
      "Sightseeing of Pachmarhi 1: Jatashankar, Handikho, Priyadarshini, Gupt Mahadev, Bada Mahadev, Green valley, Rajendragiri, Lake boating",
      "Sightseeing of Pachmarhi 2: Bison lodge, Pandav Gufa, Bee-fall, Parasailing, Reechgarh, Eco point, Bhavyadrashya, Dhoopgarh sunset Point",
      "Transfer to Pipariya railway station",
    ],
  },
  {
    packageId: "PTN_PkG_0028",
    title: "Meghalaya Tour Plan 6 Days / 5 Nights",
    header: "India",
    imageUrl: "/PTN_PkG_0028.webp",
    cities: 3,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Accommodations" },
      { icon: "fas fa-hamburger", label: "Meals" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transfer" },
    ],
    duration: "5N/6D",
    startingPrice: 22000,
    text: "Per Person",
    destinations: "Shillong,Cherrapunjee,Shnongpdeng",
    items: [
      "Guwahati to Shillong (90kms)",
      "Visit Kamakhya Temple and Umiam Lake",
      "Shillong to Cherrapunjee (55kms)",
      "Visit Mattilang Park (View of Elephant Falls), MawkdokDympep Valley",
      "Cherrapunjee to Nongriat (12kms)",
      "Trekking to the Meghalaya’s pride ‘Double Decker Bridge’ (Living root bridges)",
      "Visit Rainbow Falls and a natural Pool",
      "Cherrapunjee to Mawlynnong (74kms)",
      "Visit Shnongpdeng (Umngot River, India’s Cleanest River)",
      "Visit Asia’s Cleanest Village Mawlynnong Village",
      "Dawki to Amlarem (52kms) to Shillong (92kms)",
      "Visit Krangshuri Falls, PhePhe Falls & Sualudong falls",
      "Shillong to Guwahati (112kms)",
    ],
  },
  {
    packageId: "PTN_PkG_0029",
    title: "Meghalaya Tour Plan 5 Days / 4 Nights",
    header: "India",
    imageUrl: "/PTN_PkG_0029.webp",
    cities: 4,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Accommodations" },
      { icon: "fas fa-hamburger", label: "Meals" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transfer" },
    ],
    duration: "4N/5D",
    startingPrice: 17800,
    text: "Per Person",
    destinations:
      "2 Shillong – Tura, 01 Night – Cherrapunjee, 01 Night – Tyrna Village",
    items: [
      "Guwahati to Shillong (112kms)",
      "Visit Kamakhya Temple and Umiam Lake",
      "Shillong to Cherrapunjee (55kms)",
      "Visit Mattilang Park (View of Elephant Falls), Mawkdok Dympep Valley",
      "Cherrapunjee to Nongriat (12kms)",
      "Trekking to the Meghalaya’s pride ‘Double Decker Bridge’ (Living root bridges)",
      "Visit Rainbow Falls and a natural Pool",
      "Cherrapunjee to Mawryngkhang (51kms) to Mawlynnong (50kms)",
      "Visit Shnongpdeng (Umngot River, India’s Cleanest River)",
      "Visit Asia’s Cleanest Village Mawlynnong Village",
      "Shillong to Guwahati (112kms)",
    ],
  },
  {
    packageId: "PTN_PkG_0030",
    title: "Meghalaya Tour Plan 7 Days / 6 Nights",
    header: "India",
    imageUrl: "/PTN_PkG_0030.webp",
    cities: 5,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Accommodations" },
      { icon: "fas fa-hamburger", label: "Meals" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transfer" },
    ],
    duration: "6N/7D",
    startingPrice: 25750,
    text: "Per Person",
    destinations:
      "2 Night – Kaziranga, 02 Night – Shillong, 01 Night - Cherrapunjee, 01 Night - Shnongpdeng Campsite",
    items: [
      "Guwahati to Shillong (112kms)",
      "Visit Kamakhya Temple and Umiam Lake",
      "Shillong to Cherrapunjee (55kms)",
      "Visit Mattilang Park (View of Elephant Falls), MawkdokDympep Valley",
      "Cherrapunjee to Nongriat (12kms)",
      "Trekking to the Meghalaya’s pride ‘Double Decker Bridge’ (Living root bridges)",
      "Visit Rainbow Falls and a natural Pool",
      "Cherrapunjee to Mawryngkhang to Mawlynnong (74kms)",
      "Visit Shnongpdeng (Umngot River, India’s Cleanest River)",
      "Visit Asia’s Cleanest Village Mawlynnong Village",
      "Pongtung to Amlarem (52kms) to Shillong (92kms)",
      "Visit Krangsuri Falls, Phe Phe Falls & SuaLudong Falls",
      "Shillong to Mawphlang (27kms) to WahRilang (44kms)",
      "Visit Mawphlang Scared Groves, WahRilang, Jakrem Hot Spring",
      "Shillong to Guwahati (112kms)",
    ],
  },
  {
    packageId: "PTN_PkG_0001",
    title: "Green Wayanad - Cutural Mysore 3N/4D Package",
    header: "India",
    imageUrl: "/PTN_PkG_0001.webp",
    cities: 2,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
      // { icon: "fas fa-plane", label: "Flight" },
    ],
    duration: "3N/4D",
    startingPrice: 15000,
    text: "per person",
    destinations: "Mysore, Wayanad",
    items: [
      "Departure to Mysore (140 Kms/3.5 Hrs)",
      "Visit Sri Rangapatna - Tippu Sultan's Island Capital, Palace, Fort",
      "Visit Chamundeswari Temple, Maharaja's Palace, and light & sound fountains at the Brindavan Gardens in Mysore",
      "Depart for Wayanad (290 Kms/ 6 Hrs)",
      "Visit Pookode lake for boating and historical Chain Tree in Wayanad",
      "Sight-seeing of Edakkal Caves, Soochippara waterfalls, and Banasura Sagar Dam in Wayanad",
      "Departure to Bengaluru (290 Kms/8 Hrs)",
      "Enroute visit Sultan Battery - Museum",
      "Evening drop at Airport/Railway Station in Bengaluru",
    ],
  },
  {
    packageId: "PTN_PkG_0002",
    title: "Queen Ooty - Paradise kodai - Cultural Mysore 5N/6D Package",
    header: "India",
    imageUrl: "/PTN_PkG_0002.webp",
    cities: 4,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
      // { icon: "fas fa-plane", label: "Flight" },
    ],
    duration: "5N/6D",
    startingPrice: 21350,
    text: "per person",
    destinations: "Bengaluru, Mysore, Ooty, Kodaikanal",
    items: [
      "Arrive Bengaluru and transfer to hotel",
      "Visit Lal Bagh Gardens, Bull Temple, Vidhana Soudha, Visveswaraya Industrial & Technology Museum in Bengaluru",
      "Enroute visit Sri Rangapatna - Tippu Sultan's Island Capital, Palace, Fort",
      "Visit Chamundeswari Temple, Maharaja's Palace, and Brindavan Gardens in Mysore",
      "Depart for Ooty",
      "Visit Botanical Gardens and Ooty Lake in Ooty",
      "Proceed to Kodaikanal",
      "Evening at leisure in Kodaikanal",
      "Visit Shenbaganur Museum, Coakers walk, Telescope House, and Kodai Lake in Kodaikanal",
      "Departure to Bengaluru and transfer to Railway station / Airport",
    ],
  },
  {
    packageId: "PTN_PkG_0003",
    title:
      "Silicon Valley Bengaluru- Cultural Mysore - Gloious Coffee Capital Coorg Tour 4N/5D Package",
    header: "India",
    imageUrl: "/PTN_PkG_0003.webp",
    cities: 3,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
      // { icon: "fas fa-plane", label: "Flight" },
    ],
    duration: "4N/5D",
    startingPrice: 15000,
    text: "per person",
    destinations: "Bengaluru, Coorg, Mysore",
    items: [
      "Pick-up from Bengaluru Airport / Railway station and transfer to Hotel",
      "Visit Lalbagh Gardens, Cubbon Park, Bull Temple, Visveswaraya Industrial & Technology Museum, Vidhana Soudha, ISKCON Temple in Bengaluru",
      "Departure to Mysore",
      "Local visits in Mysore: Sri Rangapatna - Tippu Sultan’s Island capital, Palace, Fort, Chamundeswari Temple, Maharaja’s Palace, and Sound & Light fountains at Brindavan Garden",
      "Proceed to Madikere (Coorg)",
      "Transfer to Hotel in Madikere",
      "Leisure in Madikere in the evening",
      "Local visit in Madikere: Talacauvery, Fort, Abbey Waterfalls",
      "Departure to Bengaluru and drop at Airport / Railway station",
    ],
  },
  {
    packageId: "PTN_PkG_0004",
    title:
      "Majestic Karnataka With Heritage – Divine Temples – Beach – Waterfalls 6N/7D Package",
    header: "India",
    imageUrl: "/PTN_PkG_0004.webp",
    cities: 14,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
      // { icon: "fas fa-plane", label: "Flight" },
    ],
    duration: "6N/7D",
    startingPrice: 21000,
    text: "per person",
    destinations:
      "Hassan, Mangalore, Udupi, Gokarna, Murudeshwara, Sringeri, Subramanya, Horanadu, Bengaluru, Dharmasthala, Shravanabelagola, Jogg Falls, Beluru, Halebidu",
    items: [
      "Pick-up from Bengaluru Airport / Railway station and transfer to Hotel in Bengaluru",
      "Local visits in Bengaluru: Lalbagh, Cubbon Park, Bull Temple, Visveswaraya Industrial & Technology Museum, Vidhana Soudha, ISKCON Temple",
      "Departure to Hassan",
      "Enroute visit Shravanabelagola",
      "Transfer to Hotel in Hassan",
      "Departure to Mangalore",
      "Enroute visit Kukke Subramanya, Dharmasthala",
      "Transfer to Hotel in Mangalore",
      "Local visits in Mangalore: Mangala Devi Temple, Gokarananatheshwara Temple, Kadri Park, Tanneerbhavi Beach",
      "Departure to Udupi",
      "Enroute visit Horanadu, Kalasa, Kudremukh National Park, Sringeri",
      "Transfer to Hotel in Udupi",
      "Departure to Gokarna",
      "Enroute visit Kollur Mookambika Temple, Murudeshwar",
      "Transfer to Hotel in Gokarna",
      "Local visit in Gokarna",
      "Departure to Hassan",
      "Enroute visit Jog falls, Belur, Halebidu",
      "Transfer to Hotel in Hassan",
      "Departure to Bengaluru and drop at Airport/Railway station",
    ],
  },
  {
    packageId: "PTN_PkG_0005",
    title:
      "Incredible Ancient Heritage Tour To Hampi-Hospet-Aihole-Pattadakal-Badami 3N/4D Package",
    header: "India",
    imageUrl: "/PTN_PkG_0005.webp",
    cities: 7,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
      // { icon: "fas fa-plane", label: "Flight" },
    ],
    duration: "3N/4D",
    startingPrice: 16000,
    text: "per person",
    destinations: "Hampi, Pattadakal, Aihole, Badami, Bellary, Hospet",
    items: [
      "Pick-up from Bengaluru Airport / Railway station and transfer to Hampi hotel",
      "Local sightseeing in and around Hampi covering Virupaksha Swamy Temple, Museum, Vijaya Vittala Temple, Mahanavami Dibba, Narasimha Swamy Temple, Hazara Rama Temple, Royal Palace, Raghunath Temple etc.",
      "Proceed to Aihole, a peaceful village acclaimed as the cradle of Hindu temple architecture",
      "Visit Durga temple, Lord Shiva Temple, Kontigudi temples in Aihole",
      "Proceed to Pattadakal, a UNESCO World Heritage site known for its beautifully chiseled temples",
      "Visit the 8th century temples of Jambu Linga, Kada Siddeshvara and Galaganatha in Pattadakal",
      "Proceed to Badami, known for its exquisite sculptures and Red Sandstone cliffs",
      "Visit Cave temples, Badami Fort, Agastya Lake, and Tippu's Fort in Badami",
      "Transfer to a hotel in Badami for night stay",
      "Check out from the hotel and depart back to Bellary",
      "Transfer to Airport / Railway Station for the return journey",
    ],
  },
  {
    packageId: "PTN_PkG_0006",
    title: "SpritualL Divine Tamilnadu 5N/6D Package",
    header: "India",
    imageUrl: "/PTN_PkG_0006.webp",
    cities: 8,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-utensils", label: "Meals" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-bus", label: "Transport" },
      // { icon: "fas fa-plane", label: "Flight" },
    ],
    duration: "5N/6D",
    startingPrice: 25950,
    text: "per person",
    destinations:
      "Kanyakumari, Thanjavur, Rameswaram, Trichy, Madurai, Mahabalipuram, Pondicherry, Srirangam",
    items: [
      "Arrive Chennai and transfer to the Hotel",
      "Sightseeing tour of Chennai, visiting Kapaleshwar Temple, Fort St. George, Government Museum, and Marina Beach",
      "Proceed to Tanjore, enroute visit Mahabalipuram and Pondicherry",
      "Visit Brihadeshwara Shiva Temple in Tanjore",
      "Proceed to Trichy and visit the largest temple of Lord Vishnu at Srirangam",
      "Depart to Rameswaram, check into hotel upon arrival",
      "Visit Ramanath Swamy Temple and take a bath in 22 holy wells",
      "Proceed to Kanyakumari, visit Vivekananda Rock and enjoy Sunrise and Sunset",
      "Visit Thanumalayam Temple in Suchindram",
      "Proceed to Madurai, check into the Hotel",
      "Visit the famous Meenakshi Temple in Madurai",
      "Morning checkout from the hotel and proceed to Bengaluru",
    ],
  },
  {
    packageId: "PTN_PkG_0007",
    title: "Kerala Backwaters Alappuzha Resort & House 3N/4D package",
    header: "India",
    imageUrl: "/PTN_PkG_0007.webp",
    cities: 2,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Stay " },
      // { icon: "fas fa-ship", label: "Luxury houseboat" },
      { icon: "fas fa-swimmer", label: "Swimming Pool" },
      {
        icon: "fas fa-utensils",
        label: "food",
      },
      {
        icon: "fas fa-glass-cheers",
        label: "Candlelight",
      },
      // {
      //   icon: "fas fa-spa",
      //   label: "Complementary Full Body Massage for couple",
      // },
      { icon: "fas fa-route", label: "Cruise" },
      // { icon: "fas fa-sun", label: "Evening Sunset Cruise" },
    ],
    duration: "3N/4D",
    startingPrice: 41999,
    text: "Per Couple",
    destinations: "Kerala, Alleppy",
    items: [
      "Enjoy a relaxing stay in a resort on the banks of Vembanad Lake",
      "Experience luxury houseboat stay and cruise in the backwaters",
      "Indulge in complementary candlelight dinner and full-body massage for the couple",
      "Access to swimming pool and other facilities in the resort",
      "Explore the beauty of Alleppey with a sunset cruise",
    ],
  },
  {
    packageId: "PTN_PkG_0008",
    title: "Green Queen Wayand 2N/3D 3N/4D Package",
    header: "India",
    imageUrl: "/PTN_PkG_0008.webp",
    cities: 1,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Resort" },
      { icon: "fas fa-utensils", label: "Meals" },
      { icon: "fas fa-swimmer", label: "Infinity Swimming Pool" },
      // { icon: "fas fa-bicycle", label: "Cycling" },
      { icon: "fas fa-fire", label: "Campfire" },
      // { icon: "fas fa-hiking", label: "Trekking" },
      // { icon: "fas fa-binoculars", label: "Bird Watching" },
      { icon: "fas fa-tree", label: "Nature Walk" },
      // { icon: "fas fa-glass-cheers", label: "Candle Light Dinner" },
    ],
    duration: "2N/3D",
    startingPrice: 27700,
    text: "Per Couple",
    destinations: "Kerala, Wayanad",
    items: [
      "Check-In at resort and enjoy cozy wooden cottage accommodation",
      "Traditional lunch and evening snacks at the resort",
      "Campfire and candlelight dinner in the evening",
      "Option to upgrade to a suit room with special amenities",
      "Guided trekking among cardamom plantations",
      "Refresh with breakfast and opt for half day sightseeing or aromatherapy massage",
      "Enjoy activities like boating at Pookode lake and visit Chain Tree and View Point",
      "Dine together and watch fireflies dance at the campfire",
      "Scrumptious breakfast and check out from the resort",
    ],
  },
  {
    packageId: "PTN_PkG_0009",
    title: "Green Queen Wayand 3N/4D Honeymoon Package",
    header: "India",
    imageUrl: "/PTN_PkG_0009.webp",
    cities: 1,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Resort" },
      { icon: "fas fa-utensils", label: "Meals" },
      { icon: "fas fa-swimmer", label: "Infinity Swimming Pool" },
      // { icon: "fas fa-bicycle", label: "Cycling" },
      { icon: "fas fa-fire", label: "Campfire" },
      // { icon: "fas fa-hiking", label: "Trekking" },
      // { icon: "fas fa-binoculars", label: "Bird Watching" },
      { icon: "fas fa-tree", label: "Nature Walk" },
      // { icon: "fas fa-glass-cheers", label: "Candle Light Dinner" },
    ],
    duration: "3N/4D",
    startingPrice: 36000,
    text: "Per Couple",
    destinations: "Kerala, Wayanad",
    items: [
      "Check-In at resort and enjoy leisure time followed by lunch",
      "Visit to nearby Waterfall",
      "Explore Banasura Sagar dam and Tea Plantation",
      "Visit Pookode lake and enjoy various boating options",
      "Explore Kuruva Island and Tholpetty Wildlife Sanctuary",
      "Visit Thirunelly Temple",
      "Explore Edakkal Caves before check-out",
    ],
  },
  {
    packageId: "PTN_PkG_0010",
    title: "Discover Paradise On The Earth Kashmir 4N/5D package",
    header: "India",
    imageUrl: "/PTN_PkG_0010.webp",
    cities: 4,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      {
        icon: "fas fa-utensils",
        label: "Meals",
      },
      { icon: "fas fa-bus", label: "Transportation" },
      { icon: "fas fa-ship", label: "Shikara Ride" },
    ],
    duration: "4N/5D",
    startingPrice: 27999,
    text: "Per Couple",
    destinations: "Srinagar, Gulmarg, Pahalgam, Sonmarg",
    items: [
      "Meet and greet on arrival",
      "Pick up and drop back Srinagar airport",
      "Double room accommodation for 3 nights in hotels",
      "Deluxe houseboat accommodation for 1 night in Srinagar",
      "Room, dinner, and breakfast meal plan",
      "Transportation by Pvt Car Non AC",
      "Complimentary Shikara Ride at Dal Lake",
      "No additional taxes",
      "Visit Nishat Garden, Shalimar Garden, Chashme Shahi, and Parimahal in Srinagar",
      "Drive to Gulmarg and visit Jai Jai Shiv Shankar Temple",
      "Enjoy Gondola ride from Gulmarg to Kongdori and Affarwat",
      "Drive to Pahalgam and enjoy Lidder stream",
      "Walk along pony trails with a view of the mountains in Pahalgam",
      "Hire ponies to visit mini-Switzerland, green valley, Kashmir valley, Bobby house in Pahalgam",
      "Visit Betab valley, Aru, and Chandanwari in Pahalgam",
      "Drive to Sonamarg and enjoy breathtaking scenery",
      "Hire ponies for a trip to Thajiwas Glacier in Sonamarg",
      "Check-out from hotel and transfer to Srinagar airport",
    ],
  },
  {
    packageId: "PTN_PkG_0011",
    title: "Discover Heaven On The Earth Kashmir 7N/8D package",
    header: "India",
    imageUrl: "/PTN_PkG_0011.webp",
    cities: 4,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      {
        icon: "fas fa-utensils",
        label: "Meals Included",
      },
      { icon: "fas fa-car", label: "Transportation" },
      { icon: "fas fa-ship", label: "Shikara Ride" },
    ],
    duration: "7N/8D",
    startingPrice: 74900,
    text: "Per Couple",
    destinations: "Srinagar, Gulmarg, Pahalgam, Sonmarg",
    items: [
      "Meet and greet on arrival",
      "Pick up and drop back Srinagar airport",
      "Triple room accommodation for 6 nights",
      "Houseboat in Srinagar for 1 night",
      "Room, dinner, and breakfast meal plan",
      "Complimentary Shikara Ride at Dal Lake",
      "Vegetarian meals",
      "Visit Nishat Garden and Shalimar Garden in Srinagar",
      "Visit Cheshme shahi, Parimahal, and Shanakracharya Temple in Srinagar",
      "Drive to Pahalgam and enjoy Lidder stream",
      "Walk along pony trails with a view of the mountains in Pahalgam",
      "Hire ponies to visit mini-Switzerland, green valley, Kashmir valley, Bobby house in Pahalgam",
      "Visit Betab valley, Aru, and Chandanwari in Pahalgam",
      "Drive to Gulmarg and visit Jai Jai Shiv Shankar Temple",
      "Enjoy Gondola ride from Gulmarg to Kongdori and Affarwat",
      "Drive to Sonamarg and enjoy breathtaking scenery",
      "Hire ponies for a trip to Thajiwas Glacier in Sonamarg",
      "Drive back to Srinagar, check in to House Boat",
      "Enjoy Shikara ride and evening visit to market for shopping and sight-seeing",
      "Check out from House Boat and transfer to Srinagar airport",
    ],
  },
  {
    packageId: "PTN_PkG_0012",
    title: "The Princess of Hill-Station Kodaikanal 4N/5D Honeymoon Package",
    header: "India",
    imageUrl: "/PTN_PkG_0012.webp",
    cities: 1,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      {
        icon: "fas fa-utensils",
        label: "Meals",
      },
      // { icon: "fas fa-map-marked-alt", label: "Kodaikanal Sightseeing" },
      // {
      //   icon: "fas fa-mountain",
      //   label:
      //     "Bear Shola Falls, La Saleth Church, Dolphin Nose, Vattakanal Falls, Fairy Falls, Loin Cave",
      // },
      {
        icon: "fas fa-map-marked-alt",
        label: "Sightseeing",
      },
      // {
      //   icon: "fas fa-binoculars",
      //   label: "Observatory Pine Forest, Poombarai Village View",
      // },
      // { icon: "fas fa-tree", label: "500 Years Old Tree, Pine Tree Forest" },
      // { icon: "fas fa-bicycle", label: "Kodai Lake Cycling and Horse Riding" },
    ],
    duration: "4N/5D",
    startingPrice: 34900,
    text: "Per Couple",
    destinations: "Kodaikanal",
    items: [
      "Stay in a private wooden cottage overlooking Kodaikanal Lake",
      "Personal chef available for home cooked meals or In-House restaurant",
      "Visit to Kodai city view, Kurinji Andavar Temple, Palani view, Chettiar Park, Jain Temple, Museum, Silver Waterfalls, and Kodai Lake",
      "Explore Bear Shola Falls, La Saleth Church, Dolphin Nose, Vattakanal Falls, Fairy Falls, and Loin Cave",
      "Discover Gundar Falls, Mahalakshmi Temple, Poombarai Village View, Kulandai Velappar Temple, and Mannavanur Lake",
      "Enjoy Observatory Pine Forest, 500 Years Old Tree, Green Valley View, Golf Course, Pillar Rocks, Devil's Kitchen, Moier Point, Upper Lake View, Bryant Park",
      "Experience Kodai Lake Cycling and Horse Riding",
    ],
  },
  {
    packageId: "PTN_PkG_0013",
    title: " Munnar – Thekkady – Alappuzha 4N/5D",
    header: "India",
    imageUrl: "/PTN_PkG_0013.webp",
    cities: 3,
    months: null,
    features: [
      { icon: "fas fa-car", label: "Taxi" },
      { icon: "fas fa-hotel", label: "Hotel" },
      {
        icon: "fas fa-map-marked-alt",
        label: "Sightseeing ",
      },
      {
        icon: "fas fa-hiking",
        label: "Hiking",
      },
      // {
      //   icon: "fas fa-tree",
      //   label:
      //     "Activities in Periyar Tiger Reserve for Nature Experience in Thekkady",
      // },
      {
        icon: "fas fa-ship",
        label: "Houseboat",
      },
    ],
    duration: "4N/5D",
    startingPrice: 3000,
    text: "Per Night",
    destinations: "Munnar – Thekkady - Alleppey",
    items: [
      "Explore the beauty of Munnar, Thekkady, and Alleppey",
      "Enjoy waterfalls, tea estates, and rivers in Munnar",
      "Experience the wilderness of Periyar Tiger Reserve in Thekkady",
      "Cruise through the backwaters of Alleppey in a houseboat",
    ],
  },
  {
    packageId: "PTN_PkG_0014",
    imageUrl: "/PTN_PkG_0014.webp",
    title: "Divine SpritualL TOUR- VARANASI, AYODHYA 7N/8D Package",
    header: "India",
    cities: 5,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "7N/8D",
    startingPrice: 31999,
    text: "per person",
    destinations: "Varanasi, Prayagraj, Ayodhya, Lucknow, Nimisharanya",
    items: [
      "Witness Ganga Aarti at Dashashwamedh Ghat",
      "Visit Kashi Vishwanath Temple, Vishalakshi Temple & Annapurna temple",
      "Visit Baba Kal Bhairav, Sankat Mochan, Tulsi Manas Temple, Durga Ji and Birla Temple",
      "Drive to Sarnath and visit Dhamek Stupa, Sarnath Archaeological Museum, Tibetan Buddhist Monastery, Ashoka Pillar",
      "Evening visit to Namo ghat statues",
      "Drive to Vindhyachal and visit Goddess Vindhyavasini temple",
      "Transfer to Chitrakoot",
      "Visit Kamadgiri Temple, Sati Anusuiya Temple, Gupt Godavari, Ramghat, Spathik Shila, Hanuman Dhara",
      "Visit Sangam - the confluence of Ganga, Yamuna, and Saraswati rivers",
      "Explore local sightseeing including Hanuman Mandir, Anand Bhawan, Akshay vat",
      "Visit birthplace of Lord Rama, Saryu River, Shri Hanuman Garhi Mandir, Dashrath Bhawan, Kanak Bhawan",
      "Drive to Naimisharanya",
      "Explore local sightseeing including Chakra Teerth, Dadhichi Kund, Vyas Gaddi, Sooth Gaddi, Swayambhu Manu and Satrupa, Hanuman Gaddi and Pandav Kila",
      "Explore Moti Mahal, La Martiniere, Bara Imam Bara & Chhota Imam Bara",
    ],
  },{
    packageId: "PTN_PkG_0016",
    imageUrl: "/PTN_PkG_0016.webp",
    title: "Adorable Europe(Milan Departure) - 2024 Europe",
    header: "Europe",
    cities: 9,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "12N/13D",
    startingPrice: 201315,
    text: "Per Person",
    destinations:
      "France, Belgium, The Netherlands, Germany, Switzerland, Liechtenstein, Austria, Italy and Vatican City",
    items: [
      "Visit to the 3rd level of the Eiffel Tower",
      "Guided tour of Versailles Palace",
      "Full day excitement and thrill at Disneyland® Paris",
      "Enjoy romantic cruise on River Seine",
      "Illumination tour of Paris",
      "Entrance to Keukenhof (till 12th May)",
      "Entrance to Madurodam (from 13th May onwards)",
      "Mercedes Benz Museum visit",
      "Horse-drawn carriage ride at Interlaken",
      "Excursion to Jungfraujoch - Top of Europe",
      "Entrance to Mt. Titlis",
      "Swiss Chocolate Adventure in Lucerne",
      "Mini Train ride in Vaduz",
      "Swarovski Crystal Museum visit and shop",
      "Gondola ride in Venice",
      "Guided walking city tour in Florence",
      "Visit to Square of Miracles in Pisa",
      "Guided City tour with a local English speaking guide in Rome",
      "Visit to Vatican Museum & Sistine Chapel with Local guide",
    ],
  },
  {
    packageId: "PTN_PkG_0017",
    imageUrl: "/PTN_PkG_0017.webp",
    title: "Best of Europe (Rome Departure) - 12 Nights 13 Days - 2024",
    header: "Europe",
    cities: 9,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "12N/13D",
    startingPrice: 201315,
    text: "Per Person",
    destinations:
      "France, Belgium, The Netherlands, Germany, Switzerland, Liechtenstein, Austria, Italy and Vatican City",
    items: [
      "Visit to the 3rd level of the Eiffel Tower",
      "Guided tour of Versailles Palace",
      "Full day excitement and thrill at Disneyland® Paris",
      "Enjoy romantic cruise on River Seine",
      "Illumination tour of Paris",
      "Entrance to Keukenhof (till 12th May)",
      "Entrance to Madurodam (from 13th May onwards)",
      "Mercedes Benz Museum visit",
      "Horse-drawn carriage ride at Interlaken",
      "Excursion to Jungfraujoch - Top of Europe",
      "Entrance to Mt. Titlis",
      "Swiss Chocolate Adventure in Lucerne",
      "Mini Train ride in Vaduz",
      "Swarovski Crystal Museum visit and shop",
      "Gondola ride in Venice",
      "Guided walking city tour in Florence",
      "Visit to Square of Miracles in Pisa",
      "Guided City tour with a local English speaking guide in Rome",
      "Visit to Vatican Museum & Sistine Chapel with Local guide",
    ],
  },
  {
    packageId: "PTN_PkG_0018",
    imageUrl: "/PTN_PkG_0018.webp",
    title: "European Highlights",
    header: "Europe",
    cities: 5,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "9N/10D",
    startingPrice: 129822,
    text: "Per Adult Double occupancy",
    destinations: "France, Switzerland, Austria, Italy, Vatican City",
    items: [
      "Arrive into Paris – the City of Romance, Lights and Glamour",
      "Guided city tour of Paris. Visit to Eiffel tower 2nd Level. Enjoy a romantic cruise on River Seine. Visit Fragonard French perfumery",
      "Drive to scenic Switzerland",
      "Day at leisure in Switzerland",
      "Visit Mt. Titlis- enjoy Cable car rides including the world’s first rotating cable car, the Rotair – to the top of Mt. Titlis at 3,020 metres. Orientation tour of Lucerne. Free time for shopping",
      "See the largest Waterfall in Switzerland - The Rhine Falls. Visit Swarovski Crystal Museum. Orientation tour of Innsbruck",
      "Welcome to Venice - the floating city of Italy",
      "Photo stop at Piazzale Michelangelo",
      "Day trip to the eternal city of Rome. Visit the world’s smallest country – the Vatican City and St. Peter’s Basilica only",
      "A visit to the world-famous Leaning Tower of Pisa. Fly back home",
    ],
  },

  {
    packageId: "PTN_PkG_0019",
    imageUrl: "/PTN_PkG_0019.webp",
    title: "European Highlights -Milan Departure 9 Nights 10 Days",
    header: "Europe",
    cities: 5,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "9N/10D",
    startingPrice: 129706,
    text: "Per Person",
    destinations: "France, Switzerland, Austria, Italy, Vatican City",
    items: [
      "Arrive into Paris – the City of Romance, Lights and Glamour",
      "Guided city tour of Paris. Visit to Eiffel tower 2nd Level. Enjoy a romantic cruise on River Seine. Visit Fragonard French perfumery",
      "Drive to scenic Switzerland",
      "Day at leisure in Switzerland",
      "Visit Mt. Titlis- enjoy Cable car rides including the world’s first rotating cable car, the Rotair – to the top of Mt. Titlis at 3,020 metres. Orientation tour of Lucerne. Free time for shopping",
      "See the largest Waterfall in Switzerland - The Rhine Falls. Visit Swarovski Crystal Museum. Orientation tour of Innsbruck",
      "Welcome to Venice - the floating city of Italy",
      "Photo stop at Piazzale Michelangelo",
      "Day trip to the eternal city of Rome. Visit the world’s smallest country – the Vatican City and St. Peter’s Basilica only",
      "A visit to the world-famous Leaning Tower of Pisa. Fly back home",
    ],
  },
  {
    packageId: "PTN_PkG_0020",
    imageUrl: "/PTN_PkG_0020.webp",
    title: "Exclusive Europe",
    header: "Europe",
    cities: 4,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "10N/11D",
    startingPrice: 171591,
    text: "Per Person",
    destinations: "France, Switzerland, Austria, Italy, Vatican City",
    items: [
      "Arrive into Paris - the City of Romance, Lights and Glamour",
      "Visit to Eiffel tower 3rd (Top) Level. Visit the Palace of Versailles, a testament to the pinnacle of French 17th-century artistry and achievement. Enjoy a romantic cruise on the river Seine",
      "Enjoy your fun filled day at Disneyland® Paris choice of Disney® Park or Walt Disney Studios® Park",
      "Drive to the scenic Switzerland",
      "Visit Mt. Titlis- enjoy Cable car rides including the world’s first rotating cable car, the Rotair – to the top of Mt. Titlis at 3,020 metres. Enjoy a scenic cruise on Lake Lucerne",
      "Magical Alpine excursion to the top of Europe",
      "Visit the magnificent Rhine Falls & Swarovski Crystal Museum – the dazzling world of crystals. Visit Innsbruck – The Tyrolean capital of picturesque Austria",
      "Welcome to Venice - the floating city of Italy. Enjoy Gondola ride",
      "Guided walking city tour of Florence. View the remarkable and famous Leaning Tower of Pisa",
      "All roads lead to Rome – the eternal city. Visit the world’s smallest country – the Vatican City. (which includes Vatican Museum, Sisitne Chapel & St. Peter’s Basilica)",
      "Fly back home",
    ],
  },
  {
    packageId: "PTN_PkG_0021",
    imageUrl: "/PTN_PkG_0021.webp",
    title: "Fascinating Europe",
    header: "Europe",
    cities: 4,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "8N/9D",
    startingPrice: 143217,
    text: "Per Person",
    destinations: "France, Switzerland, Austria, Germany",
    items: [
      "Arrive into Paris - the City of Romance, Lights and Glamour",
      "Guided city tour of Paris. Visit to Eiffel tower 3rd (Top) Level. Visit the Palace of Versailles, a testament to the pinnacle of French 17th-century artistry and achievement. Enjoy a romantic cruise on the river Seine",
      "Enjoy your fun filled day at Disneyland® Paris choice of Disney® Park or Walt Disney Studios® Park",
      "Drive to Scenic Switzerland",
      "Visit Mt. Titlis- enjoy Cable car rides including the world’s first rotating cable car, the Rotair – to the top of Mt. Titlis at 3,020 meters. Enjoy a scenic cruise on Lake Lucerne",
      "Enjoy a magical alpine excursion to the top of Europe - the amazing Jungfraujoch inlcuded in your tour cost and scenic Interlaken",
      "Visit the magnificent Rhine Falls. Visit Swarovski Crystal Museum – the dazzling world of crystals. Visit Innsbruck – The Tyrolean capital of picturesque Austria",
      "Visit Salzburg for a guided tour. Drive to Munich. Visit BMW Velt",
      "Fly back home",
    ],
  },
  {
    packageId: "PTN_PkG_0022",
    imageUrl: "/PTN_PkG_0022.webp",
    title: "Glimpses of United Kingdom",
    header: "United Kingdom",
    cities: 4,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "5N/6D",
    startingPrice: 102684,
    text: "Per Person",
    destinations: "Scotland, England",
    items: [
      "Arrive into Glasgow the gateway to Scotland",
      "Visit the Edinburgh Castle. Cruise on Lake Loch Lomond",
      "Enjoy Scottish Distillery tour. Lake Windermere. Enjoy Cruise on Lake Windermere. Drive to Manchester.",
      "Stratford-upon-Avon. Entrance to Windsor castle. Onto London",
      "Guided city tour of London with Changing of Guards. Visit to the famous Madame Tussauds Wax Museum. Ride the London Eye",
      "Fly back home",
    ],
  },
  {
    packageId: "PTN_PkG_0023",
    imageUrl: "/PTN_PkG_0023.webp",
    title:
      "Grand Wonders of Europe (Rome Departure) - 14 Nights 15 Days - 2024",
      header: "Europe",
    cities: 10,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "14N/15D",
    startingPrice: 239146,
    text: "Per Person",
    destinations:
      "United Kingdom, France, Belgium, The Netherlands, Germany, Switzerland, Liechtenstein, Austria, Italy and Vatican City",
    items: [
      "Visit to the 3rd level of the Eiffel Tower",
      "Guided tour of Versailles Palace",
      "Full day excitement and thrill at Disneyland® Paris",
      "Enjoy romantic cruise on River Seine",
      "Paris by Night tour",
      "Entrance to Keukenhof Gardens (till 12th May)",
      "Entrance to Madurodam (from 13th May onwards)",
      "Visit Mercedes Benz Museum",
      "Horse-drawn carriage ride at Interlaken",
      "Excursion to Jungfraujoch - Top of Europe",
      "Entrance to Mt. Titlis",
      "Swiss Chocolate Adventure in Lucerne",
      "Mini Train ride in Vaduz",
      "Swarovski Crystal Museum visit",
      "Gondola ride in Venice",
      "Guided walking city tour in Florence",
      "Visit to Square of Miracles in Pisa",
      "Guided City tour with a local English speaking guide in Rome",
      "Visit to Vatican Museum & Sistine Chapel with Local guide",
    ],
  },
  {
    packageId: "PTN_PkG_0024",
    imageUrl: "/PTN_PkG_0024.webp",
    title:
      "Grand Wonders of Europe (Milan Departure) - 14 Nights 15 Days - 2024",
      header: "Europe",
    cities: 10,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "14N/15D",
    startingPrice: 239146,
    text: "Per Person",
    destinations:
      "United Kingdom, France, Belgium, The Netherlands, Germany, Switzerland, Liechtenstein, Austria, Italy and Vatican City",
    items: [
      "Visit to the 3rd level of the Eiffel Tower",
      "Guided tour of Versailles Palace",
      "Full day excitement and thrill at Disneyland® Paris",
      "Enjoy romantic cruise on River Seine",
      "Paris by Night tour",
      "Entrance to Keukenhof Gardens (till 12th May)",
      "Entrance to Madurodam (from 13th May onwards)",
      "Visit Mercedes Benz Museum",
      "Horse-drawn carriage ride at Interlaken",
      "Excursion to Jungfraujoch - Top of Europe",
      "Entrance to Mt. Titlis",
      "Swiss Chocolate Adventure in Lucerne",
      "Mini Train ride in Vaduz",
      "Swarovski Crystal Museum visit",
      "Gondola ride in Venice",
      "Guided walking city tour in Florence",
      "Visit to Square of Miracles in Pisa",
      "Guided City tour with a local English speaking guide in Rome",
      "Visit to Vatican Museum & Sistine Chapel with Local guide",
    ],
  },
  {
    packageId: "PTN_PkG_0025",
    title: "Jewels of East Europe 6 Nights 7 Days - 2024",
    header: "Europe",
    imageUrl: "/PTN_PkG_0025.webp",
    cities: 5,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
    ],
    duration: "6N/7D",
    startingPrice: 90524,
    text: "Per Person",
    destinations: "Austria, Hungary, Slovakia, Czech & Germany",
    items: [
      "Guided city tour of Vienna",
      "Visit the Opera House",
      "Entrance to Schonbrunn Palace",
      "Guided city tour of Budapest",
      "Visit the revolutionary Hero’s square",
      "Danube River Cruise",
      "Orientation tour of Bratislava",
      "Guided city tour of Prague",
      "Guided tour of Prague castle",
      "View the St. Vitus Cathedral",
      "Visit Charles Bridge",
      "See the Astronomical Clock & the Old Town Square",
      "Orientation tour of Munich",
      "Visit the BMW Welt",
    ],
  },
  {
    packageId: "PTN_PkG_0026",
    title: "Scandinavian Delights",
    header: "Europe",
    imageUrl: "/PTN_PkG_0026.webp",
    cities: 4,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
      { icon: "fas fa-plane", label: "Flight" },
    ],
    duration: "8N/9D",
    startingPrice: 194109,
    text: "Per Person",
    destinations: "Denmark, Norway, Sweden & Finland",
    items: [
      "Entrance to Tivoli Gardens",
      "Guided city tour of Copenhagen",
      "Enjoy a Nyhaven canal cruise",
      "Overnight cruise from Copenhagen to Oslo in Inside stateroom on DFDS",
      "Guided city tour of Oslo",
      "Entrance to the Museum of Cultural History",
      "Visit to the Holmenkollen Ski Jump",
      "Visit to the Vigeland Sculpture Park",
      "Enjoy Norway in a nutshell tour with a beautiful Fjord cruise, scenic train journey and an amazing drive",
      "Orientation tour of Karlstad",
      "Guided city tour of Stockholm",
      "Entrance to the Vasa Museum",
      "Enjoy a Gondola ride upto the Skyview",
      "Guided tour of the Drottningholm Palace",
      "Overnight cruise from Stockholm to Helsinki in inside stateroom on Silja Line cruise",
      "Guided city tour of Helsinki",
      "Entrance to the Rock Church",
    ],
  },
  {
    packageId: "PTN_PkG_0027",
    title: "Spanish Fiesta with Portugal",
    header: "Spain",
    imageUrl: "/PTN_PkG_0027.webp",
    cities: 10,
    months: null,
    features: [
      { icon: "fas fa-hotel", label: "Hotel" },
      { icon: "fas fa-hamburger", label: "Food" },
      { icon: "fas fa-camera", label: "Sightseeing" },
      { icon: "fas fa-shuttle-van", label: "Transport" },
      { icon: "fas fa-plane", label: "Flight" },
    ],
    duration: "10N/11D",
    startingPrice: 175644,
    text: "Per Person",
    destinations:
      "Lisbon, Sintra, Seville, Cordoba, Granada, Malaga, Toledo, Madrid, Valencia, Barcelona",
    items: [
      "City tour with a local guide in each city",
      "Photo-stop at Belem Tower in Lisbon",
      "Orientation tour of Sintra",
      "Experience the Flamenco Show in Seville",
      "Guided city tour of Seville",
      "Guided city tour of Cordoba",
      "Guided city tour of Granada",
      "Entrance to Alhambra Palace and Generalife Gardens in Granada",
      "Orientation tour of Malaga",
      "Guided city tour of Madrid",
      "Photo-stop at the Royal Palace in Madrid",
      "Entrance to Bullfighting Ring in Madrid",
      "Guided city tour of Valencia",
      "Guided city tour of Barcelona",
      "Entrance to Sagrada Familia in Barcelona",
      "Excursion to Montserrat",
      "Free time at Las Ramblas in Barcelona",
    ],
  },
];

  const toggleShowMore = (index) => {
    setShowMoreStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  const toggleShowMoreList = (index) => {
    setShowMoreListStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  const truncateContent = (content) => {
    if (content.length > maxLength) {
      return content.slice(0, maxLength) + "...";
    }
    return content;
  };
  const toProperCase = (sentence) => {
    return sentence.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  };

  const convertPriceToUserCurrency = (price) => {
    const userCountry = localStorage.getItem("userCountry"); // Retrieve user's country from local storage
    let symbol = "";
    let convertedPrice = price;

    switch (userCountry) {
      case "India":
        symbol = "₹";
        convertedPrice = price; 
        break;
      case "Australia":
        symbol = "AUD";
        convertedPrice = price / 55; 
        break;
        case "User Denied":
          symbol = "₹";
          convertedPrice = price; 
          break;
      
      default:
        symbol = "₹";
        convertedPrice = price;
        break;
    }

    return (
      symbol +
      " " +
      convertedPrice.toLocaleString("en-US", { maximumFractionDigits: 2 })
    );
  };
  const handleFilterClick = (packageId) => {
    setSelectedPackageId(packageId);
  };
  const filteredPackages = selectedPackageId
    ? packageData.filter(
        (packageItem) => packageItem.header === selectedPackageId
      )
    : packageData;

    const uniqueTitles = Array.from(
      new Set(packageData.map((packageItem) => packageItem.header))
    );
  return (
    <>
      <Helmet>
        <title>Europe Package</title>
        <meta
          name="keywords"
          content="Europe Tour Packages, European Vacation Deals, Travel to Europe, Explore Europe Tours, Best European Tours, Affordable Europe Trips, Europe Holiday Packages, Custom Europe Tours, Luxury Europe Travel, Guided Europe Tours."
        />
      </Helmet>
      <br />
      <Back />
      <div
        className="container-fluid"
        style={{ boxShadow: "none", textAlign: "left" }}
      >
        <div className="StickySectionPackage">
          <div className="heading_package">{pageTitle}</div>
          <div className="sub-heading-package">{subPageTitlle}</div>
          <div>
            <p className="explanation">{explanation}</p>
          </div>
          <hr />
        </div>
        <div className="packagesection" style={{ display: "flex" }}>
          <div className="sortandfilter">
          <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>Package Search</h4>
              <button
                onClick={() => setSelectedPackageId(null)}
                style={{
                  background: "none",
                  border: "none",
                  // borderRadius: "5px",
                  // padding: "5px 10px",
                  cursor: "pointer",
                  outline: "none",
                  color:"white",
                  position:"relative",
                  top:"-18px",
                  fontWeight:"500"
                }}
              >
                Clear Selection
              </button>
            </div>
            
            <select
              onChange={(e) => handleFilterClick(e.target.value)}
              style={{
                width: "100%",
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid brown",
                cursor: "pointer",
                outline: "none",
              }}
              value={selectedPackageId || ""}
            >
              <option value="">--Select Package--</option>
              {uniqueTitles.map((title) => (
                <option key={title} value={title}>
                  {title}
                </option>
              ))}
            </select>
            
          </div>
          <div className="show-packages" style={{ width: "80%" }}>
            {filteredPackages.map((packageItem, index) => (
              <div className="package-container" key={index}>
                <div
                  className="package-item"
                  style={{
                    marginBottom: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={packageItem.imageUrl}
                      style={{
                        width: "400px",
                        objectFit: "cover",
                        height: "380px",
                        borderRadius: "10px",
                      }}
                      alt="Package"
                    />
                  </div>
                </div>
                <div className="package-item">
                <div style={{height:"110px"}}>
                  <h5>{toProperCase(packageItem.title)}</h5>
                  <div
                    className="date"
                    style={{ display: "flex", justifyContent: "start" }}
                  >
                    {packageItem.features.map((feature, featureIndex) => (
                      <div className="monthsnamee" key={featureIndex}>
                        <i
                          className={feature.icon}
                          style={{ color: "red", fontSize: "25px" }}
                        ></i>
                        <div>{feature.label}</div>
                      </div>
                    ))}
                  </div>
                  </div>
                  <hr style={{ borderTop: "2px dotted brown" }}></hr>
                  <div className="container_des">
                    <div className="destination">
                      <label>Destinations</label>
                      <p className="destinationpara">
                        {packageItem.destinations.length > maxLength &&
                        !showMoreStates[index]
                          ? `${packageItem.destinations.slice(0, maxLength)}...`
                          : packageItem.destinations}
                        {packageItem.destinations.length > maxLength && (
                          <span
                            className="read-more"
                            onClick={() => toggleShowMore(index)}
                            style={{ color: "brown" }}
                          >
                            {showMoreStates[index]
                              ? "...View less"
                              : "Read more"}
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="highlights">
                      <label>Highlights</label>
                      <ol className="highlightslist">
                        {packageItem.items
                          .slice(
                            0,
                            showMoreListStates[index]
                              ? packageItem.items.length
                              : maxItemsToShow
                          )
                          .map((item, itemIndex) => (
                            <li key={itemIndex}>{truncateContent(item)}</li>
                          ))}
                        {packageItem.items.length > maxItemsToShow && (
                          <li>
                            <span
                              className="read-more"
                              onClick={() => handleClickReadMore(index)}
                              style={{ color: "brown" }}
                            >
                              {showMoreListStates[index]
                                ? "...View less"
                                : "Read more"}
                            </span>
                          </li>
                        )}
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="package-item">
                  <div className="container_des">
                    <div
                      className="side-by-side"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        height:"110px",
                      }}
                    >
                      <div className="label-value">
                        <label>Total Duration</label>
                        <div>{packageItem.duration}</div>
                      </div>
                      <div className="label-value">
                        <label>Starts From</label>
                        <div className="AmountPackage">
                          {packageItem.startingPrice
                            ? convertPriceToUserCurrency(
                                packageItem.startingPrice
                              )
                            : 0}
                          <p style={{ fontSize: "10px", color: "brown" }}>
                            {packageItem.text ? packageItem.text : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr style={{ borderTop: "2px dotted brown" }}></hr>
                    <div
                      className="buttons-container"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      
                      <button
                        className="email-itinerary-button"
                        onClick={() =>
                          (window.location.href =
                            "/package/packageid/" + packageItem.packageId)
                        }
                      >
                        View Itinerary
                      </button>
                      <button
                        className="enquire-now-button"
                        onClick={() => handleClickEnquireNow(packageItem)}
                      >
                        Enquire Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {clickedPackageItem && (
              <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                data={clickedPackageItem.packageId}
                title={clickedPackageItem.title}
              />
            )}
            {clickedPackageItem && (
              <HighlightModal
                isOpen={isModalOpenHighlight}
                onClose={() => setIsModalOpenHighlight(false)}
                data={clickedPackageItem.items}
              />
            )}
          </div>
        </div>
      </div>
      <ImageSection />
      <Seperators />
    </>
  );
}

export default InternationalPackage;
