/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
// import TourLogoSignup from "tour_icon2.jpg";
import toast, { Toaster } from "react-hot-toast";
import FloatingLabel from "./FloatingLabel";
// import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import "../../components/css/FloatingLable.css";

const LoginPage = () => {
  const [userData, setUserData] = useState({
    mailId: "",
    password: "",
  });

// console.log(process.env.REACT_APP_DB_URL)

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

//   const navigate = useNavigate();

  const handleSignIn = async (event) => {
    event.preventDefault();
    //  console.log(userData.password.encrip)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DB_URL}/login`,
        userData,
        { withCredentials: true }
      );

      const { user, ip } = response.data;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("UserIp", JSON.stringify(ip));

      toast.success("logged in successfully");

      window.location.href ="/";
    } catch (error) {
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.data.error === "Incorrect password"
        ) {
          toast.error("Incorrect password. Please try again.");
        } else {
          console.error("Login failed:", error.response.data.error);
          toast.error("Login failed. Please check your email and password.");
        }
      } else if (error.request) {
        console.error("Server is not responding:", error.request);
        toast.error("Server is not responding. Please try again later.");
      } else {
        console.error("Error while setting up the request:", error.message);
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  async function handleCallbackResponse(response) {
    var user = jwtDecode(response.credential);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_DB_URL}/google-login`,
        { token: response.credential, user },
        { withCredentials: true }
      );

      if (data.exists) {
        toast.success(`Welcome back! ${data.user.firstName}`);
      } else {
        toast.success(`Welcome! ${data.user.firstName}`);
      }
      localStorage.setItem("user", JSON.stringify(data.user));

      window.location.href = "/";
    } catch (error) {
      console.error("Error during Google login:", error);
      toast.error("Login failed. Please try again.");
    }
  }

  useEffect(() => {
    /* global google */
    // alert(process.env.REACT_APP_GOOGLE_CLIENT_ID);
    // alert(`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`);
    // console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID);
    // console.log(`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`);

    google.accounts.id.initialize({
      client_id:
        `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
      callback: handleCallbackResponse,
    });
    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "Large",
    });
  },[]);

  return (
    <>
      <div className="login-page-container">
        <div className="login-page-section">
          <div className="login-image-container">
            <img src={"/tour_icon2.jpg"} alt="pictournic" />
          </div>
          <div className="login-form-container">
            <form className="login-form" onSubmit={handleSignIn}>
              <div>
                <FloatingLabel
                  id="mailId"
                  label="Email Address"
                  type="email"
                  autoComplete="email"
                  required
                  name="mailId"
                  value={userData.mailId}
                  handleChange={handleChange}
                />
              </div>
              <div>
                <FloatingLabel
                  id="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  handleChange={handleChange}
                  value={userData.password}
                  required
                />
              </div>

              <div className="flex-justify-center">
                <button
                  style={{ width: "100%" }}
                  type="submit"
                  className="blue-button"
                  onClick
                >
                  Sign In
                </button>
              </div>
            </form>
            <div className="flex-justify-center">
              {/* <button
                style={{ width: "100%" }}
                onClick={() =>  window.location.href = "/SignInWithPhone"}
                type="button"
                className="green-button"
              >
                Sign In With Phone
              </button> */}
            </div>
            <hr
              style={{
                marginTop: "12px",
                marginBottom: "15px",
                borderTop: "1px solid lightgrey",
              }}
            />
            <p className="statement-link ">
              Doesn't have an account? &nbsp;
              <label
                onClick={() =>  window.location.href  ="/signup"}
                className="navigate-link m-0"
              >
                Sign Up
              </label>
            </p>
            <hr
              style={{
                marginTop: "12px",
                marginBottom: "15px",
                borderTop: "1px solid lightgrey",
              }}
            />

            <div style={{ display:'flex', justifyContent:'center', height:'45px', width:'100%' }}>
              <div id="signInDiv"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
