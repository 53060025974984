/* eslint-disable */
import React, { useState, useEffect } from "react";
import Back from "../Back";
import { withRouter } from "react-router-dom";
import axios from "../../axios";
import Throbber from "./Throbber";
import Pagination from "../Pages/Pagination";
import RemainingCatCards from "../RemainingCatCards";
import ContactusForm from "../ContactusForm";
import NotFoundPage from "../NotFoundPage";
import AllLocationsCategory from "../AllLocationsCategory";
import { Helmet } from "react-helmet";
require("dotenv").config();
import { Row } from "reactstrap";
import "../Pages/Css.css";
var PropertiesList = (props) => {
  const [properties, setproperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [postperpage] = useState(200);
  const [errorPage, seterrorPage] = useState();
  var par = props.match.params.id;
  // var heading_text = props.match.url.split("/")[1].toUpperCase();
  useEffect(() => {
    async function fetchPropertiesData() {
      try {
        // console.log("Calling DB for Second Page");
        const req = await axios.get(process.env.REACT_APP_DB_URL + "/" + par);
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // console.log(abc);
        // console.log("Comapare this result", req.data);
        setproperties(req.data);
        setLoading(true);
      } catch (error) {
        // console.log("Error is", error.response.status);
        // console.error(error);
        setLoading(true);
        seterrorPage(error.response.status);
      }
    }
    fetchPropertiesData();
    //eslint-disable-next-line
  }, []);

  // const [Pageheading] = useState([
  //   {
  //     pagetext: heading_text,
  //   },
  // ]);

  const [mainPageOptions, setmainPageOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(process.env.REACT_APP_DB_URL + "/");
        setmainPageOptions(req.data);
      } catch (error) {
        // console.log('Error is')
        console.error(error);
      }
    }
    fetchData();
  }, []);
  var all_pages_array = [];
  var all_pages = [];
  var existing_link = window.location.href;
  if (mainPageOptions !== "") {
    mainPageOptions
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map((item) => {
        // console.log('comparing ',existing_link.split('/')[3].replace('%20',' ').trim(), 'with ',item.text.trim() )
        // console.log('comparing ',typeof(existing_link.split('/')[3].replace('%20',' ')), 'with ',typeof(item.text ))
        // console.log()
        if (
          item.text.trim() !==
          existing_link.split("/")[3].replace(/%20/g, " ").trim()
        ) {
          all_pages.push(item.text);
          all_pages.push(item.thumb_ima);
          all_pages.push(item.categoryid);
          all_pages.push(item.details);
          all_pages_array.push(all_pages);
          all_pages = [];
        }
      });
    // console.log("all array");
    // console.log(all_pages_array);
    // console.log("all array");
  }

  // console.warn(props);
  // if (parseInt(props.match.params.id, 10)) {
  //   return (
  //     <>
  //       <h1>{props.match.params.id} is a Integer hence . page 3</h1>
  //     </>
  //   );
  // }

  // if (!parseInt(props.match.params.id, 10)) {
  //   //

  // }
  //Get Currentposts
  const indexOfLastPost = currentPage * postperpage;
  const indexoffirstpost = indexOfLastPost - postperpage;
  const currentPosts = properties.slice(indexoffirstpost, indexOfLastPost);
  // console.log("Postperpage");
  // console.log(postperpage);
  // console.log("Total Properties");
  // console.log(properties.length);

  //Change Page
  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  return (
    <>
      <Helmet>
        <title>{par}</title>
      </Helmet>
      {errorPage ? (
        <>
          {loading ? (
            <>
              {/* <Back /> */}
              <NotFoundPage />

              {/* <DynamicCard data={currentPosts} heading={Pageheading} /> */}

              <div className="content_pages">
                {/* <Pagination
              postsPerPage={postperpage}
              totalPosts={properties.length}
              paginate={paginate}
            /> */}
                {/* <div
              className="container"
              style={{ boxShadow: "none", borderBottom: "5px solid black" }}
            ></div> */}
                <hr></hr>
                <h4 style={{ textAlign: "center", color: "brown" }}>
                  EXPLORE more with picTOURnic <br />
                </h4>
                <h5 style={{ textAlign: "center", color: "brown", fontSize:"12px" }}>
                  Hand picked most beautiful places for Weekend Travel, Stays,
                  Corporate Events, Pre wed Shoots & many more.<br></br> Explore
                  to Extreme with Us{" "}
                  {/* <i
                    style={{ color: "#8b0000", fontSize: "20px" }}
                    className="fas fa-grin-wink"
                  ></i> */}
                </h5>{" "}
                <div className="container-fluid"> </div>
                <div>
                  <div className="container-fluid">
                    {/* <div className="row"> */}
                    <Row xs="1" sm="2" md="3" lg="5">
                      <RemainingCatCards options={all_pages_array} />
                    </Row>
                    {/* </div> */}
                    <h2
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontWeight: "600",
                      }}
                    >
                      <br />
                    </h2>
                    <h3 style={{ textAlign: "center", color: "brown" }}>
                      Not sure where to go or need help in booking or wanna know
                      more about properties?<br></br> We are here to help you!!
                      Please Submit your Request Below...
                      {/* <i
                    style={{ color: "#8b0000", fontSize: "60px" }}
                    className="fas fa-grin-wink"
                  ></i> */}
                    </h3>{" "}
                    <div className="container" style={{ boxShadow: "None" }}>
                      {" "}
                      <ContactusForm />
                    </div>
                  </div>
                </div>
              </div>
              <Back />
            </>
          ) : (
            <Throbber />
          )}
        </>
      ) : (
        <>
          {loading ? (
            <>
              <div className="container-fluid">
                <h2
                  style={{
                    textAlign: "center",
                    color: "brown",
                    marginTop: "10px",
                    fontSize: "calc(1.5vw + 10px)"
                  }}
                >
                  EXPLORE <strong style={{ color: "Green" }}>{par}</strong> with
                  picTOURnic{" "}
                  {/* <i
                    style={{ color: "brown", fontSize: "20px" }}
                    className="fas fa-grin-wink"
                  ></i>{" "} */}
                  in <strong>INDIA</strong>
                </h2>
              </div>
              {/* <Back /> */}
              {/* <DynamicCard data={currentPosts} heading={Pageheading} /> */}
              <div className="container-fluid" style={{ boxShadow: "none" }}>
                <div className="row text-center">
                  {/* <AllLocationCards options={currentPosts} /> */}
                  <AllLocationsCategory
                    options={currentPosts.sort()}
                    maincat={par}
                  />
                </div>
              </div>
              <div className="content_pages">
                <Pagination
                  postsPerPage={postperpage}
                  totalPosts={properties.length}
                  paginate={paginate}
                />
                {/* <div className="container" style={{ boxShadow: "none" }}></div> */}
                {/* <br></br> */}
                <hr></hr>
                
                <br></br>
                <h4 style={{ textAlign: "center", color: "brown" , fontSize:"16px"}}>
                  EXPLORE more with picTOURnic <br />
                </h4>
                <h5 style={{ textAlign: "center", color: "brown" , fontSize:"12px"}}>
                  Hand picked most beautiful places for Weekend Travel, Stays,
                  Corporate Events, Pre wed Shoots & many more.<br></br> Explore
                  to Extreme with Us{" "}
                  {/* <i
                    style={{ color: "#8b0000", fontSize: "20px" }}
                    className="fas fa-grin-wink"
                  ></i> */}
                </h5>{" "}
                <div className="container-fluid">
                  {" "}
                  <br></br>
                </div>
                <div className="container-fluid">
                  <Row xs="1" sm="2" md="3" lg="5">
                    <RemainingCatCards options={all_pages_array} />
                  </Row>
                  <br></br>
                  <hr></hr>
                  

                  <h2
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "600",
                      fontSize:"20px"
                    }}
                  >
                    QUICK ENQUIRY
                    <br />
                  </h2>
                  <h3 style={{ textAlign: "center", color: "brown" ,fontSize:"16px"}}>
                    Not sure where to go or need help in booking or wanna know
                    more about properties?<br></br> We are here to help you!!
                    Please Submit your Request Below...
                  </h3>

                  <ContactusForm />
                </div>
              </div>
              <Back />
            </>
          ) : (
            <Throbber />
          )}
        </>
      )}
    </>
  );
};

export default withRouter(PropertiesList);
