/* eslint-disable */
import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";

// import { isMobile } from "react-device-detect";

import "../../../components/CarouselElastic.css";
import { SlCallIn } from "react-icons/sl";
import "../../../components/css/PackageCarouselFormat.css";
import PackagePlacestoVisit from "./PackagePlacestoVisit";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import Routemap from "./Routemap";
import ScrollingContent from "../ScrollingContent";
import PackageInclusion from "./PackageInclusion";
import PackageExclusion from "./PackageExclusion";
import HighlightsSection from "./HighlightsSection";
import { FaTreeCity } from "react-icons/fa6";
import Day from "./Day";
import Back from "../../Back";
import PackageModal from "./PackageModal";
import Seperators from "../Seperators";
// import SvgComponent from "../SvgComponent";
// import SvgComponentLeaf from "./SvgComponent";

function PackageCarousel({ data }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedPackageItem, setClickedPackageItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const showGallery = () => {
    setIsOpen(true);
  };
  const handleClickEnquireNow = () => {
    // setClickedPackageItem(packageItem);
    setIsModalOpen(true);
    // console.log(packageItem.packageId);
  };

  // console.log("Data oon COnsole Page", data);
  const [carouselHeight, setCarouselHeight] = useState("auto"); // State to hold carousel height
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const sections = data;
  // console.log("dataa is ", sections);

  const convertPriceToUserCurrency = (price) => {
    const userCountry = localStorage.getItem("userCountry"); // Retrieve user's country from local storage
    let symbol = "";
    let convertedPrice = price;

    switch (userCountry) {
      case "India":
        symbol = "₹";
        convertedPrice = price;
        break;
      case "Australia":
        symbol = "AUD";
        convertedPrice = price / 55;
        break;
        case "User Denied":
          symbol = "₹";
          convertedPrice = price; 
          break;

      default:
        symbol = "₹";
        convertedPrice = price;
        break;
    }

    return (
      symbol +
      " " +
      convertedPrice.toLocaleString("en-US", { maximumFractionDigits: 2 })
    );
  };
  // Get the Id
  const extractid = sections.find((section) => section.title === "packageId");

  // console.log("Title", extractTitle.content);
  const packId = extractid && extractid.content ? extractid.content : [];
  // console.log("Title", titlee);

  // Get the Title
  const extractTitle = sections.find(
    (section) => section.title === "package_name"
  );

  // console.log("Title", extractTitle.content);
  const titlee =
    extractTitle && extractTitle.content ? extractTitle.content : [];
  // console.log("Title", titlee);

  // Get the TourIternary
  const extractIternary = sections.find(
    (section) => section.title === "TourIternary"
  );
  const Iternary =
    extractIternary && extractIternary.content.Days
      ? extractIternary.content.Days
      : [];
  // console.log("Iternary", Iternary);

  // Within Tour iternary the City Name and Duration is available
  const countryInfo =
    extractIternary && extractIternary.content.CountryNames
      ? extractIternary.content.CountryNames
      : [];
  // console.log("Countryyyy", countryInfo);
  // console.log("Countryyyy", countryInfo.length);

  const numberOfCities =
    extractIternary && extractIternary.content.Cities
      ? extractIternary.content.Cities
      : [];

  const numberOfCountries =
    extractIternary && extractIternary.content.Country
      ? extractIternary.content.Country
      : [];

  const durationPackage =
    extractIternary && extractIternary.content.Duration
      ? extractIternary.content.Duration
      : [];

  // Extract Highlights if not available , assign Null
  const extractHighlights = sections.find(
    (section) => section.title === "Highlights"
  );
  const Highlights =
    extractHighlights && extractHighlights.content
      ? extractHighlights.content
      : [];
  // console.log("HIghhhhhh", Highlights);

  // Extract the Reviews , price and Package Duration if not availabl easign null
  const extractPriceDurationReview = sections.find(
    (section) => section.title === "briefDescription"
  );

  // console.log("extractPriceDurationReview", extractPriceDurationReview);

  const extractNow =
    extractPriceDurationReview && extractPriceDurationReview.content
      ? extractPriceDurationReview.content
      : [];
  const reviews = extractNow.find((obj) => obj.Reviews)?.Reviews ?? null;
  const packagePrice =
    extractNow.find((obj) => obj.packagePrice)?.packagePrice ?? null;
  const packageDuration =
    extractNow.find((obj) => obj.packageDuration)?.packageDuration ?? null;
  const packageText = extractNow.find((obj) => obj.text)?.text ?? null;

  // console.log(
  //   "PACKAGEPRICEREVIEWDURATION",
  //   packagePrice,
  //   "||",
  //   reviews,
  //   "||",
  //   packageDuration,
  //   "||",
  //   packageText
  // );

  const toProperCase = (sentence) => {
    return sentence.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  };

  useEffect(() => {
    const carousel = document.querySelector(".package-carousel-wrapper");
    if (carousel) {
      setCarouselHeight(carousel.offsetHeight + "px"); // Set carousel height dynamically
    }
  }, []);

  const stepsSection = sections.find((section) => section.title === "steps");
  const stepsContent =
    stepsSection && stepsSection.content ? stepsSection.content : [];
  // console.log("STEPS", stepsContent);
  const images = sections.find(
    (section) => section.title === "imagesMainTopcarousel"
  ).content;
  const photos = images.map(item => item.carousel_ima.replace(/\\/g, '/'));
  console.log("PHOTOSSS", photos);
  // Find Package Exclusion content
  const packageExclusionSection = sections.find(
    (section) => section.title === "Package Exclusion"
  );
  // Find Package Inclusion content
  const packageInclusionSection = sections.find(
    (section) => section.title === "Package Inclusion"
  );
  // Assign content or empty array if not found
  const packageExclusionContent = packageExclusionSection
    ? packageExclusionSection.content
    : [];
  const packageInclusionContent = packageInclusionSection
    ? packageInclusionSection.content
    : [];
  // Calculate the Number of cites and countries
  // const formatCountryNames = (countryNames) => {
  //   let formattedString = "";
  //   // Iterate through each country
  //   Object.entries(countryNames).forEach(([country, cities]) => {
  //     // Iterate through each city in the country
  //     cities.forEach(({ name, duration }) => {
  //       // Append formatted city and duration to the string
  //       formattedString += `${country}: ${name} ${duration}, `;
  //     });
  //   });
  //   // Remove trailing comma and space
  //   return formattedString.slice(0, -2);
  // };
  // console.log(packageInclusionContent, "||", packageExclusionContent)

  const formatCountryNames = (countryNames) => {
    // console.log("ZDVsdv", countryNames);
    let formattedString = "";

    // Iterate through each country
    Object.entries(countryNames).forEach(([country, cities]) => {
      // Append country name
      formattedString += `<span style="color: brown"><strong>${country}</strong></span>: `;

      // Iterate through each city in the country
      cities.forEach(({ name, duration }) => {
        // Append formatted city and duration to the string with different color for duration
        if (duration !== undefined && duration.trim() !== "") {
          formattedString += `${name} <span style="color: green">(<strong>${duration}</strong>)</span>, `;
        } else {
          formattedString += ``;
        }
      });

      // Remove the trailing comma and space for the last city in this country
      if (formattedString.endsWith(", ")) {
        formattedString = formattedString.slice(0, -2);
      }

      // Add a period and space to separate different countries
      formattedString += ". ";
    });

    // Remove the trailing period and space
    if (formattedString.endsWith(". ")) {
      formattedString = formattedString.slice(0, -2);
    }

    return formattedString;
  };

  // useEffect(() => {
  //   const logNonNullValues = () => {
  //     const variables = {
  //       Iternary,
  //       Highlights,
  //       packageExclusionContent,
  //       packageInclusionContent,
  //     };

  //     // Log non-null values
  //     Object.entries(variables).forEach(([key, value]) => {
  //       if (value !== null && value.length !== 0) {
  //         console.log("Not Null", `${key}:`);
  //       }
  //     });
  //   };
  //   logNonNullValues();
  // }, [
  //   [Iternary, Highlights, packageExclusionContent, packageInclusionContent],
  // ]);
  // This is the function to scroll to clicked item
  const scrollToSection = (key) => {
    const element = document.getElementById(key);
    if (element) {
      const headerHeight =
        document.querySelector(".sticky-header").offsetHeight;
      const elementPosition = element.offsetTop - headerHeight - 140;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
      setActiveIndex(key);
      setActiveButton(key);
    }
  };
  // This i am adding to Verify the Position where the sticky header will come because we have navigation bar as well
  useEffect(() => {
    const handleScroll = () => {
      const stickyHeader = document.querySelector(".sticky-header");
      const navbar = document.querySelector(".navbar");
      if (stickyHeader && navbar) {
        const stickyHeaderRect = stickyHeader.getBoundingClientRect();
        const navbarRect = navbar.getBoundingClientRect();
        const navbarBottom = navbarRect.bottom;
        if (stickyHeaderRect.bottom >= navbarBottom) {
          stickyHeader.style.top = `${
            navbarBottom - stickyHeaderRect.height + 35
          }px`;
          stickyHeader.style.zIndex = "100"; // Ensure the sticky header stays below the navbar
        } else {
          stickyHeader.style.top = "0";
          stickyHeader.style.zIndex = "1020"; // Default z-index
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // This will rename the button and return it
  const getButtonText = (key) => {
    if (key === "packageExclusionContent") {
      return "Package Exclusion";
    } else if (key === "packageInclusionContent") {
      return "Package Inclusion";
    } else {
      return key;
    }
  };

  const [cityNames, setCityNames] = useState([]);

  useEffect(() => {
    const getCityNames = (countryNames) => {
      const uniqueNames = new Set();
      Object.values(countryNames).forEach((cities) => {
        cities.forEach(({ name }) => {
          uniqueNames.add(name);
        });
      });
      return Array.from(uniqueNames);
    };

    setCityNames(getCityNames(countryInfo));
    // console.log("cityNames", cityNames);
  }, [countryInfo]);

  return (
    <>
      {/* <SvgComponentLeaf /> */}
      <Back />
      <div className="fontChange">
        <center>
          <h3>{titlee ? toProperCase(titlee) : ""}</h3>
        </center>
      </div>
      <div className="package-container-fluid">
        <div className="sideContainer">
          <div className="sideContainerchild">
            <center>{reviews && <h4>{reviews} Star Reviews</h4>}</center>
            <center>
              {packageDuration && <h4>Duration: {packageDuration}</h4>}
            </center>
          </div>
          <hr />
          <div className="rightdiv">
            <div style={{ marginRight: "10px", paddingLeft: "10px" }}>
              {packagePrice ? (
                <>
                  {" "}
                  <p>Starts From</p>
                  <h5>
                    {packagePrice
                      ? convertPriceToUserCurrency(packagePrice)
                      : 0}
                    <span>
                      {packageText && (
                        <h6 style={{ fontSize: "8px", color: "brown" }}>
                          ( {packageText})
                        </h6>
                      )}
                    </span>
                  </h5>
                </>
              ) : (
                <p style={{ fontSize: "10px" }}>
                  For Price Please Send a Equiry to Us!
                </p>
              )}
            </div>
            <button
              className="enquire-now-button"
              onClick={() => handleClickEnquireNow()}
            >
              Enquire Now
            </button>
          </div>

          <div className="para p-0">
            <div className="bg-white h-100 w-100 d-flex align-items-center">
              <p className="paraTerm text-primary">
                Query? Please{" "}
                <span>
                  <SlCallIn style={{ fontSize: "20px", color: "red" }} />
                </span>
                : +91-9663803335, +91-9606022990/2/3
              </p>
            </div>
          </div>
        </div>

        <div className="package-carousel-wrapper" onClick={showGallery}>
          <Carousel
            indicators={false}
            interval={2000}
            className="package-carousel"
          >
            {images.map((item, index) => (
              <Carousel.Item key={index} interval={null}>
                <img
                  className="package-d-block package-w-100"
                  src={item.carousel_ima}
                  alt="Slide"
                  width="50%"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        {/* Third Container */}
        <div className="thirdContainer">
          {cityNames && cityNames.length > 0 ? (
            <Routemap
              title={"Destinations you will be Visiting!!"}
              steps={cityNames}
            />
          ) : null}
        </div>
      </div>

      <br></br>

      <div className="sticky-header">
        <ul>
          {Object.entries({
            Iternary,
            Highlights,
            packageExclusionContent,
            packageInclusionContent,
          }).map(([key, value]) => {
            if (value !== null && value.length !== 0) {
              return (
                <li key={key}>
                  <button
                    onClick={() => scrollToSection(key)}
                    className={activeButton === key ? "active" : ""}
                  >
                    {getButtonText(key)}
                  </button>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
      <ReactBnbGallery
        show={isOpen}
        photos={photos}
        onClose={() => setIsOpen(false)}
      />

      {cityNames.length > 0 &&
      numberOfCountries.length > 0 &&
      durationPackage ? (
        <>
          <h4 style={{ padding: "20px" }}>Package Duration</h4>
          <div className="tour-details">
            <div>
              <span>
                <i
                  className="fas fa-clock"
                  style={{
                    color: "green",
                    fontSize: "20px",
                    paddingRight: "10px",
                  }}
                ></i>
              </span>
              <span>{durationPackage}</span>
            </div>
            <div>
              <span className="city-count">{cityNames.length}</span>
              <span>
                {cityNames.length === 1 ? (
                  <span> Destination</span>
                ) : (
                  <span> Destination's</span>
                )}
              </span>
            </div>
            <div>
              <span className="country-count">{numberOfCountries.length}</span>
              <span>
                {" "}
                {numberOfCountries.length > 1 ? "Countries" : "Country"}
              </span>
            </div>
          </div>
        </>
      ) : null}

      {/* For Rendering the Country Information */}
      {countryInfo && Object.keys(countryInfo).length > 0 ? (
        <>
          <div className="tour-details">
            <div>
              <span></span>
              <span>
                <FaTreeCity style={{ color: "green", marginRight: "10px" }} />
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatCountryNames(countryInfo),
                  }}
                ></span>
                {/* {formatCountryNames(countryInfo)} */}
              </span>
            </div>
          </div>
        </>
      ) : null}

      {/* Rendering Highlights if available else not */}
      {Highlights.length > 0 ? (
        <div id="Highlights">
          <HighlightsSection data={Highlights} />
        </div>
      ) : null}
      {/* Rendering Days if available else not */}
      {Iternary.length > 0 ? (
        <div id="Iternary">
          <Day daysInfo={Iternary} />
        </div>
      ) : null}
      {/* This is for Package Inclusion , if available render or else not*/}
      {packageInclusionContent.length > 0 ? (
        <div id="packageInclusionContent">
          <PackageInclusion data={packageInclusionContent} />
        </div>
      ) : null}

      {/* Render package Exclusion same format is supported , if available render or else not*/}
      {packageExclusionContent.length > 0 ? (
        <>
          <div id="packageExclusionContent">
            <PackageExclusion data={packageExclusionContent} />
          </div>
        </>
      ) : null}

      <div className="enquiry-section">
        <button className="floating-button" onClick={handleClickEnquireNow}>
          Enquire Now Our {titlee ? toProperCase(titlee) : ""}
        </button>
        <div className="paraa">
          <p className="paraaTerm">
            Experience our commitment to excellence—we're here to exceed your
            expectations.
          </p>
        </div>
      </div>
      <div className="end-of-area">
        <hr className="divider-line" />
        <span className="divider-label"></span>
      </div>
      {packId && (
        <PackageModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          data={packId}
          title={titlee}
        />
      )}

      <Seperators />
    </>
  );
}

export default PackageCarousel;
