/* eslint-disable */
import React, { useState } from "react";

import "../../components/css/ScrollingContent.css";
import Day from "./Package/Day";
import ContentRenderer from "./Package/ContentRenderer";
import { BsHighlights } from "react-icons/bs";
import { FaAngellist } from "react-icons/fa";
function ScrollingContent({ sections, content, contentEx }) {
  console.log("sections", sections[1].content.Days);
  console.log("contentEx", contentEx);
  console.log("content", content);
  const Days_details = sections[1].content.Days;
  const [activeIndex, setActiveIndex] = useState(null);
  const formatCountryNames = (countryNames) => {
    let formattedString = "";
    // Iterate through each country
    Object.entries(countryNames).forEach(([country, cities]) => {
      // Iterate through each city in the country
      cities.forEach(({ name, duration }) => {
        // Append formatted city and duration to the string
        formattedString += `${country}: ${name} ${duration}, `;
      });
    });
    // Remove trailing comma and space
    return formattedString.slice(0, -2);
  };

  const scrollToSection = (index) => {
    const element = document.getElementById(`section-${index}`);
    if (element) {
      const headerHeight = document.querySelector(".scrolling-content-header").offsetHeight;
      const elementPosition = element.offsetTop - headerHeight-100;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth"
      });
      setActiveIndex(index);
    }
  };

  return (
    <div className="scroll-container">
      <div className="scrolling-content">
        <div
          className="scrolling-content-header"
          style={{ position: "sticky" }}
        >
          {sections.map(
            (section, index) =>
              section.title !== "steps" &&
              section.title !== "imagesMainTopcarousel" && (
                <h5
                  key={index}
                  className={`scrolling-content-heading ${
                    activeIndex === index ? "active" : ""
                  }`}
                  // onClick={() => {
                  //   const element = document.getElementById(`section-${index}`);
                  //   if (element) {
                  //     element.scrollIntoView({
                  //       behavior: "smooth",
                  //       block: "start",
                  //     });
                  //   }
                  //   setActiveIndex(index);
                  // }}
                  onClick={() => scrollToSection(index)}
                >
                  {section.title}
                </h5>
              )
          )}
        </div>
        <div className="scrolling-content-body">
          {sections.map((section, index) => (
            <div
              key={index}
              className={`scrolling-content-section ${
                activeIndex === index ? "active" : ""
              }`}
            >
              {/* If title is 'Highlights' */}
              
              {/* If title is 'TourIternary' */}
              {section.title === "TourIternary" && section.content && (
                <div
                  key={index}
                  className={`scrolling-content-section ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  <h4 id={`section-${index}`}>{section.title}</h4>
                  <div className="tour-details">
                    <div>
                      <span>
                        <i
                          className="fas fa-clock"
                          style={{
                            color: "red",
                            fontSize: "20px",
                            paddingRight: "5px",
                          }}
                        ></i>
                      </span>
                      <span>{section.content.Duration}</span>
                    </div>
                    <div>
                      <span className="city-count">
                        {Array.isArray(section.content.Cities)
                          ? section.content.Cities.length
                          : "N/A"}
                      </span>
                      <span> Cities</span>
                    </div>
                    <div>
                      <span className="country-count">
                        {Object.keys(section.content.CountryNames).length}
                      </span>
                      <span> Countries</span>
                    </div>
                  </div>
                  <div className="tour-details">
                    <div>
                      <span>
                        <i
                          className="fas fa-clock"
                          style={{
                            color: "red",
                            fontSize: "20px",
                            paddingRight: "5px",
                          }}
                        ></i>
                      </span>
                      <span>
                        {formatCountryNames(section.content.CountryNames)}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {/* If title is 'packageInclusion' */}
              

              {/* If title is 'packageEXclusion' */}
              
             
            </div>
          ))}

          
        </div>
      </div>
    </div>
  );
}

export default ScrollingContent;
