import React, { useState, useEffect, useRef } from "react";
import '../../components/css/Modal.css';

const ConfirmationModal = ({ heading, body, setUserConfirmation, handleSuccess }) => {
  const [isVisible, setIsVisible] = useState(true);
  const modalRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setUserConfirmation(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setUserConfirmation]);

  const handleSuccessClick = () => {
    handleSuccess();
    setIsVisible(false);
    setTimeout(() => {
      setUserConfirmation(false);
    }, 300); // Adjust this timeout to match your CSS transition duration
  };

  return (
    <div className={`modal-confirmation-component ${isVisible ? 'fade-in' : 'fade-out'} position-fixed`}>
      <div className="modal-confirmation-container" ref={modalRef}>
        <h2>{heading}</h2>
        <p>{body}</p>
        <div>
          <button className="btn btn-outline-danger m-1 fonts-5 text-white border-white" onClick={() => setIsVisible(false)}>Cancel</button>
          <button className="btn btn-outline-success m-1 fonts-5 text-white border-white" onClick={handleSuccessClick}>Logout</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
