import React from "react";
import "../css/Facilities.css";
function MealPlan({ options }) {
 
  return (
    <>
      {/* <div
      className="container"
      style={{ boxShadow: "none", backgroundColor: "#ee932c14" }}
    >
      <div>
        <h4 style={{ color: "brown" }}>Available Meal Plan</h4>
      </div>
      
      <p style={{fontSize:"large", color:"Black", fontWeight:"300"}}>
      <i className="fas fa-utensils" style={{color: "black", margin: "5px"}}></i> <span className="color">*</span>{options}
      </p>
      <span className="color">*</span><span>Available meal plans may or may not be part of prices shown. This indicates meal plans availability at property. Food can be ordered from outside if specific meals are not available.</span>
    </div> */}
      <div className={`description-container`}>
        <div>
          <h4 style={{ color: "brown" }}>Meal Plan</h4>
        </div>

        <div className="option">
          <i
            className="fas fa-utensils"
            style={{ color: "black", margin: "5px"  , display: "inline-block", }}
          ></i>
          <div
            style={{
              fontSize: "large",
              color: "black",
              fontWeight: "300",   display: "inline-block",
            }}
          >
            {options}
            
          </div>
          
        </div>
        <br></br>
        <span className="color">*</span><span>Available meal plans may or may not be part of prices shown. This indicates meal plans availability at property. Food can be ordered from outside if specific meals are not available.</span>
      </div>
    </>
  );
}

export default MealPlan;
