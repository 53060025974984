/* eslint-disable */
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../components/css/multiCarouselItem.css";

function PackagePlacestoVisit({ deviceType }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2, // Adjusted to display 2 items on tablets
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <br />
      <div>
        <div>
          <h4 className="custom-heading">
            Areas where you're likely to come across
          </h4>
        </div>
        <div className="multiCarouselList">
          <Carousel
            className="carousel-padding"
            swipeable={false}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={deviceType !== "mobile" ? true : false}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="custom-carousel-item"
          >
          
              <img
                src="/Andaman_and_Nicobar_Islands_picTOURnic.webp"
                alt="Image 3"
              />
            <img
                src="/Andaman_and_Nicobar_Islands_picTOURnic.webp"
                alt="Image 3"
              />
              <img
                src="/Andaman_and_Nicobar_Islands_picTOURnic.webp"
                alt="Image 3"
              />
              <img
                src="/Andaman_and_Nicobar_Islands_picTOURnic.webp"
                alt="Image 3"
              />
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default PackagePlacestoVisit;
