import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import "../../../components/css/PackageModal.css";
import emailjs from "emailjs-com";
import toast from "react-hot-toast";
import ReactGa from "react-ga4";
import axios from 'axios';

require("dotenv").config();

const PackageModal = ({ isOpen, onClose, data, title }) => {
  const [numChildren, setNumChildren] = useState(0);
  const [childAges, setChildAges] = useState([]);
  const [allChildAges, setAllChildAges] = useState("");
  const handleNumChildrenChange = (e) => {
    const count = e.target.value === "" ? 0 : parseInt(e.target.value);
    setNumChildren(count);
    // Initialize child ages array with empty strings if count is greater than 0
    setChildAges(count > 0 ? Array(count).fill("") : []);
  };

  useEffect(() => {
    if (!isOpen) {
      // Reset number of children and child ages when modal is closed
      setNumChildren(0);
      setChildAges([]);
    }
  }, [isOpen]);
  const handleChildAgeChange = (e, index) => {
    const newChildAges = [...childAges];
    newChildAges[index] = e.target.value;
    setChildAges(newChildAges);

    // Update all child ages string
    const ageString = newChildAges.filter((age) => age !== "").join(", ");
    setAllChildAges(ageString);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here you can handle the submission of the inquiry form
    // For demonstration purposes, let's just log the form data
    const formData = new FormData(e.target);
    
    // const name = formData.get("username") || "Not Filled by User";
    // const email = formData.get("email") || "Not Filled by User";
    // const phone = formData.get("phonenumber") || "Not Filled by User";
    // const city = formData.get("destination") || "Not Filled by User";
    // const adults = formData.get("adults") || "Not Filled by User";
    // const date = formData.get("checkin") || "Not Filled by User";
    // const query = formData.get("query") || "Not Filled by User";
    // // Construct an array of child ages
    // const childAgesArray = [];
    // for (let i = 0; i < numChildren; i++) {
    //   childAgesArray.push(formData.get(`childAge${i}`) || "Not Filled by User");
    // }
    // console.log("Name:", name);
    // console.log("Email:", email);
    // console.log("Phone:", phone);
    // console.log("City:", city);
    // console.log("Adults:", adults);
    // console.log("Date:", date);
    // console.log("Query:", query);
    // childAgesArray.forEach((age, index) => {
    //   const ageString = age ? `${age} Yrs` : "Not filled by user";
    //   console.log(`Child ${index + 1} Age:`, ageString);
    // });
    // // You can add your logic to send this data to your backend or perform any other actions
    // // Closing the modal
    // console.log("aewdawdfadf", e.target);
    const formObject = {
      adults: formData.get('adults') || '',
      checkin: formData.get('checkin') || '',
      children: formData.get('children') || '',
      childrenage: formData.get('childrenage') || '',
      destination: formData.get('destination') || '',
      email: formData.get('email') || '',
      foodpreference: formData.get('foodprefrence') || '',
      message: formData.get('message') || '',
      packageid: formData.get('packageid') || '',
      phonenumber: formData.get('phonenumber') || '',
      titlepackage: formData.get('titlepackage') || '',
      username: formData.get('username') ||''
  };
  
    const phone = formData.get("phonenumber");
    if (phone) {
      await axios.post(process.env.REACT_APP_DB_URL+'/api/submit-inquiry', formObject);
      // Use EmailJS to send email
      emailjs
        .sendForm(
          process.env.REACT_APP_MAIL_SERVICE,
          process.env.REACT_APP_MAIL_TEMPLATE,
          e.target,
          process.env.REACT_APP_MAIL_USER
        )
        .then(
          (result) => {
            if (result.text === "OK") {
              // alert(
              //   "Your mail has been sent successfully. Our executive will get in touch soon related to your query."
              // );
              toast.success(
                "Your inquiry has been submitted. Our team will be in touch shortly.",
                { duration: 5000 }
              );
              ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "Package Enquiry Sent Succesfully from " + phone,
                action: "User submitted Package New format Enquiry",
                label: "Successful Submission",
                value: phone,
              });
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    } else {
      // alert(
      //   "Phone number is required. Please provide your phone number. Thanks!"
      // );
      toast.error("To Serve you better we need your contact Details");
    }

    onClose();
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <Modal.Header closeButton className="packageModal-header">
        <Modal.Title className="packageModal-title">
          Enquiry for Package
          {/* {data ? data : ''} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="packageModal-text">
          Do you have any inquiries before booking your adventure? The expert
          team at picTOURnic is ready to address all your questions. Please take
          a moment to fill out the form below, and we'll be delighted to assist
          you further!
        </p>
        <Form onSubmit={handleSubmit} className="packageModal-form">
          <Form.Row>
            <Form.Group
              as={Col}
              controlId="formName"
              className="input-container"
            >
              <Form.Control
                type="text"
                name="username"
                required
                className="packageModal-input"
              />
              <Form.Label className="animated-placeholder">
                Your Name
              </Form.Label>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formEmail"
              className="input-container"
            >
              <Form.Control
                type="email"
                name="email"
                // required
                className="packageModal-input"
              />
              <Form.Label className="animated-placeholder">
                Email Address
              </Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              controlId="formPhone"
              className="input-container"
            >
              <Form.Control
                type="tel"
                name="phonenumber"
                required
                className="packageModal-input"
              />
              <Form.Label className="animated-placeholder">
                Phone Number
              </Form.Label>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formCity"
              className="input-container"
            >
              <Form.Control
                type="text"
                name="destination"
                className="packageModal-input"
              />
              <Form.Label className="animated-placeholder">
                Your City
              </Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              controlId="formAdults"
              className="input-container"
            >
              <Form.Control
                type="number"
                name="adults"
                required
                className="packageModal-input"
                min={0}
              />
              <Form.Label className="animated-placeholder">
                Number of Adults
              </Form.Label>
            </Form.Group>

            <Form.Group
              as={Col}
              controlId="formDate"
              className="input-container"
            >
              <Form.Control
                type="date"
                name="checkin"
                required
                className="packageModal-input"
                min={new Date().toISOString().split("T")[0]}
              />
              <Form.Label className="animated-placeholder">
                Date of Travel
              </Form.Label>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formDate"
              className="input-container"
              hidden
            >
              <Form.Control
                type="text"
                name="packageid"
                required
                className="packageModal-input"
                value={data}
              />
              <Form.Label className="animated-placeholder">
                Package ID
              </Form.Label>
            </Form.Group>

            <Form.Group
              controlId="formAllChildAges"
              className="input-container"
              hidden
            >
              <Form.Control
                type="text"
                value={allChildAges}
                name="childrenage"
                className="packageModal-input"
              />
              <Form.Label className="animated-placeholder">
                All Child Ages
              </Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              controlId="formAllChildAges"
              className="input-container"
              hidden
            >
              <Form.Control
                type="text"
                value={title}
                name="titlepackage"
                className="packageModal-input"
              />
              <Form.Label className="animated-placeholder">
                Packagetitle
              </Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              controlId="formDate"
              className="input-container"
            >
              <Form.Control
                type="number"
                value={numChildren}
                onChange={handleNumChildrenChange}
                min={0}
                className="packageModal-input"
                name="children"
              />
              <Form.Label className="animated-placeholder">
                Number of Children
              </Form.Label>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formName"
              className="input-container"
            >
              <Form.Control
                type="text"
                name="foodprefrence"                
                className="packageModal-input"
                placeholder="Veg or Non-veg"
              />
              <Form.Label className="animated-placeholder">
                Food Preference
              </Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            {/* Dynamically generate input fields for child ages */}
            {/* {Array.from({ length: numChildren }).map((_, index) => (
              <Form.Group
                controlId={`formChildAge${index}`}
                className="input-containers"
                key={index}
              >
                <Form.Label style={{ fontSize: "14px" }}>
                  Child {index + 1} (Yrs)
                </Form.Label>
                <Form.Control
                  type="number"
                  name={`childAge${index}`}
                  value={childAges[index]}
                  onChange={(e) => handleChildAgeChange(e, index)}
                  min={0}
                  className="packageModal-input"
                  style={{ width: "90px", paddingBottom: "5px" }}
                />
                <br></br>
              </Form.Group>
            ))} */}
            {Array.from({ length: numChildren }).map((_, index) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={index}
                className="mb-3 d-flex justify-content-center"
              >
                <Form.Group
                  controlId={`formChildAge${index}`}
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    margin: "0",
                  }}
                >
                  <Form.Label
                    style={{
                      fontSize: "14px",
                      margin: "0",
                      padding: "0",
                      width: "auto",
                      minWidth: "70px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    Child {index + 1} (Yrs)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name={`childAge${index}`}
                    value={childAges[index]}
                    onChange={(e) => handleChildAgeChange(e, index)}
                    min={0}
                    className="packageModal-input"
                    style={{
                      width: "100%",
                      maxWidth: "100px",
                      margin: "0",
                      padding: "0",
                    }}
                  />
                </Form.Group>
              </Col>
            ))}
          </Form.Row>
          <Form.Group controlId="formQuery" className="input-container" style={{ width: '100%', padding: '0 0px' }}>
    <Form.Control
      as="textarea"
      name="message"
      rows={3}
      className="packageModal-input"
      style={{ height: "60px", width: 'calc(100% - 20px)', padding: '10px', boxSizing: 'border-box' }}
    />
    <Form.Label className="animated-placeholder" style={{ paddingTop: '10px' }}>
      Please write Your queries here
    </Form.Label>
  </Form.Group>

          <Button
            variant="primary"
            type="submit"
            className="packageModal-button"
          >
            Send Query
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="packageModal-footer">
        <Button
          variant="warning"
          onClick={onClose}
          className="packageModal-button"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PackageModal;
