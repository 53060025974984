/* eslint-disable */
import { useState, React, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactGa from "react-ga4";
import Form from "react-bootstrap/Form";
import validator from "validator";
import "../components/Popup.css";
require("dotenv").config();

function Popup() {
  // eslint-disable-next-line
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  // eslint-disable-next-line
  const [timedpopup, Settimedpopup] = useState(false);
  const [divcase, showdivcase] = useState(false);
  const [show, setShow] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [numberError, setnumberError] = useState("");

  const handleClose = () => {
    setShow(false);
    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    ReactGa.event({
      category: "Close button",
      action: "Close button clicked",
      label: "User not intrested in Subscribing",
    });
  };

  //   This will store the valueinto GA and will close both the Modals
  const handleSubmit = (event) => {
    event.preventDefault();
    // localStorage.setItem("popup", JSON.stringify("subscribed"));
    var decide = 0;
    var email_ = email;
    var number_ = number;

    if (email_) {
      decide = decide + 1;
    }

    if (number_) {
      decide = decide + 1;
    }

    // alert(decide);
    // eslint-disable-next-line
    if (decide == 0) {
      setEmailError("Please Enter valid Email Address!");
      setnumberError("Please Enter valid Phone number with 10 digits!");
    } // eslint-disable-next-line
    else if (decide == 1) {
      if (email_) {
        if (
          validator.isEmail(email_, {
            allow_display_name: true,
            domain_specific_validation: true,
          })
        ) {
          setEmailError("");
          setShow(false);
          showdivcase(false);
          setEmail("");
          setNumber("");

          localStorage.setItem("popup", JSON.stringify("subscribed"));
          ReactGa.initialize(process.env.REACT_APP_GA_ID);
          ReactGa.event({
            category: "Email Submitted ",
            action: "Identity " +email_.replace("@","YO").replace(".com","YO"),
            label: "User Subscribed through mail",
            // value:email_,
          });
        } else {
          setEmailError("Please Enter valid Email Address!");
          setShow(true);
          showdivcase(true);
        }
      } else if (number_) {
        if (validator.isMobilePhone(number_, "en-IN")) {
          setnumberError("");
          setShow(false);
          showdivcase(false);
          setEmail("");
          setNumber("");
          localStorage.setItem("popup", JSON.stringify("subscribed"));
          ReactGa.initialize(process.env.REACT_APP_GA_ID);
          ReactGa.event({
            category: "Phone Number Submitted ",
            action: number_,
            label: "User Subscribed through Phone number",
            // value: number_,
          });
        } else {
          setnumberError("Please Enter valid Phone number with 10 digits!");
          setShow(true);
          showdivcase(true);
        }
      }
    } // eslint-disable-next-line
    else if (decide == 2) {
      if (
        validator.isEmail(email_, {
          allow_display_name: true,
          domain_specific_validation: true,
        })
      ) {
        setEmailError("Valid Email :)");
        if (validator.isMobilePhone(number_, "en-IN")) {
          setnumberError("");
          setEmailError("");
          setShow(false);
          showdivcase(false);
          setEmail("");
          setNumber("");
          localStorage.setItem("popup", JSON.stringify("subscribed"));
          ReactGa.initialize(process.env.REACT_APP_GA_ID);
          ReactGa.event({
            category: "Phone Number and Email Submitted ",
            action: number_ +" and " + email_.replace("@","YO").replace(".com","YO"),
            label: "User Subscribed through Phone number and mail",
            // value:number_ +" " + email_,
          });
        } else {
          setnumberError("Please Enter valid Phone number with 10 digits!");
          setShow(true);
          showdivcase(true);
        }
      } else {
        setEmailError("Please Enter valid Email Address!");
        setShow(true);
        showdivcase(true);
      }
    }

    // if (validator.isEmail(email_)) {
    //   setEmailError("Valid Email :)");
    //   setShow(false);
    //   showdivcase(false);
    //   setEmail("");
    //   setNumber("");
    // } else {
    //   setEmailError("Enter valid Email!");
    //   setShow(true);
    //   showdivcase(true);
    // }

    // if (validator.isMobilePhone(number_)) {
    //   setnumberError("Valid Phone Number :");
    //   setShow(false);
    //   showdivcase(false);
    //   setEmail("");
    //   setNumber("");
    // } else {
    //   setnumberError("Enter valid Phone number with 10 digits!");
    //   setShow(true);
    //   showdivcase(true);
    // }
  };

  //   This will show the hidden modal
  const handleSubscribe = () => {
    showdivcase(true);
    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    ReactGa.event({
      category: "Subscribe Button",
      action: "Subscribe button clicked",
      label: "User Must provide either email or number",
    });
  };
  const [userExists, setUserExists] = useState(false);
  
  //   Tthis we are using so that it should not show after every specifed duration
  // useEffect(() => {
  //   setTimeout(() => {
  //     Settimedpopup(true);
  //     setShow(true);
  //   }, 7000);
  // }, []);
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setUserExists(true);
    } else {
      const timer = setTimeout(() => {
        setShow(true);
      }, 5000); // Show modal after 5 seconds if user is not logged in
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, []);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ backgroundColor: "mediumseagreen" ,padding:"8px"}}>
          <Modal.Title style={{ color: "white", }}>picTOURnic</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding:"8px", fontSize:"15px"}}>
        Unlock better prices and superior support from  picTOURnic by Quick,
          <a href="/login"><strong style={{ color: "red" }}> Login or Sign up. </strong></a>
          Experience our commitment to excellence—we're here to exceed your expectations.
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubscribe}>
            Subscribe
          </Button>
        </Modal.Footer> */}

        {divcase ? (
          <Modal.Body>
            <Modal.Header>
              Please Share any of your EmailID or your phone number !
            </Modal.Header>

            <Form>
              <Form.Group>
                <Form.Control
                  type="email"
                  placeholder="user@domain.com"
                  value={email}
                  onChange={(e) => {
                    // setEmailError("");
                    // setNumberError("");
                    setEmail(e.target.value);
                  }}
                />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "x-small",
                  }}
                >
                  {emailError}
                </span>
              </Form.Group>
              <center>
                <label>OR</label>
              </center>
              <Form.Group>
                <Form.Control
                  type="number"
                  name="phonenumber"
                  placeholder="10 Digits Phone number without country code"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "x-small",
                  }}
                >
                  {numberError}
                </span>
              </Form.Group>

              <center>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </center>
            </Form>
          </Modal.Body>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
}

export default Popup;
