import React, { useState, useRef } from "react";
import "../../css/Day.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
const Day = ({ daysInfo }) => {
  // console.log("DaysssoFFF", daysInfo);
  const [daysState, setDaysState] = useState(daysInfo.map(() => false)); // Initialize state for each day

  const contentRefs = useRef(daysInfo.map(() => React.createRef())); // Create refs for each day

  const toggleContent = (index) => {
    setDaysState((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const getHeight = (index) => {
    return daysState[index]
      ? `${contentRefs.current[index].current.scrollHeight}px`
      : "0px";
  };

  return (
    <>
      <div className="day" >
        <h4 id="tourIternary">Tour Iternary</h4>
        {daysInfo.map((day, index) => (
          <div className="day-container" key={index}>
            <div className="day-heading-container">
              <div className="splash-effect">
                {" "}
                {/* Apply the splash effect here */}
                <div className="day-heading">
                  {day.dayname} - {day.header}
                </div>
              </div>
              <div className="day-toggle">
                <button
                  onClick={() => toggleContent(index)}
                  className="day-collapsible"
                >
                  <FontAwesomeIcon
                    icon={daysState[index] ? faAngleUp : faAngleDown}
                  />
                </button>
              </div>
            </div>
            <div
              className="day-content"
              ref={contentRefs.current[index]}
              style={{ maxHeight: getHeight(index) }}
            >
              <p>{day.description}</p>
            </div>
            <hr></hr>
          </div>
        ))}
      </div>
    </>
  );
};

export default Day;
