import React from "react";
import "../../components/css/NewLaunches.css"; // Create a corresponding CSS file for styling
import { CardText} from "reactstrap";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
// import axios from "../../axios";
import "../css/Partners.css";


const NewLaunches = () => {
  // Sample data for new launches (replace with your data)
  const newLaunchesData = [
    {
      name: "WAYANAD",
      image: "wynd.webp",
      isNew: true,
      
    },{
      name: "COORG",
      image: "coorg_imagesearch.webp",
      isNew: true,
      
    },{
      name: "MUNNAR",
      image: "munnar_imagesearch.webp",
      isNew: true,
      
    },{
      name: "GOA",
      image: "goa_imageserach.webp",
      isNew: true,
      
    },{
      name: "PONDICHERRY",
      image: "pondy_imagesearch.webp",
      isNew: true,
      
    },{
      name: "UDUPI",
      image: "udupi_imagesearch.webp",
      isNew: true,
      
    },{
      name: "OOTY",
      image: "ooty_imagesearch.webp",
      isNew: true,
      
    },{
      name: "GOKARNA",
      image: "gokarna_imagesearch.webp",
      isNew: true,
      
    }
  ];

  return (
    // <div className="new-launches">
    //   <h2 className="new-launches-title">New Launches</h2>
    //   <div className="launch-grid">
    //     {newLaunchesData.map((item, index) => (
    //       <div key={index} className="launch-item">
    //         <div className="launch-image">
    //           <img src={item.image} alt={item.name} />
    //         </div>
    //         <div className="launch-name">{item.name}</div>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <>
    
      <div
        className="container-fluid"
        style={{
          boxShadow: "none",
          backgroundColor: "rgb(247, 247, 247)",
          padding: "20px",
        }}
      >
        
        <h2 className="new-launches-title">TRENDING</h2>
        <p className="explore-text">
          Explore rich tapestry of culture, nature, and history. Visit iconic
          destinations that showcase the beauty and heritage of enchanting
          state.
        </p>
        <div className="row text-center">
          <div className="container" style={{ boxShadow: "none" }}></div>
          <br></br>
          <div className="container-fluid"></div>
          {newLaunchesData.map((everypartner, index) => (
            <Link
              to={`/image/search/${everypartner.name}`}
              style={{ margin: "0px", textDecoration: "none" }}
              key={index}
            >
              <Card
                key={index}
                style={{
                  padding: "1px",
                  width: "180px",
                  height: "105px",
                  margin: "10px",
                }}
              >
                <img
                  src={"/" + everypartner.image}
                  style={{ borderRadius: "10px" , objectFit:"cover"}}
                  alt={everypartner.image}
                ></img>
                <CardText>{everypartner.name}</CardText>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default NewLaunches;
