/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { IoMdLogOut } from "react-icons/io";
import UserDetailEditModal from "./UserDetailEditModal";
import "../../components/css/UserModal.css";
import { FaUser } from "react-icons/fa";

const UserModal = ({ handleLogoutClick, setIsUser }) => {
  const [userEditModal, setUserEditModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const buttonRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsUser(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsUser]);

  const handleCloseUserEditModal = () => {
    setUserEditModal(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsOpen(false);
  };

  const handleListMouseEnter = () => {
    setIsOpen(true);
  };

  const handleModalMouseLeave = () => {
    if (!isHovered) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className="position-relative"
      style={{
        width: window.innerWidth <= 1000 ? " 100% " : "",
      }}
    >
      <button
        ref={buttonRef}
        className="btn btn-outline-primary d-flex align-items-center text-light"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FaUser size={'20px'}/>
        <label
          style={{ fontSize: "12px", paddingLeft: "5px", cursor: "pointer", fontSize:'18px', margin:'0px' }}
        >
          {" "}
          {user && user.firstName ? user.firstName.toUpperCase() : "User"}
        </label>
      </button>
      {isOpen && (
        <div
          className="dropdown-menu dropdown-menu-right show rounded-0"
          onMouseEnter={handleListMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            position: window.innerWidth <= 1000 ? "relative" : "absolute",
            top: window.innerWidth <= 1000 ? "0px" : "40px",
            right: window.innerWidth <= 1000 ? "auto" : "0",
            width: window.innerWidth <= 1000 ? "100%" : "",
            border: window.innerWidth <= 1000 ? "none" : "",
          }}
        >
          <a className="dropdown-item disabled fonts-5">
            {user && user.firstName ? user.firstName.toUpperCase() : "User"}
          </a>

          <button
            onClick={() => setUserEditModal(true)}
            className="dropdown-item fonts-5 pointer items-align"
          >
            Edit Profile
          </button>
          <button
            onClick={handleLogoutClick}
            className="dropdown-item fonts-5 pointer bg-info text-light items-align"
          >
            Logout <IoMdLogOut size={"16px"} />
          </button>
        </div>
      )}
      {userEditModal && (
        <UserDetailEditModal
          userId={user._id}
          onClose={handleCloseUserEditModal}
        />
      )}
    </div>
  );
};

export default UserModal;
