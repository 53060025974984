import React from "react";
import "../../../components/css/ContentRenderer.css"
// import { AiFillDingtalkCircle } from "react-icons/ai";
import { GiTireIronCross } from "react-icons/gi";
import { BsFillEnvelopeCheckFill } from "react-icons/bs";
const ContentRenderer = ({ content, from }) => {
  // console.log("from",from);
  return (
    <div>
      <ul className="package-list_inex">
        {content.map((item, index) => (
          <li key={index} className="package-item_inex">
            {/* Render the header with icon */}
            <span className="header">
              {/* Conditionally render different icons based on the 'from' prop */}
              {from === 'Package Inclusion' ? (
                <BsFillEnvelopeCheckFill style={{color:"green", fontSize:"20px"}} />
              ) : (
                <GiTireIronCross style={{color:"red"}}/>
              )}{' '}
              {item.header}
            </span>
            {/* Check if subDetail exists */}
            {item.subDetail && (
              <ul className="sublist">
                {/* Render each subDetail as a bullet point with icon */}
                {item.subDetail.map((detail, index) => (
                  <li key={index}>
                    <i className="fas fa-chevron-right" style={{fontSize:"14px"}}></i> {detail}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <hr />
    </div>
  );
};

export default ContentRenderer;
