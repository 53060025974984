/* eslint-disable */
import React from "react";
// import { BsFillEnvelopeCheckFill } from "react-icons/bs";
import { FaCanadianMapleLeaf } from "react-icons/fa";
import { GiTireIronCross } from "react-icons/gi";
import "../../../components/css/PackageIncluExclu.css"
function PackageExclusion({ data }) {
  // console.log("Package Inclffgffusion", data);

  return (
    <>
      <div style={{marginLeft:"20px", marginTop:"20px"}} className="highlight">
        <h4 id="packageExclusion">Package Exclusion</h4>
        {data.map((item, index) => (
          <div className="point" key={index}>
            {/* Render header */}
            {item.header && (
              <p>
                <FaCanadianMapleLeaf
                  style={{ color: "red", fontSize: "20px" ,marginRight: "10px"}}
                />
                {item.header}
              </p>
            )}
            {/* Render subDetail if available */}
            {item.subDetail && (
              <ul style={{ paddingLeft: "20px" }}>
                {item.subDetail.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    <FaCanadianMapleLeaf style={{ color: "red",marginRight: "10px" }} />
                    {subItem}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default PackageExclusion;
