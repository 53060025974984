/* eslint-disable */
import React, { useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
// import "../../../components/css/PackageModal.css";
import { FcMediumPriority } from "react-icons/fc";

const HighlightModal = ({ isOpen, onClose, data }) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <Modal.Header closeButton className="packageModal-header">
        <Modal.Title className="packageModal-title">
          Tour Highlights
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ columnCount: 2, columnGap: "5px" }}>
       
          {data.map((item, index) => (
            <p
              key={index}
              className="packageModal-text"
              style={{ fontSize: "14px", fontWeight: "300" }}
            >
              <FcMediumPriority style={{ paddingRight: "0px" }} />
              {item}
            </p>
          ))}
       
      </Modal.Body>
      <Modal.Footer className="packageModal-footer">
        <Button
          variant="warning"
          onClick={onClose}
          className="packageModal-button"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HighlightModal;
