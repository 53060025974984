/* eslint-disable */
import React, { useState, useEffect } from "react";
import CarouselElastic from "../components/CarouselElastic";
import axios from "../axios";
// import startupindia from "../assests/DPIIT_Recognized_picTOURnic.webp";
import "../components/Home.css";
import Aos from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import ZigZag from "./ZigZag";
import MainPageCard from "./MainPageCard";
import { Row, Col, Container } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import Quotes from "./Quotes";
import MultiCarousel from "./MultiCarousel";
import UserReview from "./UserReview";
import Throbber from "./Pages/Throbber";
import LocationCarousel from "./LocationsCarousel";
import Packagerequest from "./Pages/Packagerequest";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import emailjs from "emailjs-com";
import Popup from "./Popup";
import PackageInformation from "./PackageInformation";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";
import ReactGa from "react-ga4";

// import SearchFilter from "./Pages/SearchFilter";
// import Stickybar from "../components/Pages/Stickybar";
// import ImageSection from "./Pages/ImageSection";
// import AndamanPackage from "./Pages/AndamanPackage";
import NewLaunches from "./Pages/NewLaunchs";
import Seperators from "./Pages/Seperators";
import ImageSection from "./Pages/ImageSection";


require("dotenv").config();

function Home() {
  //This is for animation.
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [show, setShow] = useState(false);
  const [loc, setLoc] = useState("");
  const [travelDate, setTravelDate] = useState("");
  const [guestNumber, setGuestNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [guestName, setGuestName] = useState("");
  const [email, setEmail] = useState("");

  const handleClose = () => {
    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    ReactGa.event({
      category: "Foreign Package Closed ",
      action: "Request Form open and closed",
      label: "User Did not fill the foreign package form",
    });
    setTravelDate("");
    setGuestNumber("");
    setMobile("");
    setGuestName("");
    setEmail("");
    setShow(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(event.target);
    var location = loc;
    var travel_date = travelDate;
    var guest_number = guestNumber;
    var mobile_number = mobile;
    var guest_name = guestName;
    var email_address = email;
    const formdata = {
      destination: "FOREIGN PACKAGE REQUEST" + location,
      checkin: travel_date,
      adults: guest_number,
      phonenumber: mobile_number,
      username: guest_name,
      email: email_address,
    };
    // eslint-disable-next-line
    if (
      // eslint-disable-next-line
      location != "" &&
      // eslint-disable-next-line
      travel_date != "" &&
      // eslint-disable-next-line
      guest_number !== "" &&
      // eslint-disable-next-line
      mobile_number.length == 10 &&
      // eslint-disable-next-line
      guest_name != ""
    ) {
      emailjs
        .send(
          process.env.REACT_APP_MAIL_SERVICE,
          process.env.REACT_APP_MAIL_TEMPLATE,
          formdata,
          process.env.REACT_APP_MAIL_USER
        )
        .then(
          (result) => {
            if (result.text === "OK") {
              ReactGa.initialize(process.env.REACT_APP_GA_ID);
              ReactGa.event({
                category: "Foreign Package RequestRaised ",
                action: mobile_number + " for " + location,
                label: "User Filled and submitted Foreign Package request",
              });
              alert(
                "Your request has been Sent Succesfully . Our Executive will get in touch soon related to your query."
              );
            }
          },
          (error) => {
            console.log(error.text);
            //  ERROR HANDLING
          }
        );

      setTravelDate("");
      setGuestNumber("");
      setMobile("");
      setGuestName("");
      setEmail("");
      setShow(false);
    } else {
      alert("please fill Requested Fields");
    }
  };

  // Being used for fetching data for the home page .
  //Here the main Categories will be extracted from backend server
  const [mainPageOptions, setmainPageOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(process.env.REACT_APP_DB_URL + "/");
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // const normarray = ;
        // console.log()
        setmainPageOptions(
          req.data.sort((a, b) => a.displayOrder - b.displayOrder)
        );
        setLoading(true);
        // console.log("The data set here is ", req.data)
      } catch (error) {
        // console.log('Error is')
        console.error(error);
      }
    }
    fetchData();
  }, []);
  const [pop_up, setpop_up] = useState("");
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("popup"));
    if (items == "subscribed") {
      setpop_up(items);

      // alert(items);
    }
  }, []);

  //Fetch the Reviews from the backend
  const [review, setReviews] = useState([]);
  useEffect(() => {
    async function fetchReviews() {
      try {
        // console.log('Calling DB')
        const reviewReq = await axios.get(
          process.env.REACT_APP_DB_URL + "/reviews"
        );
        // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        // await sleep(2000)
        // console.log(reviewReq.data)
        setReviews(reviewReq.data);
      } catch (error) {
        // console.log('Error is')
        console.error(error);
      }
    }
    fetchReviews();
  }, []);

  //Fetch Locations for Location Page

  const [locationprop, setLocation] = useState([]);
  useEffect(() => {
    async function fetchLocation() {
      try {
        // console.log('Calling DB')
        const locationReq = await axios.get(
          process.env.REACT_APP_DB_URL + "/locations"
        );
        // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        // await sleep(2000)
        // console.log(locationReq.data);
        setLocation(locationReq.data);
      } catch (error) {
        // console.log('Error is')
        console.error(error);
      }
    }
    fetchLocation();
  }, []);
  var locationsUnique = [];

  if (locationprop !== "") {
    locationprop.map((item) => {
      // console.log('checking ' , item.mianloc.split(',')[0].replace('#',''), 'in array ',locationsUnique )
      // eslint-disable-next-line
      if (locationsUnique.indexOf(item.proplocation) == -1) {
        var value = item.proplocation;

        locationsUnique.push(value);
      }
    });
  }
  // console.log(locationsUnique);

  return (
    <>
      <Helmet>
        <title>Plan your Picnic and Tour</title>
        <meta
          name="keywords"
          content="International Packages, Singapore Package, Malaysia Package, Thailand package, Indonesia package,United Arab Emirates Package,
          Australia Package, PICNIC NEAR ME, STAY NEAR ME,architecture ,sky ,me ,
          instalike ,traveller ,followme ,photoshoot ,naturelovers ,music,traveler ,mountains ,follow ,summer ,
          naturelovers ,beautifuldestinations ,vacation ,instadaily ,traveladdict ,traveller ,streetphotography ,
           ,beach ,architecture ,likeforlikes ,canon ,sky ,photoshoot ,holiday ,sea ,fashion ,travelblog ,
          happy ,italia ,tourism ,travelphoto ,portrait ,incredibleindia,canon ,photoshoot ,follow ,instadaily ,of ,
          likeforlikes ,india ,captures ,travelgram ,hiking ,wanderlust ,summer ,explore ,bird ,adventure ,happy ,
          fashion ,natur ,animals ,photographylovers ,forest ,model ,clouds ,nikon ,world ,mobilephotography ,beauty ,
          style ,birdphotography ,green,,naturelovers ,nature ,naturephotography ,photography ,landscape ,photooftheday ,
          travel ,ig ,perfection ,love ,naturelover ,instagood ,travelphotography ,wildlife ,landscapephotography ,sunset ,
          photo ,flowers ,beautiful ,picoftheday ,mountains ,sky ,instagram ,birds ,macro ,hiking ,wildlifephotography ,
          photographer ,capturesfamily ,love ,friends ,happy ,instagood ,photography ,life ,baby ,familytime ,photooftheday ,fun ,
          like ,instagram ,kids ,follow ,cute ,beautiful ,smile ,nature ,travel ,picoftheday ,summer ,fashion ,happiness ,art ,food ,lifestyle ,momlife ,home
          ,homestay 
          "
        />
      </Helmet>
      {loading ? (
        <>
          <div>
            {/* <SearchFilter /> */}

            {/* <Stickybar /> */}
            {/* <SvgComponent /> */}

            <CarouselElastic options={mainPageOptions} />
            {/* <br></br> */}
            <Seperators />
            <NewLaunches />
            {/* <center>
            <div className="banner_div">
              <div className="container-fluid">
                <br></br>
                <span className="stamp is-approved">
                  Every booking
                  <br></br>gain Reward points
                </span>
                <br></br>
              </div>
            </div>
          </center> */}
            {pop_up ? "" : <Popup />}
            {/* <ZigZag /> */}

            {/* <PageIntro /> */}
            {/* <Option options={mainPageOptions} /> */}
            {/* <Container style={{backgroundImage: `url(`+quotebck+`)`, height:"auto", color:"white", marginTop:"20px", marginBottom:"20px" }}>
        <div style={{backgroundColor: "rgba(15, 13, 11, 0.769)", borderRadius:"10px"}}>
          <Row >
            <Col sm={12}>
              <blockquote style={{textAlign:"justify", fontFamily:"sans-serif", padding:"20px"}}>
                “My Grandfather used to say that once in your lifetime you need a doctor, a lawyer, a policeman and a preacher. But everyday, three times a day you need a Farmer"
                <span style={{float:"right"}}><br/><br/>-Brenda Schoepp</span>
            </blockquote></Col>
          </Row>
      </div>
    </Container> */}
            {/* <Quotes /> */}
            {/* <span class="stamp">proposal</span> */}
            {/* <span class="stamp is-nope">Declined</span> */}

            {/* <span class="stamp is-draft">Draft</span> */}

            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body>
                <Modal.Header closeButton>
                  Please Share your details! Our Executive will connect with you
                  ASAP.
                </Modal.Header>
                <br></br>
                <Label style={{ position: "absolute", right: "10%" }}>
                  <span style={{ color: "red", position: "relative" }}>
                    * Fields are mandatory
                  </span>
                </Label>
                <br></br>
                <Form onSubmit={handleSubmit}>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={2}>
                      Tour Location
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="location"
                        value={loc}
                        id="location"
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={2}>
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          left: "0%",
                        }}
                      >
                        *
                      </span>
                      Travel Date
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="date"
                        name="date"
                        id="date"
                        placeholder="Enter Travel date"
                        required
                        min={new Date().toISOString().split("T")[0]}
                        value={travelDate}
                        onChange={(e) => {
                          setTravelDate(e.target.value);
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={2}>
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          left: "0%",
                        }}
                      >
                        *
                      </span>
                      Guests#
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="number"
                        name="guests"
                        id="guests"
                        placeholder="Number of guests"
                        required
                        value={guestNumber}
                        onChange={(e) => {
                          setGuestNumber(e.target.value);
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="examplePassword" sm={2}>
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          left: "0%",
                        }}
                      >
                        *
                      </span>{" "}
                      Mobile
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="mobile"
                        id="mobile"
                        placeholder="Please enter your 10 digit Mobile number"
                        required
                        pattern="[0-9]{10}"
                        value={mobile}
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={2}>
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          left: "0%",
                        }}
                      >
                        *
                      </span>
                      Guest Name
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="guestname"
                        id="guestname"
                        placeholder="Guest Name"
                        required
                        value={guestName}
                        onChange={(e) => {
                          setGuestName(e.target.value);
                        }}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="exampleEmail" sm={2}>
                      Email
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="user@gmail.com"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </Col>
                  </FormGroup>

                  {/* <FormGroup check row> */}
                  <Col sm={{ size: 10, offset: 2 }}>
                    {/* <Button
                style={{ margin: "2px", backgroundColor: "mediumseagreen" ,border:"none"}}
                onClick={handleSubmit}
              >
                Submit
              </Button> */}
                    <input
                      style={{
                        margin: "2px",
                        backgroundColor: "mediumseagreen",
                        fontWeight: "400",
                        border: "none",
                        color: "white",
                        borderRadius: "0.25rem",
                        lineHeight: "1.5",
                        fontSize: "1rem",
                        padding: "0.375rem 0.75rem",
                      }}
                      type="submit"
                    ></input>
                    <Button
                      style={{
                        margin: "2px",
                        backgroundColor: "red",
                        border: "none",
                      }}
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </Col>
                  {/* </FormGroup> */}
                </Form>
              </Modal.Body>
            </Modal>

            <div
              className="container-fluid"
              style={{ backgroundColor: "#f7f7f7", padding: "20px" }}
            >
              <h4 className="MainpackHeading">
                EXPLORE BEAUTIFUL STAYS IN INDIA <br />
              </h4>
              <h5 className="subpackheading"
                // style={{
                //   textAlign: "center",
                //   color: "Black",
                //   fontSize: "12px",
                // }}
              >
                Hand picked most beautiful places for Weekend Travel, Stays,
                Corporate Events, Pre wed Shoots & many more.<br></br> Explore
                to Extreme with Us
                {/* <i
                  style={{ color: "#8b0000", fontSize: "20px" }}
                  className="fas fa-grin-wink"
                ></i> */}
              </h5>
            </div>

            <div className="container-fluid">
              
              <Row xs="1" sm="2" md="3" lg="5">
                <MainPageCard options={mainPageOptions} />
              </Row>
            </div>
            <br></br>
            <div className="container-fluid">
              <LocationCarousel options={locationsUnique} />
            </div>
            {/* <Container style={{ boxShadow: "none" }}>
            <center>
              <h1 style={{ color: "mediumseagreen" }}>Andaman Tour Packages</h1>
              <h6>
                If you are looking for a memorable Andaman trip, picTOURnic
                holiday packages are the right choice for you. picTOURnic offers
                the widest range of customizable Andaman tour to suit every kind
                of traveler{" "}
              </h6>
            </center>
            <Row>
              <Col
                xs={12}
                onClick={() => {
                  setShow(true);
                  ReactGa.initialize(process.env.REACT_APP_GA_ID);
                  ReactGa.event({
                    category: "Foreign Package Clicked ",
                    action: "for Andaman & Nicobar Islands",
                    label: "User Filled and submitted Foreign Package request",
                  });
                  setLoc("Andaman & Nicobar Islands");
                }}
              >
                <div className="box1">
                  <div className="Imagebox">
                    
                    <LazyLoadImage
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "400px",
                      }}
                      alt={"Andaman Islands Package"}
                      effect="blur"
                      src="Andaman_packv1.webp"
                    />
                   
                  </div>
                </div>
              </Col>
            </Row>
          </Container> */}
            <div id="aboutInternational"></div>
            {/* <MultiCarousel /> */}
            <div className="container_Pack">
            {/* <AndamanPackage /> */}
            {/* Foreign Package Request */}
            <ImageSection />
            
          </div>

            {/* <PackageInformation /> */}
            {/* <Packagerequest /> */}
            {/* <Quotes /> */}
            {/* <div style={{ position: "relative" }}> */}
            {/* <UserReview reviews={review} /> */}
            {/* <div className="startup">
              <img
                src={startupindia}
                alt="start up india pictournic"
                style={{
                  top: "-22px",
                  width: "15%",
                  height: "49%",
                  position: "absolute",
                  left: "65px",
                }}
              />
            </div> */}
            {/* </div> */}
            {/* <ContactusForm /> */}
            {/* <div className="container" style={{boxShadow:"none"}} >
            <div className="wrapper">
          <object
          
            data={pdf  + "#toolbar=0"}
            type="application/pdf"
            width="100%"
            style={{height:"30rem"}}
            oncontextmenu="return false;"
          >
            <p>
              Alternative text - include a link{" "}
              <a href="http://africau.edu/images/default/sample.pdf">
                to the PDF!
              </a>
            </p>
          </object>
          <div class="embed-cover"></div>
          </div>
          </div> */}

            {/* <div className="showdesk">
              <div className="container-fluid" style={{ boxShadow: "none" }}>
                <div className="" style={{ textAlign: "center" }}>
            
                  <div className="startup">
                    <img
                      src={startupindia}
                      alt="start up india pictournic"
                      style={{
                  
                        width: "16%",
                        height: "39%",
                        margin: "auto",
                        
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="showmob">
              <div className="container-fluid" style={{ boxShadow: "none" }}>
                <div className="" style={{ textAlign: "center" }}>
                  
                  <div className="startup">
                    <img
                      src={startupindia}
                      alt="start up india pictournic"
                      style={{
                     
                        width: "60%",
                        height: "39%",
                        margin: "auto",
                    
                      }}
                    />
                  </div>
                </div>
              </div>
            </div> */}



          </div>
        </>
      ) : (
        <Throbber />
      )}
    </>
  );
}

export default Home;
