import axios from 'axios'
require('dotenv').config();
const instance = axios.create({
    baseURL:process.env.REACT_APP_DB_URL,
    // headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Headers': '*',
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //  },
});

export default instance