import React from "react";

function Price({ options }) {
  return (
    <>
      {/* <div
        className="container"
        style={{ boxShadow: "none", backgroundColor: "#ee932c14" }}
      >
        <div>
          <h4 style={{ color: "brown" }}>Price Starts From</h4>
        </div>
        <div className="col">
          {options.map((item, index) => (
            <p
              key={index}
              style={{ fontSize: "large", color: "Black", fontWeight: "300" }}
            >
              <i
                className="fas fa-rupee-sign"
                style={{ color: "black", margin: "5px" }}
              ></i>
              <span className="color">*</span>
              {item}
            </p>
          ))}
          <span className="color">*</span><span>Prices mentioned above are indicative only and subject to change, depending on seasons, holidays and sole discretion of property. </span>
        </div>
      </div> */}
      <div className={`description-container`}>
        <div>
          <h4 style={{ color: "brown" }}>Price Starts From</h4>
        </div>

        <div className="option">
          <i
            className="fas fa-rupee-sign"
            style={{ color: "black", margin: "5px"  , display: "inline-block", }}
          ></i>
          <div
            style={{
              fontSize: "large",
              color: "black",
              fontWeight: "300",   display: "inline-block",
            }}
          >
            {options}
            
          </div>
          
        </div>
        <br></br>
        <span className="color">*</span><span>Prices mentioned above are indicative only and subject to change, depending on seasons, holidays and sole discretion of property. </span>
      </div>
    </>
  );
}

export default Price;
