import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import '../../components/css/SubHeaderNav.css'; // Import your CSS file

const SubHeaderNav = () => {
  return (
    <div className="subheadernav">
    <Navbar expand="lg" bg="light" variant="light">
      <Nav className="mr-auto">
        <Nav.Link  target="_blank" style={{ fontSize:"18px"}} href="https://www.google.com/maps/place/Pictournic/@19.7304837,61.0071663,4z/data=!3m1!4b1!4m6!3m5!1s0x3bae13535449364d:0xe3a111ea9f7cb510!8m2!3d21.0686228!4d82.7525294!16s%2Fg%2F11q9m8ksfn?entry=ttu">
         Review us on Google
        </Nav.Link>
        
      </Nav>
      <Nav className="mr-auto">
        <Nav.Link  style={{ fontSize:"18px"}} href="/packages/package%20tour">
        Packages<sup style={{ color: "yellow", fontWeight: "700" }}> NEW</sup>
        </Nav.Link>
        
      </Nav>
      {/* <ReactBootstrap.Nav.Link
                href=""
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontWeight: "400",
                }}
              >
                Packages
                
              </ReactBootstrap.Nav.Link> */}
    </Navbar>

    <br></br>
  </div>
  );
};

export default SubHeaderNav;
