import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import FloatingLabel from "./FloatingLabel";
import countryCodes from "./StaticArrays";
import PasswordStrengthChecker from "./PasswordStrengthChecker";
import CustomToast from "../../components/Pages/CustomToast.js";
// import TourLogoSignup from "../../Image/tour_icon2.jpg";
import { FaSpinner } from "react-icons/fa";
import FloatingLabel from "./FloatingLabel";

const RegisterPage = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phoneNo: "",
    countryCode: "+91",
    birthdate: "",
    mailId: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  //   const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();

    if (userData.firstName.trim() === "" || userData.lastName.trim() === "") {
      toast.error("Please enter your first and last name");
      return;
    }

    if (userData.phoneNo.trim() === "") {
      toast.error("Please enter your Phone no");
      return;
    }

    if (userData.confirmPassword !== userData.password) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      setLoading(true);

      const userdata = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNo: userData.phoneNo,
        countryCode: userData.countryCode,
        birthdate: userData.birthdate,
        mailId: userData.mailId,
        password: userData.password,
        confirmPassword: userData.confirmPassword,
      };

      const response = await fetch(
        `${process.env.REACT_APP_DB_URL}/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userdata),
          credentials: "include",
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to register");
      }

      const { user } = data.data;
      localStorage.setItem("user", JSON.stringify(user));
      window.location.href = "/";
      setLoading(false);

      setTimeout(() => {
        toast.custom((t) => (
          <CustomToast
            userName={user.firstName}
            message={
              "Welcome to Pictounic official website, Check out latest Packages!!"
            }
            onClose={() => toast.dismiss(t.id)}
          />
        ));
      }, 2000);

      toast.success("Registered successfully!");

      setUserData({
        firstName: "",
        lastName: "",
        phoneNo: "",
        countryCode: "+91",
        birthdate: "",
        mailId: "",
        password: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.error("Registration error:", error);
      toast.error(
        error.message === "Failed to fetch"
          ? "Server is not responding: Please try Later"
          : error.message || "Registration failed. Please try again."
      );
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login-page-container">
       
        <div className="login-page-section">
          <div className="login-image-container">
            <img src={"/tour_icon2.jpg"} alt="Workations Pictournic" />
          </div>
          <div className="signup-form-container">
            <form className="login-form" onSubmit={handleRegister}>
              <div
                className="input-container"
                style={{
                  flexDirection: window.innerWidth <= 600 ? "column" : "",
                  width: window.innerWidth <= 600 ? "100%" : "",
                }}
              >
                <div className="input-pair">
                  <FloatingLabel
                    id="firstName"
                    label="First Name"
                    type="text"
                    autoComplete="given-name"
                    required
                    name="firstName"
                    value={userData.firstName}
                    handleChange={handleChange}
                  />
                </div>
                <div className="input-pair">
                  <FloatingLabel
                    id="lastName"
                    label="Last Name"
                    type="text"
                    autoComplete="family-name"
                    name="lastName"
                    value={userData.lastName}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <div
                className="input-container"
                style={{
                  width: window.innerWidth <= 600 ? "100%" : "",
                }}
              >
                <select
                  style={{
                    maxWidth: "110px",
                    maxHeight: "49px",
                    fontSize: "12px",
                  }}
                  className="form-dropdown"
                  name="countryCode"
                  onChange={handleChange}
                  value={userData.countryCode}
                >
                  {countryCodes.map((country) => (
                    <option key={country.id} value={country.code}>
                      {country.country} ({country.code})
                    </option>
                  ))}
                </select>
                <div className="input-pair">
                  <FloatingLabel
                    id="phoneNo"
                    label="Phone Number"
                    type="number"
                    autoComplete="tel"
                    required={true}
                    name="phoneNo"
                    value={userData.phoneNo}
                    handleChange={handleChange}
                  />
                </div>
              </div>

              <div className="input-pair">
                <FloatingLabel
                  id="mailId"
                  label="Email Address"
                  type="email"
                  autoComplete="email"
                  required={true}
                  name="mailId"
                  value={userData.mailId}
                  handleChange={handleChange}
                />
              </div>
              <div
                className="input-container"
                style={{
                  flexDirection: window.innerWidth <= 600 ? "column" : "",
                  width: window.innerWidth <= 600 ? "100%" : "",
                }}
              >
                <div className="input-pair">
                  <FloatingLabel
                    id="password"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    required
                    name="password"
                    value={userData.password}
                    handleChange={handleChange}
                  />
                </div>

                <div className="input-pair">
                  <FloatingLabel
                    id="confirmPassword"
                    label="Confirm Pass.."
                    type="password"
                    autoComplete="new-password"
                    required
                    name="confirmPassword"
                    value={userData.confirmPassword}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <PasswordStrengthChecker
                password={userData.password}
                setPassword={(value) =>
                  setUserData({ ...userData, password: value })
                }
              />
              <div
                className="input-container"
                style={{
                  width: window.innerWidth <= 600 ? "100%" : "",
                }}
              >
                <div className="flex-1 input-pair">
                  <FloatingLabel
                    id="birthdate"
                    label="Birthdate"
                    type="date"
                    autoComplete="bday"
                    name="birthdate"
                    value={userData.birthdate}
                    handleChange={handleChange}
                  />
                </div>
                <button
                  style={{ width: "50%" }}
                  type="submit"
                  className="blue-button"
                >
                  {loading ? (
                    <>
                      <FaSpinner className="loading-button-icon" />
                      &nbsp; Registering..
                    </>
                  ) : (
                    "Register"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
