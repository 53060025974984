import React from "react";
// import Carousel from "react-bootstrap/Carousel";
import "../components/CarouselElastic.css";
import "../components/Quotes.css";
import pk1 from "../assests/Packages/1.jpeg";
import pk2 from "../assests/Packages/2.jpeg";
import pk3 from "../assests/Packages/3.jpeg";
import pk4 from "../assests/Packages/4.jpeg";
import pk5 from "../assests/Packages/5.jpeg";
import pk6 from "../assests/Packages/6.jpeg";
import "../components/multiCarousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const MultiCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="typrwriter">
        {/* <Typewriter
          options={{ loop: true, autoStart: true }}
          onInit={(typewriter) => {
            // typewriter
            //   .typeString("Packages By Pictournic! Grab it !!")
            //   .pauseFor(2500)
            //   .deleteAll();
            typewriter
              .typeString("Package at Best Deal!  9663803335")
              .pauseFor(10500)
              .start();
          }}
        /> */}
        <hr></hr>
        <h4 style={{ textAlign: "center", color: "brown" }}>
          Package at Best Deal! <br></br> Contact us at +91-9663803335{" "}
          <i className="fas fa-handshake" style={{ color: "brown" }}></i>{" "}
        </h4>
      </div>
      <Carousel
        responsive={responsive}
        infinite={true}
        swipeable={true}
        showDots={true}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        autoPlay={true}
        autoPlaySpeed={3000}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-10-px"
        containerClass="carousel-container"
        transitionDuration={2000}
      >
        <div className="multicarousel">
          <div className="container-fluid">
            <div className="box">
              {/* <p className="marvel" style={{ color: "White" }}></p> */}
              <img src={pk1} className="model" alt="plan Picnic or Tour"></img>
              <div className="details">
                {/* <img src={Sakleshpur} className="logo" style={{width:"100px", height:"auto"}}></img> */}
              </div>
            </div>
          </div>
        </div>
        <div className="multicarousel">
          <div className="container-fluid">
            <div className="box">
              {/* <p className="marvel" style={{ color: "White" }}></p> */}
              <img src={pk2} className="model" alt="plan Picnic or Tour"></img>
              <div className="details">
                {/* <img src={Sakleshpur} className="logo" style={{width:"100px", height:"auto"}}></img> */}
              </div>
            </div>
          </div>
        </div>
        <div className="multicarousel">
          <div className="container-fluid">
            <div className="box">
              {/* <p className="marvel" style={{ color: "White" }}></p> */}
              <img src={pk3} className="model" alt="plan Picnic or Tour"></img>
              <div className="details">
                {/* <img src={Sakleshpur} className="logo" style={{width:"100px", height:"auto"}}></img> */}
              </div>
            </div>
          </div>
        </div>
        <div className="multicarousel">
          <div className="container-fluid">
            <div className="box">
              {/* <p className="marvel" style={{ color: "White" }}></p> */}
              <img src={pk4} className="model" alt="plan Picnic or Tour"></img>
              <div className="details">
                {/* <img src={Sakleshpur} className="logo" style={{width:"100px", height:"auto"}}></img> */}
              </div>
            </div>
          </div>
        </div>
        <div className="multicarousel">
          <div className="container-fluid">
            <div className="box">
              {/* <p className="marvel" style={{ color: "White" }}></p> */}
              <img src={pk5} className="model" alt="plan Picnic or Tour"></img>
              <div className="details">
                {/* <img src={Sakleshpur} className="logo" style={{width:"100px", height:"auto"}}></img> */}
              </div>
            </div>
          </div>
        </div>
        <div className="multicarousel">
          <div className="container-fluid">
            <div className="box">
              {/* <p className="marvel" style={{ color: "White" }}></p> */}
              <img src={pk6} className="model" alt="plan Picnic or Tour"></img>
              <div className="details">
                {/* <img src={Sakleshpur} className="logo" style={{width:"100px", height:"auto"}}></img> */}
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      <div
        className="container-fluid"
        style={{ boxShadow: "none", textAlign: "center" }}
      >
        {/* <div className="section">
          <div
            style={{
              margin: "auto",
              width: "50%",
              // border: "3px solid green",
              // padding: "10px",
              position: "absolute",
              top: "50vh",
              // textAlign:"center"
              left:"50vh"
            }}
          >
            <h1 style={{ zIndex: "100" }}>
              Singapore, Malaysia, Thailand, Indonesia, United Arab Emirates,
              Australia, Greece
            </h1>
          </div>
        </div> */}
        
      </div>
      ;
    </>
  );
};

export default MultiCarousel;
