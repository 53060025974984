/* eslint-disable */
import { React, useEffect } from "react";
import "./MainPageCard.css";
import { withRouter } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const RemainingCatCards = ({ options }) => {

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 1) + '...';
    }
    return text;
  };


  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  if (options == "") {
    // console.log('its empty')
    var data_set = [];
    // console.log(options)
  } else {
    // console.log(options)
    data_set = options;
  }
  return (
    <>
      {data_set.map((everyoption, i) => (
        <div className="col-md-4" key={i}>
          <a href={everyoption[0]} className="blog-link">
            <div className="blog" key={i}>
              <center>
                <LazyLoadImage
                  style={{ objectFit: "cover", width: "100%" }}
                  alt={"not available"}
                  effect="blur"
                  src={"/" + everyoption[1]}
                />
              </center>

              <div className="blog-content" key={i}>
                <center>
                  <p>{everyoption[0]}</p>
                </center>
                <center>
                  <h6>{truncateText(everyoption[3],60)}</h6>
                </center>
              </div>

              <center key={everyoption.categoryid}>
                <div className="blog-post__cta">EXPLORE</div>
              </center>
            </div>
          </a>
        </div>
      ))}
    </>
  );
};

export default withRouter(RemainingCatCards);
