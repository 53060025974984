/* eslint-disable */
import React, { useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { withRouter } from "react-router-dom";
import axios from "../../axios";
import { Row } from "reactstrap";
import Back from "../Back";
import CardsSubLocation from "./CardsSubLocation";
import Throbber from "../Pages/Throbber";
import { Helmet } from "react-helmet";
import AllLocationCards from "./AllLocationCards";
// import SingleCard from "../Card";
import PropertiesCard from "../PropertiesCard";
require("dotenv").config();
function SubLocations(props) {
  // console.log(props);
  var derivedurl = props.match.url;

  // alert(URL)s
  // alert(derivedurl)
  var parLoc = props.match.params.locationname;
  // alert("parloc is ", parLoc);

  var parCat = props.match.params.categoryname;
  const [loading, setLoading] = useState(false);
  const [sublocation, setSublocations] = useState([]);

  const [derivedid, derivedsetId] = useState(0);

  const [mainPageOptions, setmainPageOptions] = useState([]);
  const [propMainPageOptions, propSetmainPageOptions] = useState([]);

  useEffect(() => {
    async function fetchSubLocationData() {
      try {
        // console.log("Calling DB for CategoryNames Page");
        const req = await axios.get(
          process.env.REACT_APP_DB_URL +
            "/filter/locationdetails/" +
            parLoc +
            "/" +
            parCat
        );
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);

        // console.log('the Sublocationdats set is ')
        // console.log(req.data)
        // setPropLocation(dataset)
        var dataset_ = req.data;
        setSublocations(dataset_.slice(0, -1));
        var key = dataset_.length;
        // console.log("NOWWWWWWWW", key);
        // console.log(dataset_[key - 1]);
        derivedsetId(dataset_[key - 1]);
        setLoading(false);
        // console.log(dataset_)
        // setLoading(true);
      } catch (error) {
        // console.log("Error is", error.response.status);
        // console.error(error);
        setLoading(false);
        // setLoading(true);
        // seterrorPage(error.response.status);
      }
    }
    fetchSubLocationData();
    //eslint-disable-next-line
  }, []);

  // console.log(this.props.location.state)

  useEffect(() => {
    async function fetchDataForLocationaAll() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(
          process.env.REACT_APP_DB_URL + "/locationsall"
        );
        // console.log("For all locations", req.data);
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(2000);
        // console.log("Earlier Array", req.data);
        var req_arr = req.data;
        var temp_arr = [];
        req_arr.map((item) => {
          if (item !== parLoc) {
            temp_arr.push(item);
          }
        });
        // console.log("Now the Array is", temp_arr);

        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(2000);
        // setmainPageOptions(req.data);
        // setLoading(true);
        setmainPageOptions(temp_arr);
        // setLoading(true);
      } catch (error) {
        // console.log('Error is')
        // console.error(error);
        setLoading(false);
      }
    }
    fetchDataForLocationaAll();
  }, []);

  useEffect(() => {
    async function fetchAllPropertyDataForLocationAndCategoryName() {
      try {
        // console.log('Calling DB')
        const req = await axios.get(
          process.env.REACT_APP_DB_URL +
            "/for/allproperties/getdata/" +
            parLoc +
            "/" +
            parCat
        );
        // console.log("For all locations", req.data);
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        // await sleep(1000);
        // console.log("Earlier Array", req.data)
        var req_arr = req.data;

        // console.log("Now the Array is", req_arr);

        const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await sleep(2000);
        // setmainPageOptions(req.data);
        // setLoading(true);
        propSetmainPageOptions(req_arr.sort((a, b) => b.rating - a.rating));
        setLoading(true);
      } catch (error) {
        // console.log('Error is')
        // console.error(error);
        setLoading(false);
        window.location = "/";
        alert(
          "Apologies from picTournic. This Property is not available for booking. Redirecting to home page"
        );
      }
    }
    fetchAllPropertyDataForLocationAndCategoryName();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <Helmet>
            <title>
              {parLoc} {parCat}
            </title>
          </Helmet>

          {/* For Displaying the Sub Location for a State */}
          {(() => {
            if (sublocation.length > 0) {
              return (
                <>
                  <h2 style={{ textAlign: "center", color: "brown", fontSize:"18px" }} >
                    <br></br>
                    pic{" "}
                    <span style={{ color: "green" }}>
                      <span style={{ color: "green" }}></span> {parLoc}
                    </span>
                  </h2>
                  <div
                    className="container-fluid"
                    style={{ boxShadow: "none" }}
                  >
                    <div className="row text-center">
                      <CardsSubLocation
                        options={sublocation.sort()}
                        derivedurl={derivedurl}
                        derivedid={derivedid}
                        location={parLoc}
                      />
                    </div>
                  </div>
                </>
              );
            }

            return null;
          })()}

          {/* <div className="container" style={{ boxShadow: "none" }}></div> */}
          <div className="container" style={{ boxShadow: "none" }}>
            <center>
              <br></br>
              <hr></hr>
              <br></br>
              <h2 style={{ color: "brown" ,fontSize:"18px" }}>
              {/* <br></br> */}
                pic <span style={{ color: "green" }}> {parCat}</span>
              </h2>
              <h2>
                <span style={{ color: "green" }}>
                  
                  {parLoc}
                </span>
              </h2>
            </center>
          </div>

          {/* <div className="conatiner-fluid">
            <Row xs="1" sm="2" md="3" lg="4">
              <SingleCard options={propMainPageOptions} />
            </Row>
          </div> */}

          <div className="container-fluid">
            <Row xs="1" sm="2" md="4" lg="5">
              {/* <SingleCard  options={propertiesList}/> */}

              <PropertiesCard options={propMainPageOptions} />
            </Row>
          </div>
          <br></br>
          <hr></hr>
         
          <h4
            style={{ textAlign: "center", color: "brown", marginTop: "20px" }}
          >
            EXPLORE Locations Across INDIA with picTOURnic <br />
          </h4>
          <h5 style={{ textAlign: "center", color: "brown", fontSize:"12px"}} >
            Hand picked most beautiful places for Weekend Travel, Stays,
            Corporate Events, Pre wed Shoots & many more.<br></br> Explore to
            Extreme with Us{" "}
            {/* <i
              style={{ color: "#8b0000", fontSize: "20px" }}
              className="fas fa-grin-wink"
            ></i> */}
          </h5>

          <div className="container-fluid" style={{ boxShadow: "none" }}>
            <div className="row text-center">
              <AllLocationCards options={mainPageOptions.sort()} />
            </div>
          </div>
        </>
      ) : (
        <Throbber />
      )}
      <Back />
    </>
  );
}

export default withRouter(SubLocations);
