import React from "react";
import { Card } from "reactstrap";
// import * as ReactBootstrap from "react-bootstrap";
// import { Link } from "react-router-dom";

function AllLocationsCategory({ options, maincat }) {
  return (
    <>
      {options.map((everyoption, index) => (
        <a
          href={"filter/locationdetails/" + everyoption + "/" + maincat}
          key={index}
          style={{ textDecoration: "none" }}
        >
          <Card
            key={index}
            style={{
              width: "150px",
              height: "150px",
              margin: "5px",
              backgroundImage:
                "url(/" + everyoption.replace(/ /g, "_") + "_picTOURnic.webp)",
              backgroundSize: "cover",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.4)", // Darker background
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  color: "white",
                  textAlign: "center",
                  zIndex: 1,
                }}
              >
                {everyoption === "Andaman and Nicobar Islands"
                  ? "Andaman & Nicobar"
                  : everyoption}
              </h6>
            </div>
          </Card>
        </a>
      ))}
    </>
  );
}

export default AllLocationsCategory;
